import { Directive, Input, HostListener } from '@angular/core';
import { MatDialogRouterService } from '@bli/services/mat-dialog-router.service';

@Directive({
  selector: '[matRouterLink]'
})
export class MatDialogRoutingDirective {
  @Input('matRouterLink') routerLink: string;
  @HostListener('click', ['$event']) onClick($event) {
    this.navigate();
  }
  constructor(private router: MatDialogRouterService) {}
  navigate() {
    this.router.navigate(this.routerLink);
  }
}
