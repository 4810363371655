import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { FormField } from '../../../../../state/app.model';

@Component({
  selector: 'app-email-field-foster',
  templateUrl: './email-field-foster.component.html',
  styleUrls: ['./email-field-foster.component.scss']
})
export class EmailFieldFosterComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  chipControl: UntypedFormControl = new UntypedFormControl(
    '',
    Validators.email
  );
  @Input() emailCtrl: UntypedFormControl;
  @Input() field: FormField;
  @Input() value: string;
  @ViewChild('emailInput', { static: true })
  emailInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('chipList', { static: true }) chipList: MatChipGrid;
  filteredEmail: Observable<string[]>;
  emails: string[];
  allEmails: string[] = [];

  ngOnInit() {
    this.filteredEmail = this.chipControl.valueChanges.pipe(
      // startWith(null),
      map((email: string | null) => {
        if (this.chipControl.touched && this.chipControl.invalid) {
          this.chipList.errorState = true;
        } else {
          this.chipList.errorState = false;
        }
        return email ? this._filter(email) : this.allEmails.slice();
      })
    );
    this.emails = this.emailCtrl.value ? this.emailCtrl.value.split(',') : [];
    this.addValidation();
  }

  addValidation() {
    this.chipControl.clearValidators();
    if (this.emails.length) {
      this.chipControl.setValidators(Validators.email);
      return;
    }
    if (this.field.mandatory) {
      this.chipControl.setValidators([Validators.required, Validators.email]);
      return;
    }
    this.chipControl.setValidators(Validators.email);
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    // Add email
    if ((value || '').trim()) {
      // this.emails.push(value.trim());
      this.valudateAndPushEmail(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.chipControl.setValue(null);
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.setEmailList();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.valudateAndPushEmail(event.option.viewValue);
    this.emailInput.nativeElement.value = '';
    this.chipControl.setValue(null);
  }

  setEmailList() {
    const emails = this.emails.join(',');
    this.emailCtrl.setValue(emails);
    this.addValidation();
  }

  valudateAndPushEmail(email: string) {
    const ctrl = new UntypedFormControl(email, Validators.email);
    if (this.emails.includes(email) || ctrl.invalid) {
      return;
    }
    this.emails.push(email);
    this.setEmailList();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allEmails.filter(
      fruit => fruit.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
