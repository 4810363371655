import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SnackBarStore, SnackBarState } from './snack-bar.store';

@Injectable({ providedIn: 'root' })
export class SnackBarQuery extends Query<SnackBarState> {
  constructor(protected store: SnackBarStore) {
    super(store);
  }

  readonly actionClicked$ = this.select(s => s.actionClicked);
}
