import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { OperationalIntellect } from './operational-intellect.model';

export interface OperationalIntellectState extends EntityState<OperationalIntellect> {
  waitingKPIDel: string[];
  waitingKPITrendDel: string[];
  waitingRecommendationDel: string[];
  waitingMetricDel: string[];
  waitingProcessBlocksDel: string[];
  query: string;
  chartQuery: string;
  ruleQuery: string;
  sendingQuery: string;
  sendingChartQuery: string;
  sendingRuleQuery: string;
  mainModel: any;
  businessruleid: number;
  formulatype: string;
  selectedKPIAndMetricList: any[];
  selectedSecondaryKPIAndMetricList: any[];
  kpiMetricsChartData: any;
}
export function createInitialState(): OperationalIntellectState {
  return {
    waitingKPIDel: [],
    waitingKPITrendDel: [],
    waitingRecommendationDel: [],
    waitingMetricDel: [],
    waitingProcessBlocksDel: [],
    query: '',
    ruleQuery: '',
    chartQuery: '',
    fromName: '',
    sendingQuery: '',
    sendingChartQuery: '',
    sendingRuleQuery: '',
    mainModel: null,
    businessruleid: 0,
    formulatype: '',
    selectedKPIAndMetricList: [],
    selectedSecondaryKPIAndMetricList: [],
    kpiMetricsChartData: null
  };
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'operational-intellect' })
export class OperationalIntellectStore extends EntityStore<OperationalIntellectState> {

  constructor() {
    super(createInitialState());
    this.setLoading(false);
  }

}
