// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radial-text {
  font-size: 12px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/radial-progress-chart/radial-progress-chart.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF","sourcesContent":[".radial-text {\n  font-size: 12px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
