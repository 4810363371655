// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .checkbox {
  margin-bottom: 1.59em;
}
:host ::ng-deep .mat-pseudo-checkbox-checked {
  background: #02c39a !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modules/form-fields/components/form-fields-foster/form-fields-foster.component.scss"],"names":[],"mappings":"AAGE;EACE,qBAAA;AAFJ;AAME;EACE,8BAAA;AAJJ","sourcesContent":["@import 'variables';\n\n:host {\n  .checkbox {\n    margin-bottom: 1.59em;\n  }\n\n      \n  ::ng-deep .mat-pseudo-checkbox-checked {\n    background: $caribbean-green !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
