import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import { D3PackedBubbleChartService } from '../../../services/d3-packed-bubble-chart.service';
import { D3TreemapService } from '../../../services/d3-treemap.service';
import { SCSS_VARS } from '@bli/scss-variables.generated';

@Component({
  selector: 'app-bubble-graph',
  templateUrl: './bubble-graph.component.html',
  styleUrls: ['./bubble-graph.component.scss']
})
export class BubbleGraphComponent implements OnInit {
  public bubbleChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      xAxes: {
        min: 0,
        max: 50,
        ticks: {
          display: false
        }
        // gridLines: {
        //   drawBorder: false,
        //   display: false
        // }
      },
      yAxes: {
        min: 0,
        max: 50,
        ticks: {
          display: false
        }
        // gridLines: {
        //   drawBorder: false,
        //   display: false
        // }
      }
    }
  };
  public bubbleChartType: ChartType = 'bubble';
  public bubbleChartLegend = true;
  public metricParams;
  private dataset;
  public showActivity = false;
  public bubbleChartData: ChartDataset[] = [
    {
      backgroundColor: SCSS_VARS['$brand-primary'],
      hoverBackgroundColor: SCSS_VARS['$success-bg-1'],
      data: [
        { x: 17, y: 16, r: 12 },
        { x: 15, y: 14, r: 15 },
        { x: 16, y: 15, r: 23 },
        { x: 16, y: 20, r: 19 }
      ],
      label: 'Bubble info'
    }
  ];

  constructor(
    private d3PackedBubbleChartService: D3PackedBubbleChartService,
    private d3TreemapService: D3TreemapService
  ) {
    this.metricParams = [
      {
        header: 'Event Count',
        content: '4976 per day',
        footer: 'The avg  number of events with this activity per day'
      },
      {
        header: 'Cases come from',
        content: '45%',
        footer: 'of cases come from New Ticket Created'
      },
      {
        header: 'Cases go to',
        content: '30%',
        footer: 'of cases go to Status Changed to Open'
      }
    ];
    this.dataset = {
      children: [
        {
          name: 'ABC',
          children: [
            { Name: 'test1', Count: 4319 },
            { Name: 'test3', Count: 4159 },
            { Name: 'tst4 ', Count: 2583 },
            { Name: 'tetst2', Count: 2074 },
            { Name: 'test7', Count: 1894 },
            { Name: ' test71', Count: 1809 },
            { Name: ' tsstq', Count: 1713 },
            { Name: 'bbb', Count: 1636 },
            { Name: 'ccddd', Count: 1566 }
          ]
        }
      ]
    };
  }
  public bubbleChartClick(data: any) {
    this.showActivity = true;
    // console.log('test');
    // console.log(data);
  }

  ngOnInit() {
    this.renderChart();
  }

  renderChart() {
    const diameter = 600;
    const height = 400;
    const width = 600;
    const width2 = 200;
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const bubble = d3.pack().size([width, height]).padding(1.5);

    const svg = d3
      .select('#chart')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('class', 'bubble');

    const nodes = d3.hierarchy(this.dataset).sum((d: any) => {
      return d.Count;
    });

    const node = svg
      .selectAll('.node')
      .data(bubble(nodes).descendants())
      .enter()
      .filter(d => {
        return !d.children;
      })
      .append('g')
      .attr('class', 'node')
      .attr('transform', d => {
        return 'translate(' + d.x + ',' + d.y + ')';
      })
      .style('fill', (d, i: any) => {
        return color(i);
      })
      .on('click', d => {
        this.bubbleChartClick(d);
      });

    // node.append("title")
    //   .text(function (d: any) {
    //     return d.Name + ": " + d.Count;
    //   });

    node
      .append('circle')
      .attr('x', d => {
        return d.x;
      })
      .attr('y', d => {
        return d.y;
      })
      .attr('r', d => {
        return d.r;
      })
      .style('fill', (d, i: any) => {
        return SCSS_VARS['$brand-primary'];
      })
      .style('cursor', 'pointer')
      .on('mouseover', (d, i) => {
        // return d3.select(d).style('fill', SCSS_VARS['$success-bg']);
      });

    node
      .append('text')
      .attr('dy', '.2em')
      .style('text-anchor', 'middle')
      .text((d: any) => {
        return d.data.Name.substring(0, d.r / 3);
      })
      .attr('font-family', 'sans-serif')
      .attr('font-size', d => {
        return d.r / 5;
      })
      .attr('fill', 'white');

    node
      .append('text')
      .attr('dy', '1.3em')
      .style('text-anchor', 'middle')
      .text((d: any) => {
        return d.data.Count;
      })
      .attr('font-family', 'Gill Sans')
      .attr('font-size', d => {
        return d.r / 5;
      })
      .attr('fill', 'white');
  }
}
