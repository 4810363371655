import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy
} from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { SnackBarStore, SnackBarQuery, SnackBarService } from './state';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  snackBarMessage: string;
  snackBarRef: MatSnackBarRef<any>;
  @ViewChild('success', { static: true }) success: TemplateRef<any>;
  @ViewChild('error', { static: true }) error: TemplateRef<any>;

  constructor(
    private snackBar: MatSnackBar,
    private store: SnackBarStore,
    private snackBarQuery: SnackBarQuery,
    private snackbarService: SnackBarService
  ) {}

  ngOnInit() {
    this.store.reset();
    const snackSub = this.snackBarQuery.select().subscribe(result => {
      this.snackBarMessage = result.message;
      if (result.success) {
        this.successSnackBar();
      } else {
        this.errorSnackBar();
      }
    });
    this.subscription.push(snackSub);
  }

  successSnackBar(): void {
    if (this.snackBarMessage) {
      this.snackBarRef = this.snackBar.openFromTemplate(this.success, {
        duration: this.snackBarConfig.duration,
        panelClass: ['green-snackbar', 'shadow'],
        horizontalPosition: this.snackBarConfig.horizontalPosition
      });
      const sub = this.snackBarRef.afterDismissed().subscribe(info => {
        this.snackbarService.resetStore();
        this.snackbarService.snackBarDismissAction.next(
          !info.dismissedByAction
        );
      });
      this.subscription.push(sub);
    }
  }

  actionClicked(): void {
    this.snackbarService.resetStore();
    this.snackbarService.updateActionClick(true);
    this.snackBarRef.dismissWithAction();
  }

  errorSnackBar(): void {
    if (this.snackBarMessage) {
      this.snackBarRef = this.snackBar.openFromTemplate(this.error, {
        duration: this.snackBarConfig.duration,
        panelClass: ['red-snackbar-2', 'shadow'],
        horizontalPosition: this.snackBarConfig.horizontalPosition
      });
      // const sub = this.snackBarRef.afterDismissed().subscribe(() => {
      //   this.snackbarService.resetStore();
      // });
      // this.subscription.push(sub);
    }
  }

  get snackBarConfig() {
    return this.snackBarQuery.getValue().config;
  }

  ngOnDestroy(): void {
    this.subscription.map(sub => sub.unsubscribe());
  }
}
