import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bottleneck',
  templateUrl: './bottleneck.component.html',
  styleUrls: ['./bottleneck.component.scss']
})
export class BottleneckComponent {
  @Input() bottleneck: any;
  @Input() type: string;
  @Output() whiteListActivity = new EventEmitter<any>();
  @Output() activityToEmit = new EventEmitter<any>();
  @Output() deleteActivity = new EventEmitter<any>();

  whiitelist() {
    this.whiteListActivity.emit();
  }

  sendParamsToParent(param: any) {
    this.activityToEmit.emit(this.bottleneck);
  }

  delete() {
    this.deleteActivity.emit(this.bottleneck);
  }
}
