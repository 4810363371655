import { UntypedFormArray, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

export const groupRepeatableDuplicate = (key: string): ValidatorFn => {
  return (formArray: UntypedFormArray) => {
    const { controls } = formArray;
    const name = controls.map(ctrl => {
      ctrl.setErrors(null);
      return ctrl.get(key).value;
    });
    let duplicates = [
      ...new Set(
        _.filter(name, (val, i, iteratee) => _.includes(iteratee, val, i + 1))
      )
    ];
    duplicates = duplicates.filter(v => v);
    controls.forEach(ctrl => {
      if (duplicates.includes(ctrl.get(key).value)) {
        ctrl.setErrors({ duplicate: true });
      }
    });
    if (duplicates.length) {
      return { duplicates: true };
    }
    return null;
  };
};
