import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpClient
} from '@angular/common/http';
import { first, map, mergeMap } from 'rxjs/operators';
import { mapKeys, camelCase } from 'lodash';
import { AuthQuery, AuthStore } from '@bli/authentication/state';
import { Router, ActivationEnd } from '@angular/router';
import { HttpCancelService } from './http-cancel.service';
import { DetectChangeService } from './../../services/detect-change.service';

const modifiedUrls = ['bpmn_upload', '.bpmn'];

const getModifiedUrl = (url: string): string => {
  const index = modifiedUrls.filter(modifiedUrl => url.indexOf(modifiedUrl))[0];
  return modifiedUrls[index];
};

const requestHeader = (url: string, headers: HttpHeaders): HttpHeaders => {
  switch (getModifiedUrl(url)) {
    case 'edit_process_model':
      headers.set('Content-Type', 'application/xml; charset=utf-8');
      break;
    case 'bpmn_upload':
      headers.append('Content-Type', 'application/json');
      break;
    case '.bpmn':
      headers.append('Content-Type', 'application/text');
      break;
    default:
      headers.append('Request-Id', requestId());
      break;
  }
  return headers;
};

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
  constructor(
    private authQuery: AuthQuery,
    private authStore: AuthStore,
    private httpCancelService: HttpCancelService,
    private http: HttpClient,
    private detectChangeService: DetectChangeService,
    router: Router
  ) {
    router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }

  private isCaseCountAPI = false;
  private responseCount = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // if (this.authQuery.isAuthenticated()) {
    // tslint:disable-next-line: no-shadowed-variable
    return this.authQuery.getToken().pipe(
      first(),
      mergeMap(token => {
        let headers: any;
        if (
          req.url.split('/')[req.url.split('/').length - 2] ===
          'edit_process_model'
        ) {
          headers = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/xml',
            Authorization: token ? `JWT ${token}` : '',
            workspace: this.authQuery.getUserData.uuid || '0'
          });
        } else {
          headers = new HttpHeaders({
            Accept: 'application/json',
            // 'Content-Type': 'application/json',
            Authorization: token ? `JWT ${token}` : '',
            'Request-Id': requestId(),
            workspace: this.authQuery.getUserData.uuid || '0'
          });
        }
        headers = requestHeader(req.url, headers);
        req = req.clone({ headers });
        // }

        if (req.url.indexOf('.bpmn') !== -1) {
          return next.handle(req);
        }

        let dupReq: HttpRequest<any>;
        const config = req.url.split('/');
        // let headers = req.headers;
        if (!headers.has('Request-Id')) {
          headers = headers.append('Request-Id', requestId());
        }
        if (!headers.has('Content-Type') && !(req.body instanceof FormData)) {
          headers = headers.append('Content-Type', 'application/json');
        }
        if (config[0] === '') {
          if (req.method === 'POST') {
            const body = req.body;
            const requestBody = { request_id: requestId() };
            dupReq = req.clone({ body: Object.assign(body, requestBody) });
            dupReq = req.clone({ body: Object.assign(body, {}) });
          } else {
            dupReq = req;
          }
        } else {
          if (config[config.length - 2] === 'tenant-workspace-url') {
            if (config[0].split('.').length >= 3) {
              dupReq = req.clone({ url: `//${req.url}`, headers });
            } else {
              dupReq = req.clone({ url: `//www.${req.url}`, headers });
            }
          } else {
            if (!headers.has('Workspace')) {
              const workSpace = this.authQuery.getUserData.uuid || '0';
              headers = headers.append('Workspace', workSpace);
            }
            dupReq = req.clone({ url: `//${req.url}`, headers });
            // if (Environment.production) {
            // removing tenant id from the urls
            // dupReq = req.clone({ url: `//${this.authStore.getValue().uuid}${req.url}`, headers });
            // } else {
            //   dupReq = req.clone({ url: `http://${this.authStore.getValue().uuid}.${req.url}` });
            // }
          }
        }
        const workSpace = this.authQuery.getUserData.uuid || '0';
        dupReq = dupReq.clone({
          headers: dupReq.headers.set('Workspace', workSpace)
        });
        const modifiedReq = dupReq.clone({
          headers: dupReq.headers.set('Request-Id', requestId())
        });
        return next.handle(modifiedReq).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.responseCount++;
              if (
                !this.isCaseCountAPI &&
                this.responseCount &&
                dupReq.body &&
                Object.keys(dupReq.body).indexOf('global_filter') >= 0
              ) {
                this.isCaseCountAPI = true;
                const url = `${dupReq.url
                  .split('/')
                  .slice(0, dupReq.url.split('/').indexOf('analysis_id') + 2)
                  .join('/')}/process_analytics/data_count/`;
                this.http
                  .post<any>(url, { global_filter: dupReq.body.global_filter })
                  .subscribe(res => {
                    this.responseCount = 0;
                    this.isCaseCountAPI = false;
                    this.detectChangeService.updateCaseCount(res.data[0]);
                  });
              }
              const camelCaseObject = mapKeys(event.body, (v, k) =>
                camelCase(k)
              );
              const modEvent = event.clone({ body: camelCaseObject });
              return modEvent;
            }
            return event;
          })
        );
      })
    );
  }
}

function requestId() {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}
