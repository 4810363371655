import { Injectable, Inject } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { AuthStore } from './auth.store';
import { SnackBarStore } from '@bli-shared/components/snack-bar/state';
import { CognitoUtils } from '@bli-shared/utils/cognito-utils';
import { HttpClient } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from '@bli-shared/utils/app-config.module';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import {
  NewValidateWorkspacePayload,
  CorrectTenantWorkspaceResponse
} from './auth.model';
import { AuthQuery } from './auth.query';
import { Environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthSSOService } from './auth-sso.service';
import { AppService } from '@bli/state/app.service';
import { AppStore } from '@bli/state/app.store';
import { GlobalSignOutService } from './global-sign-out.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private session: CognitoUserSession;
  private userPool: CognitoUserPool;
  public forgotPasswordSubject = new BehaviorSubject<boolean>(false);
  constructor(
    private appStore: AppStore,
    private store: AuthStore,
    private authQuery: AuthQuery,
    private snackStore: SnackBarStore,
    private http: HttpClient,
    private toastrService: ToastrService,
    private authSSOService: AuthSSOService,
    private appService: AppService,
    private globalSignOut: GlobalSignOutService,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {}

  login(username: string, password: string): void {
    const cognitoUser = this.getUserData(username);
    // cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    const auth = new AuthenticationDetails(
      CognitoUtils.getAuthDetails(username, password)
    );
    this.store.isInProgress(true);
    this.appService.setLoginMethod('email');
    cognitoUser.authenticateUser(auth, {
      onSuccess: result => {
        this.store.isInProgress(false);
        this.session = result;
        this.authQuery.login(result);
        // this.userPool = CognitoUtils.getUserPool(this.authQuery.getUserData);
      },
      onFailure: err => {
        this.store.isInProgress(false);
        this.toastrService.show(
          JSON.stringify({ type: 'error', text: err.message })
        );
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email_verified;
        this.store.isInProgress(true);
        cognitoUser.completeNewPasswordChallenge('', userAttributes, {
          onSuccess: () => {
            this.store.isInProgress(false);
          },
          onFailure: err => {
            this.store.isInProgress(false);
            this.toastrService.show(
              JSON.stringify({ type: 'error', text: err.message })
            );
          }
        });
      }
    });
  }

  forgotPasswordOld(username: string): void {
    const cognitoUser = this.getUserData(username);

    this.store.isInProgress(true);
    cognitoUser.forgotPassword({
      onSuccess: result => {
        this.store.isInProgress(false);
        this.authQuery.forgotPasswordUpdate(false, result);
      },
      onFailure: err => {
        this.store.isInProgress(false);
        this.authQuery.forgotPasswordUpdate(false, null);
        this.toastrService.show(
          JSON.stringify({ type: 'error', text: err.message })
        );
      },
      inputVerificationCode: () => {
        this.store.isInProgress(false);
        this.authQuery.forgotPasswordUpdate(true, username);
        this.forgotPasswordSubject.next(true);
      }
    });
  }
  get forgotPassword$() {
    return this.forgotPasswordSubject.asObservable();
  }
  resetPassword(
    email: string,
    verificationCode: string,
    password: string
  ): void {
    const cognitoUser = this.getUserData(email);

    this.store.isInProgress(true);
    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: () => {
        this.store.isInProgress(false);
        this.toastrService.show(
          JSON.stringify({
            type: 'success',
            text: 'Password changed successfully'
          })
        );
        this.authQuery.resetPasswordUpdate(true);
      },
      onFailure: err => {
        this.store.isInProgress(false);
        this.authQuery.resetPasswordUpdate(false);
        this.toastrService.show(
          JSON.stringify({ type: 'error', text: err.message })
        );
      }
    });
  }

  forgotPassword(username: string): Observable<any> {
    this.store.setLoading(true);
    const payload = {
      email: username
    };
    const url = `${this.appConfig.USER_TENANT_MANAGEMENT.ROOT}/${this.appConfig.USER_TENANT_MANAGEMENT.FORGOT_PASSWORD}`;
    return this.http.post<any>(url, payload).pipe(
      tap(res => {
        this.store.setLoading(false);
        if (res.status === 'SUCCESS') {
          this.authQuery.forgotPasswordUpdate(true, username);
          this.forgotPasswordSubject.next(true);
        }
      }),
      catchError(err => {
        this.authQuery.forgotPasswordUpdate(null, null);
        this.store.setLoading(false);
        throw err;
      })
    );
  }

  setPassword(
    email: string,
    verificationCode: string,
    password: string
  ): Observable<any> {
    this.store.setLoading(true);
    const payload = {
      email,
      code: verificationCode,
      password
    };
    const url = `${this.appConfig.USER_TENANT_MANAGEMENT.ROOT}/${this.appConfig.USER_TENANT_MANAGEMENT.SET_PASSWORD}`;
    return this.http.post<any>(url, payload).pipe(
      tap(res => {
        this.store.setLoading(false);
        if (res.status === 'SUCCESS') {
          this.toastrService.show(
            JSON.stringify({
              type: 'success',
              text: 'Password changed successfully'
            })
          );
          this.authQuery.resetPasswordUpdate(true);
        }
      }),
      catchError(err => {
        this.store.setLoading(false);
        this.toastrService.show(
          JSON.stringify({ type: 'error', text: err.eror.message })
        );
        this.authQuery.resetPasswordUpdate(true);
        throw err;
      })
    );
  }

  validateWorkSpace(payload: NewValidateWorkspacePayload): Observable<any> {
    this.store.setLoading(true);
    const url = `${Environment.utmAPIRootWithoutHyphen}${this.appConfig.USER_TENANT_MANAGEMENT.VALIDATE_WORKSPACE}`;
    // const url = `${this.appConfig.USER_TENANT_MANAGEMENT.ROOT}/${this.appConfig.USER_TENANT_MANAGEMENT.VALIDATE_WORKSPACE}`;
    return this.http.post<any>(url, payload).pipe(
      tap(res => {
        this.store.setLoading(false);
        if (res.status === 'SUCCESS') {
          // this.store.update({ uuid: res.data[0].tenant_id });
          this.authQuery.updateUserData = { uuid: res.data[0].tenant_id };
          // this.appService.setBaseUrl(res.data[0].url)
          return res;
        }
      }),
      catchError(err => {
        // this.toastrService.show(JSON.stringify({type: 'error', text: err.error.msg}));
        // this.store.update({ uuid: null });
        this.authQuery.updateUserData = { uuid: null };
        this.store.setLoading(false);
        throw err;
      })
    );
  }
  getuserDetails(): Observable<any> {
    this.store.setLoading(true);
    let workspace;
    if (this.authQuery.getUserData && this.authQuery.getUserData.uuid) {
      workspace = this.authQuery.getUserData.uuid.split('-');
      if (workspace.length >= 4) {
        workspace.splice(workspace.length - 1, 1);
      }
      workspace = workspace.join('-');
    } else {
      workspace = location.host.split('.')[0];
    }
    this.authQuery.updateUserData = {
      uuid: Environment.production ? location.host.split('.')[0] : workspace
    };
    // this.store.update({ uuid: Environment.production ? location.host.split('.')[0] : workspace });
    const url = `${this.appConfig.USER_TENANT_MANAGEMENT.ROOT}/${this.appConfig.USER_TENANT_MANAGEMENT.TENANT_WORKSPACE}`;
    return this.http.post<any>(url, {}).pipe(
      tap(res => {
        const data = res.data[0];
        const {
          aws_user_pool_id,
          aws_user_pool_client_id,
          tenant_url,
          cognito_auth_domain
        } = data;
        this.authQuery.updateUserData = {
          userPoolId: aws_user_pool_id,
          clientId: aws_user_pool_client_id
        };
        this.appService.setBaseUrl(tenant_url);
        this.appService.setDomain(cognito_auth_domain);
        this.userPool = CognitoUtils.getUserPool(this.authQuery.getUserData);
        this.authSSOService.configSSO(data);
        // this.activatedRoute.queryParamMap.pipe(take(1)).subscribe(map => {
        //   if (map.has('code')) {
        //     this.authSSOService.getToken(map)
        //   }
        // });
        this.store.setLoading(false);
        return res;
      }),
      catchError(err => {
        if (Environment.production) {
          const host = window.location.host.split('.');
          host.splice(0, 1);
          const redirectUrl = window.location.origin.split('//');
          redirectUrl[1] = host.join('.');
          window.location.href = redirectUrl.join('//');
        } else {
          window.location.href = window.location.origin;
        }
        this.store.setLoading(false);
        throw err;
      })
    );
  }

  checkIfTenantBelongsToWorkspace(
    email: string
  ): Observable<CorrectTenantWorkspaceResponse> {
    this.store.setLoading(true);
    // const uuid = this.authQuery.getValue().uuid;
    const uuid = this.authQuery.getUserData.uuid;
    const url = `${this.appConfig.USER_TENANT_MANAGEMENT.ROOT}/${this.appConfig.USER_TENANT_MANAGEMENT.TENANT_WORKSPACE}`;
    return this.http
      .post<CorrectTenantWorkspaceResponse>(url, { email, uuid })
      .pipe(
        tap(res => {
          this.store.setLoading(false);
          if (!res.allowed) {
            this.toastrService.show(
              JSON.stringify({
                type: 'error',
                text: 'We could not validate your email against workspace provided.'
              })
            );
          }
        }),
        catchError(err => {
          this.store.setLoading(false);
          throw err;
        })
      );
  }

  logoutUser(): void {
    if (
      this.authQuery.getUserData &&
      Object.keys(this.authQuery.getUserData).length !== 0
    ) {
      this.userPool = CognitoUtils.getUserPool(this.authQuery.getUserData);
      const currentUser = this.getUserData(
        this.authQuery.getUserData.user.id.payload['cognito:username']
      );
      if (currentUser) {
        const token: any = this.authQuery.getUserData.user.access;
        const refreshToken: any = this.authQuery.getUserData.user.refresh;
        // ========= revoke ============
        this.authSSOService.revokeSSO(refreshToken.token).subscribe(
          response => {
            if (response === false) return;
            this.clearSession(token.jwtToken);
            // ========= logout ===========
            this.authSSOService.ssoLogout();
          },
          () => {
            this.clearSession(token.jwtToken);
            this.authSSOService.ssoLogout();
          }
        );
      }
    }
  }

  clearSession(token) {
    this.userPool.getCurrentUser().signOut();
    this.authQuery.removeUser(token.jwtToken);
  }

  private getUserData(email: string) {
    return new CognitoUser({
      Username: email,
      Pool: this.userPool
    });
  }
}
