import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRoutingComponent } from './mat-dialog-routing.component';
import { MatDialogRoutingDirective } from '@bli-shared/directives/mat-dialog-routing.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [MatDialogRoutingComponent, MatDialogRoutingDirective],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [MatDialogRoutingComponent, MatDialogRoutingDirective]
})
export class MatDialogRoutingModule {}
