import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProcessExplorerService } from '@bli/product-features/process-analytics/process-explorer/state';
import { Subscription } from 'rxjs';
import { ProcessExplorerGroupService } from '../../services/process-explorer-group.service';

@Component({
  selector: 'app-process-explorer-delete-group',
  templateUrl: './process-explorer-delete-group.component.html',
  styleUrls: ['./process-explorer-delete-group.component.scss']
})
export class ProcessExplorerDeleteGroupComponent implements OnDestroy {
  subscription = new Subscription();
  isLoading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public group,
    private service: ProcessExplorerGroupService,
    private peService: ProcessExplorerService,
    private dialogRef: MatDialogRef<ProcessExplorerDeleteGroupComponent>
  ) {}

  initiateDeletion() {
    const payload = {
      key: `groups.${this.group.key}`,
      data: null
    };
    this.isLoading = true;
    const sub = this.service.setPAState(payload).subscribe(() => {
      this.isLoading = false;
      this.peService.relaodProcessExplorerGraph = true;
      this.dialogRef.close();
    });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
