import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessExplorerCreateGroupComponent } from './components/process-explorer-create-group/process-explorer-create-group.component';
import { ProcessExplorerDeleteGroupComponent } from './components/process-explorer-delete-group/process-explorer-delete-group.component';
import { ProcessExplorerEmptyGroupComponent } from './components/process-explorer-empty-group/process-explorer-empty-group.component';
import { ProcessExplorerGroupDashboardComponent } from './components/process-explorer-group-dashboard/process-explorer-group-dashboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { PortalModule } from '@angular/cdk/portal';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProcessExplorerListGroupComponent } from './components/process-explorer-list-group/process-explorer-list-group.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { ProgressSpinnerModule } from '@bli-shared/components/progress-spinner/progress-spinner.module';
import { MatCardModule } from '@angular/material/card';
import { NgxErrorsModule } from '@bli-shared/modules/ngx-errors/ngxerrors.module';

@NgModule({
  declarations: [
    ProcessExplorerCreateGroupComponent,
    ProcessExplorerDeleteGroupComponent,
    ProcessExplorerEmptyGroupComponent,
    ProcessExplorerListGroupComponent,
    ProcessExplorerGroupDashboardComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    NgxDatatableModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    NgxErrorsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    PortalModule,
    ProgressSpinnerModule
  ],
  exports: [ProcessExplorerGroupDashboardComponent]
})
export class ProcessExplorerGroupingModule {}
