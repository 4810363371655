import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Enum, FormField } from '@bli/state/app.model';
import { AppService } from '@bli/state/app.service';
import { Observable, combineLatest, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormFieldFosterService {
  private dependendCallBackMemoize = new Map<string, FormField>();

  constructor(private service: AppService) {}

  selectValueRequiredActionSctipt = [
    'list_salesforce_table_columns',
    'list_salesforce_table_column_values'
  ];

  formFieldloaderSkeletonStyle = {
    height: '63px',
    width: '100%',
    'animation-duration': '1s',
    'min-width': '200px'
  };

  getCallBackAPI(
    field: FormField,
    formGroup: UntypedFormGroup,
    beforeAPI = () => {},
    afterAPI = () => {}
  ) {
    const { callback, callback_dependent_fields = [] } = field;
    const hideLoading = () => (field.loading = false);
    hideLoading();
    const api =
      field && callback && callback_dependent_fields?.length
        ? this._dependentCallback(
            formGroup,
            callback,
            callback_dependent_fields,
            beforeAPI
          )
        : this._callBack(callback, beforeAPI);
    return api.pipe(tap(afterAPI));
  }

  /**
   * while editing get initial value of field(After API call)
   * @param formField formfield object
   * @param key key to be searched
   */
  getInitialValueOfField(formField: FormField, key: string | number): Enum {
    return formField.enum.find(items => items.key === key);
  }

  /**
   * Check the call back dependend fields are having value
   * @param formGroup Form group
   * @param callbackDependentFields Call back depended field
   * @returns disable state
   */
  isDisabled(
    formGroup: UntypedFormGroup,
    callbackDependentFields: string[]
  ): Observable<boolean> {
    const dependedControls = this.getDependentFieldControls(
      formGroup,
      callbackDependentFields
    );
    return combineLatest(dependedControls).pipe(
      map(
        controls =>
          callbackDependentFields.length !==
          controls.filter(control => control).length
      )
    );
  }

  /**
   * get list of depended field's corresponding form control's value change from the form group
   * @param formGroup Form group
   * @param callbackDependentFields callback depended field
   * @returns Observable of form controls of depended fields from form or parent form
   */
  getDependentFieldControls(
    formGroup: UntypedFormGroup,
    callbackDependentFields: string[]
  ): Observable<string | number>[] {
    return callbackDependentFields.map(field => {
      const control = this.findFormGroupField(formGroup, field);
      return control.valueChanges.pipe(startWith(control.value));
    });
  }

  /**
   * get list of observable of mapped { field: value } from the form control's valueChange.
   * @param formGroup Form group
   * @param callbackDependentFields callback depended field
   * @returns list of observable of mapped { field: value } from the  form or parent form
   */
  getDependentFieldControlsValue(
    formGroup: UntypedFormGroup,
    callbackDependentFields: string[]
  ): Observable<{ [key: string]: string | number }>[] {
    return callbackDependentFields
      .map(field => {
        const control = this.findFormGroupField(formGroup, field);
        return control?.valueChanges.pipe(
          startWith(control?.value),
          map(value => ({ [field]: value }))
        );
      })
      .filter(v => v);
  }

  /**
   * get dependend control's combined observable
   * @param dependedControls Dependend controls
   * @returns Observable of controls
   */
  getDependentFieldControlsCombine(
    dependedControls: Observable<{ [key: string]: string | number }>[]
  ): Observable<{ [key: string]: string | number }> {
    return combineLatest(dependedControls).pipe(
      map(controls =>
        controls.reduce((acc, prev) => ({ ...acc, ...prev }), {})
      ),
      filter(args => {
        const values = Object.values(args);
        for (const v of values) {
          if (!v) return false;
        }
        return true;
      })
    );
  }

  /**
   * get all callback depended controls from the form or the parent form
   * combine the controls and filter the values
   * if all controls have values, function calls the drop down api
   * @param formGroup form group object
   * @param callback callback parameter
   * @param callbackDependentFields depended callback parameter
   * @param beforeAPI callback function to be executed before API call
   * @returns Observable of drop down items
   */
  private _dependentCallback(
    formGroup: UntypedFormGroup,
    callback: string,
    callbackDependentFields: string[],
    beforeAPI: () => void
  ) {
    const dependedControls = this.getDependentFieldControlsValue(
      formGroup,
      callbackDependentFields
    );
    return this.getDependentFieldControlsCombine(dependedControls).pipe(
      tap(beforeAPI),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(args =>
        this._getDropdownAttributes({
          dropdown_name: callback,
          selected_value: this._selectValueRequiredActionSctipts(
            formGroup,
            callback
          ),
          args,
          action_script_name: this.getActionScriptName(formGroup)
        })
      )
    );
  }

  /**
   * function calls the drop down api
   * @param callback callback string
   * @param beforeAPI callback function to be executed before API call
   * @returns Observable of drop down items
   */
  private _callBack(callback: string, beforeAPI: () => void) {
    if (!callback) return;
    const payload = {
      dropdown_name: callback,
      selected_value: ''
    };
    beforeAPI && beforeAPI();
    return this._getDropdownAttributes(payload);
  }

  /**
   * This function will call drop down API
   * & it will memoize the response
   * (next time when request comes, response will be taken from memoized map if exists)
   * @param payload payload
   * @returns Observable drop down API response
   */
  private _getDropdownAttributes(payload: {
    dropdown_name: string;
    selected_value: string;
    args?: object;
    action_script_name?: string;
  }): Observable<FormField> {
    const memoIdentifier = this._createMemoizeIdentifier(payload);
    const memoizedDependendCallback =
      this._findMemoizedFormField(memoIdentifier);
    if (memoizedDependendCallback) {
      return of(memoizedDependendCallback);
    }
    return this.service.getMultiSelectDatas(payload).pipe(
      map(response => {
        const [data] = response.data;
        data && this._setMemoizedFormField(memoIdentifier, data);
        return data ? data : [];
      }),
      catchError(() => of([]))
    );
  }

  /**
   * This function returns the Form Control of field from the Form Group or the parent Form Groups.
   * @param formGroup Form group
   * @param field Field
   * @returns Form Control(AbstractControl)
   */
  findFormGroupField(formGroup: UntypedFormGroup, field: string) {
    let group: UntypedFormGroup | UntypedFormArray = formGroup;
    while (group instanceof UntypedFormArray || !group.contains(field)) {
      group = group.parent;
    }
    return group.get(field);
  }

  /**
   * This function creates a memoise identifier for the corresponding drop down and its args
   * eg:- dropdownname-arg1key:arg1value|arg2key:arg2value
   * @param payload drop down API payload
   * @returns
   */
  private _createMemoizeIdentifier(payload) {
    const { dropdown_name, args, action_script_name } = payload;
    const argsReduceToIdentifier = (prev, curr) => {
      prev = prev ? `${prev}|` : '';
      return `${prev}${curr}:${args[curr]}`;
    };
    const argsIdentifier =
      args && Object.keys(args).length
        ? Object.keys(args).reduce(argsReduceToIdentifier, '')
        : '';
    return `${action_script_name}-${dropdown_name}-${argsIdentifier}`;
  }

  private _findMemoizedFormField(memoIdentifier: string) {
    return this.dependendCallBackMemoize.get(memoIdentifier) || null;
  }

  private _setMemoizedFormField(memoIdentifier: string, formField: FormField) {
    this.dependendCallBackMemoize.set(memoIdentifier, formField);
  }

  clearMemoizedDependendCallbacks() {
    this.dependendCallBackMemoize.clear();
  }

  private _selectValueRequiredActionSctipts(
    formGroup: UntypedFormGroup,
    callback: string
  ) {
    if (this.selectValueRequiredActionSctipt.includes(callback)) {
      return this.getActionScriptName(formGroup);
    }
    return '';
  }

  private getActionScriptName(formGroup: UntypedFormGroup) {
    return this.findFormGroupField(formGroup, 'action_script_name')?.value;
  }
}
