// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-wrapper p {
  line-height: 28px;
}
.confirm-wrapper .mat-dialog-title {
  margin-bottom: 0;
}
.confirm-wrapper .footer {
  text-align: right;
}
.confirm-wrapper .footer .primary-btn {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/confirm-dialog/confirm-dialog.component.scss"],"names":[],"mappings":"AAEE;EACE,iBAAA;AADJ;AAGE;EACE,gBAAA;AADJ;AAGE;EACE,iBAAA;AADJ;AAEI;EACE,iBAAA;AAAN","sourcesContent":["\n.confirm-wrapper {\n  p {\n    line-height: 28px;    \n  }\n  .mat-dialog-title {\n    margin-bottom: 0;\n  }\n  .footer {\n    text-align: right;\n    .primary-btn {\n      margin-left: 10px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
