import { Injectable, Inject } from '@angular/core';
import { ProcessAnalyticsStore } from './process-analytics.store';
import { ProcessAnalyticsQuery } from './process-analytics.query';
import { GetSharedAnalyticsResponse } from './process-analytic.model';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '@bli-shared/utils/app-config.module';
import { Observable, throwError, Subject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AnalysisResponse, CloneAnalysisPayload } from './process-analytic.model';
import { SnackBarStore } from '@bli-shared/components/snack-bar/state';
import { Router, ActivatedRoute } from '@angular/router';
import {
  LinkModelResponse,
  CreateCluster,
  CreateClusterResponse,
  AnalyticClusterResponse,
  UpdateCluster,
  UpdateClusterResponse,
  MiningModelResponse,
  LinkModel,
  CreateAnalysis,
  CreateAnalysisResponse,
  UpdateAnalysis,
  UpdateAnalysisResponse,
  DeleteAnalysis,
  DeleteCluster
} from './process-analytic.model';
import { DetectChangeService } from '../../../services/detect-change.service';
import { ToastrService } from 'ngx-toastr';
import { CheckProcessModelIdResponse } from '../conformance-check/state';

@Injectable({ providedIn: 'root' })
export class ProcessAnalyticsService {
  private deletionIds: string[] = [];
  public notifySubject = new Subject();
  public notifyRenameSubject = new Subject();

  constructor(
    private processAnalyticsStore: ProcessAnalyticsStore,
    private processAnalyticsQuery: ProcessAnalyticsQuery,
    private snackBarStore: SnackBarStore,
    private detectChangeService: DetectChangeService,
    private toastrService: ToastrService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private router: Router,
    private http: HttpClient
  ) {}

  getAllCluster(payload: any): Observable<AnalyticClusterResponse> {
    const url = `${this.rootUrl}/${this.analyticClusterUrl.READ_CLUSTER}`;
    return this.http.post<AnalyticClusterResponse>(url, payload);
  }

  addNewAnalysisCluster(payload: CreateCluster): Observable<CreateClusterResponse> {
    this.processAnalyticsStore.setLoading(true);
    const url = `${this.rootUrl}/${this.analyticClusterUrl.CREATE_CLUSTER}`;
    return this.http.post<CreateClusterResponse>(url, payload).pipe(
      tap((res: CreateClusterResponse) => {
        this.processAnalyticsStore.setLoading(false);
        this.toastrService.show(JSON.stringify({type: 'success', text: 'New cluster created successfully'}));
        this.notifyNewCluster(res.data[0].process_analytics_cluster_id);
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getCluster(payload: {process_analytics_cluster_id: number}): Observable<AnalyticClusterResponse> {
    const url = `${this.rootUrl}/${this.analyticClusterUrl.READ_CLUSTER}`;
    this.processAnalyticsStore.setLoading(true);
    return this.http.post<AnalyticClusterResponse>(url, payload).pipe(
      tap(() => {
        this.processAnalyticsStore.setLoading(false);
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  updateAnalysisCluster(payload: UpdateCluster): Observable<UpdateClusterResponse> {
    this.processAnalyticsStore.setLoading(true);
    const url = `${this.rootUrl}/${this.analyticClusterUrl.UPDATE_CLUSTER}`;
    return this.http.post<UpdateClusterResponse>(url, payload).pipe(
      tap(() => {
        this.processAnalyticsStore.setLoading(false);
        this.toastrService.show(JSON.stringify({type: 'success', text: 'Cluster updated successfully'}));
        this.notifyClusterRename();
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  deleteAnalysisCluster(payload: DeleteCluster): Observable<any> {
    const url = `${this.rootUrl}/${this.analyticClusterUrl.DELETE_CLUSTER}`;
    return this.http.post<any>(url, payload).pipe(
      tap(res => {
        // this.snackBarService.updateDismissedState(false);
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  updateSnackBarConfig(): void {
    this.snackBarStore.snackbarConfig(true, {
      duration: 5000,
      actionButtonLabel: 'Undo'
    });
  }

  showDeleteSuccessSnackBar(message: string): void {
    this.toastrService.show(JSON.stringify({type: 'success', text: message}));
  }

  updateWaitingDeletionIds(id: string, reset?: boolean) {
    if (reset) {
      this.deletionIds = [];
    } else {
      if (!this.deletionIds.includes(id)) {
        this.deletionIds.push(id);
      }
    }
    this.processAnalyticsQuery.updateStoreAnalysisDeleteData= this.deletionIds;
    // this.processAnalyticsStore.update({
    //   waitingDel: this.deletionIds
    // });
  }

  getMiningModelList(dataModelId: string): Observable<MiningModelResponse> {
    this.processAnalyticsStore.setLoading(true);
    const url = `${this.rootUrl}/${this.appConfig.ANAYSIS_API.MINING_MODEL.LIST}`;
    return this.http.post<MiningModelResponse>(url, {}).pipe(
      tap(() => {
        this.processAnalyticsStore.setLoading(false);
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  linkDataModel(payload: LinkModel): Observable<LinkModelResponse> {
    this.processAnalyticsStore.setLoading(true);
    const url = `${this.rootUrl}/${this.appConfig.ANAYSIS_API.MINING_MODEL.LINK}`;
    return this.http.post<LinkModelResponse>(url, payload).pipe(
      tap(() => {
        this.processAnalyticsStore.setLoading(false);
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  deleteLinkedModel(clusterId: string, linkModelId: string) {
    this.processAnalyticsQuery.updateStoreLinkingModelProgressData= true;
    const url = `${this.analysisClusterUrl}${clusterId}/${this.appConfig.ANAYSIS_API.MINING_MODEL.LINK}${linkModelId}/`;
    return this.http.delete<any>(url).pipe(
      tap(res => {
        if (res) {
          this.processAnalyticsQuery.updateStoreLinkingModelProgressData= false;
        }
      }),
      catchError(() => this.processAnalyticsStore.update({ linkingModelProgress: false }))
    );
  }

  getSharedUsers(payload): Observable<GetSharedAnalyticsResponse> {
    const url = `${this.rootUrl}/${this.appConfig.ANAYSIS_API.CLUSTER.USER_PERMISSION.LIST}`;
    this.processAnalyticsStore.setLoading(true);
    return this.http.post<GetSharedAnalyticsResponse>(url, payload).pipe(
      tap((res: GetSharedAnalyticsResponse) => {
        this.processAnalyticsStore.setLoading(false);
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getPermissions() {
    // const
    const url = `${this.rootUrl}/intellect_engine/get_static_dropdown_details/`;
    const payload = {
      dropdown_name : ['permission']
    };
    this.processAnalyticsStore.setLoading(true);
    return this.http.post<any>(url, payload).pipe(
      tap((res: any) => {
        this.processAnalyticsStore.setLoading(false);
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getUsers(clusterId : number): Observable<any> {
    const url = `${this.rootUrl}/${this.appConfig.ANAYSIS_API.CLUSTER.USER_PERMISSION.SEARCH}`;
    const payload = {
        email: '',
        process_analytics_cluster_id : clusterId
    };
    return this.http.post<any>(url, payload).pipe(
      tap((res: any) => {

      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  updateUserPermission(payload: any): Observable<any> {
    const url = `${this.rootUrl}/${this.appConfig.ANAYSIS_API.CLUSTER.USER_PERMISSION.UPDATE}`;
    this.processAnalyticsStore.setLoading(true);
    return this.http.post<any>(url, payload).pipe(
      tap((res: any) => {
        this.processAnalyticsStore.setLoading(false);
        this.toastrService.show(JSON.stringify({type: 'success', text: 'Permission Created Successfully.'}));
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }




  get analyticClusterUrl() {
    return this.appConfig.ANAYSIS_API.CLUSTER;
  }

  get analysisUrl() {
    return this.appConfig.ANAYSIS_API.ANALYSIS;
  }

  get rootUrl(): string {
    return this.appConfig.ANAYSIS_API.ROOT;
  }

  get analysisClusterDetails(): any {
    return this.processAnalyticsQuery.GetAnalysisClusterData;
  }

  setAnalysis(analysis: AnalysisResponse): any {
    this.processAnalyticsQuery.updateStoreAnalysisData= analysis;
  }

  get analysisId(): string {
    return this.processAnalyticsQuery.GetAnalysisData.analysis_id;
  }

  get clusterId(): string {
    return this.processAnalyticsQuery.GetAnalysisClusterData.process_analytics_cluster_id;
  }

  get analysisDetails(): any {
    return this.processAnalyticsQuery.GetAnalysisData;
  }


  getAnalysisList(payload: any): Observable<AnalysisResponse> {
    const url = `${this.rootUrl}/${this.analysisUrl.READ_CLUSTER}`;
    this.processAnalyticsStore.setLoading(true);
    return this.http.post<AnalysisResponse>(url, payload).pipe(tap(() => {
      this.processAnalyticsStore.setLoading(false);
    }),
    catchError(err => {
      this.processAnalyticsStore.setLoading(false);
      return throwError(err);
    })
    );
  }

  addNewProcessAnalysis(payload: CreateAnalysis): Observable<CreateAnalysisResponse> {
    this.processAnalyticsStore.setLoading(true);
    const url = `${this.rootUrl}/${this.analysisUrl.CREATE_CLUSTER}`;
    return this.http.post<CreateAnalysisResponse>(url, payload).pipe(
      tap((res: any) => {
        this.processAnalyticsStore.setLoading(false);
        this.toastrService.show(JSON.stringify({type: 'success', text: 'New analysis created successfully'}));
        this.notifyNewAnalysis(res.data[0].process_analysis_id);
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getAnalysis(payload: any): Observable<AnalysisResponse> {
    const url = `${this.rootUrl}/${this.analysisUrl.READ_CLUSTER}`;
    this.processAnalyticsStore.setLoading(true);
    return this.http.post<AnalysisResponse>(url, payload).pipe(
      tap(() => {
        this.processAnalyticsStore.setLoading(false);
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  updateAnalysis(payload: UpdateAnalysis): Observable<UpdateAnalysisResponse> {
    this.processAnalyticsStore.setLoading(true);
    const url = `${this.rootUrl}/${this.analysisUrl.UPDATE_CLUSTER}`;
    return this.http.post<UpdateAnalysisResponse>(url, payload).pipe(
      tap(() => {
        this.processAnalyticsStore.setLoading(false);
        this.toastrService.show(JSON.stringify({type: 'success', text: 'Analysis updated successfully'}));
        this.notifyAnalysisRename();
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  deleteAnalysis(payload: DeleteAnalysis): Observable<any> {
    const url = `${this.rootUrl}/${this.analysisUrl.DELETE_CLUSTER}`;
    return this.http.post<any>(url, payload).pipe(
      tap(res => {
        // this.snackBarService.updateDismissedState(false);
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  checkProcessModel(): Observable<CheckProcessModelIdResponse> {
    const processAnalysis = this.processAnalyticsQuery.GetProcessAnalyticsData;
    this.processAnalyticsStore.setLoading(true);
    const payload = {
      process_analytics_cluster_id: processAnalysis.analysis_cluster.process_analytics_cluster_id,
      process_analysis_id: processAnalysis.analysis.process_analysis_id
    };
    const url = `${this.rootUrl}${this.appConfig.CONFORMANCE_CHECK_API.IS_EXISTS}`;
    return this.http.post<CheckProcessModelIdResponse>(url, payload).pipe(
      tap( (response: any) => {
        this.processAnalyticsStore.setLoading(false);
        if (response.data.length && response.data[0].process_model_id) {
          this.processAnalyticsQuery.isProcessModelExists = response.data[0];
          this.detectChangeService.updateConformanceMenu(true);
          return;
        }
        this.processAnalyticsQuery.isProcessModelExists = { process_model_id: null};
        this.detectChangeService.updateConformanceMenu(false);
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  cloneAnalysis(analysisId: number) {
    const processAnalysis = this.processAnalyticsQuery.GetProcessAnalyticsData;
    const url = `${this.rootUrl}/process_analytics/clone_analysis/`;
    const payload = {
      process_analytics_cluster_id: processAnalysis.analysis_cluster.process_analytics_cluster_id,
      process_analysis_id: analysisId
    };
    return this.http.post<any>(url, payload).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }



  importAnalysis(analysis: any): Observable<any> {
    analysis.append('process_analytics_cluster_id',  this.clusterId );
    const url = `${this.rootUrl}/process_analytics/import/analysis/`;
    this.processAnalyticsStore.setLoading(true);
    return this.http.post<any>(url, analysis).pipe(
      tap((res: any) => {
        if (res && res.status !== 'progress') {
        }
        if (res && res.status === 'SUCCESS') {
          this.notifyNewAnalysis(res.data[0].process_analysis_id);
        }
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  importPage(page: any): Observable<any> {
    page.append('process_analytics_cluster_id',  this.clusterId );
    page.append('process_analysis_id ',  this.analysisId );
    const url = `${this.rootUrl}/process_analytics/import/page/`;
    this.processAnalyticsStore.setLoading(true);
    return this.http.post<any>(url, page).pipe(
      tap((res: any) => {
        if (res && res.status !== 'progress') {
        }
        if (res && res.status === 'SUCCESS') {
          // this.notifyNewAnalysis(res.data[0].process_analysis_id);
        }
      }),
      catchError(err => {
        this.processAnalyticsStore.setLoading(false);
        return throwError(err);
      })
    );
  }



  exportAnalysis(analysisId: number) {
    const processAnalysis = this.processAnalyticsQuery.GetProcessAnalyticsData;
    const url = `${this.rootUrl}/process_analytics/export/analysis/`;
    const payload = {
      process_analytics_cluster_id: processAnalysis.analysis_cluster.process_analytics_cluster_id,
      process_analysis_id: analysisId
    };
    return this.http.post<any>(url, payload).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  clonePageInCurrentAnalysis(pageId: number) {
    const { process_analytics_cluster_id } = this.processAnalyticsQuery.GetProcessAnalyticsData.analysis_cluster;
    const { process_analysis_id } = this.processAnalyticsQuery.GetProcessAnalyticsData.analysis;
    const payload = {
      process_analytics_cluster_id,
      process_analysis_id,
      destination_process_analytics_cluster_id: process_analytics_cluster_id,
      destination_process_analysis_id: process_analysis_id,
      pa_page_id: pageId
    };
    const url = `${this.rootUrl}/process_analytics/pa_page/clone/`;
    return this.http.post<any>(url, payload).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  exportPageAnalysis(pageId: number) {
    const { process_analytics_cluster_id } = this.processAnalyticsQuery.GetProcessAnalyticsData.analysis_cluster;
    const { process_analysis_id } = this.processAnalyticsQuery.GetProcessAnalyticsData.analysis;
    const payload = {
      process_analytics_cluster_id,
      process_analysis_id,
      pa_page_id: pageId
    };
    const url = `${this.rootUrl}/process_analytics/export/page/`;
    return this.http.post<any>(url, payload).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  get analysisClusterUrl() {
    return ``;
  }

  notifyNewCluster(clusterId) {
    this.notifySubject.next(clusterId);
  }

  notifyNewAnalysis(analysisId) {
    this.notifySubject.next(analysisId);
  }

  notifyClusterRename() {
    this.notifyRenameSubject.next(null);
  }

  notifyAnalysisRename() {
    this.notifyRenameSubject.next(null);
  }
}
