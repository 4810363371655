import { QueryEntity, toBoolean } from '@datorama/akita';
import { AuthState, AuthStore } from './auth.store';
import { Injectable } from '@angular/core';
// import { getInSafe } from '@bli-shared/utils/common-utils';
import { CognitoUtils } from '@bli-shared/utils/cognito-utils';
import { User } from './auth.model';
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { AppStore } from './../../state/app.store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthQuery extends QueryEntity<AuthState> {
  isAuthenticated$ = this.select(state =>
    toBoolean(state.user.access.getJwtToken)
  );
  isForgotPasswordSuccess$ = this.select(state => state.forgotPassword.success);
  private userPool: CognitoUserPool;
  getForgotPasswordEmail(): string {
    const idValue = window.sessionStorage.getItem('unique-id');
    const entity = this.getEntity(idValue);
    if (entity) return entity.forgotPassword.email;
    else {
      return null;
    }
    // return this.getValue().forgotPassword.email;
  }

  refreshAccesstoken() {
    return new Promise(resolve => {
      let value = window.sessionStorage.getItem('unique-id');
      if (!value || !window.name) {
        value = '_' + Math.random().toString(36).substr(2, 9);
        window.sessionStorage.setItem('unique-id', value);
      }

      window.name = value;
      const idValue = window.sessionStorage.getItem('unique-id');
      const entity = this.getEntity(idValue);
      let isLoggedIn: boolean;
      if (entity && Object.keys(entity).length !== 0) {
        this.userPool = CognitoUtils.getUserPool(entity);
        if (entity.user) {
          const cognitoUser = this.getCognitoUserData(
            entity.user.id.payload['cognito:username'],
            this.userPool
          );

          cognitoUser.getSession((err, session) => {
            if (err) resolve(false);
            cognitoUser.refreshSession(session.refreshToken, (err, session) => {
              if (err) throw err;
              if (session.isValid) this.updateSession(session);
              isLoggedIn = session.isValid();
              resolve(isLoggedIn);
            });
          });
        } else resolve(false);
      } else resolve(false);

      if (isLoggedIn !== undefined) resolve(isLoggedIn);
    });
  }

  isAuthenticated() {
    let value = window.sessionStorage.getItem('unique-id');
    if (!value || !window.name) {
      value = '_' + Math.random().toString(36).substr(2, 9);
      window.sessionStorage.setItem('unique-id', value);
    }

    window.name = value;
    const idValue = window.sessionStorage.getItem('unique-id');
    const entity = this.getEntity(idValue);
    let isLoggedIn: boolean;
    if (entity && Object.keys(entity).length !== 0) {
      this.userPool = CognitoUtils.getUserPool(entity);
      if (entity.user) {
        const cognitoUser = this.getCognitoUserData(
          entity.user.id.payload['cognito:username'],
          this.userPool
        );
        // const cognitoUser = CognitoUtils.getCurrentUser(entity);
        if (cognitoUser != null) {
          cognitoUser.getSession((err, session) => {
            if (err) {
              isLoggedIn = false;
            } else {
              isLoggedIn = session.isValid();
            }
          });
        } else {
          isLoggedIn = false;
        }
      } else {
        isLoggedIn = false;
      }
    } else {
      const active: any = this.getActive();
      if (active && Object.keys(active).length !== 0) {
        active._id = value;
        this.store.add(active);
        this.store.setActive(value);
        isLoggedIn = true;
      } else {
        isLoggedIn = false;
      }
    }

    return isLoggedIn;
  }

  getToken(): Observable<string> {
    return new Observable(observer => {
      let token;
      const idValue = window.sessionStorage.getItem('unique-id');
      const entity = this.getEntity(idValue);
      this.userPool = CognitoUtils.getUserPool(entity);
      if (entity.user) {
        const cognitoUser = this.getCognitoUserData(
          entity.user.id.payload['cognito:username'],
          this.userPool
        );
        if ((cognitoUser as any)?.signInUserSession) {
          (cognitoUser as any)?.cacheTokens();
        }

        // CognitoUtils.getCurrentUser(entity).getSession((err, session) => {
        //   token = session.idToken.jwtToken;
        // });
        cognitoUser.getSession((err, session) => {
          if (session && session.isValid()) {
            token = session.idToken.jwtToken;
          } else {
            if (session)
              cognitoUser.refreshSession(
                session.refreshToken,
                (err, session) => {
                  if (err) throw err;
                  if (session && session.isValid()) {
                    token = session.idToken.jwtToken;
                    this.updateSession(session);
                  } else token = null;
                }
              );
          }
          observer.next(token);
        });
      } else observer.next(null);
    });
  }

  createInitialState(): any {
    let value = window.sessionStorage.getItem('unique-id');
    if (!value || !window.name) {
      value = '_' + Math.random().toString(36).substr(2, 9);
      window.sessionStorage.setItem('unique-id', value);
    }

    window.name = value;
    return {
      _id: value,
      user: null,
      loading: false,
      uuid: null,
      userPoolId: '',
      clientId: '',
      forgotPassword: {
        email: null,
        success: false
      },
      resetPassword: {
        success: false
      }
    };
  }

  createInitialUserState(id): any {
    return {
      user: null,
      loading: false,
      uuid: id,
      userPoolId: '',
      clientId: '',
      forgotPassword: {
        email: null,
        success: false
      },
      resetPassword: {
        success: false
      }
    };
  }

  createSession(user: User) {
    return { ...user };
  }
  private getCognitoUserData(email: string, userPool) {
    return new CognitoUser({
      Username: email,
      Pool: userPool
    });
  }

  get getUserData() {
    const idValue = window.sessionStorage.getItem('unique-id');
    const entity = this.getEntity(idValue);
    if (entity) return entity;
    else {
      return null;
    }
  }

  set updateUserData(data: any) {
    let value = window.sessionStorage.getItem('unique-id');
    if (!value || !window.name) {
      value = '_' + Math.random().toString(36).substr(2, 9);
      window.sessionStorage.setItem('unique-id', value);
    }

    window.name = value;
    const idValue = window.sessionStorage.getItem('unique-id');
    if (!this.hasEntity(idValue)) {
      this.store.add(this.createInitialState());
      this.store.update(idValue, data);
    } else {
      this.store.update(idValue, data);
    }
    this.store.setActive(idValue);
  }

  // set updateForgotPassword(data: any) {
  //   const idValue= window.sessionStorage.getItem('unique-id');
  //   if(!this.hasEntity(idValue)) {

  //   } else {
  //     this.store.update(idValue, data );
  //   }
  //   this.store.setActive(idValue);
  // }

  // set updateResetPassword(data: any) {
  //   const idValue= window.sessionStorage.getItem('unique-id');
  //   if(!this.hasEntity(idValue)) {

  //   } else {
  //     this.store.update(idValue, data );
  //   }
  //   this.store.setActive(idValue);
  // }

  // set logoutUser(data:any) {
  //   const idValue= window.sessionStorage.getItem('unique-id');
  //   if(!this.hasEntity(idValue)) {

  //   } else {
  //     this.store.update(idValue, data );
  //   }
  //   this.store.setActive(idValue);
  // }

  login(data: CognitoUserSession) {
    const user = this.createSession({
      access: data.getAccessToken(),
      refresh: data.getRefreshToken(),
      id: data.getIdToken()
    });
    if (data.isValid()) {
      // this.update({ user });
      const idValue = window.sessionStorage.getItem('unique-id');
      if (!this.hasEntity(idValue)) {
        this.store.add(this.createInitialState());
        const userPool = CognitoUtils.getUserPool(this.getUserData);
        userPool.getCurrentUser().setSignInUserSession(data);
      } else {
        this.store.update(idValue, { user });
      }
      this.store.setActive(idValue);
      // this.authQuery.updateUser= user;
      if (
        this.appStore.getValue().redirectUrl &&
        this.appStore.getValue().redirectUrl !== '/'
      ) {
        this.router.navigateByUrl(this.appStore.getValue().redirectUrl);
      } else {
        this.router.navigate(['/features']);
      }
    }
  }

  updateSession(data: CognitoUserSession) {
    const user = this.createSession({
      access: data.getAccessToken(),
      refresh: data.getRefreshToken(),
      id: data.getIdToken()
    });
    const idValue = window.sessionStorage.getItem('unique-id');
    if (!this.hasEntity(idValue)) {
      this.store.add(this.createInitialState());
    } else {
      this.store.update(idValue, { user });
    }
    this.store.setActive(idValue);
  }

  forgotPasswordUpdate(showSuccessPage: boolean, email?: string) {
    // this.update({ forgotPassword: { email, success: showSuccessPage } });
    const idValue = window.sessionStorage.getItem('unique-id');
    if (!this.hasEntity(idValue)) {
      this.store.add(this.createInitialState());
    } else {
      this.store.update(idValue, {
        forgotPassword: { email, success: showSuccessPage }
      });
    }
    this.store.setActive(idValue);
    // this.authQuery.updateForgotPassword=;
  }

  resetPasswordUpdate(success: boolean) {
    // this.update({ resetPassword: { success } });
    const idValue = window.sessionStorage.getItem('unique-id');
    if (!this.hasEntity(idValue)) {
      this.store.add(this.createInitialState());
    } else {
      this.store.update(idValue, { resetPassword: { success } });
    }
    // this.authQuery.updateResetPassword={ resetPassword: { success } };
    if (success) {
      // this.router.navigateByUrl('/').then(() => {
      this.store.reset();
      // });
    }
  }

  resetUser() {
    const uuid = this.getValue().uuid;
    // this.update(createInitialUserState(uuid));
    const idValue = window.sessionStorage.getItem('unique-id');
    if (this.hasEntity(idValue)) {
      this.store.update(idValue, this.createInitialUserState(uuid));
    }
    this.store.setActive(idValue);
    // this.authQuery.logoutUser=createInitialUserState(uuid);
  }

  removeUser(token: any) {
    this.store.remove(auth => auth.user && auth.user.access.jwtToken === token);
  }

  constructor(
    protected store: AuthStore,
    private router: Router,
    private appStore: AppStore
  ) {
    super(store);
  }
}
