import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bar-chart-with-brush-v1',
  templateUrl: './bar-chart-with-brush-v1.component.html',
  styleUrls: ['./bar-chart-with-brush-v1.component.scss']
})
export class BarChartWithBrushV1Component implements OnInit {
  startId;
  endId;
  @Input() set start(start) {
    this.startId = start;
    if (this.data && this.data.length) {
      this.setBrushPosition([start, this.endId], this.x, this.brush, false);
    }
  }
  @Input() set end(end) {
    this.endId = end;
    if (this.data && this.data.length) {
      this.setBrushPosition([this.startId, end], this.x, this.brush, false);
    }
  }
  @Input() data: any[] = [];
  @Input() xTitle = '';
  @Input() yTitle = '';
  @Output() startEndEmitter = new EventEmitter();

  private svg;
  private x;
  private y;
  private brush;
  private width = 1000;
  private height = 400;
  private margin = { top: 50, bottom: 50, left: 50, right: 50 };
  private padding = 0.3;
  private invert;

  ngOnInit() {
    this.initChart();
    this.createXAxis();
    this.createYAxis();
    this.createChart();
    this.appendAxis();
    this.createBrush();
    const ids = this.data.map(d => d.id);
    this.startId = d3.min(ids);
    this.endId = d3.max(ids);
  }

  initChart() {
    this.width = parseInt(d3.select('#svg-container').style('width'), 0);
    this.height = parseInt(d3.select('#svg-container').style('height'), 0);
    this.svg = d3
      .select('#svg-container')
      .attr('width', this.width - this.margin.left - this.margin.right)
      .attr('height', this.height - this.margin.top - this.margin.bottom)
      .append('g');
  }

  createXAxis() {
    this.x = d3
      .scaleBand()
      .domain(this.data.map(d => d.id))
      .range([this.margin.left, this.width - this.margin.right])
      .padding(this.padding);
    this.invert = (() => {
      const domain = this.x.domain();
      const range = this.x.range();
      const scale = d3.scaleQuantize().domain(range).range(domain);
      return x => {
        return scale(x);
      };
    })();
  }

  createYAxis() {
    this.y = d3
      .scaleLinear()
      .domain([0, d3.max(this.data.map(d => d.count))])
      .range([this.height - this.margin.bottom, this.margin.top]);
  }

  createChart() {
    this.svg
      .append('g')
      .attr('fill', 'royalblue')
      .selectAll('rect')
      .data(this.data)
      .join('rect')
      .attr('x', (d, i) => this.x(i))
      .attr('y', d => this.y(d.count))
      .attr('height', (d, i) => {
        return this.y(0) - this.y(d.count);
      })
      .attr('width', this.x.bandwidth())
      .attr('class', 'rectangle');
  }

  xAxis = g => {
    g.attr('transform', `translate(0, ${this.height - this.margin.bottom})`)
      .call(d3.axisBottom(this.x).tickFormat((d, i) => this.data[i].label))
      .attr('font-size', '10px');
  };

  yAxis = g => {
    g.attr('transform', `translate(${this.margin.left}, 0)`)
      .call(d3.axisLeft(this.y))
      .attr('font-size', '10px');
  };

  appendAxis() {
    this.svg.append('g').call(this.xAxis);
    this.svg.append('g').call(this.yAxis);
  }

  createBrush() {
    this.brush = d3
      .brushX()
      .extent([
        [this.margin.left, this.margin.right],
        [this.width - this.margin.right, this.height - this.margin.bottom]
      ])
      .on('end', this.brushEnded);

    const gBrush = this.svg.attr('class', 'brush-x').call(this.brush);
    setTimeout(() => {
      this.setBrushPosition(
        [this.startId, this.endId],
        this.x,
        this.brush,
        false
      );
    }, 100);
  }

  brushEnded = () => {
    if (!d3.event || !d3.event.sourceEvent) {
      return;
    }
    if (!d3.event.selection) {
      this.setBrushPosition(
        [this.startId, this.endId],
        this.x,
        this.brush,
        false
      );
      return;
    }
    const startEndId = d3.event.selection.map(this.invert);
    this.startId = startEndId[0];
    this.endId = startEndId[1];
    this.setBrushPosition(startEndId, this.x, this.brush, true);
  };

  setBrushPosition(idRange, xScale, brushX, dragEnd = false) {
    const brushStartPos = idRange.map(xScale)[0];
    const brushEndPos = idRange.map(xScale)[1] + xScale.bandwidth();
    if (!brushStartPos || !brushEndPos) {
      this.clearBrushSelection(brushX);
      return;
    }
    d3.selectAll('rect.rectangle').style('opacity', (d, i, n) => {
      const barX = d3.select(n[i]).attr('x');
      return barX >= brushStartPos && barX <= brushEndPos ? '1' : '.2';
    });
    if (dragEnd) {
      const output = {
        start: this.data[idRange[0]],
        end: this.data[idRange[1]]
      };
      this.startEndEmitter.emit(output);
    }
    if (brushX && brushStartPos && brushEndPos) {
      d3.select('.brush-x')
        .transition()
        .call(brushX.move, [brushStartPos, brushEndPos]);
    }
  }

  clearBrushSelection(brushX) {
    d3.select('.brush-x').transition().call(brushX.move, [-1, -1]);
    d3.selectAll('rect.rectangle').style('opacity', (d, i, n) => {
      return '.2';
    });
  }
}
