import {
  combineLatest as observableCombineLatest,
  Observable,
  Subject,
  Subscription
} from 'rxjs';

import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import {
  Directive,
  Input,
  OnInit,
  OnDestroy,
  DoCheck,
  Inject,
  HostBinding,
  forwardRef
} from '@angular/core';

import { NgxErrorsDirective } from './ngxerrors.directive';

import { ErrorOptions } from './ngxerrors';

import { toArray } from './utils/toArray';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngxError]'
})
export class NgxErrorDirective implements OnInit, OnDestroy, DoCheck {
  @Input() set ngxError(value: ErrorOptions) {
    this.errorNames = toArray(value);
  }

  @Input() set when(value: ErrorOptions) {
    this.rules = toArray(value);
  }

  @HostBinding('hidden')
  hidden = true;

  rules: string[] = [];

  errorNames: string[] = [];

  subscription: Subscription;

  statesSubject: Subject<string[]>;

  states: Observable<string[]>;

  constructor(
    @Inject(forwardRef(() => NgxErrorsDirective))
    private ngxErrors: NgxErrorsDirective
  ) {}

  ngOnInit() {
    this.statesSubject = new Subject<string[]>();
    this.states = this.statesSubject
      .asObservable()
      .pipe(distinctUntilChanged());

    const errors = this.ngxErrors.subject.pipe(
      filter(Boolean),
      filter(obj => !!~this.errorNames.indexOf(obj[`errorName`]))
    );

    const states = this.states.pipe(
      map(statesInner => this.rules.every(rule => !!~statesInner.indexOf(rule)))
    );

    this.subscription = observableCombineLatest([states, errors]).subscribe(
      ([statesLatest, errorsLatest]) => {
        this.hidden = !(
          statesLatest &&
          errorsLatest[`control`].hasError(errorsLatest[`errorName`])
        );
      }
    );
  }

  ngDoCheck() {
    this.statesSubject.next(
      this.rules.filter(rule => (this.ngxErrors.control as any)[rule])
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
