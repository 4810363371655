// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apply-filter {
  background-color: #0000a0;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/process-explorer-filter/process-explorer-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;AACF","sourcesContent":[".apply-filter{\n  background-color: #0000a0;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
