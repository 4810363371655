// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-block;
}

::ng-deep.white.mat-mdc-progress-spinner circle, ::ng-deep.white.mat-mdc-spinner circle {
  stroke: #ffffff !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/progress-spinner/progress-spinner.component.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;AAAF;;AAKI;EACE,0BAAA;AAFN","sourcesContent":["@import 'variables';\n:host {\n  display: inline-block;\n}\n::ng-deep.white {\n  &.mat-mdc-progress-spinner,\n  &.mat-mdc-spinner {\n    circle {\n      stroke: $white !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
