import { Injectable, Inject } from '@angular/core';
import { ProcessExplorerStore } from './process-explorer.store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '@bli-shared/utils/app-config.module';
import { Observable, throwError, Subject } from 'rxjs';
import { tap, catchError ,  takeUntil } from 'rxjs/operators';
import { ProcessAnalyticsQuery } from './../../state/process-analytics.query';
import { GlobalFiltersService } from './../../../../core/global-filters/state/global-filters.service';
import { DetectChangeService } from '../../../../services/detect-change.service';

@Injectable({ providedIn: 'root' })
export class ProcessExplorerService {

  constructor(
    private processExplorerStore: ProcessExplorerStore,
    private processAnalyticsQuery: ProcessAnalyticsQuery,
    private globalFiltersService: GlobalFiltersService,
    private detectChangeService: DetectChangeService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private http: HttpClient
  ) { }

  private cancelLayoutRequest$ = new Subject<void>();
  private cancelNodeStatesRequest$ = new Subject<void>();
  private cancelGraphMetricsRequest$ = new Subject<void>();
  private cancelVariantsRequest$ = new Subject<void>();
  private cancelPathActivityGraphRequest$ = new Subject<void>();
  private cancelEdgeActivityGraphRequest$ = new Subject<void>();
  private cancelPagesListRequest$ = new Subject<void>();

  private relaodPEGraph = new Subject<true>();
  set relaodProcessExplorerGraph(reload: true) { this.relaodPEGraph.next(reload); }
  get relaodProcessExplorerGraph$() { return this.relaodPEGraph.asObservable(); }


  updateGlobalFilterPayload(payload: any) {
    const gFilter = this.globalFiltersService.getGlobalFilter();

    if (!gFilter.global_filters || (gFilter.global_filters && !gFilter.global_filters.length)) {
      payload.variant_ids = null;
      payload.cases = undefined;
    }

    if (payload.variant_ids && !payload.variant_ids.length) {
      payload.cases = undefined;
    }
    return payload;
  }

  getVariants(payload): Observable<any> {
    const url = `${this.rootUrl}/process_analytics/list_variants/`;
    const globalFilters = this.globalFilter;
    if (globalFilters && globalFilters.length) {
      payload.global_filters = this.globalFilter;
    }
    // this.detectChangeService.enableFilterButton(false);
    return this.http.post<any>(url, payload).pipe(
      tap((res) => {
        const stats = (res && res.data && res.data.length) ? res.data[0] : {};
        const variants = stats.variants ? stats.variants : [];
        const cases = stats.cases ? stats.cases : undefined;
        const varaintIds = variants.map((variant) => {
          return variant.variant_id;
        });

        // Update variant ids in global filter store
        // const filters = {...this.globalFiltersService.getGlobalFilter()};
        // filters.variant_ids = varaintIds;
        // filters.cases = cases;
        // this.globalFiltersService.saveGlobalFilterData(filters);

        // Enable the filter button
        // this.detectChangeService.enableFilterButton(true);

        // Update the cases count
        this.detectChangeService.updateCaseCount({filtered_count: stats.filtered_count, total_count: stats.total_count});
      }),
      catchError(err => {
        return throwError(err);
      }),
      takeUntil(this.onCancelVariantsRequest())
    );
  }

  public cancelVariantsRequest() {
    this.cancelVariantsRequest$.next();
  }

  public onCancelVariantsRequest() {
    return this.cancelVariantsRequest$.asObservable();
  }

  getGlobalFilterStats(payload): Observable<any> {
    const url = `${this.rootUrl}/process_analytics/case_count/`;
    const globalFilters = this.globalFilter;
    if (globalFilters && globalFilters.length) {
      payload.global_filters = this.globalFilter;
    }
    const cases = this.globalFiltersService.getGlobalFilter().cases;
    payload.cases = cases ? cases : undefined;

    return this.http.post<any>(url, payload).pipe(
      tap((res) => {
        const stats = (res && res.data && res.data.length) ? res.data[0] : {};
        // Update the cases count
        this.detectChangeService.updateCaseCount({filtered_count: stats.filtered_count, total_count: stats.total_count});
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }


  getCaseCountsAndVariants(payload): Observable<any> {
    const url = `${this.rootUrl}/process_analytics/get-variants-and-cases/`;
    const globalFilters = this.globalFilter;
    payload.global_filters = globalFilters && globalFilters.length ? this.globalFilter : [];
    payload.include_data = false;

    return this.http.post<any>(url, payload).pipe(
      tap((res) => {
        const stats = (res && res.data && res.data.length) ? res.data[0] : {};
        const varaintIds = stats.variants && stats.variants ? stats.variants : null;
        const cases = stats.cases ? stats.cases : undefined;

        // Update variant ids in global filter store
        const filters = {...this.globalFiltersService.getGlobalFilter()};
        filters.variant_ids = varaintIds;
        filters.cases = cases;
        this.globalFiltersService.saveGlobalFilterData(filters);

      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }


  getProcessExplorerLayout(inputData: any): Observable<string> {
    this.processExplorerStore.setLoading(true);
    const payload = {
      ...inputData
    }
    const globalFilters = this.globalFilter;
    if (globalFilters && globalFilters.length) {
      payload.global_filters = this.globalFilter;
    }

    const cases = this.globalFiltersService.getGlobalFilter().cases;
    payload.cases = cases ? cases : undefined;

    // payload = this.updateGlobalFilterPayload(payload);
    const gFilter = this.globalFiltersService.getGlobalFilter();

    if (!gFilter.global_filters || (gFilter.global_filters && !gFilter.global_filters.length)) {
      payload.cases = undefined;
    }

    if (payload.variant_ids && !payload.variant_ids.length) {
      payload.cases = undefined;
    }

    const url = `${this.rootUrl}/process_analytics/generate_process_map/`;
    return this.http.post<string>(url, payload).pipe(
      tap((res: any) => {
        this.processExplorerStore.setLoading(false);
      }),
      catchError(err => {
        this.processExplorerStore.setLoading(false);
        return throwError(err);
      }),
      takeUntil(this.onCancelLayoutRequest())
    );
  }

  public cancelLayoutRequest() {
    this.cancelLayoutRequest$.next();
  }

  public onCancelLayoutRequest() {
    return this.cancelLayoutRequest$.asObservable();
  }


  getNodeStates(inputData): Observable<any> {
    const filters = {...this.globalFiltersService.getGlobalFilter()};
    let payload = {
      ...inputData
    }
    payload.variant_ids = filters.variant_ids;
    payload.cases = filters.cases ? filters.cases : undefined;
    if (filters.global_filters && filters.global_filters.length) {
      payload.global_filters = filters.global_filters;
    }

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/get_node_details/`;
    return this.http.post<any>(url, payload).pipe(
      tap((res) => {
      }),
      catchError(err => {
        return throwError(err);
      }),
      takeUntil(this.onCancelNodeStatesRequest())
    );
  }

  public cancelNodeStatesRequest() {
    this.cancelNodeStatesRequest$.next();
  }

  public onCancelNodeStatesRequest() {
    return this.cancelNodeStatesRequest$.asObservable();
  }


  getEdgesStates(inputData): Observable<any> {
    const filters = {...this.globalFiltersService.getGlobalFilter()};
    let payload = {
      ...inputData
    }
    payload.variant_ids = filters.variant_ids;
    payload.cases = filters.cases ? filters.cases : undefined;
    if (filters.global_filters && filters.global_filters.length) {
      payload.global_filters = filters.global_filters;
    }

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/get_edge_details/`;
    return this.http.post<any>(url, payload).pipe(
      tap((res) => {}),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  generateGraphMetrics(inputData): Observable<string> {
    let payload: any = {
      ...inputData
    };
    const filters = {...this.globalFiltersService.getGlobalFilter()};
    payload.variant_ids = filters.variant_ids ? filters.variant_ids : [];
    payload.cases = filters.cases ? filters.cases : undefined;
    if (filters.global_filters && filters.global_filters.length) {
      payload.global_filters = filters.global_filters;
    }

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/p_exp/generate_graph_metrics/`;
    return this.http.post<string>(url, payload).pipe(
      tap((res: any) => {
      }),
      catchError(err => {
        return throwError(err);
      }),
      takeUntil(this.onCancelGraphMetricsRequest())
    );
  }
  public cancelGraphMetricsRequest() {
    this.cancelGraphMetricsRequest$.next();
  }

  public onCancelGraphMetricsRequest() {
    return this.cancelGraphMetricsRequest$.asObservable();
  }


  getPathActivityGraphData(inputData: any): Observable<string> {
    this.processExplorerStore.setLoading(true);
    const globalFilters = this.globalFilter;
    let payload: any = {
      ...inputData
    };
    const filters = {...this.globalFiltersService.getGlobalFilter()};
    payload.variant_ids = filters.variant_ids ? filters.variant_ids : [];
    payload.cases = filters.cases ? filters.cases : undefined;
    if (filters.global_filters && filters.global_filters.length) {
      payload.global_filters = filters.global_filters;
    }

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/p_exp/node_slider_changed/`;
    return this.http.post<string>(url, payload).pipe(
      tap((res: any) => {
        this.processExplorerStore.setLoading(false);
      }),
      catchError(err => {
        this.processExplorerStore.setLoading(false);
        return throwError(err);
      }),
      takeUntil(this.onCancelPathActivityGraphRequest())
    );
  }

  public cancelPathActivityGraphRequest() {
    this.cancelPathActivityGraphRequest$.next();
  }

  public onCancelPathActivityGraphRequest() {
    return this.cancelPathActivityGraphRequest$.asObservable();
  }

  getEdgeActivityGraphData(inputData: any): Observable<string> {
    this.processExplorerStore.setLoading(true);
    let payload: any = {
      ...inputData
    };

    const filters = {...this.globalFiltersService.getGlobalFilter()};
    payload.variant_ids = filters.variant_ids ? filters.variant_ids : [];
    payload.cases = filters.cases ? filters.cases : undefined;
    if (filters.global_filters && filters.global_filters.length) {
      payload.global_filters = filters.global_filters;
    }

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/p_exp/edge_slider_changed/`;
    return this.http.post<string>(url, payload).pipe(
      tap((res: any) => {
        this.processExplorerStore.setLoading(false);
      }),
      catchError(err => {
        this.processExplorerStore.setLoading(false);
        return throwError(err);
      }),
      takeUntil(this.onCancelEdgeActivityGraphRequest())
    );
  }

  public cancelEdgeActivityGraphRequest() {
    this.cancelEdgeActivityGraphRequest$.next();
  }

  public onCancelEdgeActivityGraphRequest() {
    return this.cancelEdgeActivityGraphRequest$.asObservable();
  }

  getVariantMethods(payload): Observable<any> {
    const url = `${this.rootUrl}/intellect_engine/get_static_dropdown_details/`;
    return this.http.post<any>(url, payload).pipe(
      tap((res) => {}),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getPAState(payload) {
    const { process_analytics_cluster_id } = this.processAnalyticsQuery.GetAnalysisClusterData;
    const { process_analysis_id } = this.processAnalyticsQuery.GetAnalysisData;
    payload = {
      ...payload,
      process_analytics_cluster_id,
      process_analysis_id
    }
    const url = `${this.rootUrl}/process_analytics/store/get/`;
    return this.http.post(url, payload)
  }

  setPAState(payload) {
    const { process_analytics_cluster_id } = this.processAnalyticsQuery.GetAnalysisClusterData;
    const { process_analysis_id } = this.processAnalyticsQuery.GetAnalysisData;
    payload = {
      ...payload,
      process_analytics_cluster_id,
      process_analysis_id
    }
    const url = `${this.rootUrl}/process_analytics/store/set/`;
    return this.http.post(url, payload)
  }

  get urlBase(): string {
    return `${this.urlAnalysisCluster}${this.analysisClusterId}/analysis_id/${this.analysisId}/`;
  }

  private get urlAnalysisCluster(): string {
    return `${this.urlRoot}/${this.appConfig.ANAYSIS_API.CLUSTER}`;
  }

  private get urlRoot(): string {
    return this.appConfig.ANAYSIS_API.ROOT;
  }

  get rootUrl(): string {
    return this.appConfig.ANAYSIS_API.ROOT;
  }

  get analysisClusterId(): string {
    return this.processAnalyticsQuery.GetAnalysisClusterData.process_analytics_cluster_id;
  }

  get analysisId(): string {
    return this.processAnalyticsQuery.GetAnalysisData.analysis_id;
  }

  get analysisClusterUrl(): string {
    return `${this.rootUrl}/${this.appConfig.ANAYSIS_API.CLUSTER}`;
  }

  get globalFilter(): any {
    return this.globalFiltersService.getGlobalFilterPayload() || [];
  }
}

