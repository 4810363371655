import { Injectable, ErrorHandler } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@bli/authentication/state';
import { SnackBarStore } from '@bli-shared/components/snack-bar/state';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

enum ErrorMessages {
  BlicoreException = 'Core Error',
  ETLError = 'ETL Error Occured',
  ExtractionError = 'Extraction Error',
  TimedOutError = 'Timed Out',
  ProcessSkippedError = 'Process Skipped',
  NotAllowedError = 'Not Allowed',
  InvalidColumnError = 'Invalid Column',
  UnSupportedError = 'Unsupported Error',
  DatabaseAlreadyExists = 'Database Already Exists',
  DatabaseDoesNotExist = 'Database Does Not Exist',
  SignalTableDoesNotExist = 'Signal Table Does Not Exist',
  ArgumentTypeError = 'Arugment Type Error',
  InCompletePayloadError = 'Incomplete Payload Error',
  BadArgumentError = 'Bad Argumemt Error',
  BadResultError = 'Bad Result Error',
  DuplicateItemsFoundError = 'Duplicate Items Found',
  DataError = 'Data Error',
  VoidError = 'Void Error',
  AmbuiguityError = 'Ambuiguity Error',
  AmbiguityError = 'Ambuiguity Error',
  ConfigurationError = 'Configuration Error',
  NonComplianceError = 'Non Compliance Error',
  NoDatamodelMappedError = 'No Datamodel Mapped',
  AlreadyExistError = 'Already Exists',
  DatamodelDoesNotExist = 'Datamodel Doesnot Exist',
  SQLSyntaxError = 'Sql Syntax Error',
  SQLError = 'Sql Error',
  SQLKeywordMissingError = 'Sql Keyword Missing',
  SQLTableNotFoundError = 'Sql Table Not Found',
  SQLColumnNotFoundError = 'Sql Column Not Found',
  SQLStatementNotFoundError = 'Sql Statement Not Found',
  SQLStatementMismatchError = 'Sql Statement Mismatch',
  SchedulerNotRespondingError = 'Scheduler Not Responding',
  RemoteServiceNotRespondingError = 'Remote Service Not Responding',
  RemoteServiceUnauthorizedError = 'Remote Service Unauthorized',
  CognitoUserEnrollError = 'Cognito User Enroll Error',
  AccessDeniedError = 'Access Denied',
  RemoteServiceForbiddenError = 'Remote Service Forbidden',
  DataDrainedError = 'Data Drained',
  CustomSQLAlchemyError = 'Custom Sql Alchemy Error Occured',
  DatabaseNotReachableError = 'Database Not Reachable',
  UnauthorizedError = 'Unauthorized',
  UnResolvedError = 'Unresolved',
  ForbiddenError = 'Forbidden',
  NoImplementationFoundError = 'Not Implemented',
  DeprecatedError = 'Temporarily Unavailable'
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor, ErrorHandler {
  constructor(
    private authenticationService: AuthService,
    private snackBarStore: SnackBarStore,
    private router: Router,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {}

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (
          err.status === 500 ||
          err.status === 501 ||
          err.status === 502 ||
          err.status === 503 ||
          // err.status === 504 ||  commenting this because we need to listen to 504 server timeout scenario service level
          err.status === 505 ||
          err.status === 508 ||
          err.status === 511 ||
          err.status === 521 ||
          err.status === 525
        ) {
          this.errorSnackBar('Something went wrong');
          return;
        }
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logoutUser();
          this.router.navigate(['/']);
          this.dialog.closeAll();
          // location.reload();
        }
        if (err.error.code === 403 && err.error.error_class === 'JWTError') {
          // auto logout if 403 and  error_class='JWTError' response returned from api
          this.authenticationService.logoutUser();
          this.router.navigate(['/']);
          this.dialog.closeAll();
          return;
        }
        if (err.status === 409) {
          if (err.error.error_class === 'ProcessSkippedError') {
            this.infoSnackBar(err.error.message);
            return;
          }
        }
        if (
          err.error.code === 400 &&
          err.error.error_class === 'AuthenticationError'
        ) {
          this.errorSnackBar(err.error.message);
          return;
        }
        // let error = err.error.message || err.statusText;
        let error = ErrorMessages[err.error.error_class];
        if (err.error.data && !request.url.includes('ds-connector')) {
          error = err.error.data.length
            ? err.error.data.message
            : err.error.msg;
          if (!error && err.error.message) {
            error = err.error.message;
          }
        }
        if (
          request.url.includes('data-transformation-sql') &&
          err.status !== 500
        ) {
          return throwError(err);
        }
        const url = request.url;
        if (url.includes('ds-connector')) {
          this.dataConnectorError();
          return throwError(err);
        }
        return throwError(err);
      })
    );
  }

  infoSnackBar(message: string): void {
    this.toastrService.show(JSON.stringify({ type: 'info', text: message }));
  }

  errorSnackBar(message: string): void {
    this.toastrService.show(JSON.stringify({ type: 'error', text: message }));
  }

  dataConnectorError(): void {
    this.toastrService.show(
      JSON.stringify({
        type: 'error',
        text: `Failed to connect to database. Input correct parameters and try again`
      })
    );
  }
}
