import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppService } from '@bli/state/app.service';
import { RouteData } from '@bli/state/app.model';
import { CustomIconsService } from './services/custom-icons.service';
import { AppStore } from './state/app.store';
import { RevenueOperationsStore } from './product-features/revenue-operations/state';
import { FunnelAnalysisStore } from '@bli/product-features/revenue-operations/funnel-analysis/state/funnel-analysis.store';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  constructor(
    private revenewOperationStore: RevenueOperationsStore,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private customIconsService: CustomIconsService,
    private appStore: AppStore,
    private funnelAnalysisStore: FunnelAnalysisStore
  ) {
    this.customIconsService.setIcons();
  }

  ngOnInit() {
    const appData = this.appStore.getValue();
    if (appData.version === '') {
      this.appStore.update({ version: require('../../package.json').version });
      this.revenewOperationStore.resetGlobalRangeAndTimeFilter();
      this.funnelAnalysisStore.resetGlobalFunnelRangeAndTimeFilter();
    } else {
      if (appData.version !== require('../../package.json').version) {
        this.revenewOperationStore.resetGlobalRangeAndTimeFilter();
        this.funnelAnalysisStore.resetGlobalFunnelRangeAndTimeFilter();
        this.appStore.update({
          version: require('../../package.json').version
        });
      }
    }
    const routerSub = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        // get access to activate route and return activated route instance
        map(() => this.activatedRoute),
        map(route => {
          // traverse over the state tree to find the last activated route, and then return it to the stream
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe((data: RouteData) => {
        this.appService.saveRouteData(data);
        this.appService.setCurrentNavigation(data.module);
      });
    this.subscriptions.add(routerSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
