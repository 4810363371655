import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

export interface SnackBarState {
  message: string;
  success: boolean;
  actionClicked?: boolean;
  config?: Partial<ConfigSnackBar>;
}

export function createInitialState(): SnackBarState {
  return {
    message: '',
    success: false,
    actionClicked: false,
    config: {
      duration: 3000,
      action: false,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      panelClass: ['']
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'snack-bar', resettable: true })
export class SnackBarStore extends Store<SnackBarState> {
  constructor() {
    super(createInitialState());
  }

  setMessage(data: SnackBarState) {
    this.update(data);
  }

  snackbarConfig(showAction: boolean, config: Partial<ConfigSnackBar>) {
    this.update({
      config: {
        duration: config.duration,
        horizontalPosition: config.horizontalPosition
          ? config.horizontalPosition
          : 'start',
        verticalPosition: config.verticalPosition
          ? config.verticalPosition
          : 'bottom',
        panelClass: config.panelClass,
        actionButtonLabel: showAction ? config.actionButtonLabel : undefined
      }
    });
  }
}

interface ConfigSnackBar {
  duration: number;
  actionButtonLabel: string;
  horizontalPosition: MatSnackBarHorizontalPosition;
  verticalPosition: MatSnackBarVerticalPosition;
  panelClass: string[];
  action: boolean;
}
