export const Environment = {
			production: true,
			appVersion: require('../../package.json').version + '-prod',
			analysisAPIRoot: '//eng-elb.blidev.com',
			iEAPIRoot: '//eng-elb.blidev.com',
			OIAPIRoot: '//eng-elb.blidev.com',
			dataCollectionAPIRoot: 'eng-elb.blidev.com:442/api/dc-v1',
			revOperationAPIRoot: 'eng-elb.blidev.com/api/revops',
			utmAPIRootWithoutHyphen: 'eng-elb.blidev.com:444/api/utm-v1/',
			utmAPIRoot: 'eng-elb.blidev.com:444/api/utm-v1',
			taskMiningAPIRoot: '-be1.opxce.com/api/tmv1',
			IIAPIRoot: '//eng-elb.blidev.com',
			revOpsRoot: '//eng-elb.blidev.com/api/revops/',
			revenupRoot: '//eng-elb.blidev.com:446/api/revenup/',
			genAI: '//eng-elb.blidev.com:446/api/revenup/intel/'
		  };