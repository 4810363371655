import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DataConnector } from './data-connector.model';
import { Dropdown } from '@bli/modals/drop-down';

export interface DataConnectorState extends EntityState<DataConnector> {
  connectors: { [key: string]: Dropdown } | null;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'data-connector' })
export class DataConnectorStore extends EntityStore<DataConnectorState> {
  constructor() {
    super();
  }
}
