import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataCollectionStore } from './data-collection.store';
import {
  GetBucketResponse,
  CreateBucketRequest,
  GetSharedBucketResponse,
  GetUserResponse,
  CreateBucketResponse
} from './data-collection.model';
import { APP_CONFIG, AppConfig } from '@bli-shared/utils/app-config.module';
import { Observable, throwError, Subject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { SnackBarStore } from '@bli-shared/components/snack-bar/state';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class DataCollectionService {
  private deletionIds: string[] = [];
  public clickNewButton = new Subject();
  public notifyRenameSubject = new Subject();
  public clickRefreshButton = new Subject();

  constructor(
    private dataCollectionStore: DataCollectionStore,
    private snackBarStore: SnackBarStore,
    private toastrService: ToastrService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private http: HttpClient
  ) {}

  getAllBucket(payload): Observable<GetBucketResponse> {
    const url = `${this.rootUrl}${this.DCApi.DATA_BUCKET.BUCKET}`;
    return this.http
      .post<GetBucketResponse>(url, payload)
      .pipe(tap(() => this.dataCollectionStore.setLoading(false)));
  }

  addNewBucket(payload: CreateBucketRequest): Observable<CreateBucketResponse> {
    const url = `${this.rootUrl}${this.DCApi.DATA_BUCKET.CREATE_BUCKET}`;
    this.dataCollectionStore.setLoading(true);
    return this.http.post<CreateBucketResponse>(url, payload).pipe(
      tap((res: any) => {
        this.dataCollectionStore.setLoading(false);
        this.toastrService.show(
          JSON.stringify({
            type: 'success',
            text: 'New Data Bucket Created Successfully'
          })
        );
        this.notifyNewCluster(res.data.bucket_id);
      }),
      catchError(err => {
        this.dataCollectionStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getBucket(payload: { bucket_slug: string }): Observable<GetBucketResponse> {
    const url = `${this.rootUrl}${this.DCApi.DATA_BUCKET.BUCKET}`;
    return this.http.post<GetBucketResponse>(url, payload).pipe(
      tap((res: GetBucketResponse) => {
        this.dataCollectionStore.setLoading(false);
      }),
      catchError(err => {
        this.dataCollectionStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  updateBucket(payload: any): Observable<GetBucketResponse> {
    this.dataCollectionStore.setLoading(true);
    const url = `${this.rootUrl}${this.DCApi.DATA_BUCKET.UPDATE_BUCKET}`;
    return this.http.post<GetBucketResponse>(url, payload).pipe(
      tap(() => {
        this.dataCollectionStore.setLoading(false);
        this.toastrService.show(
          JSON.stringify({
            type: 'success',
            text: 'Data Bucket Updated Successfully.'
          })
        );
        this.notifyClusterRename();
      }),
      catchError(err => {
        this.dataCollectionStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  checkWhetherDataModelUsed(
    bucketslug: string,
    datamodelslug: string,
    isDataModelCheck: boolean
  ) {
    const url = `${this.rootIEUrl}${this.IEApi.DATA_MODEL.BE_RESOURCE_DATAMODEL_ISUSED}`;
    let payload = null;
    if (isDataModelCheck) {
      payload = {
        bucket_slug: bucketslug,
        datamodel_slug: datamodelslug
      };
    } else {
      payload = {
        bucket_slug: bucketslug
      };
    }

    this.dataCollectionStore.setLoading(true);
    return this.http.post<any>(url, payload).pipe(
      tap((res: any) => {
        this.dataCollectionStore.setLoading(false);
        if (res.data[0].is_used) {
          if (isDataModelCheck) {
            this.toastrService.show(
              JSON.stringify({
                type: 'error',
                text: 'Data Model Contains Used Data, Cannot Deleted'
              })
            );
          } else {
            this.toastrService.show(
              JSON.stringify({
                type: 'error',
                text: 'Data Bucket Contains Used Data, Cannot Deleted'
              })
            );
          }
        }
      }),
      catchError(err => {
        this.dataCollectionStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  deleteBucket(payload: any) {
    const url = `${this.rootUrl}${this.DCApi.DATA_BUCKET.DELETE_BUCKET}`;
    return this.http.post(url, payload).pipe(
      tap(res => {
        // this.snackBarService.updateDismissedState(false);
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  updateSnackBarConfig(): void {
    this.snackBarStore.snackbarConfig(true, {
      duration: 5000,
      actionButtonLabel: 'Undo'
    });
  }

  // resetSnackbarConfig(): void {
  //   this.snackBarStore.snackbarConfig(true, {
  //     duration: 5000,
  //   });
  // }

  showDeleteSuccessSnackBar(message: string): void {
    this.toastrService.show(JSON.stringify({ type: 'success', text: message }));
  }

  updateWaitingDeletionIds(id: string, reset?: boolean) {
    if (reset) {
      this.deletionIds = [];
    } else {
      if (!this.deletionIds.includes(id)) {
        this.deletionIds.push(id);
      }
    }
    this.dataCollectionStore.update({
      waitingDel: this.deletionIds
    });
  }

  getPermissions() {
    // const
    const url = `${this.rootUrl}general/static-drop-down/`;
    const payload = {
      components: ['permissions']
    };
    this.dataCollectionStore.setLoading(true);
    return this.http.post<any>(url, payload).pipe(
      tap((res: any) => {
        this.dataCollectionStore.setLoading(false);
      }),
      catchError(err => {
        this.dataCollectionStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getShareBucket(payload): Observable<GetSharedBucketResponse> {
    const url = `${this.rootUrl}${this.DCApi.DATA_BUCKET.SHARED_USERS}`;
    this.dataCollectionStore.setLoading(true);
    return this.http.post<GetSharedBucketResponse>(url, payload).pipe(
      tap((res: GetSharedBucketResponse) => {
        this.dataCollectionStore.setLoading(false);
      }),
      catchError(err => {
        this.dataCollectionStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  createShareBucket(payload: any): Observable<any> {
    const url = `${this.rootUrl}${this.DCApi.DATA_BUCKET.SHARED}`;
    this.dataCollectionStore.setLoading(true);
    return this.http.post<any>(url, payload).pipe(
      tap((res: any) => {
        this.dataCollectionStore.setLoading(false);
        this.toastrService.show(
          JSON.stringify({
            type: 'success',
            text: 'Permission Created Successfully.'
          })
        );
      }),
      catchError(err => {
        this.dataCollectionStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  updateShareBucket(payload: any): Observable<any> {
    const url = `${this.rootUrl}${this.DCApi.DATA_BUCKET.UPDATE_SHARED}`;
    this.dataCollectionStore.setLoading(true);
    return this.http.post<any>(url, payload).pipe(
      tap((res: any) => {
        this.dataCollectionStore.setLoading(false);
        this.toastrService.show(
          JSON.stringify({
            type: 'success',
            text: 'Permission Updated Successfully.'
          })
        );
      }),
      catchError(err => {
        this.dataCollectionStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getUsers(): Observable<GetUserResponse> {
    const url = `${this.rootUrl}general/auto-complete/member-email/`;
    const payload = {
      email: ''
    };
    return this.http.post<GetUserResponse>(url, payload).pipe(
      tap((res: GetUserResponse) => {}),
      catchError(err => {
        this.dataCollectionStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  private postMethod(payload, url, successMessage?: string) {
    this.dataCollectionStore.setLoading(true);
    return this.http.post<any>(url, payload).pipe(
      tap(() => {
        this.dataCollectionStore.setLoading(false);
        if (successMessage) {
          this.toastrService.show(
            JSON.stringify({ type: 'success', text: successMessage })
          );
        }
      }),
      catchError(err => {
        this.dataCollectionStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  // isProcessingDataModel(modelId: string): Observable<any> {
  //   const url = `${this.rootUrl}data-model-processed/${modelId}/`;
  //   return this.http.post(url, {});
  // }

  private get sharedApi() {
    return this.appConfig.DATA_COLLECTION_API.DATA_BUCKET.SHARED;
  }

  private get shareApi() {
    return this.appConfig.DATA_COLLECTION_API.DATA_BUCKET.SHARED_USERS;
  }

  private get DCApi() {
    return this.appConfig.DATA_COLLECTION_API;
  }

  private get IEApi() {
    return this.appConfig.INTELLECT_ENGINE;
  }
  private get rootIEUrl(): string {
    return `${this.appConfig.INTELLECT_ENGINE.ROOT}/`;
  }

  private get rootUrl(): string {
    return `${this.appConfig.DATA_COLLECTION_API.ROOT}/`;
  }

  refresh() {
    this.clickRefreshButton.next(null);
  }

  addNewItemToList() {
    this.clickNewButton.next(null);
  }

  notifyNewCluster(clusterId) {
    this.clickNewButton.next(clusterId);
  }

  notifyNewAnalysis(analysisId) {
    this.clickNewButton.next(analysisId);
  }

  notifyClusterRename() {
    this.notifyRenameSubject.next(null);
  }

  notifyAnalysisRename() {
    this.notifyRenameSubject.next(null);
  }
}
