import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CompareFlow } from '../../shared/modules/revops-sankey-compareflow/state/compareflow.model';
import {
  AdditionalFilter,
  FunnelAnalysis,
  CasesVatiantsHash,
  ReadMode
} from './funnel-analysis.model';

export type FunnelTimeFilter = {
  column_data_type: string;
  column_name: string;
  end_date: any;
  filter: 'attribute_filter';
  // invert: false;
  mode: 'range';
  selected: [];
  start_date: any;
  naive_start_date: any;
  naive_end_date: any;
  periods: string;
};
export interface ActivityGroups {
  [key: string]: {
    name: string;
    activities: string[];
    expanded: boolean;
  };
}

export interface FunnelGlobalKPI {
  total_count?: number;
  total_amount?: number;
}

export interface SelectedNodeEdgeMetrices {
  EDGE: { [key: string]: string };
  NODE: { [key: string]: string };
}
export interface FunnelAnalysisState extends EntityState<FunnelAnalysis> {
  readMode: ReadMode;
  startActivity: string[];
  endActivity: string[];
  selectedStartActivity: string[];
  selectedEndActivity: string[];
  activities: any[];
  globalKpi: FunnelGlobalKPI | null;
  // funnelAnalysisCases: string | null;
  // graphElementSelectedMetrics: graphElementSelectedMetrics
  graphElementAdditionalFilter: AdditionalFilter | null;
  selectedNodeEdgeMetrices: SelectedNodeEdgeMetrices;
  chartSelection: any;
  // graphElementCasesAndVariants: graphElementCasesVatiants | null;
  casesVariantsHashDrop: CasesVatiantsHash[];
  activityGroups: ActivityGroups;
  activityLevels: any;
  compareFlow: CompareFlow | null;
  timeFilter: { [key: string]: FunnelTimeFilter };
  ranges: any;
}
function objectInitialTimeFilterData() {
  return {};
}

function objectInitialRangeData() {
  return {};
}

export function createInitialState(): FunnelAnalysisState {
  return {
    readMode: 'General',
    startActivity: [],
    endActivity: [],
    selectedStartActivity: [],
    selectedEndActivity: [],
    // funnelAnalysisCases: null,
    graphElementAdditionalFilter: null,
    selectedNodeEdgeMetrices: {
      NODE: {},
      EDGE: {}
    },
    chartSelection: {},
    // graphElementCasesAndVariants: null,
    casesVariantsHashDrop: [],
    activities: [],
    globalKpi: null,
    activityGroups: {},
    activityLevels: [],
    compareFlow: null,
    timeFilter: {},
    ranges: {}
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'funnel-analysis', resettable: true })
export class FunnelAnalysisStore extends EntityStore<FunnelAnalysisState> {
  constructor() {
    super(createInitialState());
  }

  resetFunnelTimeFilter(dashboardSlug: string) {
    const { timeFilter } = this.getValue();
    if (timeFilter[dashboardSlug]) {
      timeFilter[dashboardSlug] = null;
    }
  }

  resetGlobalFunnelRangeAndTimeFilter() {
    this.update({ timeFilter: objectInitialTimeFilterData() });
    this.update({ ranges: objectInitialRangeData() });
  }
}
