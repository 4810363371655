import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomIcons } from '@bli-shared/utils/icon.enum';

@Injectable({
  providedIn: 'root'
})
export class CustomIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer
  ) {}

  setIcons() {
    Object.entries(CustomIcons).map(([key, value]) => {
      const [namespace, name] = this._splitIconName(key);
      this.matIconRegistry.addSvgIconLiteralInNamespace(
        namespace,
        name,
        this.domSanitzer.bypassSecurityTrustHtml(value)
      );
    });
  }

  private _splitIconName(iconName) {
    if (!iconName) {
      return ['', ''];
    }
    const parts = iconName.split(':');
    switch (parts.length) {
      case 1:
        return ['', parts[0]];
      case 2:
        return parts;
      default:
        throw Error(`Invalid icon name: "${iconName}"`);
    }
  }
}
