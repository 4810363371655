import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { ProcessExplorerService } from '@bli/product-features/process-analytics/process-explorer/state';
import { Observable, Subscription } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { PEGroupNavigation } from '../../pe-group-navigation';
import { ProcessExplorerGroupService } from '../../services/process-explorer-group.service';

@Component({
  selector: 'app-process-explorer-create-group',
  templateUrl: './process-explorer-create-group.component.html',
  styleUrls: ['./process-explorer-create-group.component.scss']
})
export class ProcessExplorerCreateGroupComponent implements OnInit, OnDestroy {
  isLoading = false;
  searchCtrl = new UntypedFormControl();
  groupForm: UntypedFormGroup;
  activities$: Observable<any[]>;
  activityList = [];
  selectedActivities$: Observable<any>;
  selectedActivities: any[] = [];
  isUpdate = false;
  private subscription = new Subscription();
  @ViewChild('activitiesList', { static: true })
  activitiesList: MatSelectionList;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: UntypedFormBuilder,
    private service: ProcessExplorerGroupService,
    private peService: ProcessExplorerService
  ) {}

  ngOnInit(): void {
    const doExists =
      (type: 'name' | 'color'): ValidatorFn =>
      (control: AbstractControl): { [key: string]: any } | null =>
        this.data.groups.find(
          group => group[type].toLowerCase() === control.value.toLowerCase()
        )
          ? { duplicate: true }
          : null;

    if (this.service.groupNavigateValue.row) {
      this.isUpdate = true;
    }
    this.groupForm = this.fb.group({
      name: ['', this.isUpdate ? [] : [Validators.required, doExists('name')]],
      color: [
        '#ffffff',
        this.isUpdate ? [] : [Validators.required, doExists('color')]
      ]
    });
    if (this.service.groupNavigateValue.row) {
      const { name, color } = this.service.groupNavigateValue.row;
      this.groupForm.setValue({ name, color });
      this.selectedActivities =
        this.service.groupNavigateValue.row.activities || [];
    }
    const groupedActivities = [
      ...this.data.groups.reduce(
        (arr, group) => arr.concat(group.activities),
        []
      )
    ];
    this.activityList = Object.keys(this.data.activityList)
      .map(key => this.data.activityList[key])
      .filter(activity => {
        if (activity === 'Process Start' || activity === 'Process End') {
          return false;
        }
        if (this.selectedActivities.includes(activity)) {
          return true;
        }
        if (groupedActivities.includes(activity)) {
          return false;
        }
        return true;
      });
    this.activities$ = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map(searchText =>
        searchText
          ? this.activityList.filter(activity =>
              activity.toLowerCase().includes(searchText.toLowerCase())
            )
          : this.activityList
      ),
      tap(() =>
        setTimeout(
          () =>
            this.activitiesList?.options?.toArray().map(option => {
              if (this.selectedActivities.includes(option.value)) {
                this.activitiesList.selectedOptions.select(option);
              }
            })
        )
      )
    );

    this.selectedActivities$ = this.activitiesList.selectionChange.pipe(
      startWith({ options: [] }),
      map(selectionList => {
        if (selectionList.options.length) {
          const option = selectionList?.options[0];
          if (option.selected) {
            this.selectedActivities.push(option.value);
          } else {
            const index = this.selectedActivities.indexOf(option.value);
            this.selectedActivities.splice(index, 1);
          }
        }
        return this.selectedActivities;
      })
    );
  }

  clearAll() {
    this.activitiesList.deselectAll();
    this.selectedActivities = [];
    this.activitiesList.selectionChange.next('' as any);
  }

  close(activity) {
    this.activitiesList.options.toArray().forEach(option => {
      if (option.value === activity) {
        this.activitiesList.selectedOptions.deselect(option);
      }
      const index = this.selectedActivities.indexOf(activity, 0);
      if (index > -1) {
        this.selectedActivities.splice(index, 1);
      }
    });
  }

  update() {
    const payload = {
      key: `groups.${this.service.groupNavigateValue.row.key}`,
      data: {
        ...this.groupForm.value,
        activities: this.selectedActivities,
        expanded: this.service.groupNavigateValue.row?.expanded || false
      }
    };
    this.setStore(payload);
  }

  create() {
    const payload = {
      key: `groups.${this.groupId()}`,
      data: {
        ...this.groupForm.value,
        activities: this.selectedActivities,
        expanded: false
      }
    };
    this.setStore(payload);
  }

  private setStore(payload) {
    this.isLoading = true;
    const sub = this.service.setPAState(payload).subscribe(
      () => {
        this.isLoading = false;
        this.service.groupNavigate = PEGroupNavigation[0];
        this.peService.relaodProcessExplorerGraph = true;
      },
      () => (this.isLoading = false)
    );
    this.subscription.add(sub);
  }

  groupId() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
