import { Injectable } from '@angular/core';
import { EntityActions, QueryEntity } from '@datorama/akita';
import { GlobalFiltersStore, GlobalFiltersState } from './global-filters.store';
import { ProcessAnalyticsQuery } from '@bli/product-features/process-analytics/state';
import { AuthQuery } from '@bli/authentication/state';
@Injectable({ providedIn: 'root' })
export class GlobalFiltersQuery extends QueryEntity<GlobalFiltersState> {
  constructor(
    protected store: GlobalFiltersStore,
    private processAnalyticsQuery: ProcessAnalyticsQuery,
    private authQuery: AuthQuery
  ) {
    super(store);
    this.selectEntityAction(EntityActions.Update).subscribe();
  }

  get getGlobalFilterData() {
    const username =
      this.authQuery.getUserData.user.id.payload['cognito:username'];
    const analysisId = this.processAnalyticsQuery.GetAnalysisData
      ? this.processAnalyticsQuery.GetAnalysisData.analysis_id
      : null;
    const uniqueId = username + ':' + analysisId;
    const entity = this.getEntity(uniqueId);
    if (entity) return entity;
    else {
      return null;
    }
  }

  set updateGlobalFilterData(globalFilter: any) {
    const username =
      this.authQuery.getUserData.user.id.payload['cognito:username'];
    const analysisId = this.processAnalyticsQuery.GetAnalysisData.analysis_id;
    const uniqueId = username + ':' + analysisId;
    if (!this.hasEntity(uniqueId)) {
      this.store.add({
        id: uniqueId,
        global_filters: [],
        start_date: '',
        end_date: '',
        date_range: {},
        variant_ids: [],
        cases: [],
        stats: {},
        variant_explorer: {},
        process_explorer: {},
        active_page: {},
        isFilterApplied: false,
        isEdgeSelected: false
      });
    } else {
      this.store.update(uniqueId, globalFilter);
    }
    this.store.setActive(uniqueId);
  }
}
