// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-root-cause {
  padding: 16px;
  height: 114px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  border-left: 9px solid #070D59;
}
.card-root-cause .header {
  color: rgb(0, 0, 0);
  font-size: 14px;
}
.card-root-cause .content {
  color: rgb(0, 0, 0);
  font-size: 16px;
}
.card-root-cause .view-cases {
  height: 33px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  width: 120px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 10px;
  padding-top: 5px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/card-root-cause/card-root-cause.component.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,aAAA;EACA,kBAAA;EACA,+GAAA;EACA,yBAAA;EACA,8BAAA;AAAF;AACE;EACE,mBAAA;EACA,eAAA;AACJ;AACE;EACE,mBAAA;EACA,eAAA;AACJ;AACE;EACE,YAAA;EACA,qCAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":["@import 'variables';\n.card-root-cause {\n  padding: 16px;\n  height: 114px;\n  border-radius: 4px;\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);\n  background-color: $white;\n  border-left: 9px solid $brand-primary;\n  .header {\n    color: rgba(0, 0, 0);\n    font-size: 14px;\n  }\n  .content {\n    color: rgba(0, 0, 0);\n    font-size: 16px;\n  }\n  .view-cases {\n    height: 33px;\n    border: solid 1px rgba(0, 0, 0, 0.12);\n    width: 120px;\n    font-size: 14px;\n    border-radius: 4px;\n    margin-bottom: 10px;\n    padding-top: 5px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
