import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig, APP_CONFIG } from '@bli-shared/utils/app-config.module';
import { ID } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { DataCollectionStore } from '../../state';
import { DataConnector } from './data-connector.model';
import { DataConnectorStore } from './data-connector.store';
import { AuthQuery } from '@bli/authentication/state';

@Injectable({ providedIn: 'root' })
export class DataConnectorService {
  constructor(
    private dataConnectorStore: DataConnectorStore,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private dataCollectionStore: DataCollectionStore,
    // private detectChanges: DataSourceDetectChangesService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private authQuery: AuthQuery
  ) {}

  // ds connectors
  getAllConnector(payload) {
    // payload.bucket_slug =this.bucketId;
    const url = `${this.rootUrl}${this.DSConnectorApi.READ}`;
    return this.postMethod(payload, url);
  }

  getActiveDataConnectors() {
    // const
    const url = `${this.rootUrl}general/static-drop-down/`;
    const payload = {
      components: [
        {
          // name: 'etl_connector_type'
          name: 'etl_connectors'
        }
      ]
    };

    return this.postMethod(payload, url).pipe(
      tap(res => {
        const data = res.data.length && res.data[0];
        if (data) {
          this.dataConnectorStore.update({ connectors: data });
        }
      })
    );
  }

  getConnectorConfig(payload) {
    const url = `${this.rootUrl}general/dynamic-drop-down/`;
    return this.postMethod(payload, url);
  }

  testDSConnector(connector: string, payload: any) {
    const url = `${this.rootUrl}${this.DSConnectorApi.TEST}`;
    return this.postMethod(
      payload,
      url,
      `New data connector tested successfully`
    );
  }

  saveDSConnector(payload: any) {
    const url = `${this.rootUrl}${this.DSConnectorApi.CREATE}`;
    return this.postMethod(
      payload,
      url,
      `New data connector created successfully`
    );
  }

  updateDataConnector(payload) {
    const url = `${this.rootUrl}${this.DSConnectorApi.UPDATE}`;
    return this.postMethod(payload, url, `Data connector updated successfully`);
  }

  deleteDataConnector(payload: any) {
    // payload.bucket_slug =this.bucketId;
    const url = `${this.rootUrl}${this.DSConnectorApi.DELETE}`;
    return this.postMethod(payload, url, `Data connector deleted successfully`);
  }

  markAsDefault(payload: any) {
    const url = `${this.rootUrl}${this.DSConnectorApi.MARK_AS_DEFAULT}`;
    return this.postMethod(payload, url, `Data connector marked as default`);
  }

  private postMethod(payload, url, successMessage?: string) {
    this.dataConnectorStore.setLoading(true);
    return this.http.post<any>(url, payload).pipe(
      tap(() => {
        this.dataConnectorStore.setLoading(false);
        if (successMessage) {
          this.toastrService.show(
            JSON.stringify({ type: 'success', text: successMessage })
          );
        }
      }),
      catchError(err => {
        this.dataConnectorStore.setLoading(false);
        return throwError(err);
      })
    );
  }
  integrateSalesForce(payload: any) {
    const url = `${this.rootUrl}data-connector/salesforce/integrate/`;
    return this.postMethod(payload, url, ``);
  }
  authenticateSalesForce(payload: any) {
    const url = `${this.rootUrl}data-connector/salesforce/authenticate/`;
    return this.postMethod(payload, url, ``);
  }

  private get bucketId(): string {
    return `${this.dataCollectionStore.getValue().bucket.bucket_slug}`;
  }

  private get DSConnectorApi() {
    return this.appConfig.DATA_COLLECTION_API.DATA_BUCKET.DS_CONNECTOR;
  }
  private get rootUrl(): string {
    return `${this.appConfig.DATA_COLLECTION_API.ROOT}/`;
  }
}
