import { Injectable } from '@angular/core';
import { guid, QueryEntity } from '@datorama/akita';
import { ProcessAnalyticsStore, ProcessAnalyticsState } from './process-analytics.store';

@Injectable({ providedIn: 'root' })
export class ProcessAnalyticsQuery extends QueryEntity<ProcessAnalyticsState> {
  constructor(protected store: ProcessAnalyticsStore) {
    super(store);
  }

  readonly deletionList$ = this.select(s => s.waitingDel.length);
  readonly linkingModelProgress$ = this.select(s => s.linkingModelProgress);
  readonly analysisCluster$ = this.select(s => s.analysis_cluster);
  readonly analysis$ = this.select(s => s.analysis);
  readonly isModelExists$ = this.select(s => s.isProcessModelExists.process_model_id);
  readonly ids$= this.select(s=> s.ids);
  readonly clusterPage$ = this.select(s => s.clusterPage);
  readonly analyhsisPage$ = this.select(s => s.analysisPage);

  set isProcessModelExists(processModel: any ) {
const idValue= window.sessionStorage.getItem('unique-id');
    this.store.update(idValue,{
      isProcessModelExists: processModel
    });
  }

  get GetProcessAnalyticsData(){
    const idValue= window.sessionStorage.getItem('unique-id');
    const entity = this.getEntity(idValue);
    if(entity)
    return entity;
    else {
      return null;
    }
  }

  set updateActiveId(id: any){
    const idValue= window.sessionStorage.getItem('unique-id');
    if(this.hasEntity(idValue)) {
    this.store.setActive(idValue);
    }
  }

  set updateStoreClusterData(cluster: any){
    const idValue= window.sessionStorage.getItem('unique-id');
    if(!this.hasEntity(idValue)) {
      this.store.add({
        id:idValue,
        waitingDel: [],
        waitingClustorDel: [],
        linkingModelProgress: false,
        analysis_cluster_id: '',
        analysis_cluster: cluster,
        analysis:{},
        isConformanceOverview: {},
        isProcessModelExists: {
          process_model_id: null
        }
      });
      this.store.update(idValue, {analysis_cluster: cluster});
    } else {
      this.store.update(idValue, {analysis_cluster: cluster});
    }
    this.store.setActive(idValue);
  }

  get GetAnalysisClusterData(){
    const idValue= window.sessionStorage.getItem('unique-id');
    const entity = this.getEntity(idValue);
    if(entity)
    return entity.analysis_cluster;
    else
    return null;
  }

  set updateStoreAnalysisData(analysis: any){
    const idValue= window.sessionStorage.getItem('unique-id');
    if(!this.hasEntity(idValue)) {
      this.store.add({
        id:idValue,
        waitingDel: [],
        waitingClustorDel: [],
        linkingModelProgress: false,
        analysis_cluster_id: '',
        analysis_cluster: {},
        analysis,
        isConformanceOverview: {},
        isProcessModelExists: {
          process_model_id: null
        }
      });
    } else {
      this.store.update(idValue, {analysis});
    }
    this.store.setActive(idValue);
  }

  get GetAnalysisData(){
    const idValue= window.sessionStorage.getItem('unique-id');
    const entity = this.getEntity(idValue);
    if(entity)
    return entity.analysis;
    else
    return null;
  }


  set updateStoreClusterDeleteData(data: any){
    const idValue= window.sessionStorage.getItem('unique-id');
    if(!this.hasEntity(idValue)) {
      this.store.add({
        id:idValue,
        waitingDel: [],
        waitingClustorDel: [],
        linkingModelProgress: false,
        analysis_cluster_id: '',
        analysis_cluster: {},
        analysis:{},
        isConformanceOverview: {},
        isProcessModelExists: {
          process_model_id: null
        }
      });
    } else {
      this.store.update(idValue, {waitingClustorDel: data});
    }
    this.store.setActive(idValue);
  }

  set updateStoreAnalysisDeleteData(data: any){
    const idValue= window.sessionStorage.getItem('unique-id');
    if(!this.hasEntity(idValue)) {
      this.store.add({
        id:idValue,
        waitingDel: [],
        waitingClustorDel: [],
        linkingModelProgress: false,
        analysis_cluster_id: '',
        analysis_cluster: {},
        analysis:{},
        isConformanceOverview: {},
        isProcessModelExists: {
          process_model_id: null
        }
      }, { prepend: true });
    } else {
      this.store.update(idValue, {waitingDel: data});
    }
    this.store.setActive(idValue);
  }

  set updateStoreLinkingModelProgressData(data: any){
    const idValue= window.sessionStorage.getItem('unique-id');
    if(!this.hasEntity(idValue)) {
      this.store.add({
        id:idValue,
        waitingDel: [],
        waitingClustorDel: [],
        linkingModelProgress: false,
        analysis_cluster_id: '',
        analysis_cluster: {},
        analysis:{},
        isConformanceOverview: {},
        isProcessModelExists: {
          process_model_id: null
        }
      });
    } else {
      this.store.update(idValue, {linkingModelProgress: data});
    }
    this.store.setActive(idValue);
  }

  set clusterPage(clusterPage: number) {
    this.store.update({ clusterPage });
  }

  set analysisPage(analysisPage: number) {
    this.store.update({ analysisPage });
  }

}
