import { Injectable } from '@angular/core';
import { Bucket, DataCollection } from './data-collection.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface DataCollectionState extends EntityState<DataCollection> {
  waitingDel: string[];
  bucket: Bucket;
  enableManageModel: boolean;
  currentManageModelStep: string;
}

export function createInitialState(): DataCollectionState {
  return {
    waitingDel: [],
    bucket: null,
    enableManageModel: false,
    currentManageModelStep: ''
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'data-collection', resettable: true })
export class DataCollectionStore extends EntityStore<DataCollectionState> {
  constructor() {
    super(createInitialState());
    this.setLoading(false);
  }
}
