import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import BarChartWithVerticalBrushComponent from './components/bar-chart-with-vertical-brush/bar-chart-with-vertical-brush.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [BarChartWithVerticalBrushComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [BarChartWithVerticalBrushComponent]
})
export class BarChartWithBrushModule {}
