import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-path-activity-slider',
  templateUrl: './path-activity-slider.component.html',
  styleUrls: ['./path-activity-slider.component.scss']
})
export class PathActivitySliderComponent implements OnInit, OnChanges {
  @Input() controllers: any = {};
  @Input() isLoading = true;
  @Output() sliderValueChaned = new EventEmitter<any>();

  public activitySlider = {
    min: 0,
    max: 1,
    current: 0,
    count: 0,
    percentage: 0.0,
    percentageOfSelected: 0.0,
    covered_activities: 0,
    total_activites: 0
  };

  public pathSlider = {
    min: 0,
    max: 1,
    current: 0,
    count: 0,
    percentage: 0.0,
    percentageOfSelected: 0.0
  };

  ngOnInit() {
    this.setSliderData(this.controllers, false);
  }

  ngOnChanges(chanages) {
    const { controllers, isLoading } = chanages;
    if (controllers) {
      this.setSliderData(controllers.currentValue, false);
    }
    if (isLoading) {
      this.isLoading = isLoading.currentValue;
    }
  }

  setSliderData(controllers, init) {
    if (controllers.node_controller) {
      this.activitySlider = {
        min: controllers.node_controller.min,
        max: controllers.node_controller.max,
        current: init ? 0 : controllers.node_controller.current,
        count: controllers.node_controller.max,
        percentage: controllers.node_controller.nodes_percentage,
        percentageOfSelected: controllers.node_controller.nodes_percentage,
        covered_activities: controllers.node_controller.covered_activities,
        total_activites: controllers.node_controller.total_activites
      };
    }

    if (controllers.edge_controller) {
      this.pathSlider = {
        min: controllers.edge_controller.min,
        max: controllers.edge_controller.max,
        current: init ? 0 : controllers.edge_controller.current,
        count: controllers.edge_controller.max,
        percentage: controllers.edge_controller.edges_percentage,
        percentageOfSelected: controllers.edge_controller.edges_percentage
      };
    }
  }

  caluclatePercentage(value, count) {
    return (value / count) * 100;
  }

  onSliderDragEnd(e, filter) {
    if (this.isLoading) {
      return;
    }
    this.sliderValueChaned.next({
      controllers: {
        node_controller: this.activitySlider,
        edge_controller: this.pathSlider
      },
      filter
    });
  }
}
