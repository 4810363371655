import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
// import * as d3 from 'd3';
import { ChartDataset, ChartOptions } from 'chart.js';
import { SCSS_VARS } from '@bli/scss-variables.generated';

@Component({
  selector: 'app-line-graph',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss']
})
export class LineGraphComponent implements OnChanges {
  @Input() graphName: any;
  @Input() xAxis: any;
  @Input() yAxis: any;

  public lineChartData: ChartDataset[];
  public lineChartLabels: string[];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: tooltipItem =>
            tooltipItem[0].label === 'Duration'
              ? `${tooltipItem[0].label} : ${tooltipItem[0].label} hours`
              : `${tooltipItem[0].label} : ${tooltipItem[0].label}`
        }
      }
    }
  };
  public lineChartColors: any[] = [
    {
      borderColor: SCSS_VARS['$brand-primary'],
      backgroundColor: 'rgba(0, 0, 160, .3)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  ngOnChanges(): void {
    this.lineChartData = this.xAxis;
    this.lineChartLabels = this.yAxis;
  }
}
