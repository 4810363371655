import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SkipInterceptorHttpClientSrevice {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  get(url) {
    return this.httpClient.get(url);
  }

  post(url, payload, headers = {}) {
    return this.httpClient.post(url, payload, { headers });
  }
}
