import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Inject,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRouterService } from '@bli/services/mat-dialog-router.service';

@Component({
  selector: 'app-mat-dialog-routing',
  templateUrl: './mat-dialog-routing.component.html',
  styleUrls: ['./mat-dialog-routing.component.scss']
})
export class MatDialogRoutingComponent implements OnInit, OnDestroy {
  title: string;
  historyList: any[] = [];
  dataRouter: any;
  componentRef: ComponentRef<any>;

  @ViewChild('datasource', { static: true, read: ViewContainerRef })
  dataSource: ViewContainerRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private resolver: ComponentFactoryResolver,
    private viewContainer: ViewContainerRef,
    private matDialog: MatDialog,
    private matDialogRouteService: MatDialogRouterService
  ) {}

  ngOnInit() {
    this.dataRouter = this.data.matDialogRoute;
    this.historyList.push(this.dataRouter[0]);
    this.matDialogRouteService.navigate$.subscribe(url =>
      this.updateComponent(url)
    );
    this.matDialog.afterAllClosed.subscribe(state =>
      this.matDialogRouteService.reset()
    );
    this.createComponent();
  }

  updateComponent(path: string) {
    if (this.currentComponent.path !== path && this.currentComponent.children) {
      const children = this.currentComponent.children;
      children.forEach(component => {
        if (component.path === path) {
          this.historyList.push(component);
        }
      });
      this.createComponent();
    }
  }

  back() {
    if (this.historyList.length) {
      this.historyList.pop();
      this.createComponent();
    }
  }

  createComponent() {
    this.dataSource.clear();
    const factory = this.resolver.resolveComponentFactory(
      this.currentComponent.component
    );
    this.ngOnDestroy();
    this.componentRef = this.dataSource.createComponent(
      factory,
      null,
      this.viewContainer.injector
    );
    this.componentRef.changeDetectorRef.detectChanges();
    this.title = this.currentComponent.data.title;
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.changeDetectorRef.detach();
    }
  }

  get currentComponent() {
    return this.historyList[this.historyList.length - 1];
  }
}
