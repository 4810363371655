import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig,guid } from '@datorama/akita';
import { ProcessAnalytics,ProcessAnalyticsStateCore } from './process-analytic.model';

export interface ProcessAnalyticsState extends EntityState<ProcessAnalyticsStateCore> {
  clusterPage: number;
  analysisPage: number;
  // waitingDel: string[];
  // waitingClustorDel: string[];
  // linkingModelProgress: boolean;
  // analysis_cluster_id: string;
  // isConformanceOverview: any;
  // isProcessModelExists: {
  //   process_model_id: null
  // };
}

// export function createInitialState(): ProcessAnalyticsState {
//   return {
//     id:guid(),
//     waitingDel: [],
//     waitingClustorDel: [],
//     linkingModelProgress: false,
//     analysis_cluster_id: '',
//     analysis_cluster: {},
//     isConformanceOverview: {},
//     isProcessModelExists: {
//       process_model_id: null
//     }
//   };
// }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'process-analytics', resettable: false })
export class ProcessAnalyticsStore extends EntityStore<ProcessAnalyticsState, ProcessAnalyticsStateCore> {
  constructor() {
    super();
    this.setLoading(false);
  }
}
