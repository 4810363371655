import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-algorithmic-happy-path',
  templateUrl: './algorithmic-happy-path.component.html',
  styleUrls: ['./algorithmic-happy-path.component.scss']
})
export class AlgorithmicHappyPathComponent {
  @Input() algothmicSteps: any;
}
