import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  Injectable,
  Inject,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@bli-shared/utils/app-config.module';
import { Observable, forkJoin, throwError } from 'rxjs';
import { DropDownService } from '@bli/services/drop-down.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AppState, AppStore } from './../../../state/app.store';
import { OperationalIntellectStore } from '@bli/product-features/operational-intellect/state/operational-intellect.store';
export class TreeItemNode {
  children: TreeItemNode[];
  item: string;
  code: string;
  key: string;
  value: string;
  type: string;
  tablename: string;
  tablekey: string;
  columnname: string;
}

export class TreeItemFlatNode {
  item: string;
  key: string;
  value: string;
  level: number;
  expandable: boolean;
  code: string;
  type: string;
  tablename: string;
  tablekey: string;
  columnname: string;
}

@Injectable({ providedIn: 'root' })
export class ChecklistDatabase extends DropDownService {
  get data(): TreeItemNode[] {
    return this.dataChange.value;
  }

  clusterId: any;
  analysisId: any;
  currentType: any;
  paramsSubscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private http: HttpClient,
    private appStore: AppStore,
    private oiStore: OperationalIntellectStore,
    private activatedRoute: ActivatedRoute
  ) {
    super(appConfig, http, null);
    this.paramsSubscription = this.activatedRoute.params.subscribe(params => {
      this.clusterId = parseInt(params.cluster_id, 0) || 0;
      this.analysisId = parseInt(params.analysis_id, 0) || 0;
    });
    // this.initialize();
  }

  private formulaTypeSource = new BehaviorSubject('BASE');
  dataChange = new BehaviorSubject<TreeItemNode[]>([]);

  treeData: any[] = [];
  get;
  changeType(type: string) {
    this.formulaTypeSource.next(type);
    this.formulaTypeSource.subscribe(data => {
      this.currentType = data;
    });
    this.getDataBaseMetaData(this.currentType);
  }
  initialize() {
    this.formulaTypeSource.subscribe(data => {
      this.currentType = data;
    });
    this.getDataBaseMetaData(this.currentType);
  }

  getDataBaseMetaData(currentType: string) {
    const cid = this.clusterId;
    const aid = this.analysisId;
    let url = '';
    let payload = {};
    if (this.clusterId === 0 && this.analysisId === 0) {
      url = `${this.appConfig.OPERATIONAL_INTELLECT.ROOT}/${this.appConfig.OPERATIONAL_INTELLECT.LIST_DATABASE_METADATA_FOR_FORMULAE}`;

      payload = {
        formula_type: this.oiStore.getValue().formulatype
      };
    } else if (this.clusterId !== 0 && this.analysisId !== 0) {
      url = `${this.appConfig.ANAYSIS_API.ROOT}/${this.appConfig.ANAYSIS_API.FORMULAE.METADATA_READ}`;
      payload = {
        process_analytics_cluster_id: this.clusterId,
        process_analysis_id: this.analysisId
      };
    } else {
    }

    if (url !== '') {
      this.http.post<any>(url, payload).subscribe(result => {
        this.treeData = [];

        if (this.clusterId === 0 && this.analysisId === 0) {
          const main = { text: 'TABLE SCHEMA', code: '0.1' };
          this.treeData.push(main);
          result.data.map(obj => {
            const model = {
              text: obj.data_model.data_model_name,
              key: '',
              value: '',
              code: '0.2'
            };
            this.treeData.push(model);

            obj.data_model.column_details.forEach(element => {
              const model1 = {
                text: element.alias,
                type: element.data_type,
                key: element.key,
                value: element.value,
                code: '0.2.1'
              };
              this.treeData.push(model1);
            });

            const main1 = { text: 'KEYWORDS', code: '0.3' };
            this.treeData.push(main1);
            const main3 = { text: 'Words', code: '0.5' };
            this.treeData.push(main3);
            obj.keywords.forEach(element => {
              const model2 = {
                text: element.alias,
                key: element.key,
                value: element.value,
                code: '0.5.1'
              };
              this.treeData.push(model2);
            });

            if (obj.discovery_tables) {
              const main2 = { text: 'DISCOVERY TABLES', code: '0.4' };
              this.treeData.push(main2);
              const main4 = { text: 'Tables', code: '0.6' };
              this.treeData.push(main4);
              obj.discovery_tables.forEach(element => {
                const model3 = {
                  text: element.alias,
                  key: element.key,
                  value: element.value,
                  code: '0.6.1'
                };
                this.treeData.push(model3);
              });
            }
            this.appStore.update({ metaData: this.treeData });
            const data = this.buildFileTree(this.treeData, '0');
            // Notify the change.
            this.dataChange.next(data);
          });
        } else if (this.clusterId !== 0 && this.analysisId !== 0) {
          const main = { text: 'DATA MODEL', code: '0.1' };
          this.treeData.push(main);
          result.data.map(obj => {
            const model = {
              text: obj.data_model.alias,
              key: obj.data_model.key,
              value: obj.data_model.value,
              tablename: '',
              tablekey: '',
              code: '0.2'
            };
            this.treeData.push(model);

            obj.data_model.columns.forEach(element => {
              const model1 = {
                text: element.alias,
                columnname: element.column_name,
                type: element.data_type,
                key: element.key,
                value: element.value,
                tablename: obj.data_model.value,
                tablekey: obj.data_model.key,
                code: '0.2.1'
              };
              this.treeData.push(model1);
            });

            if (obj.discovery_tables) {
              const main2 = { text: 'DISCOVERY TABLES', code: '0.4' };
              this.treeData.push(main2);
              let index = 0.6;
              let stringIndex = index.toString();
              obj.discovery_tables.forEach(element => {
                const model3 = {
                  text: element.alias,
                  key: element.key,
                  value: element.value,
                  tablename: '',
                  tablekey: '',
                  code: stringIndex
                };
                this.treeData.push(model3);
                const childIndex = stringIndex + '.1';
                index += 0.1;
                if (index >= 1) {
                  index = index / 10;
                  stringIndex = index.toFixed(2);
                } else {
                  stringIndex = index.toString();
                }
                element.columns.forEach(childElement => {
                  const model1 = {
                    text: childElement.alias,
                    columnname: childElement.column_name,
                    type: childElement.data_type,
                    key: childElement.key,
                    value: childElement.value,
                    tablename: element.value,
                    tablekey: element.key,
                    code: childIndex
                  };
                  this.treeData.push(model1);
                });
              });
            }

            const maink = { text: 'KEYWORDS', code: '0.3' };
            this.treeData.push(maink);
            const main3 = { text: 'Words', code: '0.5' };
            this.treeData.push(main3);
            obj.keywords.forEach(element => {
              const modelck = {
                text: element.alias,
                key: element.key,
                value: element.value,
                code: '0.5.1'
              };
              this.treeData.push(modelck);
            });
            this.appStore.update({ metaData: this.treeData });
            const data = this.buildFileTree(this.treeData, '0');
            // Notify the change.
            this.dataChange.next(data);
          });
        }
      });
    }
  }
  baseUrl() {
    return `${this.appConfig.OPERATIONAL_INTELLECT.ROOT}/`;
  }
  dataBaseMetaDataUrl() {
    return `${this.appConfig.OPERATIONAL_INTELLECT.LIST_DATABASE_METADATA_FOR_FORMULAE}`;
  }

  buildFileTree(obj: any[], level: string): TreeItemNode[] {
    return obj
      .filter(
        o =>
          (o.code as string).startsWith(level + '.') &&
          (o.code.match(/\./g) || []).length ===
            (level.match(/\./g) || []).length + 1
      )
      .map(o => {
        const node = new TreeItemNode();
        node.item = o.text;
        node.code = o.code;
        node.key = o.key;
        node.value = o.value;
        node.type = o.type;
        node.tablename = o.tablename;
        node.tablekey = o.tablekey;
        if (o.columnname) {
          node.columnname = o.columnname;
        }
        if (o.code !== '0.1') {
          const children = obj.filter(so =>
            (so.code as string).startsWith(level + '.')
          );
          if (children && children.length > 0) {
            node.children = this.buildFileTree(children, o.code);
          }
        }
        return node;
      });
  }

  public filter(filterText: string) {
    let filteredTreeData;
    if (filterText) {
      filteredTreeData = this.treeData.filter(
        d =>
          d.text.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
          -1
      );
      Object.assign([], filteredTreeData).forEach(ftd => {
        let str = ftd.code as string;
        while (str.lastIndexOf('.') > -1) {
          const index = str.lastIndexOf('.');
          str = str.substring(0, index);
          if (filteredTreeData.findIndex(t => t.code === str) === -1) {
            const obj = this.treeData.find(d => d.code === str);
            if (obj) {
              filteredTreeData.push(obj);
            }
          }
        }
      });
    } else {
      filteredTreeData = this.treeData;
    }

    const data = this.buildFileTree(filteredTreeData, '0');
    // Notify the change.
    this.dataChange.next(data);
  }
}

@Component({
  selector: 'app-database-metadata',
  templateUrl: 'database-metadata.component.html',
  styleUrls: ['database-metadata.component.scss'],
  providers: [ChecklistDatabase]
})
export class DataBaseMetaDataComponent implements OnInit {
  @Input() formulaType: any;
  @Input() data: any[];

  @Output() queryChanged: EventEmitter<any> = new EventEmitter();

  flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();

  nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();

  selectedParent: TreeItemFlatNode | null = null;

  newItemName = '';

  treeControl: FlatTreeControl<TreeItemFlatNode>;

  treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;
  fullDatasource = [
    ...this.database.treeData,
    ...this.database.treeData,
    ...this.database.treeData,
    ...this.database.treeData,
    ...this.database.treeData,
    ...this.database.treeData,
    ...this.database.treeData,
    ...this.database.treeData,
    ...this.database.treeData
  ];

  checklistSelection = new SelectionModel<TreeItemFlatNode>(
    false /* multiple */
  );

  constructor(private database: ChecklistDatabase) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TreeItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }
  ngOnInit() {
    this.database.changeType(this.formulaType);
  }

  getLevel = (node: TreeItemFlatNode) => node.level;

  isExpandable = (node: TreeItemFlatNode) => node.expandable;

  getChildren = (node: TreeItemNode): TreeItemNode[] => node.children;

  hasChild = (_: number, nodeData: TreeItemFlatNode) => nodeData.expandable;

  transformer = (node: TreeItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new TreeItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.code = node.code;
    flatNode.key = node.key;
    flatNode.value = node.value;
    flatNode.type = node.type;
    flatNode.tablename = node.tablename;
    flatNode.tablekey = node.tablekey;
    if (node.columnname) {
      flatNode.columnname = node.columnname;
    }
    flatNode.expandable = node.children && node.children.length > 0;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  descendantsAllSelected(node: TreeItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
  }

  descendantsPartiallySelected(node: TreeItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: TreeItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

  filterChanged(filterText: string) {
    this.database.filter(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  logNode(node) {
    const model = {
      value: node.value,
      code: node.code,
      tablename: node.code === '0.5.1' ? '' : node.tablename,
      tablekey: node.code === '0.5.1' ? '' : node.tablekey,
      columnname: node.columnname,
      data: this.database.treeData
    };
    this.queryChanged.emit(model);
  }
}
