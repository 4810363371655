import { ID } from '@datorama/akita';

export interface ProcessExplorer {
  id: ID;
}

export interface ProcessExplorerResponse {
  data: {};
}

export interface VaraiantsList {
  data: {};
}
