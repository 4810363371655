import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@bli/authentication/state';
import { DataConnectorService } from '@bli/product-features/data-collection/data-connector/state/data-connector.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-salesforce-auth-callback',
  templateUrl: './salesforce-auth-callback.component.html',
  styleUrls: ['./salesforce-auth-callback.component.scss']
})
export class SalesforceAuthCallbackComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dataConnectorService: DataConnectorService,
    private activatedRote: ActivatedRoute,
    private authQuery: AuthQuery,
    private location: Location
  ) {}

  ngOnInit(): void {
    const state = decodeURIComponent(
      this.activatedRote.snapshot.queryParams.state
    );
    const code = decodeURIComponent(
      this.activatedRote.snapshot.queryParams.code
    );
    const path =
      'https://' +
      state.split(':')[0] +
      '.' +
      this.document.location.host +
      '/features/automations/data-connector/list?code=' +
      code +
      '&state=' +
      state.split(':')[0];
    window.location.href = path;
  }
}
