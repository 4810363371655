import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PEGroupNavigation } from '../../pe-group-navigation';
import { ProcessExplorerGroupService } from '../../services/process-explorer-group.service';
import { ProcessExplorerDeleteGroupComponent } from '../process-explorer-delete-group/process-explorer-delete-group.component';

@Component({
  selector: 'app-process-explorer-list-group',
  templateUrl: './process-explorer-list-group.component.html',
  styleUrls: ['./process-explorer-list-group.component.scss']
})
export class ProcessExplorerListGroupComponent
  implements AfterViewInit, OnDestroy
{
  ColumnMode = ColumnMode;
  group$: Observable<any>;
  rows = [];
  addLink = PEGroupNavigation[0].children[0];
  subscription = new Subscription();
  constructor(
    public service: ProcessExplorerGroupService,
    private dialog: MatDialog
  ) {}

  ngAfterViewInit(): void {
    this.getAllGroup();
  }

  getAllGroup() {
    this.group$ = this.service.getPAState({ key: 'groups' }).pipe(
      tap(
        response =>
          (this.rows = response.data.length
            ? Object.keys(response.data[0]).map(key => ({
                ...response.data[0][key],
                key
              }))
            : [])
      )
    );
  }

  edit(row) {
    const navigate = {
      ...PEGroupNavigation[0].children[1],
      row
    };
    this.service.groupNavigate = navigate;
  }

  delete(row) {
    const dialog = this.dialog.open(ProcessExplorerDeleteGroupComponent, {
      data: row
    });
    const sub = dialog.afterClosed().subscribe(() => this.getAllGroup());
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
