// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .container {
  margin: 0px -15px;
}
:host .ngx-datatable.material {
  box-shadow: none;
  border: 1px solid #e4e8ee;
  border-top: 2px solid #e4e8ee;
}
:host .ngx-datatable.material ::ng-deep .datatable-body-cell:nth-of-type(2) .datatable-body-cell-label {
  overflow: hidden;
  text-overflow: ellipsis;
}
:host .ngx-datatable.material ::ng-deep .hatatable-header-cell .datatable-header-cell-label {
  font-size: 14px;
}
:host .ngx-datatable.material .action-btn {
  margin-top: -10px;
}
:host .ngx-datatable.material .action-btn ::ng-deep .mat-button-wrapper .mat-icon {
  width: 100% !important;
  height: 100% !important;
  padding-top: 6px;
}
:host .ngx-datatable.material.material:not(.cell-selection) ::ng-deep.datatable-body-row:hover .link-model {
  display: block;
}
:host .loader {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/ngx-datatable/ngx-datatable.component.scss"],"names":[],"mappings":"AAGE;EACE,iBAAA;AAFJ;AAIE;EACE,gBAAA;EACA,yBAAA;EACA,6BAAA;AAFJ;AAKQ;EACE,gBAAA;EACA,uBAAA;AAHV;AAQM;EACE,eAAA;AANR;AASI;EACE,iBAAA;AAPN;AASQ;EACE,sBAAA;EACA,uBAAA;EACA,gBAAA;AAPV;AAcU;EACE,cAAA;AAZZ;AAmBE;EACE,gBAAA;AAjBJ","sourcesContent":["@import 'variables';\n\n:host {\n  .container {\n    margin: 0px -15px;\n  }\n  .ngx-datatable.material {\n    box-shadow: none;\n    border: 1px solid $lightest-grey;\n    border-top: 2px solid $lightest-grey;\n    ::ng-deep .datatable-body-cell {\n      &:nth-of-type(2) {\n        .datatable-body-cell-label {\n          overflow: hidden;\n          text-overflow: ellipsis;\n        }\n      }\n    }\n    ::ng-deep .hatatable-header-cell {\n      .datatable-header-cell-label {\n        font-size: 14px;\n      }\n    }\n    .action-btn {\n      margin-top: -10px;\n      ::ng-deep .mat-button-wrapper {\n        .mat-icon {\n          width:100% !important;\n          height: 100% !important;\n          padding-top: 6px;\n        }\n      }\n    }\n    &.material:not(.cell-selection) {\n      ::ng-deep.datatable-body-row {\n        &:hover {\n          .link-model {\n            display: block;\n          }\n        }\n      }\n    }\n  }\n\n  .loader {\n    margin-top: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
