// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-msg-wrapper {
  overflow: hidden;
}
.error-msg-wrapper .error-msg {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.62);
  text-align: center;
}

.ellipses {
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 30px);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/error/error.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,eAAA;EACA,0BAAA;EACA,kBAAA;AAEJ;;AAEA;EACE,uBAAA;EACA,gBAAA;EACA,wBAAA;AACF","sourcesContent":[".error-msg-wrapper {\n  overflow: hidden;\n  .error-msg {\n    font-size: 13px;\n    color: rgba(0, 0, 0, 0.62);\n    text-align: center;\n  }\n}\n\n.ellipses {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  width: calc(100% - 30px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
