import { Injectable } from '@angular/core';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root'
})
export class D3PackedBubbleChartService {
  dataset = {
    children: [
      {
        name: 'ABC',
        children: [
          { Name: 'test1', Count: 4319 },
          { Name: 'test3', Count: 4159 },
          { Name: 'tst4 ', Count: 2583 },
          { Name: 'tetst2', Count: 2074 },
          { Name: 'test7', Count: 1894 },
          { Name: ' test71', Count: 1809 },
          { Name: ' tsstq', Count: 1713 },
          { Name: 'bbb', Count: 1636 },
          { Name: 'ccddd', Count: 1566 }
        ]
      }
    ]
  };

  renderChart() {
    // const diameter = 600;
    const height = 400;
    const width = 600;
    // const width2 = 200;
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const bubble = d3.pack().size([width, height]).padding(1.5);

    const svg = d3
      .select('#chart')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('class', 'bubble');

    const nodes = d3.hierarchy(this.dataset).sum((d: any) => {
      return d.Count;
    });

    const node = svg
      .selectAll('.node')
      .data(bubble(nodes).descendants())
      .enter()
      .filter(d => {
        return !d.children;
      })
      .append('g')
      .attr('class', 'node')
      .attr('transform', d => {
        return 'translate(' + d.x + ',' + d.y + ')';
      })
      .style('fill', (d, i: any) => {
        return color(i);
      })
      .on('click', () => {
        // console.log(d);
      });

    // node.append("title")
    //   .text(function (d: any) {
    //     return d.Name + ": " + d.Count;
    //   });

    node
      .append('circle')
      .attr('x', d => {
        return d.x;
      })
      .attr('y', d => {
        return d.y;
      })
      .attr('r', d => {
        return d.r;
      })
      .style('fill', (d, i: any) => {
        return color(i);
      });

    node
      .append('text')
      .attr('dy', '.2em')
      .style('text-anchor', 'middle')
      .text((d: any) => {
        return d.data.Name.substring(0, d.r / 3);
      })
      .attr('font-family', 'sans-serif')
      .attr('font-size', d => {
        return d.r / 5;
      })
      .attr('fill', 'white');

    node
      .append('text')
      .attr('dy', '1.3em')
      .style('text-anchor', 'middle')
      .text((d: any) => {
        return d.data.Count;
      })
      .attr('font-family', 'Gill Sans')
      .attr('font-size', d => {
        return d.r / 5;
      })
      .attr('fill', 'white');

    // d3.select(self.frameElement)
    //   .style("height", height + "px")
    //   .style("width", width2 + "px");;
  }
}
