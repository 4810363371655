import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-chartjs-line-chart',
  templateUrl: './chartjs-line-chart.component.html',
  styleUrls: ['./chartjs-line-chart.component.scss']
})
export class ChartjsLineChartComponent implements OnInit {
  @Input() chartConfig: any = {};
  chart: any = {};
  theme = [
    '#003f5c',
    '#ffa600',
    '#e75290',
    '#537d06',
    '#ff6400',
    '#7a33b6',
    '#b81206'
  ];

  

  ngOnInit() {
    this.initChart();
  }

  getColor(index) {
    return this.theme[index % this.theme.length];
  }

  initChart() {
    const config: any = {
      type: 'line',
      data: {
        labels: this.chartConfig.general_options.labels,
        datasets: this.chartConfig.general_options.datasets
      },
      options: {
        legend: {
          display: this.chartConfig.diagram_area_legend.showLegend,
          position: this.chartConfig.diagram_area_legend.legendPosition,
          labels: {
            fontSize: 12,
            boxWidth: 5
          }
        },
        title: {
          display: true,
          text: this.chartConfig.general_options.title
        },
        tooltips: {
          enabled: this.chartConfig.tooltips.showTooltip,
          backgroundColor: 'rgba(0, 0, 0, 0.62)',
          cornerRadius: 0,
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10
        },
        scales: {
          xAxes: [
            {
              display: this.chartConfig.dimension_axis.displayAxis,
              scaleLabel: {
                display: true,
                labelString: this.chartConfig.dimension_axis.label
              }
            }
          ],
          yAxes: [
            {
              display: this.chartConfig.primary_axis.displayAxis,
              scaleLabel: {
                display: true,
                labelString: this.chartConfig.dimension_axis.label
              }
            }
          ]
        }
      }
    };
    config.data.datasets.map((obj, index) => {
      config.data.datasets[index].borderColor = this.getColor(index);
      config.data.datasets[index].pointBackgroundColor = this.getColor(index);
      config.data.datasets[index].fill =
        this.chartConfig.general_options.fillArea;
    });

    this.chart = new Chart('line-canvas', config);
  }
}
