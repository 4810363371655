import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ModifiedVariant } from '../../model/variant.model';
import { VerticalChartWithVertivalBrushDirective } from '../vertical-bar-chart-with-brush-base/vertical-brush';

@Component({
  selector: 'app-bar-chart-with-vertical-brush',
  templateUrl: './bar-chart-with-vertical-brush.component.html',
  styleUrls: ['./bar-chart-with-vertical-brush.component.scss']
})
export default class BarChartWithVerticalBrushComponent
  extends VerticalChartWithVertivalBrushDirective
  implements AfterViewInit
{
  @ViewChild('container', { static: true }) chartContainer: ElementRef;
  public limit = 10;
  public isFilter: boolean;

  ngAfterViewInit() {
    setTimeout(() => {
      this.createSvgContainer(this.chartContainer, 'chart-with-vertical-brush');
      const limit =
        this.startEndId[1] > this.limit
          ? this.startEndId[1] % this.limit
            ? Math.floor((this.startEndId[1] + this.limit) / this.limit) *
              this.limit
            : this.startEndId[1]
          : this.limit;
      this.modifyData(limit - 1);
    });
  }
  modifyData(limit) {
    // const firstVariantId = this.BarData[0].variant_id;
    this.modifiedData = this.BarData.map((el, index) => ({
      id: index + 1,
      ...el
    }));
    if (this.modifiedData.length > this.limit) {
      const firstElements = this.modifiedData.slice(0, limit);
      const restElement = this.modifiedData.slice(
        limit,
        this.modifiedData.length
      );
      if (restElement.length) {
        const finalElement: ModifiedVariant = {
          activity_count: 0,
          case_count: 0,
          percent_cases_covered: 0,
          throughput_time: 0,
          id: 'Others' as any,
          variant_id: 'Others',
          otherStartEnd: [0, 0]
        };
        restElement.map((rest, index) => {
          if (index === 0) {
            finalElement.otherStartEnd[0] = rest.id as number;
          }
          if (index === restElement.length - 1) {
            finalElement.otherStartEnd[1] = rest.id as number;
          }
          finalElement.activity_count += rest.activity_count;
          finalElement.case_count += rest.case_count;
          finalElement.percent_cases_covered += +rest.percent_cases_covered;
          finalElement.throughput_time += rest.throughput_time;
        });
        firstElements.push(finalElement);
      }
      this.modifiedData = [...firstElements];
      const lastModifiedData = this.modifiedData[this.modifiedData.length - 1];
      if (
        lastModifiedData.otherStartEnd &&
        lastModifiedData.otherStartEnd[0] <= this.startEndId[0]
      ) {
        this.startEndId[0] = lastModifiedData.id as number;
        this.startEndId[1] = lastModifiedData.id as number;
      }
      this.updateData(this.modifiedData, this.limit);
    } else {
      // to reset start end id to 1 and last elent when filterd
      this.startEndId = [1, this.modifiedData.length];
      this.updateData(this.modifiedData, 0);
    }
  }

  showInitial() {
    if (this.modifiedData.length !== this.limit + 1) {
      this.startEndId = [1, 1];
      this.modifyData(this.limit - 1);
      this.dragEnd(this.startEndId);
      this.isFilter = false;
    }
  }

  showLess() {
    if (this.modifiedData.length > this.limit + 1 && !this.isFilter) {
      const rest = this.modifiedData.length % this.limit;
      const limit = this.modifiedData.length - (rest ? rest : this.limit + 1);
      this.modifyData(limit);
    }
  }

  showMore() {
    if (this.modifiedData.length < this.BarData.length && !this.isFilter) {
      // const limit = this.modifiedData.length - 1 + this.limit;
      const limit = this.modifiedData.length + this.limit - 1;
      this.modifyData(limit);
    }
  }

  filter() {
    this.modifiedData = this.modifiedData.filter(
      data =>
        (data.id as number) >= this.startEndId[0] &&
        (data.id as number) <= this.startEndId[1]
    );
    this.startEndId = [1, this.modifiedData.length];
    this.updateData(this.modifiedData, this.limit);
    this.isFilter = true;
    this.applyVariantsFilter.emit({ filter: true });
  }
}
