import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment-duration-format';

@Pipe({
  name: 'hoursFormat'
})
export class HoursFormatPipe implements PipeTransform {
  transform(seconds: any): any {
    if (seconds) {
      if (seconds >= 3600) {
        const duration: any = moment.duration(seconds, 'seconds');
        const formatted = duration.format('H', { largest: 2 });
        return formatted;
      } else {
        return (seconds / 3600).toFixed(2);
      }
    }
    return seconds;
  }
}
