import {
  Component,
  OnInit,
  OnChanges,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { GlobalFiltersService } from '@bli/core/global-filters/state';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import { UtilityService } from '@bli/product-features/productivity/productivity-uitlity-service';
import { MatDialog } from '@angular/material/dialog';
import { ProcessExplorerGroupDashboardComponent } from './process-explorer-grouping/components/process-explorer-group-dashboard/process-explorer-group-dashboard.component';

declare const processGraph: any;

@Component({
  selector: 'app-process-explorer-graph',
  templateUrl: './process-explorer-graph.component.html',
  styleUrls: ['./process-explorer-graph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessExplorerGraphComponent implements OnInit, OnChanges {
  @Input() processGraphData: string;
  @Input() activityList: any = {};
  @Input() graphStats: any;
  @Input() statFormats: Array<any>;
  @Input() isLoading = true;
  @Input() hideStatsSelection = false;
  @Input() clearSelection: boolean;
  @Input() selectNode: string;
  @Input() graphErrorMessage: string;
  @Input() nodeEdgeClickable: boolean;
  @Input() highlightsNodes: any = [];
  @Input() highlightsEdges: any = [];
  @Input() dynamicEdges: string;
  @Input() isApplyEdgesOpacity: boolean;
  @Input() disabeHighlightBlink = false;
  @Input() disableGrouping = false;
  @Input() groups = {};
  @Input() hideOptions = false;

  @Output() processTypeChanged = new EventEmitter<string>();
  @Output() nodeSelected = new EventEmitter<any>();
  @Output() edgeSelected = new EventEmitter<any>();
  @Output() unSelected = new EventEmitter<any>();
  @Output() toggleGroup = new EventEmitter<any>();

  public isGraphDataLoading = true;
  public graphInstance: any;
  public weightRange = [1, 10];
  public opacityRange = [0, 9];
  // Opacity Colors - 0.8, 0.6, 0.4, 0.2, 0.1
  // public opacityColors = ['#070d59cc', '#070d5999', '#070d5966', '#070d5933', '#070d591a']
  // '#8E95F6', '#6871F3', '#424EF0', '#1D2AED', '#111ED0', '#0E18AA', '#0B1384',
  public opacityColors = [
    '#C7CAFA',
    '#A1A6F7',
    '#6871F3',
    '#1D2AED',
    '#111ED0',
    '#070D59',
    '#070D59',
    '#070D59',
    '#070D59',
    '#070D59'
  ];

  public hiddenActivities = [];
  public dynamicId = Date.now();
  public selectedFormat: any = {};

  nodesList = [];
  edgesList = [];

  utilityService: any = {};

  constructor(
    private globalFiltersService: GlobalFiltersService,
    private router: Router,
    private container: ElementRef,
    private matDialog: MatDialog
  ) {
    this.utilityService = UtilityService;
  }

  ngOnInit() {
    const globalFilters = {
      ...this.globalFiltersService.getGlobalFilter().global_filters
    };
    this.hiddenActivities = [];
    if (globalFilters) {
      Object.keys(globalFilters).map(key => {
        if (globalFilters[key].filter === 'hidden_activities_filter') {
          this.hiddenActivities = [
            ...this.hiddenActivities,
            ...globalFilters[key].hidden_activities
          ];
        }
      });
    }
    this.isGraphDataLoading = this.isLoading;
    this.renderGraph(this.processGraphData, this.dynamicEdges);
  }

  ngOnChanges(changes) {
    if (changes && changes.isLoading) {
      this.isGraphDataLoading = changes.isLoading.currentValue;
    }
    if (changes && changes.processGraphData) {
      const processGraphData =
        changes.processGraphData && changes.processGraphData.currentValue
          ? changes.processGraphData.currentValue
          : '';
      const dynamicEdges =
        changes.dynamicEdges && changes.dynamicEdges.currentValue
          ? changes.dynamicEdges.currentValue
          : '';
      this.renderGraph(processGraphData, dynamicEdges);
    }
    if (changes.selectNode) {
      this.selectNodeByKey(changes.selectNode.currentValue);
    }
    if (this.clearSelection) {
      this.removeAllSelectedClass();
    }
  }

  ProcessTypeChanged(statFormat) {
    this.statFormats.map(obj => (obj.selected = false));
    statFormat.selected = true;
    this.processTypeChanged.next(statFormat.value);
  }

  parseGraphData() {
    const graphData: any = this.processGraphData.split('\n');
    this.nodesList = [];
    this.edgesList = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < graphData.length; i++) {
      // Start: Parse edge value with string ("8h 30m")
      const regExp = /\"([^)]+)\"/;
      const matches = regExp.exec(graphData[i]);
      if (matches && matches.length) {
        const edgeStr: any = matches[1];
        const edgeValue = edgeStr.replaceAll(' ', '_');
        graphData[i] = graphData[i].replaceAll(matches[0], edgeValue);
      }
      // End: Parse edge value with string

      const lineItem = graphData[i].split(' ');
      if (lineItem[0] === 'node') {
        this.nodesList.push(lineItem);
      }
      if (lineItem[0] === 'edge') {
        this.edgesList.push(lineItem);
      }
    }

    const graph = new processGraph(
      `#process-explorer-chart-${this.dynamicId}`,
      this.nodesList,
      this.edgesList,
      this.activityList,
      this.dynamicEdges,
      this.groups
    );
    graph.initGraph(graphInstance => {
      this.graphInstance = graphInstance;
      this.updateNodesAndEdges();
    });
  }

  renderGraph(processGraphData, dynamicEdges) {
    if (this.statFormats && this.statFormats.length) {
      this.selectedFormat = this.statFormats.filter(obj => obj.selected)[0];
    }

    if (!processGraphData) {
      this.graphInstance = '';
      d3.selectAll(`#process-explorer-chart-${this.dynamicId}`).html('');
      return;
    }
    this.isGraphDataLoading = true;
    d3.selectAll(`#process-explorer-chart-${this.dynamicId}`).html('');
    this.parseGraphData();
  }

  updateNodesAndEdges() {
    const that = this;
    const container = d3.select(this.container.nativeElement);
    const svg = d3
      .select(`#process-explorer-chart-${this.dynamicId}`)
      .select('svg');
    const nodes = container.selectAll('.node');
    const edges = container.selectAll('.edge');
    const groups = container.selectAll('.group');

    const nodeLabels = [];
    svg.attr('width', '100%').attr('height', '100%');

    /* Node Processing. */
    nodes.each((d, i, n) => {
      const nthis = n[i];
      const node: any = d3.select(nthis);
      const nodeKey = node.attr('node-key');
      const nodeLabel = node.attr('node-label');
      const nodeValue = node.attr('node-value');
      nodeLabels[nodeKey] = {
        label: nodeLabel,
        value: nodeValue,
        key: nodeKey
      };
    });

    /* Edge Processing. */
    const maxCases =
      that.graphStats && that.graphStats.max ? that.graphStats.max : 1;
    const minCases =
      that.graphStats && that.graphStats.min ? that.graphStats.min : 1;
    const threshold =
      that.graphStats &&
      Object.prototype.hasOwnProperty.call(that.graphStats, 'threshold');

    edges.each((d, i, n) => {
      const ethis = n[i];
      const edge: any = d3.select(ethis);

      const edgeText = edge.select('text');
      let edgeTextValue = edgeText.text();
      edgeTextValue = edgeTextValue.replaceAll(',', '');

      let throughputTimeValue: any = '';
      let isTimeValue = false;

      if (!this.selectedFormat) {
        this.selectedFormat = {
          value: 'case_frequency'
        };
      }

      if (
        this.selectedFormat.value === 'mean_throughput_time' ||
        this.selectedFormat.value === 'median_throughput_time' ||
        this.selectedFormat.value === 'trimmed_mean_throughput_time'
      ) {
        throughputTimeValue = edgeTextValue;
        isTimeValue = true;
      }

      let timeFormatedValue: any = '';
      if (isTimeValue) {
        timeFormatedValue = throughputTimeValue.replaceAll('_', ' ');
        edgeTextValue = throughputTimeValue;
      }
      const edgeTextBox = edgeText.node().getBBox();
      edgeTextBox.x = edgeTextBox.x - edgeTextBox.width / 2;

      const edgePath = edge.select('path.link');
      const edgeMarker = edge.select('marker');

      let formatedValue = '';

      // Calculate Line weight
      let lineWeight = 1;
      let edgeSuffix = '';
      if (maxCases) {
        let caseCount = parseFloat(edgeTextValue);
        if (isTimeValue) {
          const timeValue = edgeText.attr('data-value');
          caseCount = parseFloat(timeValue ? timeValue : 0);
        }

        lineWeight =
          ((caseCount - minCases) / (maxCases - minCases)) *
            (this.weightRange[1] - this.weightRange[0]) +
          this.weightRange[0];

        lineWeight =
          lineWeight > this.weightRange[1] ? this.weightRange[1] : lineWeight;

        formatedValue = edgeTextValue
          ? parseInt(edgeTextValue, 0).toLocaleString()
          : '';
        const edgeValArr = edgeTextValue.split(' ');
        if (edgeValArr.length > 1) {
          edgeSuffix = edgeValArr[1];
        }
      }

      // Calculate Threshold
      if (threshold) {
        const thresholdVal = edgeTextValue.split(' ')[0];
        formatedValue = edgeTextValue;
        edge.classed('threshold', thresholdVal > threshold);
      }
      let lineColor = this.opacityColors[9];
      if (!lineWeight) {
        lineWeight = 1;
      } else {
        // console.log(Math.round(lineWeight), formatedValue)
        lineColor = this.opacityColors[Math.round(lineWeight) - 1];
        lineWeight = lineWeight - 5;
        if (lineWeight <= 0) {
          lineWeight = 1;
        }
      }
      // console.log(lineWeight, lineWeight + 5)

      if (!this.isApplyEdgesOpacity) {
        lineColor = this.opacityColors[9];
      }
      // const opacity = ((lineWeight - this.weightRange[0]) / (this.weightRange[1] - this.weightRange[0]) ) * (this.opacityRange[1] - this.opacityRange[0]) + this.opacityRange[0];
      edgePath.attr('stroke-width', lineWeight);
      // .attr('opacity', opacity)

      // Add opacity only for the FE rendered node.
      if (edge.classed('appended')) {
        edgePath.style('stroke', lineColor);
        edgeMarker.style('fill', lineColor);
      }

      edgeText
        .attr('x', edgeTextBox.x - edgeTextBox.width / 2 + 10)
        .attr('text-anchor', 'left')
        .attr('class', edgeTextValue)
        .text(
          `${isTimeValue ? timeFormatedValue : formatedValue} ${edgeSuffix}`
        );

      edge
        .insert('rect', 'text')
        .attr('width', edgeTextBox.width + 10)
        .attr('height', '15px')
        .attr('rx', '7px')
        .attr('x', edgeTextBox.x - edgeTextBox.width / 2 + 5)
        .attr('y', edgeTextBox.y - 1)
        .attr('class', `edge-label-backdrop ${edgeTextValue}`);
    });

    if (this.nodeEdgeClickable) {
      that.addClickEventsForNodeEdge(
        that,
        svg,
        nodes,
        edges,
        nodeLabels,
        groups
      );
    }
    this.isGraphDataLoading = false;
    this.resetToCenter();

    this.highlightEdges(true);
    this.highlightNodes(true);
    if (!this.disabeHighlightBlink) {
      setTimeout(() => {
        this.highlightEdges(false);
        this.highlightNodes(false);
      }, 1500);
    }
    // setTimeout(() => {
    //   this.highlightEdges(false);
    // }, 1500);

    // setTimeout(() => {
    //   this.highlightNodes(false);
    // }, 1500);
  }

  isNumeric = (num: any) =>
    (typeof num === 'number' ||
      (typeof num === 'string' && num.trim() !== '')) &&
    !isNaN(num as number);

  highlightNodes(isHighlight) {
    const container = d3.select(this.container.nativeElement);
    this.highlightsNodes.map(node => {
      container
        .select('.graph')
        .selectAll(`[node-key='${node}']`)
        .classed('node-highlighted', isHighlight);
    });
  }

  highlightEdges(isHighlight) {
    const container = d3.select(this.container.nativeElement);
    this.highlightsEdges.map(edge => {
      const edgeLabel = edge.split(' -> ');
      container
        .select('.graph')
        .selectAll(`[edge-start='${edgeLabel[0]}'][edge-end='${edgeLabel[1]}']`)
        .classed('highlighted', isHighlight);
    });
  }

  addClickEventsForNodeEdge(that, svg, nodes, edges, nodeLabels, groups) {
    const container = d3.select(this.container.nativeElement);
    nodes.on('click', function () {
      that.preventingDefault();
      const nodeKey = d3.select(this).attr('node-key');
      const nodeLabel = d3.select(this).attr('node-label');

      that.removeAllSelectedClass();

      container.select('.graph').classed('selected', true);
      d3.select(this).classed('selected', true);

      container
        .select('.graph')
        .selectAll(`[edge-end='${nodeKey}']`)
        .classed('node-selected selected', true);
      container
        .select('.graph')
        .selectAll(`[edge-start='${nodeKey}']`)
        .classed('node-selected selected', true);

      that.nodeSelected.next({ key: nodeKey, label: nodeLabel });
    });

    edges.on('click', function (e) {
      that.preventingDefault();
      const edgeLabel = d3.select(this).attr('edge-label');
      const edgeStart = d3.select(this).attr('edge-start');
      const edgeEnd = d3.select(this).attr('edge-end');

      if (edgeLabel === 'hidden') {
        return;
      }

      that.removeAllSelectedClass();

      container.select('.graph').classed('selected', true);
      d3.select(this).classed('selected', true);

      container
        .select('.graph')
        .selectAll(`[node-key='${edgeStart}']`)
        .classed('edge-selected selected', true);
      container
        .select('.graph')
        .selectAll(`[node-key='${edgeEnd}']`)
        .classed('edge-selected selected', true);

      that.edgeSelected.next({
        startNode: nodeLabels[edgeStart],
        endNode: nodeLabels[edgeEnd]
      });
    });

    groups.on('click', function (e) {
      that.preventingDefault();
      const groupId = d3.select(this).attr('group-id');
      that.groups[groupId].expanded = !that.groups[groupId].expanded;
      that.toggleGroup.emit({
        data: that.groups[groupId],
        key: `groups.${groupId}`
      });
    });
    svg.on('click', () => {
      that.removeAllSelectedClass();
      this.unSelected.next(true);
    });
  }

  resetToCenter() {
    if (this.graphInstance) {
      this.graphInstance.svg
        .transition()
        .duration(300)
        .call(
          this.graphInstance.dragSvg.transform,
          d3.zoomIdentity,
          d3.zoomTransform(this.graphInstance.svg.node()).invert([0, 0])
        );
    }
  }

  graphZoomInOut(zoomOpt) {
    if (zoomOpt === 'reset') {
      this.resetToCenter();
    } else {
      this.graphInstance.svg
        .transition()
        .duration(300)
        .call(
          this.graphInstance.dragSvg.scaleBy,
          zoomOpt === 'in' ? 1.3 : 1 / 1.3
        );
    }
  }

  preventingDefault() {
    const event = d3.event;
    event.preventDefault();
    event.stopPropagation();
  }

  setElementsOpacity(element, opacity = 1, timeout = 300) {
    d3.selectAll(element)
      .transition()
      .duration(timeout)
      .style('opacity', opacity);
  }

  setElementOpacity(element, opacity = 1, timeout = 300) {
    d3.select(element).transition().duration(timeout).style('opacity', opacity);
  }

  selectNodeByKey(nodeKey) {
    const container = d3.select(this.container.nativeElement);
    this.removeAllSelectedClass();
    container.select('.graph').classed('selected', true);
    container
      .select('.graph')
      .selectAll(`[node-key='${nodeKey}']`)
      .classed('selected', true);
    container
      .select('.graph')
      .selectAll(`[edge-end='${nodeKey}']`)
      .classed('node-selected selected', true);
    container
      .select('.graph')
      .selectAll(`[edge-start='${nodeKey}']`)
      .classed('node-selected selected', true);
  }

  removeAllSelectedClass() {
    const container = d3.select(this.container.nativeElement);
    container.select('.graph.selected').classed('selected', false);
    container
      .selectAll('.node.selected')
      .classed('edge-selected selected', false);
    container
      .selectAll('.edge.selected')
      .classed('node-selected selected', false);
  }

  onApplyHideActivity(event) {
    if (event && event.length) {
      const hiddenActFilter = {
        filter: 'hidden_activities_filter',
        hidden_activities: event
      };
      const filters = { ...this.globalFiltersService.getGlobalFilter() };
      filters.global_filters.push(hiddenActFilter);
      this.globalFiltersService.saveGlobalFilterData(filters);
      this.redirectTo(this.router.url);
    }
  }

  redirectTo(uri) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  openGroups() {
    this.matDialog.open(ProcessExplorerGroupDashboardComponent, {
      panelClass: ['side-sheet'],
      width: '550px',
      data: {
        activityList: this.activityList,
        groups: this.groups
          ? Object.keys(this.groups).map(key => ({ ...this.groups[key], key }))
          : []
      },
      autoFocus: false,
      disableClose: true
    });
  }
}
