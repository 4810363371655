import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, Chart } from 'chart.js';

@Component({
  selector: 'app-chartjs-bubble-chart',
  templateUrl: './chartjs-bubble-chart.component.html',
  styleUrls: ['./chartjs-bubble-chart.component.scss']
})
export class ChartjsBubbleChartComponent implements OnInit {
  @Input() chartConfig: any = {};
  chart: any = {};
  // theme = ['#003f5c', '#ffa600', '#e75290', '#537d06', '#ff6400', '#7a33b6', '#b81206'];

  // public bubbleChartOptions: ChartOptions = {
  //   responsive: true,
  //   scales: {
  //     xAxes: [{
  //       ticks: {
  //         min: 0,
  //         max: 30,
  //       }
  //     }],
  //     yAxes: [{
  //       ticks: {
  //         min: 0,
  //         max: 30,
  //       }
  //     }]
  //   }
  // };
  // public bubbleChartType: ChartType = 'bubble';
  // public bubbleChartLegend = true;

  // public bubbleChartData: ChartDataSets[] = [
  //   {
  //     data: [
  //       { x: 10, y: 10, r: 10 },
  //       { x: 15, y: 5, r: 15 },
  //       { x: 26, y: 12, r: 23 },
  //       { x: 7, y: 8, r: 8 },
  //     ],
  //     label: 'Series A',
  //   },
  // ];

  ngOnInit() {
    this.initChart();
  }
  initChart() {
    const config: ChartConfiguration = {
      type: 'bubble',
      data: {
        // labels: this.chartConfig.general_options.labels,
        datasets: this.chartConfig.general_options.datasets
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: this.chartConfig.general_options.title
          },
          legend: {
            display: this.chartConfig.diagram_area_legend.showLegend,
            position: this.chartConfig.diagram_area_legend.legendPosition,
            labels: {
              font: { size: 12 },
              boxWidth: 5
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,

        // tooltips: {
        //   enabled: this.chartConfig.tooltips.showTooltip,
        //   backgroundColor: 'rgba(0, 0, 0, 0.62)',
        //   cornerRadius: 0,
        //   xPadding: 10,
        //   yPadding: 10,
        //   caretPadding: 10
        // },
        scales: {
          xAxes: {
            title: {
              display: true,
              text: this.chartConfig.dimension_axis.label
            },
            display: this.chartConfig.dimension_axis.displayAxis,
            min: 0,
            max: 30
          },
          yAxes: {
            title: {
              display: true,
              text: this.chartConfig.primary_axis.label
            },
            display: this.chartConfig.primary_axis.displayAxis,
            min: 0,
            max: 30
          }
        }
      }
    };
    // const bubbleChartOptions: ChartOptions = {
    //   responsive: true,
    //   scales: {
    //     xAxes: [{
    //       ticks: {
    //         min: 0,
    //         max: 50,
    //       }
    //     }],
    //     yAxes: [{
    //       ticks: {
    //         min: 0,
    //         max: 50,
    //       }
    //     }]
    //   }
    // };

    // const bubbleChartType: ChartType = 'bubble';
    // const bubbleChartLegend = true;

    // const bubbleChartData: ChartDataSets[] = [
    //   {
    //     data: [
    //       { x: 15, y: 15, r: 15 },
    //       { x: 25, y: 15, r: 25 },
    //       { x: 36, y: 12, r: 33 },
    //       { x: 10, y: 18, r: 18 },
    //     ],
    //     label: 'Investment Equities',
    //   },
    // ];

    // config.data.datasets.map((obj, index) => {
    //   config.data.datasets[index].borderColor = this.getColor(index);
    //   config.data.datasets[index].pointBackgroundColor = this.getColor(index);
    //   config.data.datasets[index].fill =  this.chartConfig.general_options.fillArea;

    // });

    this.chart = new Chart('bubble-canvas', config);
  }
  getColor(index) {
    // return this.theme[index % this.theme.length];
  }

  // initChart() {
  //   const config: any = {
  //     type: 'doughnut',
  //     data: {
  //       labels: this.chartConfig.general_options.labels,
  //       datasets: this.chartConfig.general_options.datasets
  //     },
  //     options: {
  //       legend: {
  //         display: this.chartConfig.diagram_area_legend.showLegend,
  //         position: this.chartConfig.diagram_area_legend.legendPosition,
  //         labels: {
  //           fontSize: 12,
  //           boxWidth: 5
  //         }
  //       },
  //       title: {
  //         display: true,
  //         text: this.chartConfig.general_options.title
  //       },
  //       tooltips: {
  //         enabled: this.chartConfig.tooltips.showTooltip,
  //         backgroundColor: 'rgba(0, 0, 0, 0.62)',
  //         cornerRadius: 0,
  //         xPadding: 10,
  //         yPadding: 10,
  //         caretPadding: 10
  //       },
  //       scales: {
  //         xAxes: [{
  //           display: this.chartConfig.dimension_axis.displayAxis
  //         }],
  //         yAxes: [{
  //           display: this.chartConfig.primary_axis.displayAxis
  //         }],
  //       }
  //     }
  //   };
  //   config.data.datasets.map((obj, index) => {
  //     config.data.datasets[index].backgroundColor =  config.data.datasets[index].data.map((data, i) => {
  //       return this.getColor(i);
  //     });
  //   });

  //   this.chart = new Chart('donut-canvas', config);
  // }
}
