import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTPInterceptor } from './shared/utils/http-interceptor';
import { HttpCancelService } from './shared/utils/http-cancel.service';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from './shared/shared.module';
// import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
// import { Environment } from '../environments/environment';
import { ErrorInterceptor } from '@bli-shared/utils/error-interceptor';
import { GlobalErrorHandler } from '@bli-shared/utils/global-error-handler';
import { AppVersionComponent } from './app-version/app-version.component';
import { SlackAckComponent } from './slack/slack-ack/slack-ack.component';
import { SlackActionCallbackComponent } from './slack/slack-action-callback/slack-action-callback.component';
import { FivetranAuthAckComponent } from './fivetran-auth-ack/fivetran-auth-ack.component';
import { SalesforceAuthCallbackComponent } from './salesforce-auth-callback/salesforce-auth-callback.component';
// import { RouterModule } from '@angular/router';

import 'ag-grid-enterprise';

import { LicenseManager } from 'ag-grid-enterprise';
import { LicenseManager as Manager } from '@ag-grid-enterprise/core';
import { Ag_GRID_LICENSES } from '@bli-shared/utils/ag-grid-license.constant';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AppVersionComponent,
    SlackAckComponent,
    SlackActionCallbackComponent,
    FivetranAuthAckComponent,
    SalesforceAuthCallbackComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule
    // Environment.production ? [] : AkitaNgDevtools.forRoot({ shallow: false, sortAlphabetically: true })
  ],
  providers: [
    HttpCancelService,
    { provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    LicenseManager.setLicenseKey(Ag_GRID_LICENSES);
    Manager.setLicenseKey(Ag_GRID_LICENSES);
  }
}
