import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          display: 'none',
          opacity: 0
        })
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 0
            }),
            style({
              opacity: 1
            })
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1
            }),
            style({
              transform: 'translate3d(10%, 0, 0) skewX(10deg)',
              opacity: 0
            })
          ])
        )
      )
    ])
  ],
  preserveWhitespaces: false
})
export class ToastMessageComponent extends Toast implements OnInit {
  @ViewChild('ellipsisex', { static: false }) someInput: ElementRef;
  toastr: {
    type: string;
    label: string;
    text: string;
    link: any[];
  };

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
    this.toastrService.toasts.map(toast => {
      const newToast = JSON.parse(toast.message);
      this.toastr = {
        type: newToast.type || '',
        label: newToast.label || '',
        text: newToast.text || '',
        link: newToast.link || []
      };
      return this.toastr;
    });
  }

  action(event) {
    event.stopPropagation();
    this.toastPackage.triggerAction(event.target.text);
    return false;
  }
  toggleEllipsis(event) {
    event.stopPropagation();
    // this.someInput.nativeElement.classList.toggle('text-truncate');
  }
}
