// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas {
  width: 600px !important;
  height: 170px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/chartjs/chartjs-bubble-chart/chartjs-bubble-chart.component.scss"],"names":[],"mappings":"AAAA;EAEE,uBAAA;EACA,wBAAA;AAAF","sourcesContent":["canvas{\n\n  width:600px !important;\n  height:170px !important;\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
