export interface GlobalFilterRequest {
  global_filter: GlobalFilter;
}

export interface GlobalFilter {
  global_filters?: [];
  start_date?: string;
  end_date?: string;
}

export interface GlobalFiltersStateCore {
  id: any;
  start_date: string;
  end_date: string;
  date_range: any;
  global_filters: [];
  variant_ids: [];
  cases: [];
  stats: any;
  variant_explorer: object;
  process_explorer: object;
  active_page: object;
  isFilterApplied: boolean;
  isEdgeSelected: boolean;
}

export interface ThroughputTimeSelection {
  start: Start;
  between: Between;
  end: End;
}

export interface ReworkSelection {
  activity: string;
  times_per_cases: TimesPerCases;
}

export interface Start {
  occurrence: number;
  activity: string;
}

export interface Between {
  amount_time: string;
  start: number;
  end: number;
}

export interface End {
  occurrence: number;
  activity: string;
}

export interface TimesPerCases {
  start: number;
  end: number;
}

export interface GlobalFilterData {
  start_date: string;
  end_date: string;
  process_flow_selection: any;
}
