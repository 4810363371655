import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { UtilityService } from '@bli/product-features/productivity/productivity-uitlity-service';
import { ProcessFilters, ActivityFilters } from './../../utils/app.constants';

@Component({
  selector: 'app-activity-details',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss']
})
export class ActivityDetailsComponent {
  @Input() casesComeFromList: Array<any[]>;
  @Input() casesGoToList: Array<any[]>;
  @Input() nodeActivityData: object;
  @Input() activityStats: any;
  @Input() activityTitle: string;
  @Input() activityType: string;
  @Input() nodeDetailsLoading: boolean;
  @Input() edgeDetailsLoading: boolean;
  @Input() showNodeActivity: boolean;
  @Input() edgeActivityData: any;

  @Output() closeClicked = new EventEmitter<boolean>();
  @Output() activityNodeClicked = new EventEmitter<boolean>();

  public processFilters: any = ProcessFilters;
  public activityFilters: any = ActivityFilters;
  utilityService: any = {};

  constructor() {
    this.utilityService = UtilityService;
  }

  onCloseClicked() {
    this.closeClicked.next(true);
  }

  onActivityNodeClicked(node) {
    this.activityNodeClicked.next(node);
  }
}
