import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { Enum, FormField } from '@bli/state/app.model';

@Component({
  selector: 'app-multi-picklist',
  templateUrl: './multi-picklist.component.html',
  styleUrls: ['./multi-picklist.component.scss']
})
export class MultiPicklistComponent implements OnInit {
  @Input() field: FormField;
  @Input() value: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() control: UntypedFormControl;

  @ViewChild('multiSelectInput', { static: true })
  multiSelectInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('chipList', { static: true }) chipList: MatChipGrid;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredOptions: Observable<string[]>;
  filteredGroups: Observable<Enum[]>;
  selectedOptions: string[];
  groups: any[] = [];
  isLoading = false;
  searchControl = new UntypedFormControl();
  subscription = new Subscription();
  _filterChildren = (opt: Enum[], value: string): Enum[] => {
    const filterValue = value.toLowerCase();
    return opt.filter(
      item => item.value.toLowerCase().indexOf(filterValue) === 0
    );
  };

  ngOnInit() {
    this._defaultData();
    const keys = Object.keys(this.field.enum[0]);
    if (keys.includes('category')) {
      const groupsData = this.field.enum.reduce((data, item) => {
        if (!data[item.category]) {
          data[item.category] = [];
        }
        data[item.category].push(item);
        return data;
      }, {});
      const keys = Object.keys(groupsData);
      keys.forEach(key => {
        const data: any = {
          name: key,
          children: groupsData[key]
        };
        this.groups.push(data);
      });
    }

    this.selectedOptions = this.control.value
      ? this.control?.value?.split(';')
      : [];
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(name => (name ? this._filter(name) : this._defaultData()))
    );
    this.filteredGroups = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      map(searchText => this._filterGroups(searchText))
    );
    this.addValidation();
  }

  addValidation() {
    this.control.clearValidators();
    // this.searchControl.clearValidators();
    if (this.field.mandatory) {
      this.control.setValidators(Validators.required);
      // this.searchControl.setValidators(Validators.required);
      return;
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;
    if ((value || '').trim()) {
      if (!this.field.enum.find(j => j.value.includes(value))) {
        input.value = '';
        this.searchControl.reset();
        this._defaultData();
      }
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(option: string): void {
    const index = this.selectedOptions.indexOf(option);
    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.setOptionList();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.valudateAndPushData(event.option.viewValue);
    this.field.enum.find(j => j.value === event.option.viewValue).disabled =
      true;
  }

  setOptionList() {
    this.field.enum.forEach(element => {
      element.disabled = false;
    });
    const selectedKeys = [];
    this.selectedOptions.forEach(element => {
      const item = this.field.enum.find(j => j.value === element);
      if (item) {
        this.field.enum.find(j => j.value === element).disabled = true;
        selectedKeys.push(item.key);
      }
    });
    this.control.setValue(selectedKeys.join(';'));
    this.control.updateValueAndValidity();
  }

  valudateAndPushData(option: string) {
    const ctrl = new UntypedFormControl(option, Validators.required);
    if (this.selectedOptions.includes(option) || ctrl.invalid) {
      return;
    }
    this.selectedOptions.push(option);
    this.setOptionList();
    this.searchControl.reset();
  }

  validateOption(value: string, model: Enum) {
    if (model.category === 'OUTPUT-FIELD') {
      this.selectedOptions = [];
      this.control.setValue('');
      this.valudateAndPushData(model.alias ? model.alias : model.value);
      this.field.enum.find(j => j.value === value).disabled = true;
    } else {
      const outputFields = this.field.enum
        .filter(obj => obj.category === 'OUTPUT-FIELD')
        .map(obj => (obj.alias ? obj.alias : obj.value));
      outputFields.forEach(outputField => {
        const index = this.selectedOptions.indexOf(outputField);
        if (index >= 0) {
          this.selectedOptions.splice(index, 1);
          this.setOptionList();
        }
      });
    }
  }

  private _defaultData(): any[] {
    if (this.field.enum && this.field.enum.length) {
      this.field.enum.forEach(element => {
        element.disabled = false;
      });
      if (this.selectedOptions?.length) {
        this.selectedOptions.forEach(element => {
          this.field.enum.find(j => j.value === element).disabled = true;
        });
      }
    }
    return this.field.enum && this.field.enum.length
      ? this.field.enum.slice()
      : [];
  }

  private _filter(name: string): any[] {
    const filterValue = name.toString().toLowerCase();
    return filterValue
      ? this.field.enum.filter(
          s => s.value.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
        )
      : this.field.enum;
  }

  private _filterGroups(value: string) {
    if (value) {
      return this.groups
        .map(group => ({
          children: this._filterChildren(group.children, value),
          name: group.name
        }))
        .filter(group => group.children.length > 0);
    }

    return this.groups;
  }
}
