import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { VerticalBarChartDirective } from './vertical-bar-chart';
import * as d3 from 'd3';
import { UtilityService } from '@bli/product-features/productivity/productivity-uitlity-service';
import { ModifiedVariant, Variant } from '../../model/variant.model';
import { of, Subject, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Directive()
export class VerticalChartWithVertivalBrushDirective
  extends VerticalBarChartDirective
  implements OnDestroy
{
  @Input() BarData: Variant[];
  @Input() startEndId: [number, number] = [1, 1];
  @Input() hideFilter: boolean;

  @Output() barChartBurshChanged = new EventEmitter<any>();
  @Output() applyVariantsFilter = new EventEmitter<any>();

  modifiedData: ModifiedVariant[];

  constructor() {
    super();
  }

  brush: any;
  private tooltip: any;
  private barPadding = 0;
  private brushEvent = new Subject<any>();
  private subscription = new Subscription();

  createVerticalBrush() {
    this.brush = d3
      .brushY()
      .extent([
        [0, 0],
        [this.width, this.height]
      ])
      .on('start end', () => this.brushEvent.next(d3.event));
    const brushContainer = this.svg
      .append('g')
      .attr('class', 'bv-brush')
      .call(this.brush);
    this.updateTooltip(brushContainer);
    this.filterBrushEvents();
  }

  createTooltip(container: ElementRef) {
    this.tooltip = d3
      .select(container.nativeElement)
      .append('div')
      .attr('class', 'tooltip');
  }

  private filterBrushEvents() {
    const sub = this.brushEvent
      .asObservable()
      .pipe(
        filter(v => v.sourceEvent && v.selection),
        switchMap(event => of(event))
      )
      .subscribe(() => this.brushEnded());
    this.subscription.add(sub);
  }

  private brushEnded() {
    const range = d3.event.selection
      .map((r, index) => {
        index === 0 ? (r += this.barPadding) : (r -= this.barPadding);
        return r;
      })
      .map(this.y.invert);
    if (range[0] > range[1]) {
      range[0] = range[1];
    }
    if (range[0] === 'Others') {
      range[1] = 'Others';
    }
    if (range[0] !== this.startEndId[0] || range[1] !== this.startEndId[1]) {
      this.startEndId = range;
      this.setBrushPosition(this.startEndId, this.y, this.brush, true);
    }
  }

  setBrushPosition(idRange, yScale, brushY, dragEnd = false) {
    const brushStartPos = idRange.map(yScale)[0];
    const brushEndPos = idRange.map(yScale)[1] + yScale.bandwidth();
    let firstBar = null;
    let secondBar = null;
    this.svg.selectAll('rect.bar').style('opacity', (d, i, n) => {
      if (i === 0) {
        firstBar = d3.select(n[i]);
      }
      if (i === 1) {
        secondBar = d3.select(n[i]);
      }
      const barY = d3.select(n[i]).attr('y');
      return barY >= brushStartPos && barY < brushEndPos ? '1' : '.4';
    });
    this.getBarPadding(firstBar, secondBar);
    if (
      dragEnd &&
      this.startEndId[0] === idRange[0] &&
      this.startEndId[1] === idRange[1]
    ) {
      this.dragEnd(idRange);
    }
    if (brushY && brushStartPos && brushEndPos) {
      setTimeout(() =>
        this.svg
          .select('.bv-brush')
          .transition()
          .call(brushY.move, [
            brushStartPos - this.barPadding,
            brushEndPos + this.barPadding
          ])
      );
    }
  }

  protected dragEnd(idRange) {
    const selectedIdRange = [...idRange];
    if (selectedIdRange[0] === 'Others') {
      idRange[0] =
        this.modifiedData[this.modifiedData.length - 1].otherStartEnd[0];
    }
    if (selectedIdRange[1] === 'Others') {
      idRange[1] =
        this.modifiedData[this.modifiedData.length - 1].otherStartEnd[1];
    }
    this.startEndId = idRange;
    const selectedVariants = this.BarData.filter(
      (variant, index) =>
        index + 1 >= this.startEndId[0] && index + 1 <= this.startEndId[1]
    );
    this.barChartBurshChanged.emit({
      selectedVariants,
      startEndId: selectedIdRange
    });
  }

  private getBarPadding(firstBar, secondBar) {
    if (!firstBar) {
      this.barPadding = 0;
      return;
    }
    if (!secondBar) {
      const barHeight = firstBar.attr('height');
      this.barPadding = (this.height - barHeight) / 4;
      return;
    }
    const firstBarHeight = firstBar.attr('height');
    const firstBarY = firstBar.attr('y');
    const secondBarY = secondBar.attr('y');
    this.barPadding = (secondBarY - firstBarY - firstBarHeight) / 2;
  }

  updateTooltip(brush) {
    const svg = this.svg;
    const tooltip = this.tooltip;
    const totalWidth = this.width;
    // const totalHeight = this.height;
    const utlityService = UtilityService;
    brush
      .on('touchmove mousemove', function () {
        const svgPosition = svg.node().getBoundingClientRect();
        const mousePosition = d3.mouse(this);
        let tooltipYPos = 0;
        let tooltipXPos = svgPosition.width;
        let tooltipVisiblilty = 0;
        let tooltipData = null;
        svg.selectAll('rect.hover-line').remove();
        svg.selectAll('rect.bar').each((data, i, bar) => {
          const element = d3.select(bar[i]);
          const yPosition = +element.attr('y');
          const height = +element.attr('height');
          // const width = +element.attr('width');
          // const xPosition = +element.attr('x');
          if (
            mousePosition[1] >= yPosition &&
            mousePosition[1] <= yPosition + height
          ) {
            tooltipYPos = mousePosition[1];
            tooltipXPos = mousePosition[0];
            tooltipVisiblilty = 1;
            tooltipData = data;
            svg
              .append('rect')
              .attr('class', 'hover-line')
              .attr('height', '.5')
              .attr('fill', '#0005')
              .attr('width', totalWidth)
              .attr('x', 0)
              .attr('y', yPosition + height / 2);
          }
        });
        const tooltipPos = tooltip.node().getBoundingClientRect();
        tooltipXPos =
          tooltipXPos + tooltipPos.width >= totalWidth
            ? tooltipXPos - tooltipPos.width
            : tooltipXPos;
        tooltipYPos =
          tooltipYPos - tooltipPos.height > 0
            ? tooltipYPos - tooltipPos.height
            : tooltipYPos + 50;

        tooltip
          .style('left', `${tooltipXPos + 30}px`)
          .style('top', `${tooltipYPos}px`)
          .style('opacity', tooltipVisiblilty)
          .style('z-index', tooltipVisiblilty ? 1 : -1)
          .html(
            tooltipData
              ? `
          <h3 class='tooltip-title'>Variant: #${
            tooltipData.variant_id !== 'Others'
              ? tooltipData.variant_id
              : tooltipData.otherStartEnd[0] +
                ' - #' +
                tooltipData.otherStartEnd[1]
          }</h3>
          <div class='tooltip-content'><span></span><span>Case coverage: ${tooltipData.percent_cases_covered.toFixed(
            2
          )}</span></div>
          <div class='tooltip-content'><span></span><span>Cases: ${
            tooltipData.case_count
          }</span></div>
          <div class='tooltip-content'><span></span><span>Duration: ${utlityService.convertToHHMMSS(
            tooltipData.throughput_time
          )}</span></div>`
              : ''
          );
      })
      .on('mouseleave', () => {
        tooltip.style('opacity', '0');
        svg.selectAll('rect.hover-line').remove();
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
