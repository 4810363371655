// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-path {
  height: 568px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
}
.common-path .view-cases {
  width: 174px;
  height: 33px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  float: right;
  padding: 8px;
  border-radius: 4px;
  margin: 10px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1.25px;
  color: #070d59;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/card-common-path/card-common-path.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,+GAAA;EACA,yBAAA;AACJ;AAAI;EACI,YAAA;EACA,YAAA;EACA,qCAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EAEA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,cAAA;AACR","sourcesContent":[".common-path {\n    height: 568px;\n    border-radius: 4px;\n    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);\n    background-color: #ffffff;\n    .view-cases {\n        width: 174px;\n        height: 33px;\n        border: solid 1px rgba(0, 0, 0, 0.12);\n        float: right;\n        padding: 8px;\n        border-radius: 4px;\n        margin: 10px;\n        // font-family: Roboto;\n        font-size: 14px;\n        font-weight: 500;\n        font-stretch: normal;\n        font-style: normal;\n        line-height: 1.14;\n        letter-spacing: 1.25px;\n        color: #070d59;\n        //background-color: #fafafa;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
