// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background-color: white;
}
:host .mat-mdc-card {
  border-radius: 0;
  padding: 10px 24px;
}
:host .mat-mdc-card .mat-card-actions {
  padding-left: 8px;
}
:host .mat-mdc-card .mat-card-content {
  margin-bottom: 0px;
}
:host .no-card {
  padding: 10px 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/sub-header-card/sub-header-card.component.scss"],"names":[],"mappings":"AAAE;EACE,uBAAA;AACJ;AACI;EACE,gBAAA;EACA,kBAAA;AACN;AACM;EACE,iBAAA;AACR;AAEM;EACE,kBAAA;AAAR;AAII;EACE,kBAAA;AAFN","sourcesContent":["  :host {\n    background-color: white;\n\n    .mat-mdc-card {\n      border-radius: 0;\n      padding: 10px 24px;\n\n      .mat-card-actions {\n        padding-left: 8px;\n      }\n\n      .mat-card-content {\n        margin-bottom: 0px;\n      }\n    }\n\n    .no-card {\n      padding: 10px 16px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
