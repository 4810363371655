import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from '../../../material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppConstants, APP_CONSTANTS } from './../../utils/app.constants';
import { NgxErrorsModule } from './../ngx-errors/ngxerrors.module';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroupDirective
} from '@angular/forms';
import { FormFieldsFosterComponent } from './components/form-fields-foster/form-fields-foster.component';
import { EmailFieldFosterComponent } from './components/email-field-foster/email-field-foster.component';
import { SelectFieldFosterComponent } from './components/select-field-foster/select-field-foster-component';
import { MultiSelectFieldFosterComponent } from './components/multiselect-field-foster/multiselect-field-foster.component';
import { EmailPlaceholderFoasterComponent } from './components/email-placeholder-foaster/email-placeholder-foaster.component';
import { EmailPlaceholderSwitchPipe } from './components/email-placeholder-foaster/email-placeholder-switch.pipe';
import { CheckboxGroupFosterComponent } from './components/checkbox-group-foster/checkbox-group-foster.component';
import { AutocompleteFoasterComponent } from './components/autocomplete-foaster/autocomplete-foaster.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SinglePicklistComponent } from './components/single-picklist/single-picklist.component';
import { MultiPicklistComponent } from './components/multi-picklist/multi-picklist.component';
import { GroupRepeatableFosterComponent } from './components/group-repeatable-foster/group-repeatable-foster.component';
import { DynamicFosterComponent } from './components/dynamic-foster/dynamic-foster.component';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    LayoutModule,
    PortalModule,
    DragDropModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    NgxSkeletonLoaderModule
  ],
  declarations: [
    FormFieldsFosterComponent,
    SelectFieldFosterComponent,
    EmailFieldFosterComponent,
    MultiSelectFieldFosterComponent,
    EmailPlaceholderFoasterComponent,
    EmailPlaceholderSwitchPipe,
    CheckboxGroupFosterComponent,
    AutocompleteFoasterComponent,
    SinglePicklistComponent,
    MultiPicklistComponent,
    GroupRepeatableFosterComponent,
    DynamicFosterComponent
  ],
  exports: [
    CommonModule,
    MaterialModule,
    LayoutModule,
    PortalModule,
    DragDropModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    NgxErrorsModule,
    FormFieldsFosterComponent,
    SelectFieldFosterComponent,
    EmailFieldFosterComponent,
    MultiSelectFieldFosterComponent,
    EmailPlaceholderFoasterComponent,
    CheckboxGroupFosterComponent
  ],
  providers: [
    FormGroupDirective,
    { provide: APP_CONSTANTS, useValue: AppConstants },
    DatePipe
  ]
})
export class FormFieldsModule {}
