import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slack-ack',
  templateUrl: './slack-ack.component.html',
  styleUrls: ['./slack-ack.component.scss']
})
export class SlackAckComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigateByUrl('features/automations');
  }
}
