// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .mat-sheet-header {
  padding: 5px 0px;
}
:host h3 {
  padding-top: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/mat-dialog-routing/mat-dialog-routing.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[":host {\n  .mat-sheet-header {\n    padding: 5px 0px;\n  }\n  h3 {\n    padding-top: 3px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
