import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Bucket } from './data-collection.model';
import {
  DataCollectionStore,
  DataCollectionState
} from './data-collection.store';

@Injectable({ providedIn: 'root' })
export class DataCollectionQuery extends QueryEntity<DataCollectionState> {
  constructor(protected store: DataCollectionStore) {
    super(store);
  }

  readonly deletionList$ = this.select(s => s.waitingDel.length);
  readonly bucket$ = this.select(s => s.bucketId);
  readonly selectedBucket$ = this.select(s => s.bucket);
  readonly enableManageModel$ = this.select(s => s.enableManageModel);
  readonly currentManageModelStep$ = this.select(s => s.currentManageModelStep);

  set selectedBucket(bucket: Bucket) {
    this.store.update({ bucket });
  }

  set enableManageModel(enableManageModel: boolean) {
    this.store.update({ enableManageModel });
  }

  set currentManageModelStep(currentManageModelStep: string) {
    this.store.update({ currentManageModelStep });
  }
}
