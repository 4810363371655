import { ProcessExplorerCreateGroupComponent } from './components/process-explorer-create-group/process-explorer-create-group.component';
import { ProcessExplorerListGroupComponent } from './components/process-explorer-list-group/process-explorer-list-group.component';

export const PEGroupNavigation = [
  {
    path: '',
    component: ProcessExplorerListGroupComponent,
    data: {
      title: 'Groups',
      subTitle: '',
      addGroup: true
    },
    children: [
      {
        path: 'create-group',
        component: ProcessExplorerCreateGroupComponent,
        data: {
          title: 'Create Groups',
          subTitle: ''
        }
      },
      {
        path: 'update-group',
        component: ProcessExplorerCreateGroupComponent,
        data: {
          title: 'Update Groups',
          subTitle: ''
        }
      }
    ]
  }
];
