import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatChipGrid } from '@angular/material/chips';
import { FormField } from '@bli/state/app.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { groupRepeatableDuplicate } from './group-repeatable.validator';

@Component({
  selector: 'app-group-repeatable-foster',
  templateUrl: './group-repeatable-foster.component.html',
  styleUrls: ['./group-repeatable-foster.component.scss']
})
export class GroupRepeatableFosterComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  chipControl: UntypedFormControl = new UntypedFormControl(
    '',
    Validators.email
  );
  fields = [];
  filteredField$: Observable<any>;
  @Input() control: UntypedFormControl;
  @Input() field: FormField;
  @Input() data: any;
  @Input() formGroup: UntypedFormGroup;
  @ViewChild('fieldInput', { static: true })
  fieldInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('chipList', { static: true }) chipList: MatChipGrid;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.filterFields();
    this.createFormArray();
    const { params } = this.data;
    if (params && params[this.field.key]) {
      this.groupControl.removeAt(0);
      params[this.field.key].forEach(field => {
        this.add(field);
      });
    }
  }

  createFormArray() {
    const { key } = this.field;
    this.formGroup.setControl(
      key,
      this.fb.array(
        [],
        [groupRepeatableDuplicate('param_name'), Validators.required]
      )
    );
    this.add();
  }

  paramGroup({ param_name = '', param_value = '' }) {
    return this.fb.group({
      param_name: [param_name, [Validators.required]],
      param_value: [param_value, [Validators.required]]
    });
  }

  filterFields() {
    this.filteredField$ = this.chipControl.valueChanges.pipe(
      startWith(''),
      map((fieldName: string | null) => {
        if (this.chipControl.touched && this.chipControl.invalid) {
          this.chipList.errorState = true;
        } else {
          this.chipList.errorState = false;
        }
        return fieldName ? this._filter(fieldName) : this.field.options.slice();
      })
    );
  }

  private _filter(fieldName: string) {
    return this.field.options
      .filter(option =>
        option.alias.toLowerCase().includes(fieldName.toLowerCase())
      )
      .filter(
        option => !this.fields.find(field => field.alias === option.alias)
      );
  }

  add(field = {}): void {
    this.groupControl.push(this.paramGroup(field));
  }

  remove(i: number) {
    this.groupControl.removeAt(i);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fieldInput.nativeElement.value = '';
    this.chipControl.setValue(null);
    this.fields.push(event.option.value);
  }

  get groupControl(): UntypedFormArray {
    return this.formGroup.get(this.field.key) as UntypedFormArray;
  }
}
