// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host #container {
  position: relative;
}
:host #container #chart-with-vertical-brush {
  min-height: 100%;
}
:host ::ng-deep .selection {
  fill: transparent;
  stroke: #00c1d4;
  stroke-dasharray: 5 3;
  stroke-width: 1px;
}
:host ::ng-deep .mat-button-toggle-label-content {
  line-height: 36px !important;
}
:host ::ng-deep .tooltip {
  background: #fff;
  position: absolute;
  width: 150px;
  height: 80px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 12px;
  opacity: 0;
  z-index: -1;
  padding: 10px;
}
:host ::ng-deep .tooltip .tooltip-title {
  margin: 0 0 5px 0;
  font-weight: 500;
  font-size: 16px;
}
:host ::ng-deep .tooltip .tooltip-content {
  margin-bottom: 2px;
}
:host ::ng-deep .tooltip .tooltip-content span:first-child {
  margin-right: 5px;
  display: inline-block;
  background: #00c1d4;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modules/bar-chart-with-brush/components/bar-chart-with-vertical-brush/bar-chart-with-vertical-brush.component.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;AAFJ;AAGI;EACE,gBAAA;AADN;AAIE;EACE,iBAAA;EACA,eAAA;EACA,qBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,4BAAA;AAHJ;AAME;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;AAJJ;AAKI;EACE,iBAAA;EACA,gBAAA;EACA,eAAA;AAHN;AAKI;EACE,kBAAA;AAHN;AAIM;EACE,iBAAA;EACA,qBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAFR","sourcesContent":["@use '@angular/material' as mat;\n\n:host {\n  #container {\n    position: relative;\n    #chart-with-vertical-brush {\n      min-height: 100%;\n    }\n  }\n  ::ng-deep .selection {\n    fill: transparent;\n    stroke: #00c1d4;\n    stroke-dasharray: 5 3;\n    stroke-width: 1px;\n  }\n\n  ::ng-deep .mat-button-toggle-label-content {\n    line-height: 36px !important;\n  }\n\n  ::ng-deep .tooltip {\n    background: #fff;\n    position: absolute;\n    width: 150px;\n    height: 80px;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n    font-size: 12px;\n    opacity: 0;\n    z-index: -1;\n    padding: 10px;\n    .tooltip-title {\n      margin: 0 0 5px 0;\n      font-weight: 500;\n      font-size: 16px;\n    }\n    .tooltip-content {\n      margin-bottom: 2px;\n      span:first-child {\n        margin-right: 5px;\n        display: inline-block;\n        background: #00c1d4;\n        width: 10px;\n        height: 10px;\n        border-radius: 50%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
