import { Component, OnChanges, Input } from '@angular/core';
import { ChartType, ChartDataset, ChartOptions } from 'chart.js';
import { SCSS_VARS } from '@bli/scss-variables.generated';

@Component({
  selector: 'app-bar-chart-throughput',
  templateUrl: './bar-chart-throughput.component.html',
  styleUrls: ['./bar-chart-throughput.component.scss']
})
export class BarChartThroughputComponent implements OnChanges {
  @Input() xAxis: any[];
  @Input() yAxis: any[];
  @Input() graphName: string;
  @Input() xAxisLabel = '';
  @Input() yAxisLabel = '';
  @Input() xAxisMeasure = '';
  public barChartData: ChartDataset[];
  public barChartOptions: ChartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          title: item =>
            `${this.xAxisLabel}: ${item[0].label} ${this.xAxisMeasure}`
        }
      },
      legend: {
        display: false
      },
      title: {
        display: true,
        text: ''
      }
    }
    // responsive: true,
    // scales: {
    //   xAxes: {
    //     gridLines: {
    //       // color: SCSS_VARS['$brand-light-white']
    //     }
    //   },
    //   yAxes: {
    //     gridLines: {
    //       // color: SCSS_VARS['$brand-light-white']
    //     }
    //   }
    // },
  };
  public barChartLabels: string[];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  ngOnChanges() {
    this.barChartLabels = this.xAxis;
    this.barChartData = [
      {
        label: this.yAxisLabel,
        // barThickness: 25,
        // barPercentage: 1,
        maxBarThickness: 70,
        categoryPercentage: 0.2,
        barPercentage: 1.0,
        backgroundColor: SCSS_VARS['$brand-primary'],
        hoverBackgroundColor: SCSS_VARS['$brand-secondary'],
        // hoverBackgroundColor: '#00c1d4',
        data: this.yAxis
      }
      // { data: [60, 0], label: 'Series A', barThickness: 15, barPercentage: 0.4, backgroundColor:'#0000a0'},
      // { data: [0, 10], label: 'Series B', barThickness: 15, barPercentage: 0.4, backgroundColor:'#e4e8ee'}
    ];
  }
}
