// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  min-height: 100%;
}

mat-form-field {
  width: 100%;
}

.item {
  height: 100px;
}

::ng-deep .caption {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: rgb(0, 0, 0);
}

::ng-deep .button {
  margin: 0 0 0 8px;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: rgb(0, 0, 0);
}

::ng-deep .innerbutton {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: rgb(0, 0, 0);
}

::ng-deep .innerbutton .text-style-1 {
  color: rgb(0, 0, 0);
}

::ng-deep .mat-icon {
  height: 28px !important;
  width: 28px !important;
  font-size: 28px !important;
}

::ng-deep .mat-mdc-form-field-prefix .mat-icon-button, .mat-mdc-form-field-suffix .mat-icon-button {
  height: 2.5em;
  width: 2.5em;
}

.mat-tree-node {
  min-height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/database-metadata/database-metadata.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,WAAA;AAEF;;AACA;EACE,aAAA;AAEF;;AAAA;EAEE,eAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;EACA,mBAAA;AAEF;;AACA;EACE,iBAAA;EAEA,eAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAGA;EAEE,eAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,mBAAA;AADF;;AAGA;EACE,mBAAA;AAAF;;AAIA;EACE,uBAAA;EACA,sBAAA;EACA,0BAAA;AADF;;AAIA;EACE,aAAA;EACA,YAAA;AADF;;AAGA;EAAiB,gBAAA;AACjB","sourcesContent":[".list {\n  min-height: 100%;\n}\nmat-form-field {\n  width: 100%;\n}\n\n.item {\n  height: 100px;\n}\n::ng-deep  .caption {\n  // font-family: Roboto;\n  font-size: 18px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.33;\n  letter-spacing: 0.4px;\n  color: rgba(0, 0, 0);\n}\n\n::ng-deep  .button {\n  margin: 0 0 0 8px;\n  // font-family: Roboto;\n  font-size: 17px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.85;\n  letter-spacing: normal;\n  color: rgba(0, 0, 0);\n}\n\n \n::ng-deep  .innerbutton {\n  // font-family: Roboto;\n  font-size: 18px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.85;\n  letter-spacing: normal;\n  color: rgba(0, 0, 0);\n}\n::ng-deep  .innerbutton .text-style-1 {\n  color: rgba(0, 0, 0);\n}\n\n\n::ng-deep .mat-icon{\n  height:28px !important;\n  width:28px !important;\n  font-size:28px !important;\n}\n\n::ng-deep .mat-mdc-form-field-prefix .mat-icon-button, .mat-mdc-form-field-suffix .mat-icon-button {\n  height: 2.5em;\n  width: 2.5em;\n}\n.mat-tree-node { min-height: 40px }\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
