import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from '../material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroupDirective
} from '@angular/forms';
import { AlgorithmicHappyPathComponent } from './components/algorithmic-happy-path/algorithmic-happy-path.component';
import { BarChartThroughputComponent } from './components/bar-chart-throughput/bar-chart-throughput.component';
import { BottleneckComponent } from './components/bottleneck/bottleneck.component';
import { BubbleGraphComponent } from './components/bubble-graph/bubble-graph.component';
import { CardCommonPathComponent } from './components/card-common-path/card-common-path.component';
import { CardDeviationsComponent } from './components/card-deviations/card-deviations.component';
import { CardRootCauseComponent } from './components/card-root-cause/card-root-cause.component';
import { CardMetricComponent } from './components/card-metric/card-metric.component';
import { CardHappyPathComponent } from './components/card-happy-path/card-happy-path.component';
import { CardOtherFreqActivitiesComponent } from './components/card-other-freq-activities/card-other-freq-activities.component';
import { CardWhitelistComponent } from './components/card-whitelist/card-whitelist.component';
import { LineGraphComponent } from './components/line-graph/line-graph.component';
import { NewDataBucketComponent } from './components/new-data-bucket/new-data-bucket.component';
import { NgChartsModule } from 'ng2-charts';
import { ProgressSpinnerModule } from './components/progress-spinner/progress-spinner.module';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { NgxErrorsModule } from './modules/ngx-errors/ngxerrors.module';
import { FormFieldsModule } from './modules/form-fields/form-fields.module';
import { NgxErrorsDirective } from './modules/ngx-errors/ngxerrors.directive';
import { NgxErrorDirective } from './modules/ngx-errors/ngxerror.directive';
import { AppConstants, APP_CONSTANTS } from './utils/app.constants';
import { SubHeaderCardModule } from './components/sub-header-card/sub-header-card.module';
import { AppConfigModule } from './utils/app-config.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SafePipe } from './pipes/safe.pipe';
import { MatTreeModule } from '@angular/material/tree';
import { MatRippleModule } from '@angular/material/core';
import { DonutGraphComponent } from './components/donut-graph/donut-graph.component';
import { ProcessExplorerChartComponent } from './components/process-explorer-chart/process-explorer-chart.component';
import { ProcessExplorerGraphComponent } from './components/process-explorer-graph/process-explorer-graph.component';
import { PopupSearchComponent } from './components/popup-search/popup-search.component';
import { VariantsListComponent } from './components/variants-list/variants-list.component';
import { ActivityDetailsComponent } from './components/activity-details/activity-details.component';
import { RadialProgressChartComponent } from './components/radial-progress-chart/radial-progress-chart.component';
import { ProcessExplorerFilterComponent } from './components/process-explorer-filter/process-explorer-filter.component';
import { BarChartWithBrushComponent } from './components/bar-chart-with-brush/bar-chart-with-brush.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { AgGridModule } from 'ag-grid-angular';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { DynamicBarChartComponent } from './components/dynamic/dynamic-bar-chart/dynamic-bar-chart.component';
import { ColorPickerModule } from 'ngx-color-picker';

import { ToastrModule } from 'ngx-toastr';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { PathActivitySliderComponent } from './components/path-activity-slider/path-activity-slider.component';
// import { SlideshowModule } from 'ng-simple-slideshow';
import { NouisliderModule } from 'ng2-nouislider';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// import { MentionModule } from 'angular-mentions';
import { NgxDatatableComponent } from './components/ngx-datatable/ngx-datatable.component';
import { ChartjsLineChartComponent } from './components/chartjs/chartjs-line-chart/chartjs-line-chart.component';
import { ChartjsDonutChartComponent } from './components/chartjs/chartjs-donut-chart/chartjs-donut-chart.component';
import { ChartjsBoxplotChartComponent } from './components/chartjs/chartjs-boxplot-chart/chartjs-boxplot-chart.component';
import { ChartjsBubbleChartComponent } from './components/chartjs/chartjs-bubble-chart/chartjs-bubble-chart.component';
import { ChartjsScaatterPlotChartComponent } from './components/chartjs/chartjs-scatterplot-chart/chartjs-scatterplot-chart.component';
import { HoursFormatPipe } from './pipes/hours-format-pipe';
// import { DataBaseMetaDataComponent } from './components/database-metadata/database-metadata.component';
import { SortPipeModule } from './pipes/sort.pipe';
import { ProcessExplorerCropChartComponent } from './components/process-explorer-crop-chart/process-explorer-crop-chart.component';
import { ErrorComponent } from './components/error/error.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BarChartWithBrushV1Component } from './components/bar-chart-with-brush-v1/bar-chart-with-brush-v1.component';
import { HideActivitiesComponent } from './components/hide-activities/hide-activities.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
import { BarChartWithBrushModule } from './modules/bar-chart-with-brush/bar-chart-with-brush.module';
import { DataBaseMetaDataModule } from './components/database-metadata/database-metadata.module';
import { MatDialogRoutingModule } from './components/mat-dialog-routing/mat-dialog-routing.module';
import { ProcessExplorerGroupingModule } from './components/process-explorer-graph/process-explorer-grouping/process-explorer-grouping.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    LayoutModule,
    PortalModule,
    DragDropModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgChartsModule,
    NgxErrorsModule,
    FormFieldsModule,
    AppConfigModule,
    NgxDatatableModule,
    MatTreeModule,
    MatRippleModule,
    AgGridModule,
    ColorPickerModule,
    // SlideshowModule,
    NouisliderModule,
    ProgressSpinnerModule,
    ToastrModule.forRoot({
      toastComponent: ToastMessageComponent,
      positionClass: 'toast-bottom-left',
      maxOpened: 1,
      // preventDuplicates: true,
      autoDismiss: true,
      newestOnTop: true,
      extendedTimeOut: 1000,
      timeOut: 5000
    }),
    NgxSkeletonLoaderModule,
    // MentionModule,
    BarChartWithBrushModule,
    InfiniteScrollModule,
    SortPipeModule,
    DataBaseMetaDataModule,
    MatDialogRoutingModule,
    ProcessExplorerGroupingModule
  ],
  declarations: [
    AlgorithmicHappyPathComponent,
    BarChartThroughputComponent,
    BottleneckComponent,
    BubbleGraphComponent,
    CardCommonPathComponent,
    CardDeviationsComponent,
    CardRootCauseComponent,
    CardMetricComponent,
    CardHappyPathComponent,
    CardOtherFreqActivitiesComponent,
    CardWhitelistComponent,
    LineGraphComponent,
    NewDataBucketComponent,
    SnackBarComponent,
    SafePipe,
    DonutGraphComponent,
    PopupSearchComponent,
    ProcessExplorerChartComponent,
    ProcessExplorerGraphComponent,
    VariantsListComponent,
    ActivityDetailsComponent,
    RadialProgressChartComponent,
    ProcessExplorerFilterComponent,
    BarChartWithBrushComponent,
    BarChartComponent,
    DonutChartComponent,
    SnackbarComponent,
    ToastMessageComponent,
    PathActivitySliderComponent,
    DynamicBarChartComponent,
    ToastMessageComponent,
    ChartjsLineChartComponent,
    ChartjsDonutChartComponent,
    ChartjsBoxplotChartComponent,
    ChartjsBubbleChartComponent,
    ChartjsScaatterPlotChartComponent,
    NgxDatatableComponent,
    HoursFormatPipe,
    // BarChartWithVerticalBrushComponent,
    // DataBaseMetaDataComponent,
    ProcessExplorerCropChartComponent,
    ErrorComponent,
    BarChartWithBrushV1Component,
    HideActivitiesComponent,
    ConfirmDialogComponent
  ],
  exports: [
    CommonModule,
    MaterialModule,
    LayoutModule,
    PortalModule,
    DragDropModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    NgxErrorsModule,
    FormFieldsModule,
    AppConfigModule,
    NgxDatatableModule,
    MatTreeModule,
    AlgorithmicHappyPathComponent,
    BarChartThroughputComponent,
    BottleneckComponent,
    BubbleGraphComponent,
    CardCommonPathComponent,
    CardDeviationsComponent,
    CardRootCauseComponent,
    CardMetricComponent,
    CardHappyPathComponent,
    CardOtherFreqActivitiesComponent,
    CardWhitelistComponent,
    LineGraphComponent,
    NewDataBucketComponent,
    ProgressSpinnerModule,
    SnackBarComponent,
    SubHeaderCardModule,
    MatRippleModule,
    DonutGraphComponent,
    PopupSearchComponent,
    SafePipe,
    ProcessExplorerChartComponent,
    ProcessExplorerGraphComponent,
    VariantsListComponent,
    ActivityDetailsComponent,
    RadialProgressChartComponent,
    BarChartWithBrushComponent,
    BarChartComponent,
    DonutChartComponent,
    AgGridModule,
    SnackbarComponent,
    ToastrModule,
    PathActivitySliderComponent,
    DynamicBarChartComponent,
    ColorPickerModule,
    // SlideshowModule,
    NouisliderModule,
    ToastrModule,
    NgxSkeletonLoaderModule,
    // MentionModule,
    ChartjsLineChartComponent,
    ChartjsDonutChartComponent,
    ChartjsScaatterPlotChartComponent,
    ChartjsBubbleChartComponent,
    NgxDatatableComponent,
    HoursFormatPipe,
    SortPipeModule,
    // BarChartWithVerticalBrushComponent,
    BarChartWithBrushModule,
    // DataBaseMetaDataComponent,
    ProcessExplorerCropChartComponent,
    ErrorComponent,
    InfiniteScrollModule,
    BarChartWithBrushV1Component,
    HideActivitiesComponent,
    ConfirmDialogComponent
  ],
  providers: [
    NgxErrorsDirective,
    NgxErrorDirective,
    FormGroupDirective,
    { provide: APP_CONSTANTS, useValue: AppConstants },
    DatePipe
  ]
})
export class SharedModule {
  constructor() {
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Filler,
      Legend,
      Title,
      Tooltip
    );
  }
}
