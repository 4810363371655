import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
// import { User, ForgotPassword, ResetPassword } from './auth.model';
import { Router } from '@angular/router';
import { AppStore } from './../../state/app.store';
import { AuthStatCore } from './auth.model';

// export interface AuthState {
//   user: User | null;
//   uuid: string;
//   userPoolId: string;
//   clientId: string;
//   loading: boolean;
//   forgotPassword: ForgotPassword | null;
//   resetPassword: ResetPassword | null;
// }
export type AuthState = EntityState<AuthStatCore>;

export function createInitialState(): AuthState {
  let value = window.sessionStorage.getItem('unique-id');
  if (!value || !window.name) {
    value = '_' + Math.random().toString(36).substr(2, 9);
    window.sessionStorage.setItem('unique-id', value);
  }

  window.name = value;
  return {
    id: value,
    user: null,
    loading: false,
    uuid: null,
    userPoolId: '',
    clientId: '',
    domain: '',
    forgotPassword: {
      email: null,
      success: false
    },
    resetPassword: {
      success: false
    }
  };
}

// export function createInitialUserState(id): AuthState {
//   return {
//     user: null,
//     loading: false,
//     uuid: id,
//     userPoolId: '',
//     clientId: '',
//     forgotPassword: {
//       email: null,
//       success: false
//     },
//     resetPassword: {
//       success: false
//     }
//   };
// }

// export function createSession(user: User) {
//   return { ...user };
// }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'authSession', resettable: true, idKey: '_id' })
export class AuthStore extends EntityStore<AuthState, AuthStatCore> {
  constructor(
    private router: Router,
    private appStore: AppStore
  ) {
    super();
    this.isInProgress(false);
  }

  isInProgress(loading: boolean) {
    this.setLoading(loading);
  }

  // login(data: CognitoUserSession) {
  //   const user = createSession({ access: data.getAccessToken(), refresh: data.getRefreshToken(), id: data.getIdToken() });
  //   if (data.isValid()) {
  //     const idValue= window.sessionStorage.getItem('unique-id');
  //     if(!this.authQuery.hasEntity(idValue)) {

  //     } else {
  //       this.update(idValue, user);
  //     }
  //     this.setActive(idValue);
  //     if (this.appStore.getValue().redirectUrl && this.appStore.getValue().redirectUrl !== '/') {
  //       this.router.navigateByUrl(this.appStore.getValue().redirectUrl);
  //     } else {
  //       this.router.navigateByUrl('/features');
  //     }
  //   }
  // }

  // forgotPasswordUpdate(showSuccessPage: boolean, email?: string) {
  //   const idValue= window.sessionStorage.getItem('unique-id');
  //   if(!this.authQuery.hasEntity(idValue)) {

  //   } else {
  //     this.update(idValue, { forgotPassword: { email, success: showSuccessPage } } );
  //   }
  //   this.setActive(idValue);
  // }

  // resetPasswordUpdate(success: boolean) {
  //   const idValue= window.sessionStorage.getItem('unique-id');
  //   if(!this.authQuery.hasEntity(idValue)) {

  //   } else {
  //     this.update(idValue, { resetPassword: { success } } );
  //   }
  //   if (success) {
  //     this.router.navigateByUrl('/').then(() => {
  //       this.reset();
  //     });
  //   }
  // }

  // resetUser() {
  //   const uuid = this.getValue().uuid;
  //   const idValue= window.sessionStorage.getItem('unique-id');
  //   if(!this.authQuery.hasEntity(idValue)) {

  //   } else {
  //     this.update(idValue, createInitialUserState(uuid) );
  //   }
  //   this.setActive(idValue);
  // }

  // getclientId() {
  //   return this.getValue();
  // }

  // logout() {
  // const idValue= window.sessionStorage.getItem('unique-id');
  //   if(!this.authQuery.hasEntity(idValue)) {

  //   } else {
  //     this.update(idValue, createInitialState());
  //   }
  //   this.setActive(idValue);
  // }
}
