import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormField } from '@bli/state/app.model';
import { AppService } from '@bli/state/app.service';
import { Subscription } from 'rxjs';
import { FormFieldFosterService } from '../../services/form-field-foster-service.service';

@Component({
  selector: 'app-dynamic-foster',
  templateUrl: './dynamic-foster.component.html',
  styleUrls: ['./dynamic-foster.component.scss']
})
export class DynamicFosterComponent implements OnInit, OnDestroy {
  isLoading = false;
  subscription = new Subscription();
  dynamicData: FormField[];
  @Input() field: FormField;
  @Input() formGroup: UntypedFormGroup;
  @Input() control: UntypedFormControl;

  loaderSkeletonStyle =
    this.formFieldFosterService.formFieldloaderSkeletonStyle;

  constructor(
    private service: AppService,
    private formFieldFosterService: FormFieldFosterService
  ) {}

  ngOnInit(): void {
    // const { callback, callback_dependent_fields = [] } = this.field;
    // if((this.field && callback && callback_dependent_fields?.length)){
    //   this.dependentCallback(callback, callback_dependent_fields);
    // } else {
    //   this.callBack(callback)
    // }
    this.linkCallBacks();
    this._checkIfDisabled();
  }

  private linkCallBacks() {
    const beforeAPI = () => {
      this.isLoading = true;
    };
    const afterAPI = () => {
      this.isLoading = false;
    };
    const getCallBackAPISub = this.formFieldFosterService
      .getCallBackAPI(
        this.field,
        this.formGroup,
        beforeAPI.bind(this),
        afterAPI.bind(this)
      )
      .subscribe(data => {
        if (data) {
          const { type, html_control } = data;
          this.dynamicData = [
            { ...this.field, type: type ? type : html_control, enum: data.enum }
          ];
        }
      });
    this.subscription.add(getCallBackAPISub);
  }

  private _checkIfDisabled() {
    const { callback_dependent_fields = [] } = this.field;
    const sub = this.formFieldFosterService
      .isDisabled(this.formGroup, callback_dependent_fields)
      .subscribe(isDisabled => {
        if (isDisabled) {
          this._toggleControlDisable(true);
        } else {
          this._toggleControlDisable(false);
        }
      });
    this.subscription.add(sub);
  }

  private _toggleControlDisable(disable: boolean) {
    const { callback_dependent_fields = [] } = this.field;
    disable && callback_dependent_fields.length
      ? this.control.disable()
      : this.control.enable();
  }

  // private callBack(callback: string) {
  //   if(!callback)
  //   return;
  //   const payload = {
  //     dropdown_name: callback,
  //     selected_value: ''
  //   };
  //   const sub = this.getDropdownAttributes(payload).subscribe((res) => {
  //     const value = this.control?.value;
  //     if (value && res.data) {
  //       for( const item of res.data) {
  //         if (item.key === value) {
  //           this.control.setValue(value);
  //           break;
  //         }
  //       }
  //     }
  //   });

  //   this.subscription.add(sub);
  // }

  // private dependentCallback(callback: string, callbackDependentFields: string[]) {
  //   const dependedControls = this._getDependedFieldDetails(callbackDependentFields);
  //   const sub = combineLatest(dependedControls).pipe(
  //     map(controls => controls.reduce((acc, prev) => ({...acc, ...prev}), {})),
  //     filter(args => {
  //       const values = Object.values(args);
  //       for (const v of values) {
  //         if (!v) return false
  //       }
  //       return true;
  //     }),
  //     debounceTime(300),
  //     switchMap(args => this.getDropdownAttributes({
  //       dropdown_name: callback,
  //       selected_value: this.selectValueRequiredActionSctipts(callback),
  //       args
  //     })),
  //     tap((res) => {
  //       const [data] = res.data;
  //       if (data) {
  //         this.dynamicData = [{...this.field, ...data }];
  //       }
  //     })
  //   ).subscribe();

  //   this.subscription.add(sub);
  // }

  // private _getDependedFieldDetails(callbackDependentFields: string[]) {
  //   const dependedControls = callbackDependentFields.map(field => {
  //     const control = this.formFieldFosterService.findFormGroupField(this.formGroup, field);

  //     return control.valueChanges.pipe(
  //         startWith(control.value),
  //         map(value => ({ [field]: value }))
  //       );
  //   });
  //   return dependedControls;
  // }

  // private getDropdownAttributes(payload: { dropdown_name: string; selected_value: string; args?: Object }): Observable<any> {
  //   this.isLoading = true
  //   return this.service.getMultiSelectDatas(payload).pipe(
  //     tap(response => {
  //       this.field.enum = response.data;
  //       this.isLoading = false;
  //   }, () => {
  //     this.isLoading = false;
  //   })
  //   )
  // }

  // private selectValueRequiredActionSctipts(callback) {
  //   if (this.formFieldFosterService.selectValueRequiredActionSctipt.includes(callback)) {
  //     return this.actionScriptName;
  //   }
  //   return '';
  // }

  // private get actionScriptName() {
  //   return this.formFieldFosterService.findFormGroupField(this.formGroup, 'action_script_name')?.value
  // }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
