import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { AppStore, AppState } from './app.store';
import { BliNavModuleCustomPage } from './app.model';

@Injectable({ providedIn: 'root' })
export class AppQuery extends Query<AppState> {
  constructor(protected store: AppStore) {
    super(store);
  }

  readonly selectedNav$ = this.select(s => s.selectedNav);

  readonly fiscalYear$ = this.select(y => y.fiscalYear);

  readonly currencyCode$ = this.select(c => c.currency.code);

  readonly routeData$ = this.select(y => y.routeData);

  readonly modules$ = this.select(s => s.modules);

  readonly navList$ = this.select(s => s.navList);

  getModulePages$(moduleCode) {
    return this.select(s => s.modules).pipe(
      map(modules => modules.find(m => m.module_code === moduleCode)),
      map(module => module?.pages)
    );
  }

  getPage$(moduleCode, pageSlug) {
    return this.getModulePages$(moduleCode).pipe(
      map(pages => pages.find(p => p.page_hierarchy_slug === pageSlug))
    );
  }

  getSubPages$(moduleCode, pageSlug, subPageSlug) {
    return this.getPage$(moduleCode, pageSlug).pipe(
      map(page =>
        page.sub_pages.find(
          subPage => subPage.page_hierarchy_slug === subPageSlug
        )
      )
    );
  }

  getModulePages(moduleCode) {
    return this.store.getValue().modules.find(m => m.module_code === moduleCode)
      .pages;
  }

  getPage(moduleCode, pageSlug) {
    return this.getModulePages(moduleCode).find(
      p => p.page_hierarchy_slug === pageSlug
    );
  }

  getSubPages(moduleCode, pageSlug, subPageSlug) {
    if (!pageSlug || !subPageSlug) return;
    return this.getPage(moduleCode, pageSlug).sub_pages.find(
      subPage => subPage.page_hierarchy_slug === subPageSlug
    );
  }

  getFirstRevosCustomPage() {
    const pages = this.getModulePages('EIFC011RO100');
    return pages?.length ? pages[0] : null;
  }

  getScorecard(): BliNavModuleCustomPage[] | null {
    const pages = this.getModulePages('EIFC015RS100');
    return pages?.length ? pages : null;
  }

  getFirstSubpageOfPage(page) {
    return page?.sub_pages?.length ? page.sub_pages[0] : null;
  }

  get fiscalMonthMap() {
    return this.store.getValue().fiscalMonthMap;
  }

  get getCurrencyCode() {
    return this.store.getValue().currency.code;
  }

  get getBaseUrl() {
    return this.store.getValue().baseUrl;
  }

  get getRedirectUrl() {
    return this.store.getValue().redirectUrl;
  }

  get getDomainUrl() {
    return this.store.getValue().domainUrl;
  }

  get fiscalYear() {
    return this.store.getValue().fiscalYear;
  }

  set selectedNav(nav: any) {
    this.store.update({
      selectedNav: nav
    });
  }
}
