// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host app-form-fields-foster > * {
  width: 50%;
}
:host app-form-fields-foster > * ::ng-deep .mat-mdc-form-field {
  width: calc(100% - 5px);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modules/form-fields/components/dynamic-foster/dynamic-foster.component.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;AACI;EACE,uBAAA;AACN","sourcesContent":[":host {\n  app-form-fields-foster > * {\n    width: 50%;\n    ::ng-deep  .mat-mdc-form-field {\n      width: calc(100% - 5px);\n    }\n\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
