import { Pagination, Response } from '@bli/state/app.model';

export interface DataCollection {
  bucketId: string;
  bucketName: string;
  files: any[];
  owner: string;
  updated_at: string;
}

export interface BucketPagination extends Pagination {
  items: Bucket[];
}

export interface GetBucketResponse {
  SUCCESS: boolean;
  code: number;
  data: BucketPagination[];
  message: string;
  status: string;
}

export interface PainatedSharedBucket extends Pagination {
  items: SharedBucket[];
}

export interface GetSharedBucketResponse extends Response {
  data: PainatedSharedBucket[];
}

export interface GetUserResponse {
  SUCCESS: boolean;
  code: number;
  data: User[];
  message: string;
  status: string;
}

export interface CreateBucketRequest {
  name: string;
}

export interface CreateBucketResponse extends Response {
  data: [{ bucket_slug: string }];
}

export type DataBucketAccessModes = 'OWNER' | 'READ' | 'EDIT' | 'USE';
export interface Bucket {
  bucket_id: number;
  bucket_slug: string;
  name: string;
  description: string;
  owner: string;
  access_mode: DataBucketAccessModes;
  user_id: string;
  is_active: boolean;
  created_date: number;
  updated_date: string;
}

export interface SharedBucket {
  access_mode: string;
  email: DataBucketAccessModes;
  is_editable: string;
}

export interface User {
  email_id: string;
  is_active: boolean;
  role_id: string;
  role_name: string;
  user_id: string;
}
