import {
  Component,
  OnChanges,
  ViewEncapsulation,
  Input,
  ElementRef
} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-radial-progress-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './radial-progress-chart.component.html',
  styleUrls: ['./radial-progress-chart.component.scss']
})
export class RadialProgressChartComponent implements OnChanges {
  @Input() percentage: any = 0;
  @Input() padding: any = 5;
  @Input() radius: any = 100;
  @Input() bgColor = '#f1fffd';
  @Input() progressColor = '#00c4aa';
  @Input() progressWidth: any = 15;
  @Input() progressCornerRadius: any = 15;
  @Input() insideText: string;

  constructor(private container: ElementRef) {}

  ngOnChanges(changes) {
    // if (!changes.percentage) {
    //   this.percentage = 0;
    // }
    this.initChart();
  }

  initChart() {
    const container = d3.select(this.container.nativeElement);

    container.select('.radial-chart').html('');
    const element = container.select('.radial-chart');

    // tslint:disable-next-line: radix
    const radius = parseInt(this.radius);
    // tslint:disable-next-line: radix
    const border = parseInt(this.progressWidth);
    // tslint:disable-next-line: radix
    const padding = parseInt(this.padding);
    // tslint:disable-next-line: radix
    const cornerRadius = parseInt(this.progressCornerRadius);
    const startPercent = 0;
    // tslint:disable-next-line: radix
    const endPercent = parseFloat(this.percentage) / 100;

    const twoPi = Math.PI * 2;
    const boxSize = (radius + padding) * 2;

    let count = Math.abs((endPercent - startPercent) / 0.01);
    const step = endPercent < startPercent ? -0.01 : 0.01;

    const arc = d3
      .arc()
      .startAngle(0)
      .innerRadius(radius)
      .outerRadius(radius - border)
      .cornerRadius(cornerRadius);

    const parent = element;

    const svg = parent
      .append('svg')
      .attr('width', boxSize)
      .attr('height', boxSize);

    const field = svg
      .append('g')
      .attr('transform', 'translate(' + boxSize / 2 + ',' + boxSize / 2 + ')');

    const meter = field.append('g').attr('class', 'progress-meter');

    meter
      .append('circle')
      .attr('class', 'radial-background')
      .attr('fill', this.bgColor)
      .attr('fill-opacity', 1)
      .attr('r', radius);

    const front = meter
      .append('path')
      .attr('class', 'radial-foreground')
      .attr('fill', this.progressColor)
      .attr('fill-opacity', 1);

    const numberText = meter
      .append('text')
      .attr('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('dy', this.insideText ? '-1em' : '.278em')
      .attr('class', 'radial-text')
      .text(this.percentage + '%');
    if (this.insideText) {
      const text = meter
        .append('text')
        .attr('fill', '#000')
        .attr('text-anchor', 'middle')
        .attr('dy', '1em')
        .attr('class', 'radial-text')
        .text(this.insideText);
    }
    let progress = startPercent;

    (function loops() {
      updateProgress(progress);
      if (count > 0) {
        count--;
        progress += step;
        setTimeout(loops, 10);
      }
    })();

    function updateProgress(prog: any) {
      front.attr('d', arc.endAngle(twoPi * prog));
    }
  }
}
