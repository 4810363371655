import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-ngx-datatable',
  templateUrl: './ngx-datatable.component.html',
  styleUrls: ['./ngx-datatable.component.scss']
})
export class NgxDatatableComponent {
  ColumnMode = ColumnMode;
  @Input() rowData = [];
  @Input() columnDefs = [];
  @Input() isLoading = true;
  @Input() page: any = {};
  @Output() pageChanged = new EventEmitter();

  setPage($event) {
    const page = {
      itemsPerPage: $event.pageSize,
      pageNumber: $event.offset,
      total: $event.count
    };
    this.pageChanged.emit(page);
  }
}
