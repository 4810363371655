// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.donut-chart-wrapper .donut-chart {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.donut-chart-wrapper .percentage {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  fill: #A2A2A2;
  text-anchor: middle;
  transition: 0.3s ease;
  opacity: 0;
}
.donut-chart-wrapper .percentage.show {
  transition: 0.3s ease;
  opacity: 1;
}
.donut-chart-wrapper .donuts path {
  cursor: pointer;
  stroke: #fff;
  stroke-width: 2px;
}
.donut-chart-wrapper .chart-legend text {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  fill: rgba(0, 0, 0, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/donut-chart/donut-chart.component.scss"],"names":[],"mappings":"AACE;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;AAAF;AAEC;EACC,+BAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,UAAA;AAAF;AACE;EACC,qBAAA;EACA,UAAA;AACH;AAEC;EACC,eAAA;EACA,YAAA;EACA,iBAAA;AAAF;AAEC;EACC,+BAAA;EACA,eAAA;EACA,wBAAA;AAAF","sourcesContent":[".donut-chart-wrapper {\n  .donut-chart {\n\t\theight: 100%;\n\t\twidth: 100%;\n\t\tposition: absolute;\n\t\tleft: 0;\n\t\tright: 0;\n\t\ttop: 0;\n\t\tbottom: 0;\n\t}\n\t.percentage {\n\t\tfont-family: Roboto, sans-serif;\n\t\tfont-size: 15px;\n\t\tfill: #A2A2A2;\n\t\ttext-anchor: middle;\n\t\ttransition: 0.3s ease;\n\t\topacity: 0;\n\t\t&.show {\n\t\t\ttransition: 0.3s ease;\n\t\t\topacity: 1;\n\t\t}\t\n\t}\n\t.donuts path {\n\t\tcursor: pointer;\n\t\tstroke: #fff;\n\t\tstroke-width: 2px;\n\t}\n\t.chart-legend text {\n\t\tfont-family: Roboto, sans-serif;\n\t\tfont-size: 12px;\n\t\tfill: rgba(0, 0, 0, 0.6);\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
