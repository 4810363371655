import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sub-header-card',
  templateUrl: './sub-header-card.component.html',
  styleUrls: ['./sub-header-card.component.scss']
})
export class SubHeaderCardComponent {
  @Input() showInCard: boolean;
}
