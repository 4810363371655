import { Directive, ElementRef } from '@angular/core';
import * as d3 from 'd3';
import { ModifiedVariant } from '../../model/variant.model';

@Directive()
export abstract class VerticalBarChartDirective {
  private container: any;
  private margin = { top: 30, bottom: 30, right: 30, left: 30 };
  private x: any;
  protected y: any;
  private xAxis: any;
  private yAxis: any;
  private yDomain: any;
  private chartWrapper: any;
  private chart: any;
  private transition = 100;
  protected height: number;
  protected width: number;
  protected svg: any;

  abstract startEndId: [number, number];
  abstract brush: any;
  abstract createVerticalBrush();
  abstract setBrushPosition(startEndId, y, brush, dragEnd);
  abstract createTooltip(container: ElementRef);

  public createSvgContainer(container: ElementRef, svgElementId: string) {
    this.container = d3.select(container.nativeElement);
    this.width =
      parseInt(this.container.style('width'), 0) -
      this.margin.left -
      this.margin.right;
    this.height =
      parseInt(this.container.style('height'), 0) -
      this.margin.top -
      this.margin.bottom;
    const maxYLabel = '#Others'.length;
    this.svg = this.container
      .select(`#${svgElementId}`)
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${maxYLabel * 6}, ${this.margin.top})`);

    this.createXAxis();
    this.createYAxis();
    this.createTooltip(container);
    this.createVerticalBrush();
  }

  private createXAxis() {
    this.x = d3.scaleLinear().range([0, this.width]).nice();
    this.xAxis = this.svg
      .append('g')
      .attr('transform', `translate(0, ${this.height})`)
      .call(d3.axisBottom(this.x));
  }

  private createYAxis() {
    this.y = d3.scaleBand().range([0, this.height]).padding(0.5);
    this.yAxis = this.svg.append('g').call(d3.axisLeft(this.y));
    this.chartWrapper = this.svg.append('g').attr('class', 'chart');
  }

  public updateData(data: ModifiedVariant[], limit) {
    this.x.domain([0, d3.max(data, d => d.percent_cases_covered)]);
    this.xAxis
      .transition()
      .duration(this.transition)
      .call(d3.axisBottom(this.x).ticks(5));
    this.yDomain = this.y.domain(data.map(d => d.id));
    this.yAxis
      .transition()
      .duration(this.transition)
      .call(
        d3.axisLeft(this.y).tickValues(
          this.yDomain.domain().filter((t, i) => {
            if (t === 'Others' || t === 1) {
              return t;
            }
            if (data.length <= 21) {
              return true;
            }
            // if (data.length <= 101) { return !((i + 1) % 5); }
            // if (data.length > 101) { return !((i + 1) % 10); }
            // console.log(limit, data.length);
            // return limit === 0 ? true : !((i + 1) % ((data.length - 1) / limit));
            return limit === 0
              ? true
              : !(
                  (i + 1) %
                  (data.length <= 101
                    ? 5
                    : data.length <= 501
                      ? 10
                      : (data.length - 1) / limit)
                );
          })
        )
      );

    this.chart = this.chartWrapper.selectAll('rect.bar').data(data);
    this.chart
      .join(
        enter =>
          enter
            .append('rect')
            .attr('x', () => 0)
            .attr('y', d => this.y(d.id) + this.y.bandwidth())
            .attr('width', d => this.x(d.percent_cases_covered))
            .attr('height', this.y.bandwidth())
            .attr('class', 'bar')
            .attr('fill', '#00c1d4')
            .style('opacity', '.4')
            .call(enterEle =>
              enterEle
                .transition(0)
                .duration(this.transition)
                .attr('y', d => this.y(d.id))
            ),
        update =>
          update
            .attr('fill', '#00c1d4')
            .attr('class', 'bar')
            .attr('x', () => 0)
            .call(updateEle =>
              updateEle
                .transition(0)
                .duration(this.transition)
                .attr('y', d => this.y(d.id))
                .attr('x', () => 0)
                .attr('height', this.y.bandwidth())
                .attr('width', d => this.x(d.percent_cases_covered))
            ),
        exit =>
          exit.call(exitEle =>
            exitEle
              .transition(0)
              .duration(this.transition)
              .attr('y', () => this.height)
              .remove()
          )
      )
      .call(() => {
        this.y.invert = (() => {
          const domain = this.yDomain.domain();
          const range = this.y.range();
          if (!domain.length) {
            return y => null;
          }
          const scale = d3.scaleQuantize().domain(range).range(domain);
          return y => {
            return scale(y);
          };
        })();
        setTimeout(
          () =>
            this.setBrushPosition(this.startEndId, this.y, this.brush, false),
          this.transition + 50
        );
      });
  }
}
