export interface ScheduleDetails {
  created_date: string;
  day: string | null;
  day_of_week: string | null;
  frequency: string;
  interval: string;
  is_active: string;
  job_id: string;
  name: string;
  next_run_time: string;
  start_datetime: string;
  updated_date: string;
}

export interface RevenueOperationDataModel {
  created_date: string;
  data_model_id: number;
  description: string;
  is_active: string;
  job_id: string;
  name: string;
  number_of_business_rules: number;
  owner: string;
  process_analysis_id: number;
  process_analytics_cluster_id: number;
  project_id: number;
  schedule_details: ScheduleDetails[];
  unique_id: string;
  updated_date: string;
  user_id: string;
  module: string;
  module_display: string;
}

export type RevenueOperationDataModelResponse = {
  data: RevenueOperationDataModel[];
};
export type DefaultColumnMapping = '$case_id' | '$timestamp' | '$activity';

export interface RevenueOperationsDataModelColumns {
  alias: string;
  column_datatype: string;
  column_name: string;
  mapping: DefaultColumnMapping | string;
}

export interface RevenueOperationDataModelDeails {
  column_details: RevenueOperationsDataModelColumns[];
  data_model_id: number;
  data_model_name: string;
}

export type RevenueOperationDataModelDeailsResponse = {
  data: RevenueOperationDataModelDeails[];
};
export interface RevenueOperation {
  id: any;
}

export function createRevenueOperation(params: Partial<RevenueOperation>) {
  return {} as RevenueOperation;
}

export interface SelectedColumn {
  value: string;
  checked: boolean;
}

export interface CasesAndVariants {
  cases: string;
  variants: string;
}
export interface RevOpsDashboardFilter {
  column_name: string;
  column_data_type: string;
  invert: boolean;
  selected: SelectedColumn[];
  cases_variants?: CasesAndVariants | null;
  cases_starts_with?: Activities | null;
  cases_ends_with?: Activities | null;
  start_date: string;
  end_date: string;
}
export interface Activities {
  activities: string[];
  invert?: boolean;
}
export interface RevOpsDashboardRange {
  range: any;
}

export interface LevelInfo {
  group_id?: string;
  name: string;
  level: string;
}

export interface ProcessMapLevels {
  groups: LevelInfo;
  activities: LevelInfo;
}

export type ComponentType = 'kpi' | 'chart';

export interface UdpateComponentPriority {
  ro_component_slug: string;
  priority: number;
}

export interface UdpateComponentPriorityPayload {
  ro_components: UdpateComponentPriority[];
}
