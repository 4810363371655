import { Injectable } from '@angular/core';
import { MatDialogRouter } from '@bli-shared/components/mat-dialog-routing/mat-dialog-routing-model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatDialogRouterService {
  matDialogRouter = new BehaviorSubject<MatDialogRouter[]>([]);
  matDialogRouter$ = this.matDialogRouter.asObservable();
  navigateTo = new BehaviorSubject<string>('');
  navigate$ = this.navigateTo.asObservable();

  setMatRouter(routerList: MatDialogRouter[]) {
    this.matDialogRouter.next(routerList);
  }

  navigate(url: string) {
    this.navigateTo.next(url);
  }

  reset() {
    this.matDialogRouter.next([]);
    this.navigateTo.next('');
  }

  get routerList() {
    return this.matDialogRouter.value;
  }

  get url() {
    return this.navigateTo.value;
  }
}
