import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { Enum, FormField } from '@bli/state/app.model';

@Component({
  selector: 'app-checkbox-group-foster',
  templateUrl: './checkbox-group-foster.component.html',
  styleUrls: ['./checkbox-group-foster.component.scss']
})
export class CheckboxGroupFosterComponent implements OnInit {
  enum: (Enum & { checked: boolean })[];
  @Input() field: FormField;
  @Input() formGroup: UntypedFormGroup;
  @Input() control: UntypedFormControl;
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    // this.addControls();
    this.enum = this.field.enum?.map(e => ({ ...e, checked: false }));
    const { value } = this.control;
    if (value) {
      this.enum.forEach(item => {
        if (value.includes(item.key)) {
          item.checked = true;
        }
      });
    }
    if (this.control.value === undefined || this.control.value === null) {
      this.control.setValue('');
    }
  }

  toggle(item: Enum & { checked: boolean }) {
    const allCheckboxItems = (this.control.value as string)
      .split(',')
      .filter(v => v);
    if (item.checked && !allCheckboxItems.includes(item.key)) {
      allCheckboxItems.push(item.key);
      this.control.setValue(`${allCheckboxItems.join(',')}`);
      return;
    }
    const index = allCheckboxItems.indexOf(item.key);
    if (!item.checked && index > -1) {
      allCheckboxItems.splice(index, 1);
      this.control.setValue(`${allCheckboxItems.join(',')}`);
      return;
    }
  }

  // addControls() {
  //   this.field?.enum.map(item => this.formGroup.addControl(item.key, this.fb.control('')))
  // }
}
