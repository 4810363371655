import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { MetricParams } from './metric-card-model';

@Component({
  selector: 'app-card-metric',
  templateUrl: './card-metric.component.html',
  styleUrls: ['./card-metric.component.scss']
})
export class CardMetricComponent implements OnInit {
  hover: boolean;
  @Input() metricsParams: MetricParams;
  @Output() activeMetric = new EventEmitter();
  @HostListener('click') onClick() {
    if (this.metricsParams.metricType === 'activated-card') {
      this.activeMetric.emit(this.metricsParams);
    }
  }
  @HostListener('mouseover') onMouseOver() {
    this.hover = true;
  }
  @HostListener('mouseout') onmouseOut() {
    this.hover = false;
  }

  ngOnInit() {
    this.metricsParams.metricType =
      this.metricsParams.metricType || 'simple-card';
  }
}
