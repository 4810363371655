import {
  Component,
  ElementRef,
  OnInit,
  OnChanges,
  OnDestroy,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import * as d3 from 'd3';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'app-donut-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() DonutData: Array<any> = [];

  @Output() donutSelected = new EventEmitter<string>();
  @Output() donutUnSelected = new EventEmitter<string>();

  constructor(private container: ElementRef) {}

  ngOnInit() {
    this.initDonutChart();
  }

  ngOnChanges(changes) {
    // this.initDonutChart();
  }

  ngOnDestroy() {
    d3.select(window).on('resize.donut-chart-wrapper', null);
  }

  initDonutChart() {
    d3.select(window).on('resize.donut-chart-wrapper', null);
    const container = d3.select(this.container.nativeElement);
    const angularThis = this;

    const data: any = this.DonutData;
    const totalCount = data.reduce((accumulator, obj) => {
      return accumulator + obj.count;
    }, 0);

    const margin = {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20
    };

    const donutSize = 20;
    const width =
      parseInt(container.select('.donut-chart').style('width'), 0) -
      margin.left -
      margin.right;
    const height =
      parseInt(container.select('.donut-chart').style('height'), 0) -
      margin.top -
      margin.bottom;
    const radius = Math.min(width, height) / 2 - donutSize / 2;
    const svg = container.select('svg.donut-chart');
    const svgG = svg
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    const colors = data.map((d, i) => {
      return `rgba(79,79,189,${(i + 1) / data.length})`;
    });

    const pie = d3
      .pie()
      .sort(null)
      .value((d: any) => {
        return d.count;
      });

    const arc: any = d3
      .arc()
      .outerRadius(radius)
      .innerRadius(radius - donutSize);

    const arcHover: any = d3
      .arc()
      .outerRadius(radius + 5)
      .innerRadius(radius - donutSize);

    const donuts = svgG
      .selectAll('.donuts')
      .data(pie(data))
      .enter()
      .append('g')
      .attr('class', 'donuts')
      .on('mouseover', donutMouseOver)
      .on('mouseout', donutMouseOut)
      .on('click', donutClick);

    donuts
      .append('path')
      .attr('d', arc)
      .attr('fill', (d: any) => {
        return colors[d.index];
      });

    const percentage = svgG
      .append('text')
      .attr('class', 'percentage')
      .attr('y', radius * 0.07);

    /* Legent related functions */
    const nodeWidth = d => d.getBBox().width;

    const legend = svg.append('g').attr('class', 'chart-legend');

    const lgLabel = legend.selectAll('g').data(data).enter().append('g');

    lgLabel
      .append('circle')
      .attr('r', 4)
      .attr('cy', 6)
      .style('fill', (d, i) => colors[i]);

    lgLabel
      .append('text')
      .attr('x', 8)
      .attr('y', 10)
      .text((d: any) => d.label);

    let offset = 0;
    lgLabel.attr('transform', function (d, i) {
      const x = offset;
      offset += nodeWidth(this) + 10;
      return `translate(${x}, 0)`;
    });
    legend.attr('transform', function () {
      return `translate(${(width - nodeWidth(this)) / 2}, ${height + 15})`;
    });

    function addArc(donut) {
      donut
        .select('path')
        .transition()
        .duration(500)
        .ease(d3.easeBounce)
        .attr('d', arc);
    }

    function addArcHover(donut) {
      donut.select('path').transition().attr('d', arcHover);
    }

    function donutMouseOver() {
      const selectedData: any = d3.select(this).data()[0];
      const percent: any = (selectedData.value / totalCount) * 100;
      percentage
        .text(`${percent ? parseFloat(percent).toFixed(2) : 0}%`)
        .classed('show', true);
      addArcHover(d3.select(this));
    }

    function donutMouseOut() {
      percentage.classed('show', false);
      if (!d3.select(this).classed('selected')) {
        addArc(d3.select(this));
      }
    }

    let prevSelected = null;
    function donutClick() {
      const slectedDonutData: any = d3.select(this).data()[0];
      if (d3.select(this).classed('selected')) {
        // Remove filter logic
        d3.select(this).classed('selected', false);
        addArc(d3.select(this));
        angularThis.donutUnSelected.next(slectedDonutData.data);
        return;
      }
      if (prevSelected) {
        prevSelected.classed('selected', false);
        addArc(prevSelected);
      }
      // Add filter logic
      addArcHover(d3.select(this));
      d3.select(this).classed('selected', true);
      prevSelected = d3.select(this);
      angularThis.donutSelected.next(slectedDonutData.data);
    }

    // Define responsive behavior
    function resizeDonut() {
      const nWidth =
        parseInt(container.select('.donut-chart').style('width'), 0) -
        margin.left -
        margin.right;
      const nHeight =
        parseInt(container.select('.donut-chart').style('height'), 0) -
        margin.top -
        margin.bottom;

      if (!nWidth || !nHeight) {
        return;
      }

      const nRadius = Math.min(nWidth, nHeight) / 2 - donutSize / 2;
      svgG.attr('transform', `translate(${nWidth / 2},${nHeight / 2})`);

      arc.outerRadius(nRadius).innerRadius(nRadius - donutSize);

      arcHover.outerRadius(nRadius + 5).innerRadius(nRadius - donutSize);

      percentage.attr('y', nRadius * 0.07);

      svg.selectAll('path').attr('d', arc);

      /* Legends update */
      legend.attr('transform', function () {
        return `translate(${(nWidth - nodeWidth(this)) / 2}, ${nHeight + 15})`;
      });
    }
    // d3.select(window).on('resize.donut-chart-wrapper', resizeDonut);

    // When resize happens with window resize. (Ex. When clicks collapse and exapnd navigation)
    // Debounce Method - Brush will reset when window resize stoped.
    let resizeTimer;
    const observer = new ResizeObserver(entries => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        resizeDonut();
      }, 100);
      entries.forEach(entry => {});
    });
    const wrapper: any = container.select('.donut-chart-wrapper').node();
    if (wrapper) {
      observer.observe(wrapper);
    }
  }
}
