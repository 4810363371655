import { ComponentPortal } from '@angular/cdk/portal';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PEGroupNavigation } from '../../pe-group-navigation';
import { ProcessExplorerGroupService } from '../../services/process-explorer-group.service';

@Component({
  selector: 'app-process-explorer-group-dashboard',
  templateUrl: './process-explorer-group-dashboard.component.html',
  styleUrls: ['./process-explorer-group-dashboard.component.scss']
})
export class ProcessExplorerGroupDashboardComponent implements OnInit {
  selectedComponent$: Observable<any>;
  selectedItem: any;
  selectedComponent: ComponentPortal<any>;
  history: any[] = [];
  addLink = PEGroupNavigation[0].children[0];
  constructor(public service: ProcessExplorerGroupService) {}

  ngOnInit(): void {
    this.service.groupNavigate = PEGroupNavigation[0];
    this.selectedComponent$ = this.service.groupNavigate$.pipe(
      tap(data =>
        data.path ? this.history.push(this.selectedItem) : (this.history = [])
      ),
      tap(data => setTimeout(() => this.createComponent(data)))
    );
  }

  createComponent(componentDetails: any) {
    this.selectedItem = componentDetails;
    this.selectedComponent = new ComponentPortal(this.selectedItem.component);
  }

  back() {
    const last = this.history.pop();
    this.createComponent(last);
  }
}
