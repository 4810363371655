import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Environment } from './environments/environment';

import { enableAkitaProdMode, persistState } from '@datorama/akita';

enableAkitaProdMode();
persistState();

export const storage = persistState();

if (Environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    if (window[`ngRef`]) {
      window[`ngRef`].destroy();
    }
    window[`ngRef`] = ref;

    // Otherwise, log the boot error
  })
  .catch(err => console.error(err));
