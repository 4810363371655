import { NgModule, InjectionToken } from '@angular/core';
import { Environment } from 'src/environments/environment';

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
  NAV_PATH: {
    ROOT: string;
    LIST: string;
    REFRESH_LIST: string;
  };
  ANAYSIS_API: {
    ROOT: string;
    CLUSTER: {
      CREATE_CLUSTER: string;
      UPDATE_CLUSTER: string;
      DELETE_CLUSTER: string;
      READ_CLUSTER: string;
      USER_PERMISSION: {
        LIST: string;
        SEARCH: string;
        UPDATE: string;
      };
    };
    MINING_MODEL: {
      LIST: string;
      LINK: string;
    };
    ANALYSIS: {
      CREATE_CLUSTER: string;
      UPDATE_CLUSTER: string;
      DELETE_CLUSTER: string;
      READ_CLUSTER: string;
    };
    PROCESS_OVERVIEW: {
      ACTIVITY: string;
      PROFILE: string;
      METRICS: string;
      HAPPYACTIVITIES: string;
      UNHAPPYACTIVITIES: string;
      THROUGHPUT: string;
      BOTTLENECKS: string;
    };
    PROCESS_EXPLORER: {
      LAYOUT: string;
    };
    PROCESS_CONFORMANCE: {
      CONFORMANCE_STATS_HISTORY: string;
      VIOLATION_STATS: string;
    };
    FORMULAE: {
      METADATA_READ: string;
    };
  };
  INTELLECT_ENGINE: {
    ROOT: string;
    SCHEDULER: {
      READ: string;
      CREATE: string;
      UDATE: string;
      REMOVE: string;
    };
    PROJECT: {
      SEARCH_PROJECT: string;
      CREATE_PROJECT: string;
      UPDATE_PROJECT: string;
      DELETE_PROJECT: string;
    };
    DATA_MODEL: {
      LIST: string;
      LIST_COLUMNS: string;
      BE_RESOURCE_DATAMODEL_ISUSED: string;
    };
    BUSINESS_RULES: {
      SEARCH_BUSINESS_RULE: string;
      CREATE_BUSINESS_RULE: string;
      UPDATE_BUSINESS_RULE: string;
      LINK_DATA_MODEL: string;
      DELETE_BUSINESS_RULE: string;
      SELECT_BUSINESS_RULE_TREE: string;
      TOGGLE_BUSINESS_RULE: string;
      SIGNAL_RULES: {
        APPLY_COLUMN_FILTER: string;
        SEARCH_SIGNAL_RULE: string;
        CREATE_SIGNAL_RULE: string;
        UPDATE_SIGNAL_RULE: string;
        DELETE_SIGNAL_RULE: string;
        ATTACH_BUSINESS_RULE: string;
        CREATE_CRITERIA: string;
        UPDATE_CRITERIA: string;
        SEARCH_CRITERIA: string;
        DELETE_CRITERIA: string;
        DROPDOWN_ATTRS: string;
        DROPDOWN_DETAILS: string;
      };
      ROUTING_RULES: {
        SEARCH_ROUTING_RULE: string;
        CREATE_ROUTING_RULE: string;
        UPDATE_ROUTING_RULE: string;
        DELETE_ROUTING_RULE: string;
        ATTACH_BUSINESS_RULE: string;
      };
      ACTIONS: {
        SEARCH_AVAILABLE_ACTIONS: string;
        CREATE_ACITION: string;
        ATTACH_ACTION: string;
        SEARCH_ACTION: string;
        UPDATE_ACTION: string;
        DELETE_ACTION: string;
      };
    };
    DROPDOWN: {
      DROPDOWN_ATTRS: string;
      DROPDOWN_DETAILS: string;
    };
    ENTITY_FUNCTION: {
      SEARCH: string;
    };
    SCHEDULE: {
      SHOW_PROJECT: string;
      ADD_SCHEDULE: string;
      UPDATE_SCHEDULE: string;
      REMOVE_SCHEDULE: string;
    };
  };
  USER_TENANT_MANAGEMENT: {
    ROOT: string;
    EMAIL_AUTOCOMPLETE: string;
    VALIDATE_WORKSPACE: string;
    TENANT_WORKSPACE: string;
    FORGOT_PASSWORD: string;
    SET_PASSWORD: string;
    NAV_PATH: string;
  };
  CONFORMANCE_CHECK_API: {
    CONFORMANCE_OVERVIEW: string;
    CONFORMANCE: string;
    WHITELIST: string;
    IS_EXISTS: string;
    ACTIVITY_LIST: string;
    CREATE_PROCESS_MODEL: string;
    READ_PROCESS_MODEL: string;
    UPDATE_PROCESS_MODEL: string;
    DELETE_PROCESS_MODEL: string;
    LIST_VIOLATIONS: string;
    LIST_WHITELIST: string;
    WHITELIST_VIOLATIONS: string;
    BLACKLIST_VIOLATIONS: string;
  };
  CASE_EXPLORER: string;
  REVENEW_OPERATION_API: {
    ROOT: string;
    LIST_DATABASE_METADATA_FOR_FORMULAE: string;
    LIST_DATABASE_METADATA_SCHEMA: string;
    LIST_DATABASE_METADATA_TABLES: string;
    FORMULA_TEST: string;
    FORMULA_UPSERT: string;
    LIST_DATABASE_METADATA_FOR_FORMULAE_OLD: string;
    LIST_DATABASE_METADATA_SCHEMA_OLD: string;
    LIST_DATABASE_METADATA_TABLES_OLD: string;
    FORMULA_TEST_OLD: string;
    FORMULA_UPSERT_OLD: string;
    REVENEW_KPI: {
      READ: string;
      CREATE: string;
      ATTACH: string;
      DETTACH: string;
      UPDATE: string;
      DELETE: string;
    };
  };
  DATA_COLLECTION_API: {
    ROOT: string;
    DATA_BUCKET: {
      DATA_MODEL_WISE_DATA_SOURCE_RELATIONSHIP: string;
      DATA_MODEL_WISE_DATA_SOURCE: string;
      DATA_MODEL_WISE_DATA_SOURCE_1: string;
      CREATE_BUCKET: string;
      UPDATE_BUCKET: string;
      DELETE_BUCKET: string;
      BUCKET: string;
      DATA_MODEL: {
        CREATE: string;
        READ: string;
        UPDATE: string;
        DELETE: string;
        LOGS: string;
        MANAGE: string;
        APPLY: string;
        UPDATE_CONFIG: string;
      };
      DATA_MODEL_MAPPING: {
        LOGS: string;
        LINKED_DATA_SOURCE: string;
        ATTACH_OR_DETACH: string;
        MAPPED_DATA_SOURCES: string;
        DATA_SOURCE_MAPPING: string;
        DATA_SOURCE_COLUMNS: string;
      };
      DATA_MODEL_TBL_LIST: string;
      DATA_SOURCE: string;
      DATA_SOURCE_LOGS: string;
      DELETE_SOURCE: string;
      DATA_SOURCE_UPLOAD: string;
      DATA_SOURCE_UPLOAD_INITIATE: string;
      DATA_SOURCE_UPLOAD_CHUNK: string;
      DATA_SOURCE_UPLOAD_COMPLETE: string;
      DATA_SOURCE_UPDATE: string;
      PREVIEW_SOURCE_UPLOAD: string;
      DATA_SOURCE_FREEZE: string;
      DS_CONNECTOR: {
        READ: string;
        TEST: string;
        CREATE: string;
        UPDATE: string;
        DELETE: string;
        MARK_AS_DEFAULT: string;
      };
      DS_CONNECTOR_AVAILABLE: string;
      SHARED_USERS: string;
      SHARED: string;
      UPDATE_SHARED: string;
      SHARE_TO_USERS: string;
    };
    DATA_JOB: {
      DATA_CONNECTORS: string;
      DATA_JOB: string;
      CREATE_DATA_JOBS: string;
      UPDATE_DATA_JOB: string;
      READ_DATA_JOBS: string;
      DELETE_DATA_JOB: string;
      DATA_JOB_DO: string;
      EXTRACTION: {
        EXTRACTION: string;
        EXTRACTION_CREATE: string;
        EXTRACTION_READ: string;
        EXTRACTION_UPDATE: string;
        EXTRACTION_DELETE: string;
        EXTRACTION_TOGGLE: string;
        TABLE_LIST: string;
        EXTRACTION_TASK: string;
        EXTRACTION_TASK_LIST: string;
        EXTRACTION_TEMPLATES: string;
        EXTRACTION_REARRANGE: string;
        EXTRACTION_TASK_PARAMS: string;
        EXTRACTION_TASK_PARAMS_LIST: string;
        EXTRACTION_LOG: string;
        EXTRACTION_TASK_SQL: string;
        SOURCE_LIST: string;
        READ_ATTACHED_SOURCE: string;
        ATTACH_SOURCE: string;
        TOGGLE_STATUS: string;
        DELETE_SOURCE: string;
        UPDATE_SOURCE: string;
        UPSERT_SOURCE: string;
      };
      TRANSFORMATION: {
        TRANSFORMATION: string;
        TRANSFORMATION_CREATE: string;
        TRANSFORMATION_READ: string;
        TRANSFORMATION_UPDATE: string;
        TRANSFORMATION_LIST: string;
        TRANSFORMATION_PARAMS_LIST: string;
        TRANSFORMATION_TEMPLATE_LIST: string;
        TRANSFORMATION_TABLE_LIST: string;
        TRANSFORMATION_SQL: string;
        TRANSFORMATION_MAPPERS_UPSERT: string;
      };
      DATA_MODEL_LOAD: {
        ASSIGN_MODEL: string;
        DATA_MODEL_LIST: string;
      };
    };
    DATA_PUSH: string;
    DATA_MODEL_PROCESSING: string;
    DATA_MODEL_FIELD: string;
    DATA_MODEL_MAP: string;
    FILES: string;
    API_KEY: string;
    ETL: {
      QUERY: {
        QUERY_METADATA_READ: string;
        QUERY_METADATA_SCHEMA: string;
        QUERY_METADATA_TABLES: string;
        PREVIEW: string;
      };
    };
  };
  USER_MANAGEMENT: {
    ROOT: string;
    NOT_ALL_MEMBER: string;
    READ_MEMBER: string;
    INVITE_MEMBER: string;
    REINVITE_MEMBER: string;
    UPDATE_MEMBER: string;
    DELETE_MEMBER: string;
    READ_ROLE: string;
    CREATE_ROLE: string;
    UPDATE_ROLE: string;
    DELETE_ROLE: string;
    CUSTOM_ROLE: string;
    TEAM_PERMISSION: string;
    ROLE_PERMISSION: string;
    ROLE_PERMISSION1: string;
    INVITATION: string;
    FORGET_PASSWORD: string;
    DEPARTMENT: string;
    CREATE_DEPARTMENT: string;
    UPDATE_DEPARTMENT: string;
    DELETE_DEPARTMENT: string;
    ADD_MANAGER: string;
    ADD_MEMBER: string;
    MANAGER_DEPARTMENT: string;
    DELETE_MANAGER_DEPARTMENT: string;
    MEMBER_DEPARTMENT: string;
    DELETE_MEMBER_DEPARTMENT: string;
    READ_EVENT_LOG: string;
    READ_EVENT_LOGGER_UNIQUE_VALUES: string;
  };
  TAKS_MINING: {
    ROOT: string;
    TOP_WEBSITES: string;
    TOP_WEBSITES_DETAILS: string;
    TOP_APPS: string;
    TOP_APPS_DETAILS: string;
  };
  OPERATIONAL_INTELLECT: {
    ROOT: string;
    LIST_RULE_CHART: string;
    LIST_DATABASE_METADATA_FOR_FORMULAE: string;
    PREVIEW_QUERY_RESULT: string;
    ADD_RULE_METRIC: string;
    SEARCH_RULE_METRIC: string;
    UPDATE_RULE_METRIC: string;
    DELETE_RULE_METRIC: string;
    DATA_MODEL: {
      LIST_DATA_MODEL: string;
      LINK_DATA_MODEL: string;
      SEARCH_DATA_MODEL: string;
    };
    KPI: {
      CREATE_KPI: string;
      SEARCH_KPI: string;
      UPDATE_KPI: string;
      UPDATE_TARGET_CONFIG: string;
      UPDATE_THRESHOLD_CONFIG: string;
      DELETE_KPI: string;
      TOGGLE_ACTIVATION_KPI: string;
      ATTACH_DASHBOARD: string;
      FORMULA: {
        OPERATORS: string;
        AGGREGATORS: string;
        COLUMNS: string;
        CONFIGS: string;
        ENTITYFUNCTIONS: string;
      };
    };
    KPITREND: {
      STATIC_DROPDOWN_DETAILS: string;
      CREATE_KPI_TREND: string;
      SEARCH_KPI_TREND: string;
      UPDATE_KPI_TREND: string;
      DELETE_KPI_TREND: string;
      UPDATE_SERIES_CONFIG: string;
      ATTACH_DASHBOARD: string;
      DETACH_DASHBOARD: string;
      LINK_CHART_TO_KPI: string;
    };
    METRIC: {
      CREATE_METRIC: string;
      SEARCH_METRIC: string;
      UPDATE_METRIC: string;
      UPDATE_METRIC_TARGET: string;
      UPDATE_METRIC_THRESHOLD: string;
      LINK_CHART_TO_METRIC: string;
      DELETE_METRIC: string;
      TOGGLE_ACTIVATION_METRIC: string;
      ATTACH_METRIC: string;
      DETACH_METRIC: string;
      RETRIEVE_METRIC_FOR_DASHBOARD: string;
    };
    METRIC_TREND: {
      CREATE_METRIC_TREND: string;
      SEARCH_METRIC_TREND: string;
      UPDATA_METRIC_TREND: string;
      UPDATE_SERIES_CONFIG: string;
      DELINK_METRIC_TREND_CHART: string;
      DELETE_METRIC_TREND: string;
      ATTACH_METRIC_TREND: string;
      DEACH_METRIC_TREND: string;
      SEARCH_METRIC_CHART: string;
    };
    RECOMMENDATION: {
      CREATE_BUSINESS_RULE: string;
      SEARCH_BUSINESS_RULE: string;
      UPDATE_BUSINESS_RULE: string;
      DELETE_BUSINESS_RULE: string;
      TOGGLE_BUSINESS_RULE: string;
      SEARCH_CRITERIA_ACTION_FOR_RECOMMENDATION: string;
      ADD_CRITERIA_ACTION_FOR_RECOMMENDATION: string;
      UPDATE_CRITERIA_ACTION_FOR_RECOMMENDATION: string;
      ADD_RECOMMENDATION_KPI_METRIC: string;
      UPDATE_RECOMMENDATION_KPI_METRIC: string;
      SEARCH_RECOMMENDATION_KPI_METRIC: string;
      LIST_RECOMMENDATIONS: string;
      VIEW_RECOMMENDATION: string;
      LIST_OPERANDS: string;
      LIST_RULE_COMPONENTS: string;
    };
    PROCESSBLOCK: {
      CREATE_BUSINESS_RULE: string;
      SEARCH_BUSINESS_RULE: string;
      UPDATE_BUSINESS_RULE: string;
      DELETE_BUSINESS_RULE: string;
      SEARCH_CRITERIA_ACTION_FOR_PROCESS_BLOCK: string;
      ADD_CRITERIA_ACTION_FOR_PROCESS_BLOCK: string;
      UPDATE_CRITERIA_ACTION_FOR_PROCESS_BLOCK: string;
      ADD_PROCESS_BLOCK_KPI_METRIC: string;
      UPDATE_PROCESS_BLOCK_KPI_METRIC: string;
      SEARCH_PROCESS_BLOCK_KPI_METRIC: string;
      LIST_CASE_TABLE_MOCK_ITEMS: string;
      LIST_CASE_TABLE_ITEMS: string;
      CREATE_CASE_TABLE_CONFIG: string;
      UPDATE_CASE_TABLE_CONFIG: string;
      LIST_CASE_TABLE_CONFIG: string;
    };
    CASE_TABLE: {
      CREATE_CASE_TABLE_CONFIG: string;
      UPDATE_CASE_TABLE_CONFIG: string;
      LIST_CASE_TABLE_CONGIF_COLUMNS: string;
    };
  };
  INTELLIGENT_INSIGHTS: {
    ROOT: string;
    SHOW_BLACKLIST: string;
    SHOW_WHITELIST: string;
    VIOLATION_TOGGLE: string;
    VIOLATION_DETAILS: string;
    VIOLATION_GRAPH: string;
  };
  REVOPS_API: {
    ROOT: string;
  };
  REVENUP: {
    ROOT: string;
  };
  GEN_AI: {
    ROOT: string;
  };
}

export const APP_DI_CONFIG: AppConfig = {
  NAV_PATH: {
    ROOT: Environment.utmAPIRoot,
    LIST: 'nav-path/',
    REFRESH_LIST: 'refresh-menu/'
  },
  ANAYSIS_API: {
    ROOT: Environment.analysisAPIRoot,
    CLUSTER: {
      CREATE_CLUSTER: 'process_analytics/create_cluster/',
      UPDATE_CLUSTER: 'process_analytics/update_cluster/',
      DELETE_CLUSTER: 'process_analytics/delete_cluster/',
      READ_CLUSTER: 'process_analytics/read_cluster/',
      USER_PERMISSION: {
        LIST: 'process_analytics/cluster/user-permission/list/',
        SEARCH: 'process_analytics/cluster/member-email/search/',
        UPDATE: 'process_analytics/cluster/user-permission/update/'
      }
    },
    MINING_MODEL: {
      LIST: 'process_analytics/list_data_models/',
      LINK: 'process_analytics/link_data_model/'
    },
    ANALYSIS: {
      CREATE_CLUSTER: 'process_analytics/create_analysis/',
      UPDATE_CLUSTER: 'process_analytics/update_analysis/',
      DELETE_CLUSTER: 'process_analytics/delete_analysis/',
      READ_CLUSTER: 'process_analytics/read_analysis/'
    },
    PROCESS_OVERVIEW: {
      ACTIVITY: 'process_overview/activity_page/',
      PROFILE: 'process_overview/activity_profile/',
      METRICS: 'process_overview/metrics_generations/',
      HAPPYACTIVITIES: 'process_overview/happy_activities/',
      UNHAPPYACTIVITIES: 'process_overview/unhappy_activities/',
      THROUGHPUT: 'process_overview/throughput_generations/',
      BOTTLENECKS: 'process_overview/bottleneck_generations/'
    },
    PROCESS_EXPLORER: {
      LAYOUT: 'process_discovery/graph_from_metrics/'
    },
    PROCESS_CONFORMANCE: {
      CONFORMANCE_STATS_HISTORY:
        'conformance/conformance_overview/conformance_stats_history/',
      VIOLATION_STATS: 'conformance/conformance_overview/violation_stats/'
    },
    FORMULAE: {
      METADATA_READ: 'process_analytics/formula/metadata/read/'
    }
  },
  INTELLECT_ENGINE: {
    ROOT: Environment.iEAPIRoot,
    SCHEDULER: {
      CREATE: 'api/scheduler/add-schedule/',
      READ: 'api/scheduler/read-schedule/',
      REMOVE: 'api/scheduler/remove-schedule/',
      UDATE: 'api/scheduler/update-schedule/'
    },
    PROJECT: {
      SEARCH_PROJECT: 'intellect_engine/search_project/',
      CREATE_PROJECT: 'intellect_engine/create_project/',
      UPDATE_PROJECT: 'intellect_engine/update_project/',
      DELETE_PROJECT: 'intellect_engine/delete_project/'
    },
    DATA_MODEL: {
      LIST: 'intellect_engine/datamodel/list_data_models/',
      LIST_COLUMNS: 'intellect_engine/datamodel/list_columns/',
      BE_RESOURCE_DATAMODEL_ISUSED: 'be/resource/datamodel/is_used/'
    },
    BUSINESS_RULES: {
      SEARCH_BUSINESS_RULE: 'intellect_engine/search_business_rule/',
      CREATE_BUSINESS_RULE: 'intellect_engine/create_business_rule/',
      UPDATE_BUSINESS_RULE: 'intellect_engine/update_business_rule/',
      DELETE_BUSINESS_RULE: 'intellect_engine/delete_business_rule/',
      LINK_DATA_MODEL:
        'intellect_engine/datamodel/link_data_model_to_business_rule/',
      SELECT_BUSINESS_RULE_TREE: 'intellect_engine/select_business_rule_tree/',
      TOGGLE_BUSINESS_RULE:
        'intellect_engine/toggle_active_inactive_business_rule/',
      SIGNAL_RULES: {
        SEARCH_SIGNAL_RULE: 'intellect_engine/search_signal_rule/',
        CREATE_SIGNAL_RULE: 'intellect_engine/create_signal_rule/',
        UPDATE_SIGNAL_RULE: 'intellect_engine/update_signal_rule/',
        DELETE_SIGNAL_RULE: 'intellect_engine/delete_signal_rule/',
        ATTACH_BUSINESS_RULE: 'intellect_engine/attach_business_rule/',
        CREATE_CRITERIA: 'intellect_engine/create_criteria/',
        UPDATE_CRITERIA: 'intellect_engine/update_criteria/',
        SEARCH_CRITERIA: 'intellect_engine/search_criteria/',
        DELETE_CRITERIA: 'intellect_engine/delete_criteria/',
        DROPDOWN_ATTRS: 'intellect_engine/get_dropdown_attrs/',
        DROPDOWN_DETAILS: 'intellect_engine/get_static_dropdown_details/',
        APPLY_COLUMN_FILTER: 'intellect_engine/signal_rule/apply_column_filter/'
      },
      ROUTING_RULES: {
        SEARCH_ROUTING_RULE: 'intellect_engine/search_routing_rule/',
        CREATE_ROUTING_RULE: 'intellect_engine/create_routing_rule/',
        UPDATE_ROUTING_RULE: 'intellect_engine/update_routing_rule/',
        DELETE_ROUTING_RULE: 'intellect_engine/delete_routing_rule/',
        ATTACH_BUSINESS_RULE: 'intellect_engine/attach_business_rule/'
      },
      ACTIONS: {
        SEARCH_AVAILABLE_ACTIONS:
          'intellect_engine/search_available_action_scripts/',
        CREATE_ACITION: 'intellect_engine/create_action/',
        ATTACH_ACTION: 'intellect_engine/attach_action/',
        SEARCH_ACTION: 'intellect_engine/search_action/',
        UPDATE_ACTION: 'intellect_engine/update_action/',
        DELETE_ACTION: 'intellect_engine/delete_action/'
      }
    },
    DROPDOWN: {
      DROPDOWN_ATTRS: 'intellect_engine/get_dropdown_attrs/',
      DROPDOWN_DETAILS: 'intellect_engine/get_static_dropdown_details/'
    },
    ENTITY_FUNCTION: {
      SEARCH: 'intellect_engine/search_entity_function/'
    },
    SCHEDULE: {
      SHOW_PROJECT: 'intellect_engine/show_project_tree/',
      ADD_SCHEDULE: 'api/scheduler/add-schedule/',
      UPDATE_SCHEDULE: 'intellect_engine/update_schedule/',
      REMOVE_SCHEDULE: 'intellect_engine/remove_schedule/'
    }
  },
  USER_TENANT_MANAGEMENT: {
    ROOT: Environment.utmAPIRoot,
    EMAIL_AUTOCOMPLETE: 'general/auto-complete/member-email/',
    VALIDATE_WORKSPACE: 'tenant-workspace-url/',
    TENANT_WORKSPACE: 'team-config/',
    FORGOT_PASSWORD: 'forgot-password/',
    SET_PASSWORD: 'set-password/',
    NAV_PATH: 'nav-path/'
  },
  CONFORMANCE_CHECK_API: {
    CONFORMANCE_OVERVIEW: 'process_analytics/conformance/overview/',
    CONFORMANCE: 'conformance/',
    ACTIVITY_LIST: 'process_analytics/list_activities/',
    WHITELIST: 'conformance/whitelist_activities/',
    IS_EXISTS: '/process_analytics/conformance/process_model/is_exist/',
    CREATE_PROCESS_MODEL:
      '/process_analytics/conformance/process_model/create/',
    READ_PROCESS_MODEL: '/process_analytics/conformance/process_model/read/',
    UPDATE_PROCESS_MODEL:
      '/process_analytics/conformance/process_model/update/',
    DELETE_PROCESS_MODEL:
      '/process_analytics/conformance/process_model/delete/',
    // LIST_VIOLATIONS: '/process_analytics/conformance/list_violations/'
    LIST_VIOLATIONS: '/process_analytics/conformance/violation/list/',
    LIST_WHITELIST: '/process_analytics/conformance/violation/show_whitelist/',
    WHITELIST_VIOLATIONS: '/process_analytics/conformance/violation/whitelist/',
    BLACKLIST_VIOLATIONS: '/process_analytics/conformance/violation/blacklist/'
  },
  // CASE_EXPLORER: 'process_explorer/case_explorer/',
  CASE_EXPLORER: 'process_analytics/c_exp/list_case_details/',
  REVENEW_OPERATION_API: {
    ROOT: Environment.revOperationAPIRoot,
    LIST_DATABASE_METADATA_FOR_FORMULAE: 'formulas/metadata/read/',
    LIST_DATABASE_METADATA_SCHEMA: 'formulas/metadata-schema/read/',
    LIST_DATABASE_METADATA_TABLES: 'formulas/metadata-tables/read/',
    FORMULA_TEST: 'formulas/test/',
    FORMULA_UPSERT: 'formulas/upsert/',
    LIST_DATABASE_METADATA_FOR_FORMULAE_OLD: 'formula/metadata/read/',
    LIST_DATABASE_METADATA_SCHEMA_OLD: 'formula/metadata-schema/read/',
    LIST_DATABASE_METADATA_TABLES_OLD: 'formula/metadata-tables/read/',
    FORMULA_TEST_OLD: 'formula/test/',
    FORMULA_UPSERT_OLD: 'formula/upsert/',
    REVENEW_KPI: {
      READ: 'quote/kpi/read/',
      CREATE: 'quote/kpi/create/',
      ATTACH: 'quote/kpi/attach/',
      DETTACH: 'quote/kpi/attach/',
      UPDATE: 'quote/kpi/update/',
      DELETE: 'quote/kpi/delete/'
    }
  },
  DATA_COLLECTION_API: {
    ROOT: Environment.dataCollectionAPIRoot,
    DATA_BUCKET: {
      DATA_MODEL_WISE_DATA_SOURCE_RELATIONSHIP:
        'data-model-wise-data-source-relationship/',
      DATA_MODEL_WISE_DATA_SOURCE: 'data-model-wise-data-source/',
      DATA_MODEL_WISE_DATA_SOURCE_1: 'data-model-wise-data-source1/',
      CREATE_BUCKET: 'bucket/create/',
      UPDATE_BUCKET: 'bucket/update/',
      DELETE_BUCKET: 'bucket/delete/',
      BUCKET: 'bucket/read/',
      DATA_MODEL: {
        CREATE: 'data-model/create/',
        READ: 'data-model/read/',
        UPDATE: 'data-model/update/',
        DELETE: 'data-model/delete/',
        LOGS: 'data-model/read-logs/',
        MANAGE: 'data-model/manage/',
        APPLY: 'data-model/apply/',
        UPDATE_CONFIG: 'data-model/update-config/'
      },
      DATA_MODEL_MAPPING: {
        LOGS: 'data-model/read-logs/',
        LINKED_DATA_SOURCE: 'data-model/read-data-sources/',
        ATTACH_OR_DETACH: 'data-model/attach-data-sources/',
        MAPPED_DATA_SOURCES: 'data-model/read-mapped-data-sources/',
        DATA_SOURCE_MAPPING: 'data-model/mapping-data-sources/',
        DATA_SOURCE_COLUMNS: 'data-sources/read-columns/'
      },
      DATA_MODEL_TBL_LIST: 'data-model-tbl-list/',
      DATA_SOURCE: 'data-sources/read/',
      DATA_SOURCE_LOGS: 'data-sources/read-logs/',
      DELETE_SOURCE: 'data-sources/delete/',
      DATA_SOURCE_UPLOAD: 'data-sources/upload/',
      DATA_SOURCE_UPLOAD_INITIATE: 'data-sources/upload-initiate/',
      DATA_SOURCE_UPLOAD_CHUNK: 'data-sources/upload-chunk/',
      DATA_SOURCE_UPLOAD_COMPLETE: 'data-sources/upload-complete/',
      DATA_SOURCE_UPDATE: 'data-sources/update/',
      PREVIEW_SOURCE_UPLOAD: 'data-sources/preview-upload/',
      DATA_SOURCE_FREEZE: 'data-sources/freeze-properties/',
      DS_CONNECTOR: {
        READ: 'data-connector/read/',
        TEST: 'data-connector/test/',
        CREATE: 'data-connector/create/',
        UPDATE: 'data-connector/update/',
        DELETE: 'data-connector/delete/',
        MARK_AS_DEFAULT: 'data-connector/make-default/'
      },
      DS_CONNECTOR_AVAILABLE: 'ds-connector-available/',
      SHARED_USERS: 'bucket/share/users/',
      SHARED: 'bucket/share/',
      UPDATE_SHARED: 'bucket/share/update/',
      SHARE_TO_USERS: '/bucket/share/users/'
    },
    DATA_JOB: {
      DATA_CONNECTORS: 'general/static-drop-down/',
      DATA_JOB: 'data-job/',
      CREATE_DATA_JOBS: 'data-job/create/',
      UPDATE_DATA_JOB: 'data-job/update/',
      READ_DATA_JOBS: 'data-job/read/',
      DELETE_DATA_JOB: 'data-job/delete/',
      DATA_JOB_DO: 'data-job-do/',
      EXTRACTION: {
        EXTRACTION: 'data-extraction/',
        EXTRACTION_CREATE: 'extraction/create/',
        EXTRACTION_READ: 'extraction/read/',
        EXTRACTION_UPDATE: 'extraction/update/',
        EXTRACTION_DELETE: 'extraction/delete/',
        EXTRACTION_TOGGLE: 'extraction/toggle-status/',
        TABLE_LIST: 'ds-all-table/',
        EXTRACTION_TASK: 'data-extraction-task/',
        EXTRACTION_TASK_LIST: 'data-extraction-task-list/',
        EXTRACTION_TEMPLATES: 'data-extraction-template-list/',
        EXTRACTION_REARRANGE: 'extraction/re-arrange/',
        EXTRACTION_TASK_PARAMS: 'extraction-task-params/',
        EXTRACTION_TASK_PARAMS_LIST: 'extraction-task-params-list/',
        EXTRACTION_LOG: 'extraction-log/',
        EXTRACTION_TASK_SQL: 'data-extraction-task-sql/',
        SOURCE_LIST: 'extraction/sources/list/',
        READ_ATTACHED_SOURCE: 'extraction/sources/read/attached/',
        ATTACH_SOURCE: 'extraction/sources/attach/',
        TOGGLE_STATUS: 'extraction/sources/toggle-status/',
        DELETE_SOURCE: 'extraction/sources/delete/',
        UPDATE_SOURCE: 'extraction/sources/update/',
        UPSERT_SOURCE: 'extraction/sources/config/upsert/'
      },
      TRANSFORMATION: {
        TRANSFORMATION: 'data-transformation/',
        TRANSFORMATION_CREATE: 'transformation/create/',
        TRANSFORMATION_READ: 'transformation/read/',
        TRANSFORMATION_UPDATE: 'transformation/update/',
        TRANSFORMATION_LIST: 'data-transformation-list/',
        TRANSFORMATION_PARAMS_LIST: 'task-transformation-param-list/',
        TRANSFORMATION_TABLE_LIST: 'data-transformation-table-list/',
        TRANSFORMATION_TEMPLATE_LIST: 'data-transformation-template-list/',
        TRANSFORMATION_SQL: 'data-transformation-sql/',
        TRANSFORMATION_MAPPERS_UPSERT: 'transformation/mappers/upsert/'
      },
      DATA_MODEL_LOAD: {
        ASSIGN_MODEL: 'data-job-assign-model/',
        DATA_MODEL_LIST: 'data-job-model-list/'
      }
    },
    DATA_MODEL_FIELD: 'data-model-field/',
    DATA_MODEL_PROCESSING: 'data-source-processed/',
    DATA_MODEL_MAP: 'data-model-map/',
    FILES: 'files',
    DATA_PUSH: 'data-push/',
    API_KEY: 'api-key/',
    ETL: {
      QUERY: {
        QUERY_METADATA_READ: 'etl/query-metadata/read/',
        QUERY_METADATA_SCHEMA: 'etl/query-metadata-schema/read/',
        QUERY_METADATA_TABLES: 'etl/query-metadata-tables/read/',
        PREVIEW: 'etl/query/preview/'
      }
    }
  },
  USER_MANAGEMENT: {
    ROOT: Environment.dataCollectionAPIRoot,
    NOT_ALL_MEMBER: 'member/',
    READ_MEMBER: 'all-member/',
    INVITE_MEMBER: 'member/invite/',
    REINVITE_MEMBER: 'member/re-invite/',
    UPDATE_MEMBER: 'member/update/',
    DELETE_MEMBER: 'member/delete/',
    READ_ROLE: 'user-role/read/',
    CREATE_ROLE: 'user-role/create/',
    UPDATE_ROLE: 'user-role/update/',
    DELETE_ROLE: 'user-role/delete/',
    CUSTOM_ROLE: 'custom-role/',
    TEAM_PERMISSION: 'team-permission/',
    ROLE_PERMISSION: 'role-permission/',
    ROLE_PERMISSION1: 'role-permission1/',
    INVITATION: 'invitation/',
    FORGET_PASSWORD: 'forget-password/',
    DEPARTMENT: 'department/',
    CREATE_DEPARTMENT: 'department/create/',
    UPDATE_DEPARTMENT: 'department/update/',
    DELETE_DEPARTMENT: 'department/delete/',
    ADD_MANAGER: 'department/manager/add/',
    ADD_MEMBER: 'department/member/add/',
    MANAGER_DEPARTMENT: 'department/manager/',
    DELETE_MANAGER_DEPARTMENT: 'department/manager/delete/',
    MEMBER_DEPARTMENT: 'department/member/',
    DELETE_MEMBER_DEPARTMENT: 'department/member/delete/',
    READ_EVENT_LOG: 'read-event-log/',
    READ_EVENT_LOGGER_UNIQUE_VALUES: 'event-logger-unique-values/'
  },
  TAKS_MINING: {
    ROOT: Environment.taskMiningAPIRoot,
    TOP_WEBSITES: 'top-website/',
    TOP_WEBSITES_DETAILS: 'top-website-details/',
    TOP_APPS: 'top-app/',
    TOP_APPS_DETAILS: 'top-app-details/'
  },
  OPERATIONAL_INTELLECT: {
    ROOT: Environment.OIAPIRoot,
    LIST_RULE_CHART: 'operational_intellect/list_rule_charts/',
    LIST_DATABASE_METADATA_FOR_FORMULAE:
      'operational_intellect/list_database_metadata_for_formula/',
    PREVIEW_QUERY_RESULT: 'operational_intellect/preview_query_result/',
    ADD_RULE_METRIC: 'operational_intellect/create_rule_metric/',
    SEARCH_RULE_METRIC: 'operational_intellect/search_rule_metric/',
    UPDATE_RULE_METRIC: 'operational_intellect/update_rule_metric/',
    DELETE_RULE_METRIC: 'operational_intellect/delete_rule_metric/',
    DATA_MODEL: {
      LIST_DATA_MODEL: 'operational_intellect/list_data_models/',
      LINK_DATA_MODEL: 'operational_intellect/link_data_model/',
      SEARCH_DATA_MODEL: 'operational_intellect/search_oie_project/'
    },
    KPI: {
      CREATE_KPI: 'operational_intellect/create_kpi/',
      SEARCH_KPI: 'operational_intellect/search_kpi/',
      UPDATE_KPI: 'operational_intellect/update_kpi/',
      UPDATE_TARGET_CONFIG: 'operational_intellect/update_kpi/target_config/',
      UPDATE_THRESHOLD_CONFIG:
        'operational_intellect/update_kpi/threshold_config/',
      DELETE_KPI: 'operational_intellect/delete_kpi/',
      ATTACH_DASHBOARD: 'operational_intellect/kpi/attach_dashboard/',
      TOGGLE_ACTIVATION_KPI: 'operational_intellect/toggle_activation_kpi/',
      FORMULA: {
        OPERATORS: 'operational_intellect/list_operators_for_formula/',
        AGGREGATORS: 'operational_intellect/list_aggregators_for_formula/',
        COLUMNS: 'operational_intellect/list_operands_for_formula/',
        CONFIGS: 'intellect_engine/list_formule_entity_functions/',
        ENTITYFUNCTIONS: 'intellect_engine/search_entity_function/'
      }
    },
    KPITREND: {
      STATIC_DROPDOWN_DETAILS:
        'operational_intellect/get_static_dropdown_details/',
      CREATE_KPI_TREND: 'operational_intellect/create_kpi_chart/',
      SEARCH_KPI_TREND: 'operational_intellect/search_kpi_chart/',
      UPDATE_KPI_TREND: 'operational_intellect/update_kpi_chart/',
      DELETE_KPI_TREND: 'operational_intellect/delete_kpi_chart/',
      UPDATE_SERIES_CONFIG:
        'operational_intellect/update_kpi_chart/series_config/',
      ATTACH_DASHBOARD: 'operational_intellect/kpi_chart/attach_dashboard/',
      DETACH_DASHBOARD: 'operational_intellect/kpi_chart/detach_dashboard/',
      LINK_CHART_TO_KPI: 'operational_intellect/update_kpi/link_chart/'
    },
    METRIC: {
      CREATE_METRIC: 'operational_intellect/create_metric/',
      SEARCH_METRIC: 'operational_intellect/search_metric/',
      UPDATE_METRIC: 'operational_intellect/update_metric/',
      UPDATE_METRIC_TARGET:
        'operational_intellect/update_metric/target_config/',
      UPDATE_METRIC_THRESHOLD:
        'operational_intellect/update_metric/threshold_config/',
      LINK_CHART_TO_METRIC: 'operational_intellect/update_metric/link_chart/',
      DELETE_METRIC: 'operational_intellect/delete_metric/',
      TOGGLE_ACTIVATION_METRIC:
        'operational_intellect/toggle_activation_metric/',
      ATTACH_METRIC: 'operational_intellect/metric/attach_dashboard/',
      DETACH_METRIC: 'operational_intellect/metric/detach_dashboard/',
      RETRIEVE_METRIC_FOR_DASHBOARD:
        'operational_intellect/search_metric_for_dashboard/'
    },
    METRIC_TREND: {
      CREATE_METRIC_TREND: 'operational_intellect/create_metric_chart/',
      SEARCH_METRIC_TREND: 'operational_intellect/search_metric_chart/',
      UPDATA_METRIC_TREND: 'operational_intellect/update_metric_chart/',
      UPDATE_SERIES_CONFIG:
        'operational_intellect/update_metric_chart/series_config/',
      DELETE_METRIC_TREND: 'operational_intellect/delete_metric_chart/',
      DELINK_METRIC_TREND_CHART:
        'operational_intellect/update_metric/delink_chart/',
      ATTACH_METRIC_TREND:
        'operational_intellect/metric_chart/attach_dashboard/',
      DEACH_METRIC_TREND:
        'operational_intellect/metric_chart/detach_dashboard/',
      SEARCH_METRIC_CHART:
        'operational_intellect/search_metric_chart_for_dashboard/'
    },
    RECOMMENDATION: {
      CREATE_BUSINESS_RULE: 'intellect_engine/create_business_rule/',
      SEARCH_BUSINESS_RULE: 'intellect_engine/search_business_rule/',
      UPDATE_BUSINESS_RULE: 'intellect_engine/update_business_rule/',
      DELETE_BUSINESS_RULE: 'intellect_engine/delete_business_rule/',
      TOGGLE_BUSINESS_RULE:
        'intellect_engine/toggle_active_inactive_business_rule/',
      SEARCH_CRITERIA_ACTION_FOR_RECOMMENDATION:
        'operational_intellect/search_criteria_action_for_recommendation/',
      ADD_CRITERIA_ACTION_FOR_RECOMMENDATION:
        'operational_intellect/add_criteria_action_for_recommendation/',
      UPDATE_CRITERIA_ACTION_FOR_RECOMMENDATION:
        'operational_intellect/update_criteria_action_for_recommendation/',
      ADD_RECOMMENDATION_KPI_METRIC:
        'operational_intellect/add_recommendation_kpi_metric/',
      UPDATE_RECOMMENDATION_KPI_METRIC:
        'operational_intellect/update_recommendation_kpi_metric/',
      SEARCH_RECOMMENDATION_KPI_METRIC:
        'operational_intellect/search_recommendation_kpi_metric/',
      LIST_RECOMMENDATIONS: 'operational_intellect/list_recommendations/',
      VIEW_RECOMMENDATION: 'operational_intellect/view_recommendation/',
      LIST_OPERANDS: 'operational_intellect/list_operands_for_formula/',
      LIST_RULE_COMPONENTS: 'operational_intellect/list_rule_components/'
    },
    PROCESSBLOCK: {
      CREATE_BUSINESS_RULE: 'intellect_engine/create_business_rule/',
      SEARCH_BUSINESS_RULE: 'intellect_engine/search_business_rule/',
      UPDATE_BUSINESS_RULE: 'intellect_engine/update_business_rule/',
      DELETE_BUSINESS_RULE: 'intellect_engine/delete_business_rule/',
      SEARCH_CRITERIA_ACTION_FOR_PROCESS_BLOCK:
        'operational_intellect/search_criteria_action_for_process_block/',
      ADD_CRITERIA_ACTION_FOR_PROCESS_BLOCK:
        'operational_intellect/add_criteria_action_for_process_block/',
      UPDATE_CRITERIA_ACTION_FOR_PROCESS_BLOCK:
        'operational_intellect/update_criteria_action_for_process_block/',
      ADD_PROCESS_BLOCK_KPI_METRIC:
        'operational_intellect/add_process_block_kpi_metric/',
      UPDATE_PROCESS_BLOCK_KPI_METRIC:
        'operational_intellect/update_process_block_kpi_metric/',
      SEARCH_PROCESS_BLOCK_KPI_METRIC:
        'operational_intellect/search_process_block_kpi_metric/',
      LIST_CASE_TABLE_MOCK_ITEMS:
        'operational_intellect/list_case_table_process_block_mock_items/',
      LIST_CASE_TABLE_ITEMS:
        'operational_intellect/list_case_table_process_block_items/',
      CREATE_CASE_TABLE_CONFIG:
        'operational_intellect/create_case_table_process_block_config/',
      UPDATE_CASE_TABLE_CONFIG:
        'operational_intellect/update_case_table_process_block_config/',
      LIST_CASE_TABLE_CONFIG:
        'operational_intellect/list_case_table_process_block_config/'
    },
    CASE_TABLE: {
      CREATE_CASE_TABLE_CONFIG:
        'operational_intellect/create_case_table_config/',
      UPDATE_CASE_TABLE_CONFIG:
        'operational_intellect/update_case_table_config/',
      LIST_CASE_TABLE_CONGIF_COLUMNS:
        'operational_intellect/list_case_table_config_columns/'
    }
  },
  INTELLIGENT_INSIGHTS: {
    ROOT: Environment.IIAPIRoot,

    SHOW_BLACKLIST: 'intelligent_insight/show/blacklist/',
    SHOW_WHITELIST: 'intelligent_insight/show/whitelist/',
    VIOLATION_TOGGLE: 'intelligent_insight/violations/toggle/',
    VIOLATION_DETAILS: 'intelligent_insight/violations/show_details/',
    VIOLATION_GRAPH: 'intelligent_insight/violations/get_graph/'
  },
  REVOPS_API: {
    ROOT: Environment.revOpsRoot
  },
  REVENUP: {
    ROOT: Environment.revenupRoot
  },
  GEN_AI: {
    ROOT: Environment.genAI
  }
};

@NgModule({
  providers: [
    {
      provide: APP_CONFIG,
      useValue: APP_DI_CONFIG
    }
  ]
})
export class AppConfigModule {}
