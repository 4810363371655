import { Component } from '@angular/core';
import { Environment } from './../../environments/environment';

@Component({
  selector: 'app-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss']
})
export class AppVersionComponent {
  title = 'Enterprise Intellect App Version';
  currentApplicationVersion = Environment.appVersion;

}
