import {
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoIdToken
} from 'amazon-cognito-identity-js';
export interface AuthStatCore {
  _id: any;
  user: User | null;
  uuid: string;
  userPoolId: string;
  clientId: string;
  loading: boolean;
  forgotPassword: ForgotPassword | null;
  resetPassword: ResetPassword | null;
}
export interface User {
  refresh: CognitoRefreshToken;
  access: CognitoAccessToken;
  id: CognitoIdToken;
}

export interface ForgotPassword {
  email: string;
  success: boolean;
}

export interface ResetPassword {
  success: boolean;
}

export interface ValidateWorkspacePayload {
  uuid: string;
}

export interface NewValidateWorkspacePayload {
  tenant_id: string;
}

export interface ValidateWorkspaceValidateResponse {
  exists: boolean;
}

export interface CorrectTenantWorkspaceResponse {
  allowed: boolean;
}
