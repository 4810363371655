import {
  Component,
  ElementRef,
  OnInit,
  OnChanges,
  OnDestroy,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import * as d3 from 'd3';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'app-bar-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() BarData: Array<any> = [];

  @Output() barSelected = new EventEmitter<string>();
  @Output() barUnSelected = new EventEmitter<string>();

  constructor(private container: ElementRef) {}

  ngOnInit() {
    this.initBarChart();
  }

  ngOnChanges(changes) {
    this.initBarChart();
  }

  ngOnDestroy() {
    d3.select(window).on('resize.bar-chart-wrapper', null);
  }

  initBarChart() {
    d3.select(window).on('resize.bar-chart-wrapper', null);
    const container = d3.select(this.container.nativeElement);
    // const this = this;
    const data = this.BarData;
    container.select('.bar-chart').html('');
    container.select('.bar-tooltip').remove();

    const margin = {
      top: 20,
      right: 20,
      bottom: 30,
      left: 40
    };
    const width =
      parseInt(container.select('.bar-chart').style('width'), 0) -
      margin.left -
      margin.right;
    const height =
      parseInt(container.select('.bar-chart').style('height'), 0) -
      margin.top -
      margin.bottom;
    const xScale = d3
      .scaleBand()
      .domain(
        data.map((d: any, index: any) => {
          return index + 1;
        })
      )
      .range([0, width])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, d => {
          return d.count;
        })
      ])
      .range([height, 0]);

    const svg = container
      .select('.bar-chart')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('preserveAspectRatio', 'xMinYMid')
      .append('g')
      .attr('class', 'bar-g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d, index: any) => {
        return xScale(String(index + 1));
      })
      .attr('width', xScale.bandwidth())
      .attr('y', d => {
        return yScale(d.count);
      })
      .attr('height', d => {
        if (height > 0) {
          return height - yScale(d.count);
        }
        return 0;
      })
      .on('mouseover', barMouseover)
      .on('mousemove', barMousemove)
      .on('mouseout', barMouseout)
      .on('click', barClick);

    // const xAxis = d3.axisBottom(xScale).tickFormat((d, index) => {
    //   return data[index].label;
    // });

    const xAxis = d3
      .axisBottom(xScale)
      .tickValues(
        xScale.domain().filter((d, i) => {
          if (data.length > 10) {
            return !(i % 10);
          }
          return d;
        })
      )
      .tickFormat((d: any, index) => {
        return data[d - 1].label;
      });

    svg
      .append('g')
      .classed('x-axis', true)
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);

    const yAxis = d3.axisLeft(yScale).ticks(3);

    svg.append('g').classed('y-axis', true).call(yAxis);

    // tooltips
    const tooltip = container
      .select('.bar-chart-wrapper')
      .append('div')
      .attr('class', 'bar-tooltip')
      .classed('hide', true);

    function barClick() {
      const slectedBarData: any = d3.select(this).data()[0];
      if (!d3.select(this).classed('selected')) {
        // Add Filter logic
        svg.classed('selected', true);
        container.selectAll('.bar').classed('selected', false);
        d3.select(this).classed('selected', true);
        this.barSelected.next(slectedBarData);
      } else {
        // Remove Filter logic
        svg.classed('selected', false);
        container.selectAll('.bar').classed('selected', false);
        this.barUnSelected.next(slectedBarData);
      }
    }

    function barMouseover() {
      tooltip.classed('show', true).classed('hide', false);
    }

    function barMousemove() {
      const barBBox = d3.select(this).node().getBBox();
      const svgBBox = svg.node().getBBox();
      const d: any = d3.select(this).data()[0];
      tooltip
        .html(
          `<span class="label">${d.label}</span><hr/><span class="count">${d.count}</span>`
        )
        .style('left', barBBox.x + barBBox.width / 2 + 'px')
        .style('top', svgBBox.height - barBBox.height - 34 + 'px');
    }

    function barMouseout() {
      tooltip.classed('show', false).classed('hide', true);
    }

    // Define responsive behavior
    function resizeBarChart() {
      const nWidth =
        parseInt(container.select('.bar-chart').style('width'), 0) -
        margin.left -
        margin.right;
      const nHeight =
        parseInt(container.select('.bar-chart').style('height'), 0) -
        margin.top -
        margin.bottom;

      if (!nWidth || !nHeight) {
        return;
      }

      xScale.range([0, nWidth]).padding(0.1);

      yScale.range([nHeight, 0]);

      // Update the axis and text with the new scale
      svg
        .select('.x-axis')
        .call(xAxis)
        .attr('transform', 'translate(0,' + nHeight + ')')
        .select('.label')
        .attr(
          'transform',
          'translate(' + nWidth / 2 + ',' + margin.bottom / 1.5 + ')'
        );

      svg.select('.y-axis').call(yAxis);

      // Force D3 to recalculate and update the line
      svg
        .selectAll('.bar')
        .attr('x', (d, index) => {
          return xScale(String(index + 1));
        })
        .attr('width', xScale.bandwidth())
        .attr('y', (d: any) => {
          return yScale(d.count);
        })
        .attr('height', (d: any) => {
          return nHeight - yScale(d.count);
        });
    }

    // d3.select(window).on('resize.bar-chart-wrapper', resizeBarChart);
    // When resize happens with window resize. (Ex. When clicks collapse and exapnd navigation)
    // Debounce Method - Brush will reset when window resize stoped.
    let resizeTimer;
    const observer = new ResizeObserver(entries => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        resizeBarChart();
      }, 100);
      entries.forEach(entry => {});
    });
    const wrapper: any = container.select('.bar-chart-wrapper').node();
    observer.observe(wrapper);
  }
}
