// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .hidden-activity-wrapper {
  max-width: inherit;
}

.apply-btn-wrapper {
  text-align: right;
  margin-right: 8px;
  margin-bottom: 14px;
  margin-top: 6px;
}
.apply-btn-wrapper .bli-primary-btn {
  height: 34px;
  line-height: 26px;
}

::ng-deep .mat-icon-button {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.12);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/hide-activities/hide-activities.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AACA;EACE,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;AAEF;AADE;EACE,YAAA;EACA,iBAAA;AAGJ;;AAAA;EACE,wHAAA;EACA,qCAAA;AAGF","sourcesContent":["::ng-deep .hidden-activity-wrapper {\n  max-width: inherit;\n}\n.apply-btn-wrapper {\n  text-align: right;\n  margin-right: 8px;\n  margin-bottom: 14px;\n  margin-top: 6px;\n  .bli-primary-btn {\n    height: 34px;\n    line-height: 26px;\n  }\n}\n::ng-deep .mat-icon-button {\n  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);\n  border: 1px solid rgba(0, 0, 0, 0.12);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
