import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { FormField } from '../../../../../state/app.model';
import { AppService } from '@bli/state/app.service';
import { FormFieldFosterService } from '../../services/form-field-foster-service.service';
import { Enum } from '@bli/modals/drop-down';

@Component({
  selector: 'app-multiselect-field-foster',
  templateUrl: './multiselect-field-foster.component.html',
  styleUrls: ['./multiselect-field-foster.component.scss']
})
export class MultiSelectFieldFosterComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() field: FormField;
  @Input() value: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() control: UntypedFormControl;

  @ViewChild('multiSelectInput', { static: true })
  multiSelectInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('chipList', { static: true }) chipList: MatChipGrid;
  filteredOptions: Observable<string[]>;
  selectedOptions: Enum[];
  mandatoryOptions: any[];
  allOptions: string[] = [];

  isLoading = false;
  searchControl = new UntypedFormControl();
  subscription = new Subscription();

  loaderSkeletonStyle =
    this.formFieldFosterService.formFieldloaderSkeletonStyle;

  constructor(
    private service: AppService,
    private formFieldFosterService: FormFieldFosterService
  ) {}

  ngOnInit() {
    const { callback, callback_dependent_fields = [] } = this.field;

    // if((this.field && callback && callback_dependent_fields?.length)){
    //   this.dependentCallback(callback, callback_dependent_fields);
    // }
    // if (this.field && callback && !callback_dependent_fields?.length) {
    //   this.callBack(callback)
    // }
    this.linkCallBacks();
    if (
      this.field &&
      this.field.type === 'autocomplete' &&
      !(callback || callback_dependent_fields?.length)
    ) {
      const payload = {
        dropdown_name: this.field.callback,
        selected_value: ''
      };
      this.service.getMultiSelectDatas(payload).subscribe(response => {
        this.field.enum = [];
        response.data
          .map(obj => ({ key: obj, value: obj }))
          .forEach(element => {
            this.field.enum.push(element);
          });
      });
    }
    // this.selectedOptions = this.control.value ? this.control?.value?.split(',') : [];
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      // map(value =>  typeof value === 'string' ? value : value.name),
      //  map(value => value ? this.selectedOptions = [] : this.selectedOptions.push(value)),
      map(name => (name ? this._filter(name) : this._defaultData()))
    );
    this.searchControl.setValue(this.control?.value);
    this.addValidation();
  }

  private linkCallBacks() {
    const beforeAPI = () => {
      this.toggleSearchDisable(true);
      this.isLoading = true;
    };
    const afterAPI = () => {
      this.isLoading = false;
    };
    const getCallBackAPISub = this.formFieldFosterService
      .getCallBackAPI(
        this.field,
        this.formGroup,
        beforeAPI.bind(this),
        afterAPI.bind(this)
      )
      .subscribe(data => {
        this.selectedOptions = [];
        this.field.enum = data?.enum || [];
        this.mandatoryOptions = data.enum.filter(data => data.mandatory);
        const mandatoryOptionKeys = this.mandatoryOptions.map(obj => obj.key);
        if (data && this.control?.value) {
          this.selectedOptions = [];
          const keys = this.control?.value
            .split(',')
            .filter(key => !mandatoryOptionKeys.includes(key));
          keys.forEach(key => {
            this.selectedOptions.push(
              this.formFieldFosterService.getInitialValueOfField(data, key)
            );
          });
        }
        this.toggleSearchDisable(false);
        // this.searchFilter();
      });
    this.subscription.add(getCallBackAPISub);
  }

  // private callBack(callback: string) {
  //   const payload = {
  //     dropdown_name: callback,
  //     selected_value: ''
  //   };
  //   const sub = this.getDropdownAttributes(payload).subscribe(() => {
  //     if (this.control?.value) {
  //       this.searchControl.setValue(this.control?.value);
  //     }
  //   });

  //   this.subscription.add(sub);
  // }

  // private dependentCallback(callback: string, callbackDependentFields: string[]) {
  //   const controls = callbackDependentFields
  //     .map(field => this.formFieldFosterService.findFormGroupField(this.formGroup, field)?.valueChanges.pipe(map(value => ({ [field]: value }))))
  //     .filter(v => v);

  //   const sub = combineLatest(controls).pipe(
  //     startWith(
  //       callbackDependentFields
  //         .map(field => ({[field]: this.formFieldFosterService.findFormGroupField(this.formGroup, field)?.value}))
  //         .filter(v => v)
  //     ),
  //     map(controls => controls.reduce((acc, prev) => ({...acc, ...prev}), {})),
  //     filter(args => !!Object.values(args).filter(v => v).length),
  //     debounceTime(300),
  //     switchMap(args => this.getDropdownAttributes({
  //       dropdown_name: callback,
  //       selected_value: this.selectValueRequiredActionSctipts(callback),
  //       args
  //     })),
  //     tap(() => {
  //       this.control.reset();
  //       this.searchControl.reset();
  //       this.setOptionList();
  //     })
  //   ).subscribe();

  //   this.subscription.add(sub);
  // }

  // private getDropdownAttributes(payload: { dropdown_name: string; selected_value: string; args?: Object }): Observable<any> {
  //   this.isLoading = true
  //   return this.service.getMultiSelectDatas(payload).pipe(
  //     tap(response => {
  //       // check for generic component
  //       let data = response.data;
  //       if (data[0]?.enum) {
  //         data = data[0].enum;
  //         this.field.enum = data;
  //         this.isLoading = false;
  //         return;
  //       }
  //       this.mandatoryOptions = data.filter(data => data.mandatory);
  //       this.selectedOptions = data.filter(data => this.control.value?.split(',').includes(data.key) && !data.mandatory).map(data => data.value);
  //       this.field.enum = data.filter(data => !data.mandatory);
  //       this.isLoading = false;
  //   }, () => {
  //     this.isLoading = false;
  //   })
  //   )
  // }

  toggleSearchDisable(disable: boolean) {
    const { callback_dependent_fields = [] } = this.field;
    disable && callback_dependent_fields.length
      ? this.searchControl.disable()
      : this.searchControl.enable();
  }

  addValidation() {
    this.control.clearValidators();
    // this.searchControl.clearValidators();
    if (this.field.mandatory) {
      this.control.setValidators(Validators.required);
      // this.searchControl.setValidators(Validators.required);
      return;
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      if (!this.field.enum.find(j => j.value.includes(value))) {
        input.value = '';
        const model: Enum = {
          key: value,
          value: value
        };
        this.valudateAndPushData(model);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    // this.control.setValue(null);
  }

  remove(option: Enum): void {
    const index = this.selectedOptions.indexOf(option);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.setOptionList();
    }
    this.field.enum.find(j => j === option).disabled = false;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.valudateAndPushData(event.option.value);
    this.field.enum.find(j => j === event.option.value).disabled = true;
    // this.multiSelectInput.nativeElement.value = '';
    // this.control.setValue(null);
  }

  setOptionList() {
    // this.field.enum.forEach(element=> {
    //   element.disabled= false;
    // });
    // const selectedKeys = [];
    const mandatoryOptions = this.mandatoryOptions.map(obj => obj.key);
    const selectedOptions = this.selectedOptions.map(obj => obj.key);
    const selected = [...mandatoryOptions, ...selectedOptions];
    // if(this.mandatoryOptions && this.mandatoryOptions.length) {
    //     this.mandatoryOptions.map(data => selectedKeys.push(data.key));
    // this.selectedOptions.forEach(element=> {
    //   const item = this.field.enum.find(j => j.value === element)
    //   if(item) {
    //     this.field.enum.find(j => j.value === element).disabled = true;
    //     selectedKeys.push(item.key)
    //   }
    // })
    this.control.setValue(selected.join(','));
  }

  valudateAndPushData(option: Enum) {
    // const ctrl = new FormControl(option, Validators.required);
    // if (this.selectedOptions.includes(option.) || ctrl.invalid) {
    //   return;
    // }
    this.selectedOptions.push(option);
    this.setOptionList();
    this.searchControl.reset();
  }

  private _defaultData(): any[] {
    // this.selectedOptions = this.control.value ? this.control.value.split(',') : [];
    if (this.field?.enum.length) {
      this.field.enum.forEach(element => {
        element.disabled = !!element?.mandatory;
      });
      if (this.selectedOptions?.length) {
        this.selectedOptions.forEach(element => {
          const field = this.field.enum.find(j => j.key === element.key);
          if (field) {
            field.disabled = true;
          }
        });
      }
    }
    return this.field.enum && this.field.enum.length
      ? this.field.enum.slice()
      : [];
  }

  // private selectValueRequiredActionSctipts(callback) {
  //   if (this.formFieldFosterService.selectValueRequiredActionSctipt.includes(callback)) {
  //     return this.actionScriptName;
  //   }
  //   return '';
  // }

  // private get actionScriptName() {
  //   return this.formFieldFosterService
  //     .findFormGroupField(this.formGroup, 'action_script_name')?.value
  // }

  private _filter(name: string): any[] {
    // const filterValue = name.toLowerCase();
    // this.field.enum.sort((a, b) => a.value.localeCompare(b.value));
    // return this.field.enum.filter(option => option.value.toLowerCase().indexOf(filterValue) === 0);
    const filterValue = name.toString().toLowerCase();
    return filterValue
      ? this.field.enum.filter(
          s => s.value.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
        )
      : this.field.enum;
  }
}
