import { Injectable } from '@angular/core';
import { ProcessExplorer } from './process-explorer.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export type ProcessExplorerState = EntityState<ProcessExplorer>

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'process-explorer' })
export class ProcessExplorerStore extends EntityStore<ProcessExplorerState> {

  constructor() {
    super();
  }

}

