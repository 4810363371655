import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@bli-shared/guards/auth.guard';
import { AppVersionComponent } from './app-version/app-version.component';
import { SlackAckComponent } from './slack/slack-ack/slack-ack.component';
import { SlackActionCallbackComponent } from './slack/slack-action-callback/slack-action-callback.component';
import { FivetranAuthAckComponent } from './fivetran-auth-ack/fivetran-auth-ack.component';
import { SalesforceAuthCallbackComponent } from './salesforce-auth-callback/salesforce-auth-callback.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        m => m.AuthenticationModule
      )
  },
  {
    path: 'version',
    component: AppVersionComponent
  },
  {
    path: 'slack-ack',
    component: SlackAckComponent
  },
  {
    path: 'fivetran-auth-ack',
    component: FivetranAuthAckComponent
  },
  {
    path: 'slack/action/callback',
    component: SlackActionCallbackComponent
  },
  {
    path: 'salesforce/auth-callback',
    component: SalesforceAuthCallbackComponent
  },
  {
    path: 'email',
    loadChildren: () =>
      import(
        './shared/modules/email-link-mapping/email-link-mapping.module'
      ).then(m => m.EmailLinkMappingModule)
  },
  {
    path: 'features',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./product-features/product-features.module').then(
        m => m.ProductFeaturesModule
      )
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
