// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin-bottom: 15px;
}
:host .example-chip-list {
  width: 100%;
}
:host .app-form-fields-foster {
  gap: 20px;
}
:host .app-form-fields-foster .mat-mdc-form-field {
  width: 100%;
  width: 45%;
  margin-right: 10px;
}
:host .error {
  border: 2px solid red;
  border-radius: 4px;
}
:host .error-msg {
  color: red;
  font-size: 12px;
}
:host .mt--20 {
  margin-top: -20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modules/form-fields/components/group-repeatable-foster/group-repeatable-foster.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,SAAA;AAEJ;AADI;EACE,WAAA;EACA,UAAA;EACA,kBAAA;AAGN;AAAE;EACE,qBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,UAAA;EACA,eAAA;AAEJ;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[":host {\n  margin-bottom: 15px;\n  .example-chip-list {\n    width: 100%;\n  }\n  .app-form-fields-foster {\n    gap: 20px;\n    .mat-mdc-form-field {\n      width: 100%;\n      width: 45%;\n      margin-right: 10px;\n    }\n  }\n  .error {\n    border: 2px solid red;\n    border-radius: 4px;\n  }\n  .error-msg {\n    color: red;\n    font-size: 12px;\n  }\n  .mt--20 {\n    margin-top: -20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
