import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Enum, FormField } from '@bli/state/app.model';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { FormFieldFosterService } from '../../services/form-field-foster-service.service';

@Component({
  selector: 'app-autocomplete-foaster',
  templateUrl: './autocomplete-foaster.component.html',
  styleUrls: ['./autocomplete-foaster.component.scss']
})
export class AutocompleteFoasterComponent implements OnInit, OnDestroy {
  @Input() field: FormField;
  @Input() value: string;
  @Input() formGroup!: UntypedFormGroup;
  @Input() control: UntypedFormControl;

  searchControl = new UntypedFormControl();
  filter$: Observable<Enum[]>;
  subscription = new Subscription();
  isLoading = false;

  loaderSkeletonStyle =
    this.formFieldFosterService.formFieldloaderSkeletonStyle;

  constructor(private formFieldFosterService: FormFieldFosterService) {}

  ngOnInit(): void {
    const { callback, callback_dependent_fields = [] } = this.field;
    if (callback && callback_dependent_fields) {
      this._linkCallBacks();
      this._checkIfDisabled();
    }
    if (this.field.enum?.length) {
      this._searchFilter();
    }
    this.searchControl.setValue(this.control?.value);
  }

  private _linkCallBacks() {
    const beforeAPI = () => {
      this.isLoading = true;
    };
    const afterAPI = () => {
      this.isLoading = false;
    };
    const getCallBackAPISub = this.formFieldFosterService
      .getCallBackAPI(
        this.field,
        this.formGroup,
        beforeAPI.bind(this),
        afterAPI.bind(this)
      )
      .subscribe(data => {
        this.field.enum = data?.enum || [];
        this._searchFilter();
        this._setValueOfControls(data);
      });
    this.subscription.add(getCallBackAPISub);
  }

  private _checkIfDisabled() {
    const { callback_dependent_fields = [] } = this.field;
    const sub = this.formFieldFosterService
      .isDisabled(this.formGroup, callback_dependent_fields)
      .subscribe(isDisabled => {
        if (isDisabled) {
          this._toggleSearchDisable(true);
        } else {
          this._toggleSearchDisable(false);
        }
      });
    this.subscription.add(sub);
  }

  private _setValueOfControls(field: FormField) {
    if (field && this.control?.value) {
      const selectedItem = this.formFieldFosterService.getInitialValueOfField(
        field,
        this.control?.value
      );
      this.searchControl.setValue(selectedItem.key);
      this.control.setValue(selectedItem.key);
    }
  }

  private _searchFilter() {
    this.filter$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      map(searchText => this._filter(searchText))
    );
    this.searchControl.setValue(this.control?.value);
    const sub = this.control.valueChanges.subscribe(
      value => !value && this.searchControl.reset()
    );
    this.subscription.add(sub);
  }

  private _toggleSearchDisable(disable: boolean) {
    const { callback_dependent_fields = [] } = this.field;
    disable && callback_dependent_fields.length
      ? this.searchControl.disable()
      : this.searchControl.enable();
  }

  displayWith(option) {
    return typeof option === 'object' ? option?.value : option;
  }

  private _filter(searchText: string) {
    const fields = (searchText && typeof searchText === 'string' && this.field?.enum?.length) ? 
    this.field?.enum.filter(e =>{
      return e.value?.toLowerCase().includes(searchText?.toLowerCase())}) :
    this.field?.enum;
    // Dependent calls should only triggered with proper selection, Commenting this now
    if(fields && fields.length === 0)
      this.control.setValue(searchText);
    return fields;
  }

  resetChildFields() {
    const { children = [] } = this.field;
    children.map(field => {
      this.formGroup?.get(field)?.reset();
    });
  }

  validateDataAgainstAvailableValues() {
    const data = this.field.enum.map(item => item.key);
    if (!data.includes(this.searchControl.value)) {
      this.searchControl.setValue('');
      this.control.setValue('');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
