import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fivetran-auth-ack',
  templateUrl: './fivetran-auth-ack.component.html',
  styleUrls: ['./fivetran-auth-ack.component.scss']
})
export class FivetranAuthAckComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigateByUrl('features/data-connectors');
  }
}
