import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@bli-shared/utils/app-config.module';
import { ProcessAnalyticsQuery } from '@bli/product-features/process-analytics/state';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessExplorerGroupService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private paQuery: ProcessAnalyticsQuery,
    private http: HttpClient
  ) {}

  private navigate = new BehaviorSubject(null);

  getPAState(payload) {
    const { process_analytics_cluster_id } =
      this.paQuery.GetAnalysisClusterData;
    const { process_analysis_id } = this.paQuery.GetAnalysisData;
    payload = {
      ...payload,
      process_analytics_cluster_id,
      process_analysis_id
    };
    const url = `${this.rootUrl}/process_analytics/store/get/`;
    return this.http.post(url, payload);
  }

  setPAState(payload) {
    const { process_analytics_cluster_id } =
      this.paQuery.GetAnalysisClusterData;
    const { process_analysis_id } = this.paQuery.GetAnalysisData;
    payload = {
      ...payload,
      process_analytics_cluster_id,
      process_analysis_id
    };
    const url = `${this.rootUrl}/process_analytics/store/set/`;
    return this.http.post(url, payload);
  }
  set groupNavigate(link) {
    this.navigate.next(link);
  }

  get groupNavigate$() {
    return this.navigate.asObservable();
  }

  get groupNavigateValue() {
    return this.navigate.getValue();
  }

  private get rootUrl(): string {
    return `${this.appConfig.ANAYSIS_API.ROOT}`;
  }
}
