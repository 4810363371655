// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}
:host h5 {
  margin: 0;
  color: rgba(0, 0, 0, 0.54);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modules/form-fields/components/checkbox-group-foster/checkbox-group-foster.component.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,SAAA;EACA,mBAAA;AADF;AAEE;EACE,SAAA;EACA,0BAAA;AAAJ","sourcesContent":["@use 'variables';\n\n:host {\n  flex-direction: column;\n  gap: 10px;\n  margin-bottom: 10px;\n  h5 {\n    margin: 0;\n    color: variables.$light-grey;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
