// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  display: contents;
}
:host ::ng-deep .mat-mdc-form-field-underline {
  width: 100%;
}
:host .title {
  font-size: 24px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modules/form-fields/components/multi-picklist/multi-picklist.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[":host {\n  width: 100%;\n  display: contents;\n\n  ::ng-deep .mat-mdc-form-field-underline {\n    width: 100%;\n  }\n\n  .title {\n    font-size: 24px;\n    font-weight: 500;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
