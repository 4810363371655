import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

type TextPosition = 'before' | 'after';

@Component({
  selector: 'app-popup-search',
  templateUrl: './popup-search.component.html',
  styleUrls: ['./popup-search.component.scss']
})
export class PopupSearchComponent implements OnInit {
  @Input() buttonText;
  @Input() staticTextPosition: TextPosition;
  @Input() buttonStaticText;
  @Input() searchList;
  @Input() enableSearch: boolean;
  @Input() disabled: boolean;
  @Input() isBorder: boolean;
  @Input() width: string;
  @Input() style: string;
  @Output() selectedOption = new EventEmitter();
  control = new UntypedFormControl();
  filteredSearch$: Observable<string[]>;
  opened: boolean;
  selectedItem: string;

  constructor(public element: ElementRef) {}

  ngOnInit() {
    this.customizeElement();
    this.staticTextPosition = this.staticTextPosition || 'after';
    this.buttonStaticText = this.buttonStaticText || '';
    this.filteredSearch$ = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  selected(option) {
    this.buttonText = option;
    this.selectedOption.emit(option);
    // this.control.reset();
  }

  openPanel(event) {
    if (event) {
      this.control.setValue('');
    }
    this.opened = event;
  }

  customizeElement() {
    this.element.nativeElement.firstElementChild.classList.add('custom');
    this.element.nativeElement.firstElementChild.style.cssText = this.style;
  }

  public get buttonTextFull() {
    if (this.staticTextPosition === 'after') {
      return `${this.buttonText} ${this.buttonStaticText}`;
    } else {
      return `${this.buttonStaticText} ${this.buttonText}`;
    }
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.searchList.filter(street =>
      this._normalizeValue(street).includes(filterValue)
    );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}
