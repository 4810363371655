import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthQuery } from '@bli/authentication/state';
import { AppStore } from '@bli/state/app.store';
import { DetectChangeService } from '../../services/detect-change.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthQuery,
    private router: Router,
    private appStore: AppStore,
    private detectChangeService: DetectChangeService,
    private toastrService: ToastrService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(resolve => {
      if (Object.keys(next.data).length !== 0) {
        this.detectChangeService.updateMenu(next.data.isMenu);
      }
      if (this.auth.isAuthenticated()) {
        this.appStore.update({ redirectUrl: '' });
        resolve(true);
        return;
      } else {
        this.auth.refreshAccesstoken().then(res => {
          const redirectUrl = location.href.split(location.host)[1];
          if (redirectUrl.includes('login')) {
            resolve(true);
            return;
          }
          if (!res) {
            this.appStore.update({ redirectUrl });
            this.toastrService.show(
              JSON.stringify({
                type: 'error',
                text: 'Unauthorized Access.. Please Login'
              })
            );
            this.router.navigate(['/']);
            resolve(false);
          } else resolve(true);
        });
      }
      // resolve(true);
    });
  }
}
