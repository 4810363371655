import { Injectable } from '@angular/core';
import { GlobalFiltersStateCore } from './global-filter.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export type GlobalFiltersState = EntityState<GlobalFiltersStateCore>;

// export function createInitialState(): GlobalFiltersState {
//   return {
//     id:guid(),
//     global_filters: [],
//     start_date: '',
//     end_date: '',
//     date_range: {},
//     variant_ids: [],
//     cases: [],
//     stats: {},
//     variant_explorer: {},
//     process_explorer: {},
//     active_page: {},
//     isFilterApplied: false,
//     isEdgeSelected: false
//   };
// }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'global-filters' })
export class GlobalFiltersStore extends EntityStore<
  GlobalFiltersState,
  GlobalFiltersStateCore
> {
  constructor() {
    super();
  }

  saveGlobalFilterData(globalFilter: any) {
    this.add(globalFilter);
  }
}
