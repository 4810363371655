export interface Pagination {
  is_paginated: boolean;
  total_items: number;
  items_per_page: number;
  current_page: number;
  total_pages: number;
  has_next_page: boolean;
  has_prev_page: boolean;
  is_first_page: boolean;
  is_last_page: boolean;
  items: any[];
}
// -----------old-------------------
export interface ProcessAnalytics {
  analysis_cluster_id: string;
  datamodel_id: string;
  owner: string;
  updated_at: string;
  analysis_cluster_name: string;
}
// -----------old end-------------------

export interface ProcessAnalyticsStateCore {
  id:any;
  analysis: any,
  analysis_cluster: any,
  waitingDel: string[];
  waitingClustorDel: string[];
  linkingModelProgress: boolean;
  analysis_cluster_id: string;
  isConformanceOverview: any;
  isProcessModelExists: {
    process_model_id: null
  };
}

export interface CreateCluster {
  name: string;
  description: string;
}
export interface CreateClusterData {
  process_analytics_cluster_id: number;
  unique_id: string;
}

export interface CreateClusterResponse {
  data: CreateClusterData[];
}

export interface UpdateCluster extends CreateCluster {
  process_analytics_cluster_id: number;
}

export interface UpdateClusterResponse {
  data: number[];
}

export interface DeleteCluster {
  process_analytics_cluster_id: number;
}

export interface AnalyticCluster {
  process_analytics_cluster_id: number;
  unique_id: string;
  name: string;
  description: string;
  owner: string;
  user_id: string;
  data_model_id: number;
  is_active: string;
  created_date: string;
  updated_date: string;
}

export interface AnalyticClusterPagination extends Pagination {
  items: AnalyticCluster[];
}

export interface AnalyticClusterResponse {
  data: AnalyticClusterPagination[] | AnalyticCluster[];
}

export interface CreateProcessAnalyticsResponse {
  data: ProcessAnalytics;
}

export interface ProcessAnalyticResponse {
  data: ProcessAnalytics;
}

export interface ProcessAnalyticsResponse {
  data: ProcessAnalytics[];
}

export interface DataModel {
  data_model_id: number;
  name: string;
}

export interface MiningModel {
  name: string;
  bucket_id: number;
  data_model: DataModel[];
}

export interface MiningModelResponse {
  data: MiningModel[];
}

export interface LinkModel {
  process_analytics_cluster_id: number;
  data_model_id: number;
}

export interface LinkModelResponse {
  data: number[];
}

export interface CreateAnalysis {
  process_analytics_cluster_id: number;
  name: string;
  description: string;
}

export interface CreateAnalysisData {
  process_analysis_id: number;
}
export interface CreateAnalysisResponse {
  data: CreateAnalysisData[];
}
export interface UpdateAnalysis extends CreateAnalysis {
  process_analysis_id: number;
}

export interface DeleteAnalysis {
  process_analysis_id: number;
  process_analytics_cluster_id: number;
}

export interface DeleteAnalysisResponse {
  data: number[];
}

export interface UpdateAnalysisResponse {
  data: number[];
}

export interface Analysis {
  process_analysis_id: number;
  unique_id: string;
  name: string;
  description: string;
  owner: string;
  user_id: string;
  is_active: string;
  created_date: string;
  updated_date: string;
}

export interface AnalysisPagination extends Pagination {
  items: Analysis[];
}

export interface AnalysisResponse {
  data: AnalysisPagination[] | Analysis[];
}


export interface SharedAnalytics {
  access_mode: ProcessAnalyticsAccessModes;
  email: string;
  is_editable: string;
}

export type ProcessAnalyticsAccessModes = 'OWNER' | 'READ' | 'EDIT' | 'USE';

export interface PainatedSharedAnalytics extends Pagination {
  items: SharedUsers[];
}

export interface GetSharedAnalyticsResponse extends Response {
  data: PainatedSharedAnalytics[];
}

export interface SharedUsers {
  access_mode: string;
  email: ProcessAnalyticsAccessModes;
  is_editable: string;
}

export interface CloneAnalysisPayload {
  process_analytics_cluster_id: number;
  process_analysis_id: number;
}
