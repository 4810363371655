import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-duration-format';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class UtilityService {
  public static convertToHHMMSS(seconds) {
    if (seconds && seconds > 0) {
      const duration: any = moment.duration(seconds, 'seconds'); // milliseconds
      const formatted = duration.format('Y[Y] M[M] D[D] H[h] m[m] s[s]', {
        largest: 2
      });
      return formatted ? formatted : '0s';
    }
    return '0s';
  }

  public static convertAndRoundToHHMMSS(seconds) {
    if (seconds && seconds > 0) {
      const duration: any = moment.duration(seconds, 'seconds');
      const formatted = duration.format('Y[Y] M[M] D[D] H[h] m[m] s[s]', {
        largest: 2
      });
      return formatted ? formatted : '0s';
    }
    return '0s';
  }

  public static parseToLocalDate(data, key) {
    const parsed = data.map((d: any) => {
      return {
        ...d,
        [key]: moment(d[key]).format('DD-MM-YYYY')
      };
    });
    return parsed;
  }

  public static parseProductivityDetails(prodData) {
    const parsedData = _(prodData)
      .groupBy('date')
      .map((prod, id) => ({
        // name: _.mapValues(prod, (p) => {
        //   return 'p.label';
        // }),
        label: id,
        Passive: _.sumBy(prod, 'passive'),
        Productive: _.sumBy(prod, 'productive'),
        Undefined: _.sumBy(prod, 'undefined'),
        Unproductive: _.sumBy(prod, 'unproductive'),
        total: _.sumBy(prod, (p: any) => {
          return p.passive + p.productive + p.undefined + p.unproductive;
        })
      }))
      .value();
    return parsedData;
  }

  public static parseGroupBy(data, key) {
    const grouped = _.groupBy(data, d => {
      return d[key];
    });
    return grouped;
  }
}
