import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-happy-path',
  templateUrl: './card-happy-path.component.html',
  styleUrls: ['./card-happy-path.component.scss']
})
export class CardHappyPathComponent {
  @Input() happyPath: any;
}
