import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@bli-shared/utils/app-config.module';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ComponentType,
  RevenueOperationDataModelDeailsResponse,
  RevenueOperationDataModelResponse,
  UdpateComponentPriorityPayload
} from './revenue-operation.model';
import { RevenueOperationsQuery } from './revenue-operations.query';
import { ActiveDashboard } from './revenue-operations.store';

@Injectable({ providedIn: 'root' })
export class RevenueOperationsService {
  private metaDataTablesSubject = new BehaviorSubject<any>([]);
  public saveGlobalFilter = new BehaviorSubject<object | boolean | null>(null);
  public loadGlobalFilter = new BehaviorSubject<object | boolean | null>(null);
  public;
  chunks = [];
  parallelRequestCount = 5;
  totalItemPerPage = 10;
  totalPages = 10;
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private revOpsQuery: RevenueOperationsQuery,
    private http: HttpClient
  ) {}

  getRevenueOperatiionStaticDropdown(payload: any) {
    const url = `${this.rootUrl}general/static-drop-down/`;
    return this.http.post<any>(url, payload);
  }

  checkDataModelLinked(): Observable<RevenueOperationDataModelResponse> {
    const payload = this.isCustomDashboard
      ? {
          page_hierarchy_slug:
            this.revOpsQuery.getValue().activeRevOpsCustomDashboard
              .dashboardSlug
        }
      : {};
    return this.http.post<RevenueOperationDataModelResponse>(
      `${this.dashboardBaseUrl}search-project/`,
      payload
    );
  }

  getAttributes(): Observable<RevenueOperationDataModelDeailsResponse> {
    const payload = this.isCustomDashboard
      ? {
          page_hierarchy_slug:
            this.revOpsQuery.getValue().activeRevOpsCustomDashboard
              .dashboardSlug
        }
      : {
          process_analytics_cluster_id: this.analyticClusterId,
          process_analysis_id: this.analysisId
        };

    const url = this.isCustomDashboard
      ? `${this.dashboardBaseUrl}data_model_details/`
      : `${this.paRootUrl}/process_analytics/attribute_filter/data_model_details/`;
    return this.http
      .post<RevenueOperationDataModelDeailsResponse>(url, payload)
      .pipe(
        tap((res: RevenueOperationDataModelDeailsResponse) => {
          if (res.data?.length) {
            const { column_details } = res.data[0];
            this.revOpsQuery.revOpsDataModelColumn = column_details;
          }
        })
      );
  }

  getCaseCountsAndVariants(filter): Observable<any> {
    const url = `${this.paRootUrl}/process_analytics/get-variants-and-cases/`;
    const { process_analysis_id, process_analytics_cluster_id } =
      this.revOpsQuery.getValue().dataModel;
    const payload = {
      process_analysis_id,
      process_analytics_cluster_id,
      include_data: false,
      global_filters: filter
    };
    return this.http.post<any>(url, payload);
  }

  getStatusFiterList() {
    const url = `${this.rootUrl}general/static-drop-down/`;
    // const { process_analysis_id, process_analytics_cluster_id } =
    //   this.revOpsQuery.getValue().dataModel;
    const payload = {
      components: ['list_status_filter']
    };
    return this.http.post<any>(url, payload);
  }

  updatePriority(
    type: ComponentType,
    payload: UdpateComponentPriorityPayload
  ): Observable<any> {
    if (this.activeCustomDashboardPageSlug)
      payload = {
        ...payload,
        ...{ page_hierarchy_slug: this.activeCustomDashboardPageSlug }
      };
    const url = `${this.dashboardBaseUrl}${type}/priority/update/`;
    return this.http.post<any>(url, this.makePayload(payload));
  }

  getAllMetaDataSchemas(): Observable<any> {
    const payload: any = {};
    if (this.activeCustomDashboardPageSlug)
      payload.page_hierarchy_slug = this.activeCustomDashboardPageSlug;
    const rootUrl = this.appConfig.REVOPS_API.ROOT;
    const dashboardBaseUrl =
      Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
        .length === 0
        ? `${rootUrl}/${this.activeDashboard.boardBEUrl}`
        : `${rootUrl}`;
    const url =
      Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
        .length === 0
        ? `${dashboardBaseUrl}${this.appConfig.REVENEW_OPERATION_API.LIST_DATABASE_METADATA_SCHEMA_OLD}`
        : `${dashboardBaseUrl}${this.appConfig.REVENEW_OPERATION_API.LIST_DATABASE_METADATA_SCHEMA}`;
    return this.http.post<any>(url, payload);
  }
  getAllMetaDataSchemaTables(schema: any, schemaIndex: any): any {
    const rootUrl = this.appConfig.REVOPS_API.ROOT;
    const dashboardBaseUrl =
      Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
        .length === 0
        ? `${rootUrl}/${this.activeDashboard.boardBEUrl}`
        : `${rootUrl}`;
    const url =
      Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
        .length === 0
        ? `${dashboardBaseUrl}${this.appConfig.REVENEW_OPERATION_API.LIST_DATABASE_METADATA_TABLES_OLD}`
        : `${dashboardBaseUrl}${this.appConfig.REVENEW_OPERATION_API.LIST_DATABASE_METADATA_TABLES}`;
    const payload: any = {
      schema_name: schema.schema_name,
      items_per_page: 200,
      requested_page: 1
    };
    if (this.activeCustomDashboardPageSlug)
      payload.page_hierarchy_slug = this.activeCustomDashboardPageSlug;
    return this.http.post<any>(url, payload).pipe(
      tap(response => {
        if (response) {
          if (Object.prototype.hasOwnProperty.call(response, 'status')) {
            if (response.status === 'SUCCESS') {
              const items = response.data[0].items;
              items.forEach(element => {
                element.schema_alias = schema.display_value;
                element.schema_name = schema.schema_name;
                element.schema_index = schemaIndex;
              });

              this.metaDataTablesSubject.next(items);
            }
          }
        }
      })
    );
  }
  getAllMetaDataSchemaTablesParallel(schema: any, schemaIndex: any): any {
    this.metaDataTablesSubject.next([]);
    this.chunks = [];
    // const offset = 0;
    let index = 0;
    return new Promise((resolve, reject) => {
      while (index < this.parallelRequestCount) {
        index += 1;
        // work out the chunks that need to be processed and the associated REST method (start, continue or finish)
        this.chunks.push({
          schema_name: schema.schema_name,
          items_per_page: this.totalItemPerPage,
          requested_page: index
        });
      }
      this.chunks.forEach(element => {
        this.getAsyncResult(element, schema, schemaIndex)
          .then()
          .catch(err => {
            reject(err);
          });
      });
    });
  }

  getAsyncResult(payload, schema, schemaIndex: any): any {
    return new Promise((resolve, reject) => {
      // setTimeout(() => {
      this.repeateUntilSuccess(
        this.http,
        payload,
        schema,
        schemaIndex,
        resolve,
        reject
      );
      // }, timeout);
    });
  }
  private repeateUntilSuccess(
    httpClient,
    payload,
    schema,
    schemaIndex: any,
    resolve,
    reject
  ) {
    const rootUrl = this.appConfig.REVOPS_API.ROOT;
    const dashboardBaseUrl =
      Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
        .length === 0
        ? `${rootUrl}/${this.activeDashboard.boardBEUrl}`
        : `${rootUrl}`;
    const url =
      Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
        .length === 0
        ? `${dashboardBaseUrl}${this.appConfig.REVENEW_OPERATION_API.LIST_DATABASE_METADATA_TABLES_OLD}`
        : `${dashboardBaseUrl}${this.appConfig.REVENEW_OPERATION_API.LIST_DATABASE_METADATA_TABLES}`;
    if (this.activeCustomDashboardPageSlug)
      payload.page_hierarchy_slug = this.activeCustomDashboardPageSlug;
    httpClient.post(url, payload).subscribe(
      (response: any) => {
        if (response) {
          if (Object.prototype.hasOwnProperty.call(response, 'status')) {
            if (response.status === 'SUCCESS') {
              const items = response.data[0].items;
              items.forEach(element => {
                element.schema_alias = schema.display_value;
                element.schema_name = schema.schema_name;
                element.schema_index = schemaIndex;
              });

              this.metaDataTablesSubject.next(response.data[0].items);
              if (response.data[0].current_page < this.parallelRequestCount) {
              } else {
                if (
                  response.data[0].current_page <= response.data[0].total_pages
                ) {
                  setTimeout(() => {
                    payload.requested_page += 1;
                    this.repeateUntilSuccess(
                      httpClient,
                      payload,
                      schema,
                      schemaIndex,
                      resolve,
                      reject
                    );
                  }, 1000);
                }
              }
            } else if (response.status === 'ERROR') {
              // Repeat after timeout
              reject(response.data[0].message);
            }
          }
        }
      },
      err => {
        reject(err.error);
      }
    );
  }

  // Save And Load Global Filters Methods
  getGlobaFiltersList() {
    const url = `${this.rootUrl}${this.activeDashboard.boardBEUrl}${
      this.isCustomDashboard ? 'flow/' : ''
    }global-filter/read/`;
    const payload = {
      ...this.dashboardSlugOrScreen
    };
    return this.http.post<any>(url, payload);
  }

  saveGlobalFiltersList(payload: any) {
    payload = {
      ...payload,
      ...this.dashboardSlugOrScreen
    };
    const url = `${this.rootUrl}${this.activeDashboard.boardBEUrl}${
      this.isCustomDashboard ? 'flow/' : ''
    }global-filter/create/`;
    return this.http.post<any>(url, payload);
  }

  updateGlobalFiltersList(payload: any) {
    payload = {
      ...payload,
      ...this.dashboardSlugOrScreen
    };
    const url = `${this.rootUrl}${this.activeDashboard.boardBEUrl}${
      this.isCustomDashboard ? 'flow/' : ''
    }global-filter/update/`;
    return this.http.post<any>(url, payload);
  }
  deleteGlobalFiltersList(payload: any) {
    payload = {
      ...payload,
      ...this.dashboardSlugOrScreen
    };
    const url = `${this.rootUrl}${this.activeDashboard.boardBEUrl}${
      this.isCustomDashboard ? 'flow/' : ''
    }global-filter/delete/`;
    return this.http.post<any>(url, payload);
  }

  makePayload(payload: any) {
    if (!this.activeCustomDashboardPageSlug) {
      return payload;
    }
    // remove screen name
    const { screen_name, ...rest } = payload;
    return {
      ...rest,
      page_hierarchy_slug: this.activeCustomDashboardPageSlug
    };
  }

  get isCustomDashboard(): boolean {
    return !!Object.keys(
      this.revOpsQuery.getValue().activeRevOpsCustomDashboard
    ).length;
  }

  get dashboardSlugOrScreen() {
    if (
      Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
        .length
    ) {
      const { dashboardSlug } =
        this.revOpsQuery.getValue().activeRevOpsCustomDashboard;
      return { page_hierarchy_slug: dashboardSlug };
    }
    return { screen: 'FUNNEL' };
  }

  get metaDataTables$() {
    return this.metaDataTablesSubject.asObservable();
  }

  get saveGlobalFilter$() {
    return this.saveGlobalFilter.asObservable();
  }
  get loadGlobalFilter$() {
    return this.loadGlobalFilter.asObservable();
  }
  private get dashboardBaseUrl() {
    // console.log(this.revOpsQuery.getValue().)
    return Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
      .length === 0
      ? `${this.rootUrl}${this.activeDashboard.boardBEUrl}`
      : `${this.rootUrl}`;
  }

  private get activeDashboard(): ActiveDashboard {
    return this.revOpsQuery.getValue().activeDashboard;
  }

  get paRootUrl(): string {
    return this.appConfig.ANAYSIS_API.ROOT;
  }

  private get rootUrl(): string {
    return `${this.appConfig.REVOPS_API.ROOT}`;
  }

  private get analysisId() {
    return this.revOpsQuery.getValue().dataModel.process_analysis_id;
  }

  private get analyticClusterId() {
    return this.revOpsQuery.getValue().dataModel.process_analytics_cluster_id;
  }

  private get activeCustomDashboardPageSlug(): string {
    return this.revOpsQuery.getValue().activeRevOpsCustomDashboard
      .dashboardSlug;
  }
}
