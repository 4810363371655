import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GlobalFiltersService } from '@bli/core/global-filters/state/global-filters.service';

@Component({
  selector: 'app-hide-activities',
  templateUrl: './hide-activities.component.html',
  styleUrls: ['./hide-activities.component.scss']
})
export class HideActivitiesComponent {
  @Input() hiddenActivities: any = [];
  @Output() applyHideActivity = new EventEmitter<any>();
  activitiesList: any = [];
  isActvityLoading = false;
  pageSize = 100;
  public activitiesColDefs = [
    {
      headerName: 'Activity',
      field: 'value',
      width: 380,
      sorting: true,
      filter: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      enableFilter: true
    }
  ];
  public unSelectedActivity = [];
  public gridApi: any;
  public gridColumnApi: any;

  constructor(private globalFiltersService: GlobalFiltersService) {}

  gridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.initActivities();
  }

  initActivities() {
    this.isActvityLoading = true;
    this.globalFiltersService.getThroughputTimeProcess().subscribe(response => {
      const activitiesList =
        response && response.data && response.data.length ? response.data : [];
      this.activitiesList = [];
      activitiesList.map(activity => {
        if (!this.hiddenActivities.includes(activity.value)) {
          this.activitiesList.push({
            ...activity,
            selected: !this.hiddenActivities.includes(activity.value)
          });
        }
      });
      setTimeout(() => {
        this.selectAllData();
      }, 2000);
    });
  }

  selectAllData() {
    this.gridApi.forEachNode(node => {
      node.setSelected(node.data.selected);
    });
    this.isActvityLoading = false;
  }

  rowClicked(event) {
    event.data.selected = !event.data.selected;
    this.unSelectedActivity = [];
    this.gridApi.forEachNode(node => {
      if (!node.data.selected) {
        this.unSelectedActivity.push(node.data.value);
      }
    });
  }

  onApplyHideActivity() {
    const selectedActivities = [];
    const hiddenActivities = [];
    this.gridApi.getSelectedNodes().map(item => {
      selectedActivities.push(item.data.value);
    });
    this.gridApi.forEachNode(node => {
      if (!selectedActivities.includes(node.data.value)) {
        hiddenActivities.push(node.data.value);
      }
    });
    this.applyHideActivity.next(hiddenActivities);
  }
}
