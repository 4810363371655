import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-other-freq-activities',
  templateUrl: './card-other-freq-activities.component.html',
  styleUrls: ['./card-other-freq-activities.component.scss']
})
export class CardOtherFreqActivitiesComponent {
  @Input() otherFreqActivity: any;
  @Output() getActivity = new EventEmitter();

  onClick() {
    this.getActivity.emit(this.otherFreqActivity.name);
  }
}

// {
//   "name": "T09-4 Process or receive external advice from party 4",
//   "count": 5,
//   "number_of_cases": 5,
//   "perc_of_cases": 0.3486750348675035
// }
