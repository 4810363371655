import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  RouteData,
  LoginMethod,
  FiscalMonthInNumber,
  FiscalMonthFormat
} from '@bli/state/app.model';
import { monthMMM } from '@bli-shared/utils/calendar.utils';

export interface AppState {
  version: any;
  routeData: any;
  loginMethod: LoginMethod;
  baseUrl: string;
  redirectUrl: string;
  domainUrl: string;
  metaData: any[];
  navList: any[];
  fiscalYear: any;
  fiscalMonthMap: Map<FiscalMonthInNumber, FiscalMonthFormat> | null;
  currency: any;
  userNotification: any;
  modules: any[];
  selectedNav: {
    name: string;
    permission: 'R' | 'W' | 'RW' | 'WR';
  };
}

export function createInitialState(): AppState {
  return {
    version: '',
    routeData: '',
    loginMethod: 'email',
    baseUrl: '',
    redirectUrl: '',
    domainUrl: '',
    metaData: null,
    navList: null,
    fiscalYear: {},
    fiscalMonthMap: null,
    currency: {},
    userNotification: {},
    modules: null,
    selectedNav: {
      name: '',
      permission: 'RW'
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app' })
export class AppStore extends Store<AppState> {
  constructor() {
    super(createInitialState());
  }

  saveRouteData(routeData: RouteData) {
    this.update({ routeData });
  }

  saveNavList(navData: any) {
    this.update({ navList: navData });
  }

  saveMenuModules(modules: any) {
    this.update({ modules });
  }

  saveTenantCurrency(currency: any) {
    this.update({ currency });
  }

  savefiscalYear(fiscalYear: any) {
    this.update({ fiscalYear });
  }

  setFiscalMonthMap(fiscalStartMonth: number) {
    const fiscalMonthMap = new Map<FiscalMonthInNumber, FiscalMonthFormat>();
    const maxMonth = 12;
    for (let i = 1; i <= maxMonth; i++) {
      const monthNumber = (fiscalStartMonth - 1) % maxMonth;
      const month = monthMMM[monthNumber] as FiscalMonthFormat;
      fiscalMonthMap.set(i as FiscalMonthInNumber, month);
      fiscalStartMonth++;
    }
    this.update({ fiscalMonthMap });
  }

  updateUserNotification(userNotification: any) {
    this.update({ userNotification });
  }
}
