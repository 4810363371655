import { Component, OnInit, Input } from '@angular/core';
import { ChartType } from 'chart.js';
import { SCSS_VARS } from '@bli/scss-variables.generated';

@Component({
  selector: 'app-donut-graph',
  templateUrl: './donut-graph.component.html',
  styleUrls: ['./donut-graph.component.scss']
})
export class DonutGraphComponent implements OnInit {
  @Input() graphData: any[];
  public lineChartLegend: any;
  public doughnutChartLabels: string[] = [
    'Download Sales',
    'In-Store Sales',
    'Mail-Order Sales'
  ];
  public doughnutChartData: any;
  public doughnutChartType: ChartType = 'doughnut';
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    }
  };
  public lineChartColors: any[] = [
    {
      backgroundColor: [
        SCSS_VARS['$brand-primary'],
        SCSS_VARS['$lightest-grey']
      ]
    }
  ];

  

  ngOnInit() {
    this.doughnutChartData = [this.graphData, [], [], []];
  }
}
