import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubHeaderCardComponent } from './sub-header-card.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [SubHeaderCardComponent],
  imports: [CommonModule, MatCardModule],
  exports: [SubHeaderCardComponent]
})
export class SubHeaderCardModule {}
