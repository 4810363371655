import { Component, Input } from '@angular/core';
import { FormField } from '../../../../../state/app.model';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-fields-foster',
  templateUrl: './form-fields-foster.component.html',
  styleUrls: ['./form-fields-foster.component.scss']
})
export class FormFieldsFosterComponent {
  @Input() formFields: FormField[];
  @Input() formGroup: UntypedFormGroup;
  @Input() data: any;
}
