import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bar-chart-with-brush',
  // encapsulation: ViewEncapsulation.None,
  templateUrl: './bar-chart-with-brush.component.html',
  styleUrls: ['./bar-chart-with-brush.component.scss']
})
export class BarChartWithBrushComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() BarData: Array<any>;
  @Input() startEndId: Array<any>;

  @Output() barChartBurshChanged = new EventEmitter<string>();

  xScale: any;
  brushX: any;

  ngOnInit() {
    this.initBarChart();
  }

  ngOnChanges(changes) {
    if (changes.startEndId.previousValue) {
      this.validateRange();
      this.setBrushPosition(this.startEndId, this.xScale, this.brushX);
    }
    if (changes.BarData) {
      this.initBarChart();
    }
  }

  ngOnDestroy() {
    d3.select(window).on('resize', null);
  }

  validateRange() {
    if (this.startEndId.length === 2) {
      if (this.startEndId[0] < 1) {
        this.startEndId[0] = 1;
      }
      if (this.startEndId[0] > this.BarData.length) {
        this.startEndId[0] = this.BarData.length;
      }
      if (this.startEndId[1] < 1) {
        this.startEndId[1] = 1;
      }
      if (this.startEndId[1] > this.BarData.length) {
        this.startEndId[1] = this.BarData.length;
      }
      if (this.startEndId[0] > this.startEndId[1]) {
        this.startEndId[0] = this.startEndId[1];
      }
      return;
    }
    this.startEndId = [1, 1];
  }

  initBarChart() {
    d3.select('#bar-chart-with-brush').html('');
    const padding = 20;
    const width =
      parseInt(d3.select('#bar-chart-with-brush').style('width'), 0) - padding;
    const height =
      parseInt(d3.select('#bar-chart-with-brush').style('height'), 0) - padding;

    this.validateRange();

    const data = this.BarData;
    let startEndId = this.startEndId;

    this.xScale = d3
      .scaleBand()
      .domain(data.map((d: any) => d.id))
      .range([0, width])
      .padding(0.1);
    if (data.length) {
      this.xScale.invert = (() => {
        const domain = this.xScale.domain();
        const range = this.xScale.range();
        const scale = d3.scaleQuantize().domain(range).range(domain);
        return x => {
          return scale(x);
        };
      })();
    }

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.count)])
      .range([0, height]);

    const svg = d3
      .select('#bar-chart-with-brush')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${padding * 2}, 4)`);
    const div = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', d => {
        return this.xScale(d.id);
      })
      .attr('width', this.xScale.bandwidth())
      .attr('y', (d: any) => {
        return yScale(d.count);
      })
      .attr('height', (d: any) => {
        return height - yScale(d.count);
      })
      .style('opacity', 0.1)
      .attr('fill', '#00c1d4')
      .on('mouseover', d => {
        div.transition().duration(200).style('opacity', 0.9);
        div
          .html('<br/> Enters') // + d.varient_id + ' ' + d.activity_count + ' ' + d.throughput_time
          .style('left', d3.event.pageX + 'px')
          .style('top', d3.event.pageY - 28 + 'px');
      })
      .on('mouseout', d => {
        div.transition().duration(500).style('opacity', 0);
      });

    const xAxis = d3.axisBottom(this.xScale).tickFormat((d: any, index) => {
      return data[index].label;
    });
    svg
      .append('g')
      .classed('bb-x-axis', true)
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);

    const yAxis = d3.axisLeft(yScale).tickFormat((d: any, index) => {
      return d;
    });
    svg.append('g').classed('bb-y-axis', true).call(yAxis);

    this.brushX = d3
      .brushX()
      .extent([
        [0, 0],
        [width, height]
      ])
      .on('end', brushEnded);

    svg.append('g').attr('class', 'bb-brush').call(this.brushX);
    // Removed to avoid the inital load
    // .call(agThis.setBrushPosition.bind(this, startEndId, agThis.xScale, agThis.brushX));

    function brushEnded() {
      if (!d3.event.sourceEvent) {
        return;
      }
      if (!d3.event.selection) {
        return;
      }
      startEndId = d3.event.selection.map(this.xScale.invert);
      this.setBrushPosition(startEndId, this.xScale, this.brushX, true);
    }

    // Responsive behavior
    function resizeBarBrushChart() {
      if (!this.BarData.length) {
        return;
      }
      if (!d3.select('#bar-chart-with-brush').node()) {
        return;
      }
      const cWidth =
        parseInt(d3.select('#bar-chart-with-brush').style('width'), 0) -
        padding;
      const cHeight =
        parseInt(d3.select('#bar-chart-with-brush').style('height'), 0) -
        padding;

      // Update the axis and text with the new scale
      this.xScale.range([0, cWidth]).padding(0.1);

      this.xScale.invert = (() => {
        const domain = this.xScale.domain();
        const range = this.xScale.range();
        const scale = d3.scaleQuantize().domain(range).range(domain);
        return x => {
          return scale(x);
        };
      })();

      svg
        .select('.bb-x-axis')
        .call(xAxis)
        .attr('transform', 'translate(0,' + cHeight + ')')
        .select('.label')
        .attr(
          'transform',
          'translate(' + cWidth / 2 + ',' + padding / 1.5 + ')'
        );

      yScale.range([cHeight, 0]);

      svg.select('.bb-y-axis').call(yAxis);

      // Force D3 to recalculate and update the bar
      svg
        .selectAll('.bar')
        .attr('x', (d: any) => {
          return this.xScale(d.id);
        })
        .attr('width', this.xScale.bandwidth())
        .attr('y', (d: any) => {
          return yScale(d.count);
        })
        .attr('height', (d: any) => {
          return cHeight - yScale(d.count);
        })
        .attr('class', 'bar');

      // Debounce Method - Brush will reset when window resize stoped.
      let resizeTimer;
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        this.brushX.extent([
          [0, 0],
          [cWidth, cHeight]
        ]);
        d3.select('.bb-brush').call(this.brushX);
        this.setBrushPosition(startEndId, this.xScale, this.brushX);
      }, 250);
    }
    d3.select(window).on('resize', resizeBarBrushChart);
    setTimeout(() => {
      resizeBarBrushChart();
    }, 250);
  }

  setBrushPosition(idRange, xScale, brushX, dragEnd = false) {
    const brushStartPos = idRange.map(xScale)[0];
    const brushEndPos = idRange.map(xScale)[1] + xScale.bandwidth();
    d3.selectAll('rect.bar').style('opacity', (d, i, n) => {
      const barX = d3.select(n[i]).attr('x');
      return barX >= brushStartPos && barX <= brushEndPos ? '1' : '.2';
    });
    if (dragEnd) {
      this.startEndId = idRange;
      this.barChartBurshChanged.emit(idRange);
      // this.barChartBurshChanged.next(idRange);
    }
    if (brushX && brushStartPos && brushEndPos) {
      d3.select('.bb-brush')
        .transition()
        .call(brushX.move, [brushStartPos, brushEndPos]);
    }
  }
}
