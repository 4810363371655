// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .rectangle:hover {
  opacity: 0.6;
}
:host ::ng-deep .brush-x .selection {
  stroke-dasharray: 10px;
  stroke: black;
  fill: rgba(0, 0, 0, 0);
  stroke-width: 3px;
}

.bar-chart-with-brush-wrapper .bc-container {
  position: absolute;
  padding: 30px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.bar-chart-with-brush-wrapper #svg-container {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
}
.bar-chart-with-brush-wrapper .x-axis-title {
  font-size: 12px;
  position: absolute;
  bottom: 40px;
}
.bar-chart-with-brush-wrapper .y-axis-title {
  font-size: 12px;
  position: absolute;
  left: -25px;
  transform: rotate(-90deg);
  top: 140px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bar-chart-with-brush-v1/bar-chart-with-brush-v1.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AAEE;EACE,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AAAJ;;AAIC;EACC,kBAAA;EACA,aAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;AADF;AAGC;EACC,WAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;AADF;AAGC;EACC,eAAA;EACE,kBAAA;EACA,YAAA;AADJ;AAGC;EACC,eAAA;EACE,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,UAAA;AADJ","sourcesContent":[":host {\n  .rectangle:hover {\n    opacity: 0.6;\n  }\n  ::ng-deep .brush-x .selection {\n    stroke-dasharray: 10px;\n    stroke: black;\n    fill: rgba(0, 0, 0, 0);\n    stroke-width: 3px;\n  }\n}\n.bar-chart-with-brush-wrapper {\n\t.bc-container {\n\t\tposition: absolute;\n\t\tpadding: 30px;\n\t\tleft: 0;\n\t\tright: 0;\n\t\ttop: 0;\n\t\tbottom: 0;\n\t}\n\t#svg-container {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tleft: 0;\n\t\tright: 0;\n\t}\n\t.x-axis-title {\n\t\tfont-size: 12px;\n    position: absolute;\n    bottom: 40px;\n\t}\n\t.y-axis-title {\n\t\tfont-size: 12px;\n    position: absolute;\n    left: -25px;\n    transform: rotate(-90deg);\n    top: 140px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
