import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalFiltersStore } from './global-filters.store';
import { GlobalFiltersQuery } from './global-filters.query';
import { ProcessAnalyticsQuery } from '../../../product-features/process-analytics/state';
import { Observable, throwError, Subject } from 'rxjs';
import { tap, catchError, takeUntil } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@bli-shared/utils/app-config.module';
import { SnackBarStore } from '@bli-shared/components/snack-bar/state';
import {
  RevenueOperationsQuery,
  ActiveDashboard
} from '@bli/product-features/revenue-operations/state';

@Injectable({ providedIn: 'root' })
export class GlobalFiltersService {
  throughptTimeProcess$: Observable<any> = new Observable<any>();
  constructor(
    private globalFiltersStore: GlobalFiltersStore,
    private globalFilterQuery: GlobalFiltersQuery,
    private http: HttpClient,
    private snackBarStore: SnackBarStore,
    private processAnalyticsQuery: ProcessAnalyticsQuery,
    private revOpsQuery: RevenueOperationsQuery,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {}

  private cancelAttrubuteRequest$ = new Subject<void>();
  private cancelReworkStatsRequest$ = new Subject<void>();

  public isRevOps = false;

  updateGlobalFilterPayload(payload: any) {
    const gFilter = this.getGlobalFilter();

    if (
      !gFilter.global_filters ||
      (gFilter.global_filters && !gFilter.global_filters.length)
    ) {
      payload.variant_ids = null;
      payload.cases = undefined;
    }

    if (payload.variant_ids && !payload.variant_ids.length) {
      payload.cases = undefined;
    }

    if (
      Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
        .length
    ) {
      delete payload.variant_ids;
    }
    return payload;
  }

  getReworkFilterStats(reqPayload) {
    this.globalFiltersStore.setLoading(true);
    const gFilter = this.getGlobalFilter();
    let payload = {
      ...reqPayload,
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId,
      variant_ids: gFilter.variant_ids || [],
      cases: gFilter.cases ? gFilter.cases : undefined
    };

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/rework_filter/stats/`;
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.globalFiltersStore.setLoading(false);
      }),
      catchError(err => {
        this.globalFiltersStore.setLoading(false);
        return throwError(err);
      }),
      takeUntil(this.onCancelReworkStatsRequest())
    );
  }

  public cancelReworkStatsRequest() {
    this.cancelReworkStatsRequest$.next();
  }

  public onCancelReworkStatsRequest() {
    return this.cancelReworkStatsRequest$.asObservable();
  }

  getReworkFilterGraph(reqPayload): Observable<any> {
    this.globalFiltersStore.setLoading(true);
    const gFilter = this.getGlobalFilter();
    let payload = {
      ...reqPayload,
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId,
      variant_ids: gFilter.variant_ids || [],
      cases: gFilter.cases ? gFilter.cases : undefined
    };

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/rework_filter/histogram/`;
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.globalFiltersStore.setLoading(false);
      }),
      catchError(err => {
        this.globalFiltersStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getCropSelectionStats(reqPayload): Observable<any> {
    this.globalFiltersStore.setLoading(true);
    const gFilter = this.getGlobalFilter();
    let payload = {
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId,
      variant_ids: gFilter.variant_ids || [],
      cases: gFilter.cases ? gFilter.cases : undefined,
      ...reqPayload
    };

    payload = this.updateGlobalFilterPayload(payload);

    return this.http
      .post<any>(
        `${this.rootUrl}/process_analytics/crop_filter/stats/`,
        payload
      )
      .pipe(
        tap(response => {
          const data = response.data;
          this.globalFiltersStore.setLoading(false);
          return data;
        }),
        catchError(err => {
          this.globalFiltersStore.setLoading(false);
          return throwError(err);
        })
      );
  }

  getProcessExplorerLayout(reqPayload: any): Observable<string> {
    const gFilter = this.getGlobalFilter();
    let payload = {
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId,
      variant_ids: gFilter.variant_ids || [],
      cases: gFilter.cases ? gFilter.cases : undefined,
      ...reqPayload
    };

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/crop_filter/overview/`;
    return this.http.post<string>(url, payload).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  updateCropSelection(payload) {
    this.globalFiltersStore.setLoading(true);
    const url = `${this.globalFilterUrl}/crop_selection/`;
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.globalFiltersStore.setLoading(false);
      }),
      catchError(err => {
        this.globalFiltersStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getThroughputTimeProcess(): Observable<any> {
    this.globalFiltersStore.setLoading(true);
    const payload = {
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId
    };

    return this.http
      .post<any>(`${this.rootUrl}/process_analytics/list_activities/`, payload)
      .pipe(
        tap(response => {
          const data = response.data;
          this.globalFiltersStore.setLoading(false);
          return data;
        }),
        catchError(err => {
          this.globalFiltersStore.setLoading(false);
          return throwError(err);
        })
      );
  }

  getThroughputHistogram(reqPayload) {
    this.globalFiltersStore.setLoading(true);
    const gFilter = this.getGlobalFilter();
    let payload = {
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId,
      variant_ids: gFilter.variant_ids || [],
      cases: gFilter.cases ? gFilter.cases : undefined,
      ...reqPayload
    };

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/throughput_time_filter/histogram/`;
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.globalFiltersStore.setLoading(false);
      }),
      catchError(err => {
        this.globalFiltersStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getThroughputStates(reqPayload): Observable<any> {
    this.globalFiltersStore.setLoading(true);
    const gFilter = this.getGlobalFilter();
    let payload = {
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId,
      variant_ids: gFilter.variant_ids || [],
      cases: gFilter.cases ? gFilter.cases : undefined,
      ...reqPayload
    };

    payload = this.updateGlobalFilterPayload(payload);

    return this.http
      .post<any>(
        `${this.rootUrl}/process_analytics/throughput_time_filter/stats/`,
        payload
      )
      .pipe(
        tap(response => {
          const data = response.data;
          this.globalFiltersStore.setLoading(false);
          return data;
        }),
        catchError(err => {
          this.globalFiltersStore.setLoading(false);
          return throwError(err);
        })
      );
  }

  getAttributes() {
    this.globalFiltersStore.setLoading(true);
    const payload = {
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId
    };

    const url = `${this.rootUrl}/process_analytics/attribute_filter/data_model_details/`;
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.globalFiltersStore.setLoading(false);
      }),
      catchError(err => {
        this.globalFiltersStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getAttributesColumnValue(reqPayload) {
    this.globalFiltersStore.setLoading(true);
    const gFilter = this.getGlobalFilter();
    let payload = {
      ...this.dataModelPayload,
      variant_ids: gFilter.variant_ids || [],
      cases: gFilter.cases || undefined,
      ...reqPayload
    };

    payload = this.updateGlobalFilterPayload(payload);
    let url: any = '';
    if (
      Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
        .length
    )
      url = `${this.appConfig.REVOPS_API.ROOT}${this.activeDashboard.boardBEUrl}flow/values_from_data_model_column/`;
    else
      url = `${this.rootUrl}/process_analytics/attribute_filter/values_from_data_model_column/`;

    return this.http.post(url, payload).pipe(
      tap(() => {
        this.globalFiltersStore.setLoading(false);
      }),
      catchError(err => {
        this.globalFiltersStore.setLoading(false);
        return throwError(err);
      }),
      takeUntil(this.onCancelAttrubuteRequest())
    );
  }

  public cancelAttrubuteRequest() {
    this.cancelAttrubuteRequest$.next();
  }

  public onCancelAttrubuteRequest() {
    return this.cancelAttrubuteRequest$.asObservable();
  }

  getAttributeStats(reqPayload) {
    this.globalFiltersStore.setLoading(true);
    const gFilter = this.getGlobalFilter();
    let payload = {
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId,
      variant_ids: this.getGlobalFilter().variant_ids || [],
      cases: gFilter.cases || undefined,
      ...reqPayload
    };

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/attribute_filter/stats/`;
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.globalFiltersStore.setLoading(false);
      }),
      catchError(err => {
        this.globalFiltersStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getProcessFlowStats(reqPayload) {
    this.globalFiltersStore.setLoading(true);
    const gFilter = this.getGlobalFilter();
    let payload = {
      ...reqPayload,
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId,
      variant_ids: gFilter.variant_ids || [],
      cases: gFilter.cases ? gFilter.cases : undefined
    };

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/process_flow/stats/`;
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.globalFiltersStore.setLoading(false);
      }),
      catchError(err => {
        this.globalFiltersStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getActivityStats(activityFilter) {
    this.globalFiltersStore.setLoading(true);
    const gFilter = this.getGlobalFilter();
    let payload = {
      activity_filter: activityFilter,
      process_analytics_cluster_id: this.analyticClusterId,
      process_analysis_id: this.analysisId,
      variant_ids: gFilter.variant_ids || [],
      cases: gFilter.cases ? gFilter.cases : undefined
    };

    payload = this.updateGlobalFilterPayload(payload);

    const url = `${this.rootUrl}/process_analytics/ativity_filter/stats/`;
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.globalFiltersStore.setLoading(false);
      }),
      catchError(err => {
        this.globalFiltersStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  manageTimeLineFilter(filters) {
    if (filters.start_date === filters.end_date) {
      filters.end_date = '';
    }
  }

  getGlobalDateRange(): any {
    const filters = { ...this.globalFilterQuery.getGlobalFilterData };
    // const filters = {...this.globalFiltersStore.getValue()};
    return filters.date_range;
  }

  getGlobalFilter(): any {
    const filters = { ...this.globalFilterQuery.getGlobalFilterData };
    // const filters = {...this.globalFiltersStore.getValue()};
    // delete filters.date_range;
    this.manageTimeLineFilter(filters);
    const {
      global_filters,
      start_date,
      end_date,
      variant_ids,
      cases,
      stats,
      variant_explorer,
      process_explorer,
      active_page,
      isFilterApplied,
      isEdgeSelected
    } = filters;

    const reqData = {
      global_filters,
      start_date,
      end_date,
      variant_ids,
      cases,
      stats,
      variant_explorer,
      process_explorer,
      active_page,
      isFilterApplied,
      isEdgeSelected
    };
    return reqData;
  }

  getGlobalFilterPayload() {
    // const filters = {...this.globalFiltersStore.getValue()};
    const filters = { ...this.globalFilterQuery.getGlobalFilterData };
    // this.manageTimeLineFilter(filters);
    let globalFilters: any = null;
    if (filters.global_filters) {
      globalFilters = JSON.parse(JSON.stringify(filters.global_filters));
      globalFilters.map((filterObj: any) => {
        delete filterObj.name;
      });
    }

    return globalFilters;
  }

  saveGlobalFilterData(globalFilter) {
    // this.globalFiltersStore.saveGlobalFilterData(globalFilter);
    this.globalFilterQuery.updateGlobalFilterData = globalFilter;
  }

  resetGlobalfilter() {
    this.globalFiltersStore.reset();
  }

  get globalFilterUrl() {
    return `${this.baseUrl}global_filter/`;
  }

  get baseUrl() {
    return `${this.analyticsUrl}${this.analyticClusterId}/analysis/${this.analysisId}/`;
  }

  get analyticsUrl(): string {
    return `${this.rootUrl}/${this.appConfig.ANAYSIS_API.CLUSTER}`;
  }

  get rootUrl(): string {
    return this.appConfig.ANAYSIS_API.ROOT;
  }

  get analysisId(): string {
    return this.isRevOps
      ? this.revOpsQuery.getValue().dataModel.process_analysis_id
      : this.processAnalyticsQuery.GetAnalysisData
        ? this.processAnalyticsQuery.GetAnalysisData.analysis_id
        : null;
    //  this.processAnalyticsQuery.getValue().analysis.analysis_id ;
  }

  get analyticClusterId(): any {
    return this.isRevOps
      ? this.revOpsQuery.getValue().dataModel.process_analytics_cluster_id
      : this.processAnalyticsQuery.GetAnalysisClusterData
          .process_analytics_cluster_id;
    //  this.processAnalyticsQuery.getValue().analysis_cluster.process_analytics_cluster_id ;
  }

  private get activeDashboard(): ActiveDashboard {
    return this.revOpsQuery.getValue().activeDashboard;
  }

  get dataModelPayload() {
    if (
      Object.keys(this.revOpsQuery.getValue().activeRevOpsCustomDashboard)
        .length
    ) {
      const { dashboardSlug } =
        this.revOpsQuery.getValue().activeRevOpsCustomDashboard;
      return { page_hierarchy_slug: dashboardSlug };
    }
    const { process_analytics_cluster_id, process_analysis_id } =
      this.revOpsQuery.getValue().dataModel;
    return { process_analytics_cluster_id, process_analysis_id };
  }
}
