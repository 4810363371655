import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-new-data-bucket',
  templateUrl: './new-data-bucket.component.html',
  styleUrls: ['./new-data-bucket.component.scss']
})
export class NewDataBucketComponent {
  public createBucket: boolean;
  public bucketName = new UntypedFormControl();
  public addData: boolean;
  public uploadFile: boolean;
}
