import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { AppQuery } from '@bli/state/app.query';
import {
  AvailabelIdentityProvider,
  IdentityProviders,
  SSOErrors
} from './auth-sso.model';
import { SkipInterceptorHttpClientSrevice } from '@bli/services/skip-interceptor-http-client.service';
import { AuthQuery } from './auth.query';
import { AuthStore } from './auth.store';
import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@bli/state/app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthSSOService {
  private _identityProviders$: BehaviorSubject<IdentityProviders> =
    new BehaviorSubject<IdentityProviders>(null);
  private storage = window.localStorage;
  private headers = new HttpHeaders().set(
    'Content-Type',
    'application/x-www-form-urlencoded'
  );
  isLoggedInUsingSSO = 'isSSOLogin';
  ssoDetails = {
    Google: {
      icon: 'assets/auth-sso/google.png',
      loading: false
    }
  };

  SSOErrorDetails = {
    invalid_request: 'Request Failed. Contact admin.',
    invalid_client: 'Authentication Failed. Contact admin.',
    invalid_grant: 'Login Expired or Denied',
    unauthorized_client: 'Access denied.',
    unsupported_grant_type: 'SSO is not enabled.'
  };

  constructor(
    private appService: AppService,
    private authStore: AuthStore,
    private authQuery: AuthQuery,
    private appQuery: AppQuery,
    private toastrService: ToastrService,
    private skipInterceptorSrevice: SkipInterceptorHttpClientSrevice
  ) {}

  configSSO(config) {
    const { identity_providers } = config;
    this._identityProviders$.next(identity_providers);
  }

  resolveProvider(provider: AvailabelIdentityProvider) {
    this.storage.setItem(this.isLoggedInUsingSSO, 'true');
    const { ProviderDetails } = this._identityProviders$.getValue()[provider];
    const { authorize_scopes } = ProviderDetails;
    const { clientId } = this.authQuery.getUserData;
    const urlParams =
      `?identity_provider=${provider}` +
      `&client_id=${clientId}` +
      `&redirect_uri=${this.redirectUrl}` +
      `&scope=${authorize_scopes}` +
      `&response_type=code`;
    // reset loading
    this.ssoDetails[provider].loading = false;
    window.location.href = `${this.appQuery.getDomainUrl}/oauth2/authorize${urlParams}`;
  }

  getToken(code: string) {
    const { clientId } = this.authQuery.getUserData;

    const payload = {
      grant_type: 'authorization_code',
      client_id: clientId,
      code,
      redirect_uri: this.redirectUrl
    };
    this.authStore.setLoading(true);
    this.appService.setLoginMethod('sso');
    return this.skipInterceptorSrevice
      .post(
        `${this.appQuery.getDomainUrl}/oauth2/token`,
        this._convertObjectToString(payload, '&'),
        this.headers
      )
      .pipe(
        tap(res => {
          const userSession = this._getCognitoUserSession(res);
          this.authQuery.login(userSession);
        }),
        catchError(err => {
          this.authStore.setLoading(false);
          this.toastrService.show(
            JSON.stringify({ type: 'error', text: err?.error?.error })
          );
          return throwError(err);
        })
      );
  }

  ssoError(error: SSOErrors) {
    return this.SSOErrorDetails[error];
  }

  private _getCognitoUserSession(authResult) {
    const payload = {
      IdToken: authResult.id_token,
      AccessToken: authResult.access_token,
      RefreshToken: authResult.refresh_token
    };
    const idToken = new CognitoIdToken(payload);
    const accessToken = new CognitoAccessToken(payload);
    const refreshToken = new CognitoRefreshToken(payload);

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken
    };

    const username = sessionData.IdToken.payload['cognito:username'];
    const session = new CognitoUserSession(sessionData);
    this.cacheTokens(username, session);
    return session;
  }

  revokeSSO(token: string) {
    if (this.storage.getItem(this.isLoggedInUsingSSO) !== 'true') {
      return of(false);
    }
    const { clientId } = this.authQuery.getUserData;
    const { domainUrl } = this.appQuery.getValue();

    const headers = new HttpHeaders()
      .set('Host', domainUrl)
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/x-www-form-urlencoded');

    // this.authStore.setLoading(true);
    this.appService.setLoginMethod('sso');
    const payload = {
      client_id: clientId,
      token
    };
    return this.skipInterceptorSrevice
      .post(
        `${this.appQuery.getDomainUrl}/oauth2/revoke`,
        this._convertObjectToString(payload, '&'),
        headers
      )
      .pipe(
        tap(() => this.storage.setItem(this.isLoggedInUsingSSO, 'false')),
        catchError(err => {
          this.authStore.setLoading(false);
          this.toastrService.show(
            JSON.stringify({ type: 'error', text: err?.error?.error })
          );
          return throwError(err);
        })
      );
  }

  /**
   * This is used to save the session tokens to local storage
   * @returns {void}
   */
  cacheTokens(username, signInUserSession) {
    const { clientId } = this.authQuery.getUserData;
    const keyPrefix = `CognitoIdentityServiceProvider.${clientId}`;
    const idTokenKey = `${keyPrefix}.${username}.idToken`;
    const accessTokenKey = `${keyPrefix}.${username}.accessToken`;
    const refreshTokenKey = `${keyPrefix}.${username}.refreshToken`;
    const clockDriftKey = `${keyPrefix}.${username}.clockDrift`;
    const lastUserKey = `${keyPrefix}.LastAuthUser`;
    this.storage.setItem(
      idTokenKey,
      signInUserSession.getIdToken().getJwtToken()
    );
    this.storage.setItem(
      accessTokenKey,
      signInUserSession.getAccessToken().getJwtToken()
    );
    this.storage.setItem(
      refreshTokenKey,
      signInUserSession.getRefreshToken().getToken()
    );
    this.storage.setItem(clockDriftKey, `${signInUserSession.getClockDrift()}`);
    this.storage.setItem(lastUserKey, username);
  }

  ssoLogout() {
    const { clientId } = this.authQuery.getUserData;

    // logout uri payload
    const payload = {
      client_id: clientId,
      logout_uri: this.logoutUrl
      // scope: 'openid+profile+aws.cognito.signin.user.admin'
    };

    // redirect uri payload
    // const payload = {
    //   response_type: 'code',
    //   client_id: clientId,
    //   logout_uri: this.logoutUrl,
    //   redirect_uri: this.redirectUrl,
    //   scope: 'openid+profile'
    // };
    this.authStore.setLoading(true);
    window.location.href = `${
      this.appQuery.getDomainUrl
    }/logout?${this._convertObjectToString(payload, '&')}`;
    // return this.skipInterceptorSrevice.get(`${this.appQuery.getDomainUrl}/logout?${this._convertObjectToString(payload, '&')}`)
    // // return this.skipInterceptorSrevice.get(`${this._domain}/oauth2/revoke?${this._convertObjectToString(payload, '&')}`)
    //   .pipe(
    //     catchError(err => {
    //       this.authStore.setLoading(false);
    //       this.toastrService.show(JSON.stringify({ type: 'error', text: err?.error?.error }));
    //       return throwError(err);
    //     })
    //   );
  }

  private _convertObjectToString(object: object, delimiter = '') {
    return Object.keys(object).reduce((prev, curr) => {
      return `${prev ? `${prev}${delimiter}` : ''}${
        curr ? `${curr}=${object[curr]}` : ''
      }`;
    }, '');
  }

  get identityProviders$() {
    return this._identityProviders$.asObservable();
  }

  get redirectUrl() {
    if (
      location.hostname === 'localhost' ||
      location.hostname === '127.0.0.1'
    ) {
      return `https://localhost:4300/sso-login`;
    }
    const { getBaseUrl } = this.appQuery;
    return `${getBaseUrl}sso-login`;
  }

  get logoutUrl() {
    if (
      location.hostname === 'localhost' ||
      location.hostname === '127.0.0.1'
    ) {
      return `https://localhost:4300/login`;
    }
    const { getBaseUrl } = this.appQuery;
    return `${getBaseUrl}login`;
  }
}
