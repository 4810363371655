import { Component } from '@angular/core';
import { PEGroupNavigation } from '../../pe-group-navigation';
import { ProcessExplorerGroupService } from '../../services/process-explorer-group.service';

@Component({
  selector: 'app-process-explorer-empty-group',
  templateUrl: './process-explorer-empty-group.component.html',
  styleUrls: ['./process-explorer-empty-group.component.scss']
})
export class ProcessExplorerEmptyGroupComponent {
  addLink = PEGroupNavigation[0].children[0];
  constructor(public service: ProcessExplorerGroupService) {}
}
