import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Enum, FormField } from '@bli/state/app.model';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-single-picklist',
  templateUrl: './single-picklist.component.html',
  styleUrls: ['./single-picklist.component.scss']
})
export class SinglePicklistComponent implements OnInit, OnDestroy {
  @Input() field: FormField;
  @Input() value: string;
  @Input() formGroup!: UntypedFormGroup;
  @Input() control: UntypedFormControl;

  searchControl = new UntypedFormControl();
  isLoading = false;
  filter$: Observable<Enum[]>;
  filterGroups$: Observable<Enum[]>;
  groups: any[] = [];
  subscription = new Subscription();
  _filterChildren = (opt: Enum[], value: string): Enum[] => {
    const filterValue = value.toLowerCase();
    return opt.filter(
      item => item.value.toLowerCase().indexOf(filterValue) === 0
    );
  };

  ngOnInit() {
    const keys = Object.keys(this.field.enum[0]);
    if (keys.includes('category')) {
      const groupsData = this.field.enum.reduce((data, item) => {
        if (!data[item.category]) {
          data[item.category] = [];
        }

        data[item.category].push(item);
        return data;
      }, {});
      const keys = Object.keys(groupsData);
      keys.forEach(key => {
        const data: any = {
          name: key,
          children: groupsData[key]
        };
        this.groups.push(data);
      });
    }

    this.filter$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      map(searchText => this._filter(searchText))
    );
    this.filterGroups$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      map(searchText => this._filterGroups(searchText))
    );
    this.searchControl.setValue(this.control?.value);
    const sub = this.control.valueChanges.subscribe(
      value => !value && this.searchControl.reset()
    );
    this.subscription.add(sub);
  }

  private _filter(searchText: string) {
    const fields = searchText
      ? this.field.enum.filter(e => {
          return e.value?.toLowerCase().includes(searchText?.toLowerCase());
        })
      : this.field.enum;

    if (fields && fields.length === 0) this.control.setValue(searchText);
    return fields;
  }

  private _filterGroups(value: string) {
    if (value) {
      return this.groups
        .map(group => ({
          children: this._filterChildren(group.children, value),
          name: group.name
        }))
        .filter(group => group.children.length > 0);
    }

    return this.groups;
  }

  checkData() {
    const data = this.field.enum.map(item => item.key);
    if (!data.includes(this.searchControl.value)) {
      this.searchControl.setValue('');
      this.control.setValue('');
    }
  }

  displayFn(value) {
    let displayValue = '';
    if (value && this.field.enum) {
      const model = this.field.enum.find(x => x.key === value);
      if (model != null) {
        displayValue = model.alias ? model.alias : model.value;
        this.formGroup.get(this.field.key).patchValue(model.key);
      } else {
        this.formGroup.get(this.field.key).patchValue(null);
        displayValue = '';
      }
    }

    return displayValue;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
