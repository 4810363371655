import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, Chart } from 'chart.js';

@Component({
  selector: 'app-chartjs-scatterplot-chart',
  templateUrl: './chartjs-scatterplot-chart.component.html',
  styleUrls: ['./chartjs-scatterplot-chart.component.scss']
})
export class ChartjsScaatterPlotChartComponent implements OnInit {
  

  @Input() chartConfig: any = {};
  chart: any = {};
  // public scatterChartOptions: ChartOptions = {
  //   responsive: true,
  // };

  // public scatterChartData: ChartDataset[] = [
  //   {
  //     data: [
  //       { x: 1, y: 1 },
  //       { x: 2, y: 3 },
  //       { x: 3, y: -2 },
  //       { x: 4, y: 4 },
  //       { x: 5, y: -3, r: 20 },
  //     ],
  //     label: 'Series A',
  //     pointRadius: 10,
  //   },
  // ];
  // public scatterChartType: ChartType = 'scatter';
  ngOnInit() {
    this.initChart();
  }
  initChart() {
    const config: ChartConfiguration = {
      type: 'scatter',
      data: {
        // labels: this.chartConfig.general_options.labels,
        datasets: this.chartConfig.general_options.datasets
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: this.chartConfig.general_options.title
          },
          legend: {
            display: this.chartConfig.diagram_area_legend.showLegend,
            position: this.chartConfig.diagram_area_legend.legendPosition,
            labels: {
              boxWidth: 5,
              font: {
                size: 12
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: {
            display: this.chartConfig.dimension_axis.displayAxis,
            title: {
              display: true,
              text: this.chartConfig.dimension_axis.label
            },
            min: 0,
            max: 30
          },
          yAxes: {
            display: this.chartConfig.primary_axis.displayAxis,
            title: {
              display: true,
              text: this.chartConfig.primary_axis.label
            },
            min: 0,
            max: 30,
            ticks: {
              // lineHeight:  4
            }
          }
        }
      }
    };

    this.chart = new Chart('scatter-canvas', config);
  }
}
