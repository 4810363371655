import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Pipe({
  name: 'emailPlaceholderSwitch'
})
export class EmailPlaceholderSwitchPipe implements PipeTransform {
  transform(placeholder: string, placeholderList: string[]): string {
    const placeholderItem = placeholderList.find(p => p === placeholder);
    if (placeholderItem) {
      return placeholderItem;
    }
    const ctrl = new UntypedFormControl(placeholder, Validators.required);
    if (ctrl.valid) {
      return placeholder;
    }
    return '';
  }
}
