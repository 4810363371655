export const SCSS_VARS = {
  '$brand-primary': '#0000a0',
  '$brand-secondary': '#00c1d4',
  '$brand-black': 'rgb(0,0,0)',
  '$brand-light-white': 'rgba(255,255,255,0.6)',
  '$brand-light-black': 'rgba(0,0,0,0.6)',
  $white: '#fff',
  $black: '#000',
  '$lightest-grey': '#e4e8ee',
  '$green-6': '#090',
  '$error-bg': '#fff0ef',
  '$error-color': 'red',
  '$error-color-2': '#f44336',
  '$error-color-3': '#e36d68',
  '$black-1': '#343733',
  '$success-bg': '#f3ffed',
  '$caribbean-green': '#02c39a'
};
