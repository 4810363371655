import { Component, OnInit, Input } from '@angular/core';
import { GlobalFiltersService } from './../../../core/global-filters/state/global-filters.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-process-explorer-filter',
  templateUrl: './process-explorer-filter.component.html',
  styleUrls: ['./process-explorer-filter.component.scss']
})
export class ProcessExplorerFilterComponent implements OnInit {
  @Input() edgeActivityData: any;
  @Input() processFilters: any;
  @Input() activityFilters: any;
  @Input() type: string;
  @Input() activityTitle: string;

  public filters: any[];
  public existingFilter: any = {};
  public filterValues: any = {};
  public activeBtn = false;
  public buttonText = '';
  public selectedFilter = {};

  constructor(
    public globalFiltersService: GlobalFiltersService,
    public router: Router
  ) {}

  ngOnInit() {
    if (this.processFilters && Object.keys(this.processFilters)) {
      this.filterValues = Object.keys(this.processFilters);
      this.selection('Case with this connection');
      this.buttonText = 'Case with this connection';
    } else {
      this.filterValues = Object.keys(this.activityFilters);
      this.selection('Case with this activity');
      this.buttonText = 'Case with this activity';
    }
  }

  selection(selectedOption) {
    this.activeBtn = true;
    // Activity filter
    if (!this.processFilters) {
      if (
        selectedOption === 'Case with this activity' ||
        selectedOption === 'Case without this activity'
      ) {
        this.selectedFilter = {
          filter: 'activity_filter',
          [this.activityFilters[selectedOption]]: {
            flow: 'all',
            activities: [this.activityTitle]
          }
        };
      } else {
        this.selectedFilter = {
          filter: 'activity_filter',
          [this.activityFilters[selectedOption]]: {
            activities: [this.activityTitle]
          }
        };
      }
    } else {
      this.selectedFilter = {
        filter: 'process_flow_filter',
        start_activity: this.edgeActivityData.startNode.label,
        flow: this.processFilters[selectedOption],
        end_activity: this.edgeActivityData.endNode.label
      };
    }

    // this.existingFilter = {};
    // if (this.processFilters && Object.keys(this.processFilters)) {
    //   const newSelection = {
    //     start_activity: this.edgeActivityData.startNode.label,
    //     flow: this.processFilters[selectedOption],
    //     end_activity: this.edgeActivityData.endNode.label
    //   };
    //   this.existingFilter = JSON.parse(JSON.stringify(this.globalFiltersService.getGlobalFilter()));
    //   if (this.existingFilter.process_flow_selection) {
    //     this.existingFilter.process_flow_selection.push(newSelection);
    //   } else {
    //     this.existingFilter.process_flow_selection = [newSelection];
    //   }
    //   this.processFilters = {};
    // } else {
    //   this.existingFilter = JSON.parse(JSON.stringify(this.globalFiltersService.getGlobalFilter()));
    //   if (!this.existingFilter.activity_selection) {
    //     this.existingFilter.activity_selection = {};
    //   }
    //   if (this.existingFilter.activity_selection
    //     && this.existingFilter.activity_selection[this.activityFilters[selectedOption]]
    //     && this.existingFilter.activity_selection[this.activityFilters[selectedOption]].activities) {
    //     this.existingFilter.activity_selection[this.activityFilters[selectedOption]].activities.push(this.activityTitle);
    //     this.existingFilter.activity_selection[this.activityFilters[selectedOption]].activities =
    //       [...new Set(this.existingFilter.activity_selection[this.activityFilters[selectedOption]].activities)];
    //   } else {
    //     this.existingFilter.activity_selection[this.activityFilters[selectedOption]] = {
    //       flow: 'all',
    //       activities: [this.activityTitle]
    //     };
    //     if (this.activityFilters[selectedOption] === 'cases_starts_with' || this.activityFilters[selectedOption] === 'cases_ends_with') {
    //       delete this.existingFilter.activity_selection[this.activityFilters[selectedOption]].flow;
    //     }
    //   }
    // }
  }

  applyFilter() {
    const filters = { ...this.globalFiltersService.getGlobalFilter() };
    filters.global_filters.push(this.selectedFilter);
    this.globalFiltersService.saveGlobalFilterData(filters);
    this.activeBtn = false;
    const url = this.router.url;
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
}
