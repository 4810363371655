import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProcessAnalyticsQuery } from './../product-features/process-analytics/state/process-analytics.query';

@Injectable({
  providedIn: 'root'
})
export class DetectChangeService {
  private menuSourceType = new BehaviorSubject<any>('ProductFeaturesMenu');
  private menuRefreshed = new BehaviorSubject<any>(false);
  private menuList = new BehaviorSubject<any>(null);
  private navMenuResoponse = new BehaviorSubject<any>(null);
  private isNavBar = new BehaviorSubject<any>(true);
  private isConformance = new BehaviorSubject<any>(null);
  private globalFilter = new BehaviorSubject<any>([]);
  private globalParam = new BehaviorSubject<any>({
    start_date: '2010-10-02',
    end_date: '2012-01-23'
  });
  private isCaseCount = new BehaviorSubject<any>('');
  private isLoadingSelectionBar = new BehaviorSubject<any>(false);
  private isGridSheetConfig = new BehaviorSubject<any>(false);
  private isEnableFilterButton = new BehaviorSubject<any>(false);
  private isSideMenuToggled = new BehaviorSubject<any>(false);

  constructor(private processAnalyticsQuery: ProcessAnalyticsQuery) {}

  updateMenu(menu: string) {
    this.menuList.next(menu);
  }

  getMenu(): Observable<any> {
    return this.menuList.asObservable();
  }

  updateMenuResponse(menuResponse: any) {
    this.navMenuResoponse.next(menuResponse);
  }

  getMenuResponse(): Observable<any> {
    return this.navMenuResoponse.asObservable();
  }

  updateMenuList(menu: any) {
    this.menuSourceType.next(menu);
  }

  updateMenuRefreshed(refreshed: boolean) {
    this.menuRefreshed.next(refreshed);
  }

  getMenuRefrehed(): Observable<boolean> {
    return this.menuRefreshed.asObservable();
  }

  getMenuList(): Observable<any> {
    return this.menuSourceType.asObservable();
  }

  updateConformanceMenu(type: boolean) {
    this.isConformance.next(type);
  }

  getConformanceMenu(): Observable<any> {
    return this.isConformance.asObservable();
  }

  getGlobalFilter(): Observable<any> {
    return this.globalFilter.asObservable();
  }

  setGlobalFilter(globalFilterList: any) {
    this.globalFilter.next(globalFilterList);
  }

  getGlobalParam(): Observable<any> {
    return this.globalParam.asObservable();
  }

  setGlobalParam(globalFilterList: any) {
    this.globalParam.next(globalFilterList);
  }

  detectConformanceKey(): boolean {
    const mineBpmnConformance = this.processAnalyticsQuery
      .GetProcessAnalyticsData
      ? this.processAnalyticsQuery.GetProcessAnalyticsData.isProcessModelExists
      : null;
    return mineBpmnConformance ? mineBpmnConformance.process_model_id : false;
  }

  updateCaseCount(count: any) {
    this.isCaseCount.next(count);
  }

  getCaseCount(): Observable<any> {
    return this.isCaseCount.asObservable();
  }

  enableFilterButton(enable: boolean) {
    this.isEnableFilterButton.next(enable);
  }

  getFilterButtonState(): Observable<any> {
    return this.isEnableFilterButton.asObservable();
  }

  updateSelectionBarLoad(val: boolean) {
    this.isLoadingSelectionBar.next(val);
  }

  getSelectionBarLoad(): Observable<boolean> {
    return this.isLoadingSelectionBar.asObservable();
  }

  updateNavBar(val: boolean) {
    this.isNavBar.next(val);
  }

  getNavBar(): Observable<boolean> {
    return this.isNavBar.asObservable();
  }

  updateGridSheetConfigBar(val: any) {
    this.isGridSheetConfig.next(val);
  }

  getGridSheetConfigBar(): Observable<any> {
    return this.isGridSheetConfig.asObservable();
  }

  updateSideMenuToggled(val: boolean) {
    this.isSideMenuToggled.next(val);
  }

  getSideMenuToggled(): Observable<any> {
    return this.isSideMenuToggled.asObservable();
  }
}
