import {
  Component,
  ElementRef,
  OnInit,
  OnChanges,
  OnDestroy,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import * as d3 from 'd3';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'app-dynamic-bar-chart',
  templateUrl: './dynamic-bar-chart.component.html',
  styleUrls: ['./dynamic-bar-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicBarChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() BarData: Array<any> = [];
  @Input() config: any = {};

  @Output() barSelected = new EventEmitter<string>();
  @Output() barUnSelected = new EventEmitter<string>();

  constructor(private container: ElementRef) {}
  public settings: any = {};
  cardStyle = {};

  ngOnInit() {
    this.initBarChart();
  }

  ngOnChanges(changes) {
    if (changes.config && changes.config.currentValue) {
      this.settings = changes.config.currentValue;
    }
    this.initBarChart();
  }

  ngOnDestroy() {
    d3.select(window).on('resize.bar-chart-wrapper', null);
  }

  initBarChart() {
    d3.select(window).on('resize.bar-chart-wrapper', null);
    const container = d3.select(this.container.nativeElement);
    // const angularThis = this;
    let data = this.BarData ? [...this.BarData] : [];

    const settings = this.settings;

    // Update Card border and color
    if (settings.diagram_area_legend.showBorder) {
      const { borderColor, borderThickness, borderStyle } =
        settings.diagram_area_legend;
      this.cardStyle = {
        'border-color': borderColor ? borderColor : '#ccc',
        'border-width': borderThickness ? borderThickness + 'px' : 0,
        'border-style': borderStyle ? borderStyle : 'solid'
      };
    } else {
      this.cardStyle = {
        'border-width': 0
      };
    }
    // Max bar settings
    if (settings.general_options.maxElements) {
      data = data.splice(0, settings.general_options.maxElements);
    }
    container.select('.bar-chart').html('');
    container.select('.bar-tooltip').remove();

    const { showVertical } = settings.general_options;
    const { legendPosition } = settings.diagram_area_legend || 'topright';
    const { legendOverlay } = settings.diagram_area_legend || true;

    const margin = {
      top: 10,
      right: 10,
      bottom: 20,
      left: 40
    };
    const marginInit = Object.assign({}, margin);

    const width =
      parseInt(container.select('.bar-chart').style('width'), 0) -
      margin.left -
      margin.right;
    const height =
      parseInt(container.select('.bar-chart').style('height'), 0) -
      margin.top -
      margin.bottom;

    const keys = data.length ? Object.keys(data[0]) : [];
    keys.shift();

    const { barDistance } = settings.general_options;
    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.label))
      .range([0, showVertical ? height : width])
      .padding(barDistance ? barDistance / 100 : 0.1);

    const xScaleBar = d3
      .scaleBand()
      .domain(keys)
      .rangeRound([0, xScale.bandwidth()])
      .padding(0.05);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d3.max(keys, key => d[key]))])
      .nice()
      .range([showVertical ? 0 : height, showVertical ? width : 0]);

    const svg = container
      .select('.bar-chart')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('preserveAspectRatio', 'xMinYMid')
      .append('g')
      .attr('class', 'bar-chart-container')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const xAxis = d3[showVertical ? 'axisLeft' : 'axisBottom'](xScale)
      .tickValues(
        xScale.domain().filter((d, i) => {
          if (data.length > 10) {
            return !(i % 10);
          }
          return d;
        })
      )
      .tickFormat(d => {
        return d;
      });

    // Remove x-axis lables
    if (!settings.dimension_axis.displayAxisLabels) {
      xAxis.tickFormat(() => '');
    }

    // Remove x-axis ticklines
    if (!settings.dimension_axis.displayTicklines) {
      xAxis.tickSize(0);
    }

    const xAxisGrid = d3[showVertical ? 'axisLeft' : 'axisBottom'](xScale)
      .tickSize(
        settings.dimension_axis.showGridlines
          ? showVertical
            ? -width
            : -height
          : 0
      )
      .tickFormat(d => '');

    svg
      .append('g')
      .classed('x-axis', true)
      .classed('hide', !settings.dimension_axis.displayAxis)
      .attr('transform', `translate(0, ${showVertical ? 0 : height})`)
      .style('stroke-width', settings.dimension_axis.axisThickness || 1)
      .style(
        'stroke-dasharray',
        settings.dimension_axis.borderStyle === 'dashed' ? '3,3' : '0, 0'
      )
      .call(xAxis);

    d3.selectAll('.x-axis .domain, .x-axis .tick line').style(
      'stroke',
      settings.dimension_axis.axisColor || 'rgba(0, 0, 0, 0.2)'
    );

    svg
      .append('g')
      .classed('x-grid', true)
      .attr('transform', `translate(0, ${showVertical ? 0 : height})`)
      .call(xAxisGrid)
      .style(
        'stroke-dasharray',
        settings.dimension_axis.gridBorderStyle === 'dashed' ? '3,3' : '0, 0'
      );

    d3.selectAll('.x-grid .tick line')
      .style('stroke-width', settings.dimension_axis.gridThickness || 1)
      .style(
        'stroke',
        settings.dimension_axis.gridColor || 'rgba(0, 0, 0, 0.2)'
      );

    const yAxis = d3[showVertical ? 'axisBottom' : 'axisLeft'](yScale).ticks(5);

    // Remove x-axis lables
    if (!settings.primary_axis.displayAxisLabels) {
      yAxis.tickFormat(() => '');
    }

    // Remove x-axis ticklines
    if (!settings.primary_axis.displayTicklines) {
      yAxis.tickSize(0);
    }

    const yAxisGrid = d3[showVertical ? 'axisBottom' : 'axisLeft'](yScale)
      .ticks(5)
      .tickSize(
        settings.primary_axis.showGridlines
          ? showVertical
            ? -height
            : -width
          : 0
      )
      .tickFormat(d => '');

    svg
      .append('g')
      .classed('y-axis', true)
      .classed('hide', !settings.primary_axis.displayAxis)
      .attr('transform', `translate(0, ${showVertical ? height : 0})`)
      .style('stroke-width', settings.primary_axis.axisThickness || 1)
      .style(
        'stroke-dasharray',
        settings.primary_axis.borderStyle === 'dashed' ? '3,3' : '0, 0'
      )
      .call(yAxis);

    d3.selectAll('.y-axis .domain, .y-axis .tick line').style(
      'stroke',
      settings.primary_axis.axisColor || 'rgba(0, 0, 0, 0.2)'
    );

    svg
      .append('g')
      .classed('y-grid', true)
      .attr('transform', `translate(0, ${showVertical ? height : 0})`)
      .call(yAxisGrid)
      .style(
        'stroke-dasharray',
        settings.primary_axis.gridBorderStyle === 'dashed' ? '3,3' : '0, 0'
      );

    d3.selectAll('.y-grid .tick line')
      .style('stroke-width', settings.primary_axis.gridThickness || 1)
      .style('stroke', settings.primary_axis.gridColor || 'rgba(0, 0, 0, 0.2)');

    // Create Bar
    const svgBar = svg
      .append('g')
      .classed('bar-g', true)
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .classed('bar', true)
      .attr('transform', d => {
        return `translate(${showVertical ? 0 : xScale(d.label)}, ${
          showVertical ? xScale(d.label) : 0
        })`;
      })
      .selectAll('rect')
      .data(d => keys.map(key => ({ key, value: d[key] })))
      .enter()
      .append('rect')
      .style('fill', d => this.setBarColor(d.key))
      .attr('x', d => {
        return showVertical ? 0 : xScaleBar(d.key);
      })
      .attr('y', (d, index) => {
        if (showVertical) {
          return xScaleBar(d.key);
        } else {
          return yScale(d.value);
        }
      })
      .attr('width', d => {
        if (showVertical) {
          if (width > 0) {
            return yScale(d.value);
          }
          return 0;
        } else {
          return xScaleBar.bandwidth();
        }
      })
      .attr('height', d => {
        if (showVertical) {
          return xScaleBar.bandwidth();
        } else {
          if (height > 0) {
            return height - yScale(d.value);
          }
          return 0;
        }
      });

    if (settings.general_options.showTooltip) {
      svgBar.on('mouseover', barMouseover);
      svgBar.on('mousemove', barMousemove);
      svgBar.on('mouseout', barMouseout);
    }
    if (!settings.general_options.disableSelection) {
      svgBar.on('click', barClick);
    }

    // Create Legend
    const legendData = Object.keys(data.length ? data[0] : []);
    if (legendData.includes('label')) {
      legendData.splice(legendData.indexOf('label'), 1);
    }

    const legend = svg
      .append('g')
      .classed('legend', true)
      .classed('hide', !settings.diagram_area_legend.showLegend);

    const legendBG = legend.append('rect').classed('legend-box', true);

    // const legendItems = legend.append('g').classed('legend-items', true);

    const legendItems = legend
      .selectAll('g')
      .data(legendData)
      .enter()
      .append('g')
      .classed('legend-items', true)
      .classed('hide', data.length ? false : true);

    legendItems
      .append('circle')
      .attr('r', 4)
      .attr('cy', 6)
      .style('fill', (d, i) => this.setBarColor(d));

    legendItems
      .append('text')
      .attr('x', 8)
      .attr('y', 10)
      .text((d: any) => d);

    const getNodeBB = d => d.getBBox();
    const isHorizontal = () =>
      legendPosition === 'topcenter' || legendPosition === 'bottomcenter'
        ? true
        : false;

    let offset = 0;
    legendItems.attr('transform', function (d, i) {
      const off = offset;
      offset += getNodeBB(this)[isHorizontal() ? 'width' : 'height'] + 10;
      return `translate(${isHorizontal() ? off : 0}, ${
        isHorizontal() ? 0 : off
      })`;
    });

    legend.attr('transform', `translate(${getLegendPosition(width, height)})`);

    // tooltips
    const tooltip = container
      .select('.bar-chart-wrapper')
      .append('div')
      .attr('class', 'bar-tooltip')
      .classed('hide', true);

    function barClick() {
      const slectedBarData: any = d3.select(this).data()[0];
      if (!d3.select(this).classed('selected')) {
        // Add Filter logic
        svg.classed('selected', true);
        container.selectAll('.bar').classed('selected', false);
        d3.select(this).classed('selected', true);
        this.barSelected.next(slectedBarData);
      } else {
        // Remove Filter logic
        svg.classed('selected', false);
        container.selectAll('.bar').classed('selected', false);
        this.barUnSelected.next(slectedBarData);
      }
    }

    function barMouseover() {
      tooltip.classed('show', true).classed('hide', false);
    }

    function barMousemove() {
      const barBBox = d3.select(this).node().getBBox();
      const svgBBox = svg.node().getBBox();
      const d: any = d3.select(this).data()[0];

      const gTransString = d3.select(this.parentNode).attr('transform');
      const gTranslate = gTransString
        .substring(gTransString.indexOf('(') + 1, gTransString.indexOf(')'))
        .split(',');
      barBBox.x = parseInt(gTranslate[0], 0) + barBBox.x;
      barBBox.y = parseInt(gTranslate[1], 0) + barBBox.y;

      tooltip
        .html(
          `<span class='label'>${d.key}</span><hr/> <span class='count'>${d.value}</span>`
        )
        .style(
          'left',
          `${
            showVertical
              ? barBBox.x + barBBox.width / 2
              : barBBox.x + barBBox.width / 2
          }px`
        )
        .style(
          'top',
          `${
            showVertical
              ? barBBox.y - barBBox.height
              : svgBBox.height - barBBox.height - 34
          }px`
        );
    }

    function barMouseout() {
      tooltip.classed('show', false).classed('hide', true);
    }

    // Define responsive behavior
    function resizeBarChart() {
      const nWidth =
        parseInt(container.select('.bar-chart').style('width'), 0) -
        margin.left -
        margin.right;
      const nHeight =
        parseInt(container.select('.bar-chart').style('height'), 0) -
        margin.top -
        margin.bottom;

      if (!nWidth || !nHeight) {
        return;
      }

      // Set legent position.
      legend.attr(
        'transform',
        `translate(${getLegendPosition(nWidth, nHeight)})`
      );

      // tslint:disable-next-line: no-shadowed-variable
      const { barDistance } = settings.general_options;
      xScale
        .range([0, showVertical ? nHeight : nWidth])
        .padding(barDistance ? barDistance / 100 : 0.1);

      // Update the axis and text with the new scale
      xAxisGrid.tickSize(
        settings.dimension_axis.showGridlines
          ? showVertical
            ? -nWidth
            : -nHeight
          : 0
      );
      svg
        .select('.x-axis')
        .attr('transform', `translate(0, ${showVertical ? 0 : nHeight})`)
        .call(xAxis);

      svg
        .select('.x-grid')
        .call(xAxisGrid)
        .attr('transform', `translate(0, ${showVertical ? 0 : nHeight})`)
        .style(
          'stroke-dasharray',
          settings.dimension_axis.gridBorderStyle === 'dashed' ? '3,3' : '0, 0'
        );

      d3.selectAll('.x-grid .tick line')
        .style('stroke-width', settings.dimension_axis.gridThickness || 1)
        .style(
          'stroke',
          settings.dimension_axis.gridColor || 'rgba(0, 0, 0, 0.2)'
        );

      // Y-Axis settings
      yScale.range([showVertical ? 0 : nHeight, showVertical ? nWidth : 0]);
      svg
        .select('.y-axis')
        .attr('transform', `translate(0, ${showVertical ? nHeight : 0})`)
        .call(yAxis);

      yAxisGrid.tickSize(
        settings.primary_axis.showGridlines
          ? showVertical
            ? -nHeight
            : -nWidth
          : 0
      );

      svg
        .select('.y-grid')
        .attr('transform', `translate(0, ${showVertical ? nHeight : 0})`)
        .call(yAxisGrid)
        .style(
          'stroke-dasharray',
          settings.primary_axis.gridBorderStyle === 'dashed' ? '3,3' : '0, 0'
        );

      d3.selectAll('.y-grid .tick line')
        .style('stroke-width', settings.primary_axis.gridThickness || 1)
        .style(
          'stroke',
          settings.primary_axis.gridColor || 'rgba(0, 0, 0, 0.2)'
        );

      svg.selectAll('.bar-g .bar').attr('transform', (d: any) => {
        return `translate(${showVertical ? 0 : xScale(d.label)}, ${
          showVertical ? xScale(d.label) : 0
        })`;
      });

      xScaleBar.domain(keys).rangeRound([0, xScale.bandwidth()]);

      svg
        .selectAll('.bar-g .bar rect')
        .attr('x', (d: any) => {
          return showVertical ? 0 : xScaleBar(d.key);
        })
        .attr('y', (d: any, index) => {
          if (showVertical) {
            return xScaleBar(d.key);
          } else {
            return yScale(d.value);
          }
        })
        .attr('width', (d: any) => {
          if (showVertical) {
            return yScale(d.value);
          } else {
            return xScaleBar.bandwidth();
          }
        })
        .attr('height', (d: any) => {
          if (showVertical) {
            return xScaleBar.bandwidth();
          } else {
            return nHeight - yScale(d.value);
          }
        });
    }

    // tslint:disable-next-line: variable-name
    function getLegendPosition(_width, _height) {
      legendBG.attr('width', 0);
      const LB = legend.node().getBBox();
      let legX = 0;
      let legY = 0;
      if (LB.width && LB.height) {
        legendBG
          .attr('width', LB.width + 10)
          .attr('height', LB.height + 15)
          .attr('x', -10)
          .attr('y', -10);
        switch (legendPosition) {
          case 'topleft':
            legX = 0;
            legY = 0;
            if (!legendOverlay) {
              margin.left = LB.width + (marginInit.left + 10);
              legX = legX - (LB.width + marginInit.left);
            }
            break;
          case 'topcenter':
            legX = _width / 2 - LB.width / 2;
            legY = 0;
            if (!legendOverlay) {
              margin.top = LB.height + (marginInit.top + 10);
              legY = legY - (LB.height + marginInit.top);
            }
            break;
          case 'topright':
            legX = _width - LB.width;
            legY = 0;
            if (!legendOverlay) {
              margin.right = LB.width + (marginInit.right + 10);
              legX = legX + (LB.width + marginInit.right + 10);
            }
            break;
          case 'middleleft':
            legX = 0;
            legY = _height / 2 - LB.height / 2;
            if (!legendOverlay) {
              margin.left = LB.width + (marginInit.left + 10);
              legX = legX - (LB.width + marginInit.left);
            }
            break;
          case 'middleright':
            legX = _width - LB.width;
            legY = _height / 2 - LB.height / 2;
            if (!legendOverlay) {
              margin.right = LB.width + (marginInit.right + 10);
              legX = legX + (LB.width + marginInit.right + 10);
            }
            break;
          case 'bottomleft':
            legX = 0;
            legY = _height - LB.height;
            if (!legendOverlay) {
              margin.left = LB.width + (marginInit.left + 10);
              legX = legX - (LB.width + marginInit.left);
            }
            break;
          case 'bottomcenter':
            legX = _width / 2 - LB.width / 2;
            legY = _height - LB.height;
            if (!legendOverlay) {
              margin.bottom = LB.height + (marginInit.bottom + 20);
              legY = legY + (LB.height + marginInit.bottom + 15);
            }
            break;
          case 'bottomright':
            legX = _width - LB.width;
            legY = _height - LB.height;
            if (!legendOverlay) {
              margin.right = LB.width + (marginInit.right + 10);
              legX = legX + (LB.width + marginInit.right + 10);
            }
            break;
        }
      }
      // Set svg position.
      svg.attr(
        'transform',
        'translate(' + margin.left + ',' + margin.top + ')'
      );
      return `${legX}, ${legY}`;
    }

    // d3.select(window).on('resize.bar-chart-wrapper', resizeBarChart);
    // When resize happens with window resize. (Ex. When clicks collapse and exapnd navigation)
    // Debounce Method - Brush will reset when window resize stoped.
    let resizeTimer;
    const observer = new ResizeObserver(entries => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        resizeBarChart();
      }, 100);
      entries.forEach(entry => {});
    });
    const wrapper: any = container.select('.bar-chart-wrapper').node();
    observer.observe(wrapper);
  }

  setBarColor(key) {
    return (
      this.config.data_series[key].backgroundColor || 'rgba(7, 7, 162, 0.71)'
    );
  }
}
