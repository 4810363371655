import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInputEvent } from '@angular/material/chips';
import { FormField } from '@bli/state/app.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-email-placeholder-foaster',
  templateUrl: './email-placeholder-foaster.component.html',
  styleUrls: ['./email-placeholder-foaster.component.scss']
})
export class EmailPlaceholderFoasterComponent implements OnInit {
  @Input() placeholderCtrl: UntypedFormControl;
  @Input() field: FormField;
  @ViewChild('placeholderInput', { static: true })
  placeholderInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('chipList', { static: true }) chipList: MatChipGrid;

  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  chipControl: UntypedFormControl = new UntypedFormControl('');
  placeholders: string[] = [];
  selectedPlaceholders: string[] = [];
  filteredPlaceholder$: Observable<string[]>;
  ngOnInit(): void {
    this.placeholders = this.field.placeholder
      ? this.field.placeholder.map(p => p.name)
      : [];
    this.filteredPlaceholder$ = this.chipControl.valueChanges.pipe(
      map(placeholder => {
        if (this.chipControl.touched && this.chipControl.invalid) {
          this.chipList.errorState = true;
        } else {
          this.chipList.errorState = false;
        }
        return this._filter(placeholder || '');
      })
    );

    this.selectedPlaceholders = this.placeholderCtrl.value
      ? this.placeholderCtrl.value.split(',')
      : [];
    this._addValidation();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const items = this.placeholders
      .filter(placeholder => !this.selectedPlaceholders.includes(placeholder))
      .filter(placeholder => placeholder.indexOf(filterValue) === 0);
    return items;
  }

  private _addValidation(): void {
    if (this.field.mandatory && !this.selectedPlaceholders.length) {
      this.chipControl.setValidators([Validators.required]);
    } else {
      this.chipControl.clearValidators();
    }
  }

  private _validatePlaceholder(placeholder: string): void {
    if (this.selectedPlaceholders.includes(placeholder)) {
      return;
    }
    const ctrl = new UntypedFormControl(placeholder, []);
    if (this.placeholders.map(p => p).includes(placeholder) || ctrl.valid) {
      this.selectedPlaceholders.push(placeholder);
      this._setPlaceholderList();
    }
  }

  private _setPlaceholderList(): void {
    const selectedPlaceholders = this.selectedPlaceholders.join(',');
    this.placeholderCtrl.setValue(selectedPlaceholders);
    this._addValidation();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this._validatePlaceholder(event.option.viewValue);
    this.placeholderInput.nativeElement.value = '';
    this.chipControl.setValue(null);
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;
    if ((value || '').trim()) {
      this._validatePlaceholder(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.chipControl.setValue(null);
  }

  remove(email: string): void {
    const index = this.selectedPlaceholders.indexOf(email);
    if (index >= 0) {
      this.selectedPlaceholders.splice(index, 1);
      this._setPlaceholderList();
    }
  }
}
