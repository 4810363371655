import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Store } from '@datorama/akita';

import { AppConfig } from '@bli-shared/utils/app-config.module';
import {
  DropdownDetailsPayload,
  DropdownDetailsResponse,
  DropDownAttrPayload,
  DropDownAttrResponse
} from '../modals/drop-down';

@Injectable({
  providedIn: 'root'
})
export class DropDownService {
  private config: AppConfig;
  private httpClient: HttpClient;
  private store: Store;
  constructor(config: AppConfig, http: HttpClient, store: Store) {
    this.config = config;
    this.httpClient = http;
    this.store = store;
  }

  getDropdownDetails(
    payload: DropdownDetailsPayload
  ): Observable<DropdownDetailsResponse> {
    const url = `${this.primaryUrl}${this.dropdownUrl.DROPDOWN_DETAILS}`;
    return this.httpClient.post<DropdownDetailsResponse>(url, payload).pipe(
      tap(() => {
        this.store.setLoading(false);
      }),
      catchError(err => {
        this.store.setLoading(false);
        return throwError(err);
      })
    );
  }

  getDropdownAttributes(payload: DropDownAttrPayload) {
    const url = `${this.primaryUrl}${this.dropdownUrl.DROPDOWN_ATTRS}`;
    return this.httpClient.post<DropDownAttrResponse>(url, payload).pipe(
      tap(() => {
        this.store.setLoading(false);
      }),
      catchError(err => {
        this.store.setLoading(false);
        return throwError(err);
      })
    );
  }

  private get dropdownUrl() {
    return this.config.INTELLECT_ENGINE.DROPDOWN;
  }

  private get primaryUrl() {
    return `${this.config.OPERATIONAL_INTELLECT.ROOT}/`;
  }
}
