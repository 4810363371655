import {
  Component,
  OnInit,
  OnChanges,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { GlobalFiltersService } from '@bli/core/global-filters/state';
import * as d3 from 'd3';
import { graphviz } from 'd3-graphviz';
import { Router } from '@angular/router';

// import * as d3 from 'd3';
// import * as d3g from 'd3-graphviz';
// const _ = d3g.graphviz;

@Component({
  selector: 'app-process-explorer-chart',
  templateUrl: './process-explorer-chart.component.html',
  styleUrls: ['./process-explorer-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessExplorerChartComponent implements OnInit, OnChanges {
  @Input() processGraphData: string;
  @Input() graphStats: any;
  @Input() statFormats: Array<any>;
  @Input() isLoading = true;
  @Input() hideStatsSelection = false;
  @Input() clearSelection: boolean;
  @Input() selectNode: string;
  @Input() graphErrorMessage: string;
  @Input() nodeEdgeClickable: boolean;
  @Input() highlightsNodes: any = [];
  @Input() highlightsEdges: any = [];

  @Output() processTypeChanged = new EventEmitter<string>();
  @Output() nodeSelected = new EventEmitter<any>();
  @Output() edgeSelected = new EventEmitter<any>();
  @Output() unSelected = new EventEmitter<any>();

  public isGraphDataLoading = true;
  public graphInstance: any;
  public weightRange = [1, 5];
  public hiddenActivities = [];
  public dynamicId = Date.now();

  constructor(
    private globalFiltersService: GlobalFiltersService,
    private router: Router,
    private container: ElementRef
  ) {}

  ngOnInit() {
    const globalFilters = {
      ...this.globalFiltersService.getGlobalFilter().global_filters
    };
    this.hiddenActivities = [];
    if (globalFilters) {
      Object.keys(globalFilters).map((key, index) => {
        if (globalFilters[key].filter === 'hidden_activities_filter') {
          this.hiddenActivities = [
            ...this.hiddenActivities,
            ...globalFilters[key].hidden_activities
          ];
        }
      });
    }
    this.isGraphDataLoading = this.isLoading;
    this.renderGraph(this.processGraphData);
  }

  ngOnChanges(changes) {
    if (changes && changes.isLoading) {
      this.isGraphDataLoading = changes.isLoading.currentValue;
    }
    if (changes && changes.processGraphData) {
      const processGraphData =
        changes.processGraphData && changes.processGraphData.currentValue
          ? changes.processGraphData.currentValue
          : '';
      this.renderGraph(processGraphData);
    }
    if (changes.selectNode) {
      this.selectNodeByKey(changes.selectNode.currentValue);
    }
    if (this.clearSelection) {
      this.removeAllSelectedClass();
    }
  }

  ProcessTypeChanged(statFormat) {
    this.statFormats.map(obj => (obj.selected = false));
    statFormat.selected = true;
    this.processTypeChanged.next(statFormat.value);
  }

  renderGraph(processGraphData) {
    if (!processGraphData) {
      this.graphInstance = '';
      d3.selectAll(`#process-explorer-chart-${this.dynamicId}`).html('');
      return;
    }
    this.isGraphDataLoading = true;
    this.graphInstance = graphviz(`#process-explorer-chart-${this.dynamicId}`)
      .zoom(true)
      .zoomScaleExtent([0.1, 20])
      .onerror(error => {})
      .totalMemory(120 * 1024 * 1024)
      .renderDot(processGraphData, this.updateNodesAndEdges.bind(this));
    // d3.select(`#process-explorer-chart-${this.dynamicId}`).graphviz().renderDot(this.processGraphData, this.updateNodesAndEdges.bind(this));
  }

  updateNodesAndEdges() {
    const that = this;
    const container = d3.select(this.container.nativeElement);
    const svg = d3
      .select(`#process-explorer-chart-${this.dynamicId}`)
      .select('svg');
    const nodes = container.selectAll('.node');
    const edges = container.selectAll('.edge');
    const nodeLabels = [];
    svg.attr('width', '100%').attr('height', '100%');

    const graph = svg.select('#graph0');
    // Remove Graph title.
    graph.select('title').remove();

    // const edgeLayer = graph.append('g').attr('class', 'edge-layer');
    // const nodeLayer = graph.append('g').attr('class', 'node-layer');

    /* Node Processing. */
    nodes.each((d, i, n) => {
      const nthis = n[i];
      /* Replacing poygon and ellipse with rect. */
      const node: any = d3.select(nthis);
      const el: any = node.select('polygon, ellipse').node();
      const nodeKey = node.select('title').text();
      if (el) {
        const elBox = el.getBBox();

        node.attr('transform', `translate(${elBox.x}, ${elBox.y})`);

        const rect = node
          .insert('rect', 'text')
          .attr('width', elBox.width)
          .attr('height', elBox.height)
          .attr('rx', elBox.height / 2)
          .attr('class', 'process-rect');

        const nodeGCircles = node
          .append('g')
          .attr('class', `process-circle`)
          .attr('transform', `translate(18, 18)`);

        nodeGCircles.append('circle').attr('r', 12);

        nodeGCircles.append('circle').attr('r', 8);

        /* Adding key and lebel to the node. */
        const nodeData = node.select('text').text().split(' (');
        const nodeLabel = nodeData[0];
        const nodeValue = nodeData[1] ? nodeData[1].split(')')[0] : '';
        nodeLabels[nodeKey] = {
          label: nodeLabel,
          value: nodeValue,
          key: nodeKey
        };
        node.attr('node-key', nodeKey);
        node.attr('node-label', nodeLabel);
        node.select('title').text(nodeLabel);

        node
          .select('text')
          .attr('text-anchor', 'left')
          .attr('x', 40)
          .attr('y', 15)
          .text(nodeLabel);

        if (nodeLabel === '@@S') {
          node.select('text').text('Start');
        }
        if (nodeLabel === '@@E') {
          node.select('text').text('End');
        }

        // tslint:disable-next-line: radix
        const formatedValue = nodeValue
          ? parseInt(nodeValue).toLocaleString()
          : '';
        node.append('text').attr('x', 40).attr('y', 29).text(formatedValue);

        el.remove();
      }

      // nodeLayer.append(() => {
      //   return node.node();
      // });
    });
    /* Edge Processing. */
    const maxCases =
      that.graphStats && that.graphStats.max ? that.graphStats.max : 1;
    const minCases =
      that.graphStats && that.graphStats.min ? that.graphStats.min : 1;
    const threshold =
      that.graphStats &&
      Object.prototype.hasOwnProperty.call(that.graphStats, 'threshold');

    edges.each((d, i, n) => {
      const ethis = n[i];
      const edge: any = d3.select(ethis);
      /* Adding label and path weight to the each node. */
      const edgeStartEnd = edge.select('title').text().split('->');
      const edgeLabel = edge.select('text').text().split(' (')[0];
      edge.attr('edge-label', edgeLabel);
      edge.attr('edge-start', edgeStartEnd[0]);
      edge.attr('edge-end', edgeStartEnd[1]);
      edge
        .select('title')
        .text(
          `${nodeLabels[edgeStartEnd[0]].label} - > ${
            nodeLabels[edgeStartEnd[1]].label
          }`
        );

      const edgeText = edge.select('text');
      const edgeValueInFontAttr = edge.select('text').attr('font-family');
      let edgeTextValue = edgeText.text();
      const isTimeValue = this.isNumeric(edgeValueInFontAttr);
      let timeFormatedValue = '';
      if (isTimeValue) {
        timeFormatedValue = edgeTextValue;
        edgeTextValue = edgeValueInFontAttr;
      }
      const edgeTextBox = edgeText.node().getBBox();
      edgeTextBox.x = edgeTextBox.x - edgeTextBox.width / 2;

      const edgePath = edge.select('path');
      let formatedValue = '';

      // Calculate Line weight
      let lineWeight = 1;
      let edgeSuffix = '';
      if (maxCases) {
        const caseCount = parseFloat(edgeTextValue);
        lineWeight =
          ((caseCount - minCases) / (maxCases - minCases)) *
            (this.weightRange[1] - this.weightRange[0]) +
          this.weightRange[0];

        lineWeight =
          lineWeight > this.weightRange[1] ? this.weightRange[1] : lineWeight;

        formatedValue = edgeTextValue
          ? parseInt(edgeTextValue, 0).toLocaleString()
          : '';
        const edgeValArr = edgeTextValue.split(' ');
        if (edgeValArr.length > 1) {
          edgeSuffix = edgeValArr[1];
        }
      }

      // Calculate Threshold
      if (threshold) {
        const thresholdVal = edgeTextValue.split(' ')[0];
        formatedValue = edgeTextValue;
        edge.classed('threshold', thresholdVal > threshold);
      }

      edgePath.attr('stroke-width', lineWeight);

      edgeText
        .attr('x', edgeTextBox.x - 1)
        .attr('text-anchor', 'left')
        .attr('class', edgeTextValue)
        .text(
          `${isTimeValue ? timeFormatedValue : formatedValue} ${edgeSuffix}`
        );

      edge
        .insert('rect', 'text')
        .attr('width', edgeTextBox.width + 10)
        .attr('height', '15px')
        .attr('rx', '7px')
        .attr('x', edgeTextBox.x - 6)
        .attr('y', edgeTextBox.y - 1)
        .attr('class', `edge-label-backdrop ${edgeTextValue}`);

      // edgeLayer.append(() => {
      //   return edge.node();
      // });
    });

    if (this.nodeEdgeClickable) {
      that.addClickEventsForNodeEdge(that, svg, nodes, edges, nodeLabels);
    }
    this.isGraphDataLoading = false;
    this.resetToCenter();

    this.highlightEdges(true);
    setTimeout(() => {
      this.highlightEdges(false);
    }, 1500);

    this.highlightNodes(true);
    setTimeout(() => {
      this.highlightNodes(false);
    }, 1500);
  }

  isNumeric = (num: any) =>
    (typeof num === 'number' ||
      (typeof num === 'string' && num.trim() !== '')) &&
    !isNaN(num as number);

  highlightNodes(isHighlight) {
    const container = d3.select(this.container.nativeElement);
    this.highlightsNodes.map(node => {
      container
        .select('.graph')
        .selectAll(`[node-key='${node}']`)
        .classed('node-highlighted', isHighlight);
    });
  }

  highlightEdges(isHighlight) {
    const container = d3.select(this.container.nativeElement);
    this.highlightsEdges.map(edge => {
      const edgeLabel = edge.split(' -> ');
      container
        .select('.graph')
        .selectAll(`[edge-start='${edgeLabel[0]}'][edge-end='${edgeLabel[1]}']`)
        .classed('highlighted', isHighlight);
    });
  }

  addClickEventsForNodeEdge(that, svg, nodes, edges, nodeLabels) {
    const container = d3.select(this.container.nativeElement);
    nodes.on('click', function () {
      that.preventingDefault();
      const nodeKey = d3.select(this).attr('node-key');
      const nodeLabel = d3.select(this).attr('node-label');

      that.removeAllSelectedClass();

      container.select('.graph').classed('selected', true);
      d3.select(this).classed('selected', true);

      container
        .select('.graph')
        .selectAll(`[edge-end='${nodeKey}']`)
        .classed('node-selected selected', true);
      container
        .select('.graph')
        .selectAll(`[edge-start='${nodeKey}']`)
        .classed('node-selected selected', true);

      that.nodeSelected.next({ key: nodeKey, label: nodeLabel });
    });

    edges.on('click', function (e) {
      that.preventingDefault();
      const edgeLabel = d3.select(this).attr('edge-label');
      const edgeStart = d3.select(this).attr('edge-start');
      const edgeEnd = d3.select(this).attr('edge-end');

      that.removeAllSelectedClass();

      container.select('.graph').classed('selected', true);
      d3.select(this).classed('selected', true);

      container
        .select('.graph')
        .selectAll(`[node-key='${edgeStart}']`)
        .classed('edge-selected selected', true);
      container
        .select('.graph')
        .selectAll(`[node-key='${edgeEnd}']`)
        .classed('edge-selected selected', true);

      that.edgeSelected.next({
        startNode: nodeLabels[edgeStart],
        endNode: nodeLabels[edgeEnd]
      });
    });

    svg.on('click', () => {
      that.removeAllSelectedClass();
      this.unSelected.next(true);
    });
  }

  resetToCenter() {
    const svg = d3
      .select(`#process-explorer-chart-${this.dynamicId}`)
      .select('svg');
    const svgBB = svg.attr('viewBox').split(' ');
    if (this.graphInstance) {
      this.graphInstance
        .zoomBehavior()
        .transform(
          this.graphInstance.zoomSelection().transition().duration(300),
          d3.zoomIdentity.translate(4, parseFloat(svgBB[3])).scale(1)
        );
    }
  }

  graphZoomInOut(zoomOpt) {
    if (zoomOpt === 'reset') {
      this.resetToCenter();
      // this.graphInstance.resetZoom(d3.transition().duration(300));
    } else {
      this.graphInstance
        .zoomBehavior()
        .scaleBy(
          this.graphInstance.zoomSelection().transition().duration(300),
          zoomOpt === 'in' ? 1.3 : 1 / 1.3
        );
    }
  }

  preventingDefault() {
    const event = d3.event;
    event.preventDefault();
    event.stopPropagation();
  }

  setElementsOpacity(element, opacity = 1, timeout = 300) {
    d3.selectAll(element)
      .transition()
      .duration(timeout)
      .style('opacity', opacity);
  }

  setElementOpacity(element, opacity = 1, timeout = 300) {
    d3.select(element).transition().duration(timeout).style('opacity', opacity);
  }

  selectNodeByKey(nodeKey) {
    const container = d3.select(this.container.nativeElement);
    this.removeAllSelectedClass();
    container.select('.graph').classed('selected', true);
    container
      .select('.graph')
      .selectAll(`[node-key='${nodeKey}']`)
      .classed('selected', true);
    container
      .select('.graph')
      .selectAll(`[edge-end='${nodeKey}']`)
      .classed('node-selected selected', true);
    container
      .select('.graph')
      .selectAll(`[edge-start='${nodeKey}']`)
      .classed('node-selected selected', true);
  }

  removeAllSelectedClass() {
    const container = d3.select(this.container.nativeElement);
    container.select('.graph.selected').classed('selected', false);
    container
      .selectAll('.node.selected')
      .classed('edge-selected selected', false);
    container
      .selectAll('.edge.selected')
      .classed('node-selected selected', false);
  }

  onApplyHideActivity(event) {
    if (event && event.length) {
      const hiddenActFilter = {
        filter: 'hidden_activities_filter',
        hidden_activities: event
      };
      const filters = { ...this.globalFiltersService.getGlobalFilter() };
      filters.global_filters.push(hiddenActFilter);
      this.globalFiltersService.saveGlobalFilterData(filters);
      this.redirectTo(this.router.url);
    }
  }

  redirectTo(uri) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
}
