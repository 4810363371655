import { Injectable } from '@angular/core';
import { SnackBarStore } from './snack-bar.store';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  snackBarDismissAction: Subject<any> = new Subject();
  constructor(private snackBarStore: SnackBarStore) {}

  resetStore(): void {
    this.snackBarStore.reset();
  }

  updateActionClick(actionValue: boolean): void {
    this.snackBarStore.update(state => {
      return {
        actionClicked: actionValue
      };
    });
  }
}
